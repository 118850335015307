import React, { useContext } from "react";
import PropTypes from "prop-types";
import PureModal from "components/ui/modal/pure-modal";
import PatientPhysicianForm from "components/physician-search/patient-physician-form";
import { aLink } from "../../utils/styles";
import FormikField from "components/forms/formik-field";
import {
  getEquipmentQuestions,
  sawPhysicianOptions,
  papIssuesOptions,
  quantityOnHandOptions
} from "./options";
import PhoneNumberField from "components/forms/phone-number-field";
import capitalize from "lodash/capitalize";
import RadioGroupField from "components/forms/radio-group-field";
import { getIsValidDysfunctionAnswers } from "./validation";
import { Field } from "formik";
import { STATES } from "utils/enum";
import {
  isRequired,
  isValidPhone,
  isValidZip,
  normalizePhone
} from "utils/redux-form";
import PatientLmnsOnFile from "features/bonafide/components/order-form/patient-lmns";
import { propOr, uniq } from "ramda";
import { MdInfo, MdSearch } from "react-icons/lib/md";
import { PatientProfileContext } from "components/profile/context";
import InsuranceForm from "./insurance";

const AdditionalInfo = ({
  values,
  setFieldValue,
  physician,
  usesBonafideApi,
  insuranceOptions,
  patientInsurances
}) => {
  const { company_order_settings } = useContext(PatientProfileContext);
  const validDysfunctions = getIsValidDysfunctionAnswers(values);
  React.useEffect(() => {
    if (values.changed_physician === false) {
      setFieldValue("physician", {
        ...physician,
        phone: normalizePhone(propOr("", "phone", physician))
      });
    }
  }, [values.changed_physician]);

  React.useEffect(() => {
    if (values.issue_type === "") {
      setFieldValue("issues_with_pap_device", false);
    } else setFieldValue("issues_with_pap_device", true);
  }, [values.issue_type]);

  React.useEffect(() => {
    //Make sure to send null if we don't actually ask the question
    if (company_order_settings.quantity_on_hand === false)
      setFieldValue("quantity_on_hand", null);
  }, [company_order_settings.quantity_on_hand]);

  return (
    <div>
      {company_order_settings.saw_physician_last && (
        <FormikField
          name="saw_physician_last"
          component="select"
          label={
            <div
              style={{ color: !values.saw_physician_last ? "red" : "unset" }}
            >
              I saw my sleep physician in the last:
            </div>
          }
          validate={isRequired}
        >
          {sawPhysicianOptions.map(({ text, value }) => (
            <option key={value} value={value}>
              {text}
            </option>
          ))}
        </FormikField>
      )}

      {values.saw_physician_last === "12 or more months" && (
        <div className="order-form-warning">
          There will be a delay in supplies if a new order is needed and the
          patient must go in for a follow-up.
        </div>
      )}
      {company_order_settings.updated_physician && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto auto",
            gridGap: 10,
            alignItems: "center"
          }}
        >
          <Field
            component={RadioGroupField}
            name="changed_physician"
            label="Updated physician information."
            value={values.changed_physician}
            options={[
              { value: true, text: "Yes" },
              { value: false, text: "No" }
            ]}
          />
          <PureModal
            renderTrigger={({ openModal }) => (
              <span
                onClick={openModal}
                style={
                  values.changed_physician
                    ? aLink
                    : { pointerEvents: "none", color: "#dadada" }
                }
              >
                <MdSearch /> Lookup Physician
              </span>
            )}
            renderContent={({ closeModal }) => (
              <PatientPhysicianForm
                physician={values.physician}
                updatePatientPhysician={values => {
                  setFieldValue("physician", values);
                  closeModal();
                }}
              />
            )}
          />
          {usesBonafideApi && (
            <PureModal
              renderTrigger={({ openModal }) => (
                <span onClick={openModal} style={aLink}>
                  <MdInfo /> LMNs On File
                </span>
              )}
              renderContent={() => (
                <PatientLmnsOnFile
                  handlePhysicianSelect={npi => {
                    setFieldValue("changed_physician", true);
                    setFieldValue("physician", {
                      npi,
                      name: "",
                      address: {
                        street_address: "",
                        zip: "",
                        state: "",
                        city: ""
                      },
                      fax: "",
                      phone: ""
                    });
                  }}
                />
              )}
            />
          )}
        </div>
      )}

      {company_order_settings.updated_physician && (
        <div className="order-form-section">
          {values.changed_physician && (
            <div className="order-form-advice">
              Please enter as much information as you can.
            </div>
          )}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: 10
            }}
          >
            <FormikField
              name="physician.name"
              label="My current sleep physician is"
              type="text"
              disabled={!values.changed_physician}
            />
            <FormikField
              name="physician.npi"
              label="NPI #"
              type="text"
              disabled={!values.changed_physician}
            />
          </div>
          <span>
            <FormikField
              label="Street Address"
              name="physician.address.street_address"
              type="text"
              disabled={!values.changed_physician}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridGap: 10
              }}
            >
              <FormikField
                label="City"
                name="physician.address.city"
                type="text"
                disabled={!values.changed_physician}
              />
              <FormikField
                label="State"
                name="physician.address.state"
                component="select"
                disabled={!values.changed_physician}
              >
                <option />
                {STATES.map(({ value, text }) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </FormikField>
              <FormikField
                label="Zip"
                name="physician.address.zip"
                type="text"
                disabled={!values.changed_physician}
                validate={isValidZip}
              />
            </div>
            <FormikField
              name="physician.phone"
              label="Phone Number:"
              type="tel"
              disabled={!values.changed_physician}
              component={PhoneNumberField}
              validate={isValidPhone}
            />
            <FormikField
              name="physician.fax"
              label="Fax Number:"
              type="tel"
              disabled={!values.changed_physician}
              component={PhoneNumberField}
            />
          </span>
        </div>
      )}
      {/* Beginning of primary insurance */}
      <InsuranceForm
        insuranceOptions={insuranceOptions}
        patientInsurances={patientInsurances}
      />

      {/* <Field
        component={RadioGroupField}
        name="issues_with_pap_device"
        label="I am having issues with my PAP device."
        value={values.issues_with_pap_device}
        options={[
          { value: true, text: "Yes" },
          { value: false, text: "No" }
        ]}
      /> */}
      {/* {values.issues_with_pap_device && ( */}
      <div className="order-form-section">
        {/* Any issues: */}
        <FormikField name="issue_type" component="select" label="Issue:">
          {papIssuesOptions.map(({ text, value }) => (
            <option key={value} value={value}>
              {text}
            </option>
          ))}
        </FormikField>
      </div>
      {values.insurances?.some(x => x?.requires_dysfunctions ?? true) && (
        <div>
          <div style={{ paddingBottom: 10 }}>
            {uniq(
              values.requested_equipment.map(equipment =>
                equipment == "nondisposable filter" ||
                equipment == "disposable filter"
                  ? "filter"
                  : equipment
              )
            ).map((equipment, i) => (
              <div key={equipment}>
                <h5>{capitalize(equipment, i)}</h5>
                {getEquipmentQuestions(equipment).map(question => (
                  <div key={question.key} style={{ paddingLeft: 15 }}>
                    <Field
                      component={RadioGroupField}
                      key={question.key}
                      name={question.key}
                      label={question.text}
                      value={values[question.key]}
                      options={[
                        { value: true, text: "Yes" },
                        { value: false, text: "No" }
                      ]}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
          {!validDysfunctions && (
            <div className="order-form-warning">
              Eligibility to have insurance cover replacement of these items
              requires the presence of a dysfunction.
            </div>
          )}
        </div>
      )}
      {company_order_settings.washing_supplies && (
        <Field
          component={RadioGroupField}
          name="washes_supplies"
          label="To extend the use of your supplies in between replacement periods, are you washing your supplies with warm water and soap?"
          value={values.washes_supplies}
          options={[
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]}
        />
      )}
      <FormikField
        name="other_issues"
        label="Please include any additional information / concerns regarding your PAP therapy."
        type="text"
      />
      {company_order_settings.quantity_on_hand && (
        <FormikField
          name="quantity_on_hand"
          component="select"
          label="Insurance requires us to document the amount of supplies you have on hand."
        >
          {quantityOnHandOptions.map(({ text, value }) => (
            <option key={value} value={value}>
              {text}
            </option>
          ))}
        </FormikField>
      )}
      {company_order_settings.hospice_care && (
        <Field
          component={RadioGroupField}
          name="inpatient_facility"
          label="Are you currently under Hospice care or in an inpatient care facility such as Hospital, Nursing Home, or other inpatient facilities?"
          value={values.inpatient_facility}
          options={[
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]}
        />
      )}
    </div>
  );
};

export default AdditionalInfo;

AdditionalInfo.propTypes = {
  values: PropTypes.shape({
    saw_physician_last: PropTypes.string,
    requested_equipment: PropTypes.arrayOf(PropTypes.string),
    changed_insurance: PropTypes.bool,
    changed_physician: PropTypes.bool,
    issue_type: PropTypes.string,
    quantity_on_hand: PropTypes.string,
    issues_with_pap_device: PropTypes.bool,
    physician: PropTypes.object,
    inpatient_facility: PropTypes.bool,
    washes_supplies: PropTypes.bool,
    insurances: PropTypes.arrayOf(PropTypes.object)
  }),
  insuranceOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  physician: PropTypes.object,
  usesBonafideApi: PropTypes.bool,
  patientInsurances: PropTypes.arrayOf(PropTypes.object)
};
