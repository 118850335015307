import React from "react";
import PropTypes from "prop-types";
import { BonafidePatientContext } from "features/bonafide/use-bonafide";
import MdErrorOutline from "react-icons/lib/md/error-outline";
import { InlineWaiting } from "components/ui";
import moment from "moment";

const formatDateField = date => (date ? moment(date).format("MM/DD/YYYY") : "");

const PatientsPriorAuth = () => {
  const { priors, errors, isFetching } = React.useContext(
    BonafidePatientContext
  );
  return (
    <div style={{ padding: 10 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <h4>Prior Auth</h4>
        {errors.priors && (
          <div style={{ color: "red" }}>
            <MdErrorOutline />
            <span style={{ paddingLeft: 10 }}>{errors.lmns}</span>
          </div>
        )}
        {isFetching && <InlineWaiting />}
      </div>
      <table
        style={{
          width: "100%"
        }}
      >
        <thead>
          <tr>
            <th>Customer Id</th>
            <th>Order NO</th>
            <th>From</th>
            <th>To</th>
            <th>Insurance</th>
          </tr>
        </thead>
        <tbody>
          {priors.map(auth => (
            <tr key={auth.orderno}>
              <td>{auth.customerid}</td>
              <th>{auth.orderno}</th>
              <td>{formatDateField(auth.fromdate)}</td>
              <td>{formatDateField(auth.todate)}</td>
              <td>{auth.insurance}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PatientsPriorAuth;

PatientsPriorAuth.propTypes = {
  handlePhysicianSelect: PropTypes.func
};
