import React from "react";
import PropTypes from "prop-types";
import Icons from "./icons";
import HasRolePermission from "components/shared/has-role-permission";
import { browserHistory } from "browser-history";

const Footer = ({ openPhone }) => (
  <div className="user-actions">
    <div className="user-action" title="Open Phone" onClick={openPhone}>
      <Icons.Call />
    </div>
    <HasRolePermission
      allowedRoles={[
        "Administrator",
        "SleepCoach",
        "ServiceCoach",
        "ServiceAdmin",
        'ContractorAdministrator'
      ]}
    >
      <div
        className="user-action"
        title="Go To Voicemails"
        onClick={() => {
          browserHistory.push("/voicemails");
        }}
      >
        <Icons.Recording />
      </div>
    </HasRolePermission>
    <HasRolePermission allowedRoles={["Administrator"]}>
      <div
        className="user-action"
        title="Go To Emails"
        onClick={() => {
          browserHistory.push("/emails");
        }}
      >
        <Icons.MdMail />
      </div>
    </HasRolePermission>
    {/* <div
      className="user-action"
      title="Log Out"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        requestUserLogout();
      }}
    >
      <Icons.LogOut />
    </div> */}
  </div>
);

export default Footer;

Footer.propTypes = {
  openPhone: PropTypes.func.isRequired,
  vmCountSinceLastPageVisit: PropTypes.number
};
Footer.contextTypes = {
  router: PropTypes.object
};
