import React from "react";
import { convertDatesToGridSpan } from "./scheduler-helpers";
import WeekSelect from "./week-select";
import TimeSlots from "./time-slots";
import ScheduledSlot from "./scheduled-slot";
import TempScheduledSlot from "./temp-scheduled-slot";
import useAvailabilityScheduler, {
  TimeSlotContext
} from "./use-availabilityScheduler";
import Calandar from "./calendar";
import EditTimeSlot from "./edit-time-slot";
import CreateTimeSlot from "./create-time-slot";
import CalandarLegend from "./calendar-legend";
import CalendarHelpMenu from "./calander-help-menu";

const AvailabilityScheduler = React.memo(() => {
  const {
    userTimeSlots,
    getUserTimeSlots,
    createTimeSlot,
    deleteTimeSlotById,
    pendingTimeSlot,
    setPendingTimeSlot,
    week,
    selectWeek,
    selected,
    setSelected,
    editSlot,
    setEditSlot
  } = useAvailabilityScheduler();

  return (
    <TimeSlotContext.Provider
      value={{
        userTimeSlots,
        getUserTimeSlots,
        createTimeSlot,
        deleteTimeSlotById,
        pendingTimeSlot,
        setPendingTimeSlot,
        week,
        selectWeek,
        selected,
        setSelected,
        editSlot,
        setEditSlot
      }}
    >
      <div className="scheduler">
        <div className="calander-header">
          <WeekSelect />
          <CalandarLegend />
          <CalendarHelpMenu />
        </div>
        <div className="calendar-container">
          <Calandar>
            <TimeSlots week={week} />
            {pendingTimeSlot ? (
              <TempScheduledSlot
                {...convertDatesToGridSpan(pendingTimeSlot)}
                setEditSlot={setEditSlot}
              />
            ) : null}
            {userTimeSlots
              .map(s => ({ ...s, ...convertDatesToGridSpan(s) }))
              .map(slot => (
                <ScheduledSlot key={slot.slot_id} slot={slot} />
              ))}
          </Calandar>
          <div className="scheduler-controls">
            {editSlot && <EditTimeSlot {...editSlot} />}
            {pendingTimeSlot && <CreateTimeSlot {...pendingTimeSlot} />}
          </div>
        </div>
      </div>
    </TimeSlotContext.Provider>
  );
});

export default AvailabilityScheduler;
