import React from "react";
import PropTypes from "prop-types";
import { path } from "ramda";
import { Pie, PieChart, Tooltip, Legend, ResponsiveContainer } from "recharts";
import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
import ReactTooltip from 'react-tooltip';
import { graphColors } from "utils/styles";
import { formatPercantage } from "utils/misc";

const EmailBreakdown = ({ emailContacts, onExport }) => (
  <div className="graph-container">
    <ReactTooltip />
    {onExport && (<div className="stat-export-icon" data-tip="Export" onClick={onExport}><FaFileExcelO /></div>)}
    <h5 className="graph-header">Emails</h5>
    <ResponsiveContainer height={250}>
      <PieChart>
        <Pie
          data={[
            {
              name: "Resupply Request",
              count: path(["resupply_request", "successes"], emailContacts),
              fill: graphColors[1]
            },
            {
              name: "Mobile App Instruction",
              count: path(
                ["download_mobile_app_request", "successes"],
                emailContacts
              ),
              fill: graphColors[2]
            },
            {
              name: "Text Consent",
              count: path(["text_consent_request", "successes"], emailContacts),
              fill: graphColors[3]
            },
            {
              name: "Follow Up",
              count: path(["follow_up", "successes"], emailContacts),
              fill: graphColors[4]
            },
            {
              name: "Other",
              count: path(["other", "successes"], emailContacts),
              fill: graphColors[6]
            }
          ]}
          isAnimationActive={false}
          dataKey="count"
          nameKey="name"
        />
        <Legend />
        <Tooltip
          formatter={value =>
            `${value} - ${formatPercantage(value, emailContacts.success_total)}`
          }
        />
      </PieChart>
    </ResponsiveContainer>
  </div>
);

EmailBreakdown.propTypes = { 
  emailContacts: PropTypes.object,
  onExport: PropTypes.func 
};

export default EmailBreakdown;
