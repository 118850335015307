import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import MdWarning from "react-icons/lib/md/warning";
import MdPerson from "react-icons/lib/md/person";
import MdPlusOne from "react-icons/lib/md/plus-one";
import MdLocalShipping from "react-icons/lib/md/local-shipping";

const VerifyOrderProductCompatibility = ({
  lineItemFlagged,
  back_ordered,
  equipmentTypeFlagged,
  compatibleWithPatientEq
}) => (
  <div
    style={{
      display: "flex",
      justifyContent: "flex-end",
      paddingTop: 3,
      color: "rgb(232, 145, 12)",
      height: 15
    }}
  >
    {back_ordered && (
      <i
        title="Item is on back order"
        className="no-print"
        style={{
          lineHeight: "1.15em",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <MdLocalShipping size={16} />
      </i>
    )}
    {!compatibleWithPatientEq && (
      <i
        title="Item is not compatible with patients current equipment"
        className="no-print"
        style={{
          lineHeight: "1.15em",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <MdPerson size={16} />
      </i>
    )}
    {lineItemFlagged && (
      <i
        title="Selected equipment is not compatible with other items included in order."
        className="no-print"
        style={{
          lineHeight: "1.15em",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <MdWarning size={16} />
      </i>
    )}
    {equipmentTypeFlagged && (
      <i
        title="Duplicate equipment types"
        className="no-print"
        style={{
          lineHeight: "1.15em",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <MdPlusOne size={16} />
      </i>
    )}
  </div>
);

export default connect(
  (state, { equipmentId, orderEquipment, patientEquipment }) => ({
    lineItemFlagged: selectors.getIsLineItemFlagged(state, {
      equipmentId,
      orderEquipment
    }),
    compatibleWithPatientEq: selectors.getIsLineItemCompatibleWithPatientEq(
      state,
      {
        equipmentId,
        patientEquipment
      }
    ),
    equipmentTypeFlagged: selectors.getIsLineItemTypeFlagged(state, {
      equipmentId,
      orderEquipment
    })
  })
)(VerifyOrderProductCompatibility);

VerifyOrderProductCompatibility.propTypes = {
  lineItemFlagged: PropTypes.bool,
  compatibleWithPatientEq: PropTypes.bool,
  showText: PropTypes.bool,
  orderEquipment: PropTypes.arrayOf(PropTypes.string).isRequired,
  equipmentId: PropTypes.string.isRequired,
  patientEquipment: PropTypes.arrayOf(PropTypes.string),
  itemPatientEqIncompatible: PropTypes.bool,
  equipmentTypeFlagged: PropTypes.bool,
  equipmentType: PropTypes.string,
  equipmentQty: PropTypes.number,
  back_ordered: PropTypes.bool
};

VerifyOrderProductCompatibility.defaultProps = {
  lineItemFlagged: false
};
