import React from "react";
import { SingleStatCard } from "components/dashboard/dashboard-cards/single-stat-card";
import './compliance-metrics-patients-overview.scss';


const ComplianceMetricsPatientsOverview = ({
  complianceMetricsData
}) => {

  const dashboardData = [
    {
      title: "Current Active Patients",
      tooltipText: "Current Active Compliance Patients",
      class: "active-patients-card",
      value: complianceMetricsData.ActivePatientsCount,
      isCompliantPatientsCard: false
    },
    {
      title: "Compliant Patients",
      subtitle: "Over all compliance patients",
      tooltipText: "",
      class: "compliant-patients-card",
      value: complianceMetricsData.CompliantPatientsCount ? {count: complianceMetricsData.CompliantPatientsCount, percentage: complianceMetricsData.CompliantPatientsPercentage } : {count: "", percentage: "" },
      isCompliantPatientsCard: true
    },
    {
      title: "Ready for Outreach",
      tooltipText: "",
      class: "ready-outreach-patients-card",
      value: complianceMetricsData.ReadyForOutreach,
      isCompliantPatientsCard: false
    },
  ];

  return (
    <>
      {dashboardData.map((patientStat, i) => (
        <SingleStatCard
          key={i}
          classProp={patientStat.class}
          title={patientStat.title}
          subtitle= {patientStat.subtitle}
          tooltipText={patientStat.tooltipText}
          numDashboardCards={dashboardData.length}
          value={patientStat.value} 
          isCompliantPatientsCard={patientStat.isCompliantPatientsCard}
        />
      ))}
    </>
  );
};

export default ComplianceMetricsPatientsOverview;