import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

export const SelectRowContext = createContext();

export const RowSelectCell = React.memo(({ id }) => {
  const { isRowSelected, toggleRow } = useContext(SelectRowContext);
  return (
    <td
      style={{
        padding: 0
      }}
    >
      <label
        style={{
          width: "100%",
          height: 34,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          cursor: "pointer"
        }}
      >
        <input
          checked={isRowSelected(id)}
          name={id}
          onChange={toggleRow}
          type="checkbox"
          style={{ padding: 0, margin: 0, cursor: "pointer" }}
        />
      </label>
    </td>
  );
});

RowSelectCell.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export const RowSelectHeaderCell = React.memo(() => {
  const { toggleAllRows } = useContext(SelectRowContext);
  return (
    <td
      className="query-table-header-cell-container"
      style={{ minWidth: 15, width: 15 }}
    >
      <div
        className="query-table-header-cell"
        onClick={toggleAllRows}
        style={{ width: 15 }}
      >
        <div className="header-text" style={{ width: 15, paddingLeft: 3 }}>
          All
        </div>
      </div>
    </td>
  );
});
