import React from "react";
import PropTypes from "prop-types";
import { PageHeader } from "components/ui";
import useApiQuery, { Table } from "hooks/useQuery/api-query";
import { connect } from "react-redux";
import { getFormOptionsSleepCoachWithUserFirst, selectors } from "reducers";
import { useLocation } from "react-router-dom";
import { pathOr } from "ramda";
import PatientsTableRow from "./table-row";
import PatientsTableControls from "./table-controls";
import useRowSelect, {
  SelectRowContext,
  RowSelectHeaderCell
} from "hooks/useRowSelectNew";
import usePatientsTableSettings, {
  PatientsTableSettingsContext
} from "./settings";
import CompanyTeamSelect from "components/shared/company-team-select";
import SleepCoachTeamSelect from "components/shared/coach-team-select";
import {
  outreachCycleOptions,
  yesNoOptions,
  recallStatusOptions
} from "utils/enum";
import DateRangeHeaderColumn from "hooks/useQuery/api-query/table-header/date-range-header-filter";

const PatientsTable = ({ companies, sleepCoaches, formOfContactOptions }) => {
  const location = useLocation();
  const initialFilters = pathOr({}, ["state", "filters"], location);
  const initialQuery = {
    sort: {},
    filters: initialFilters,
    pagination: { page: 1, per: 150 }
  };
  const { query, queryDispatcher, rows, data, isFetching, fetchData } =
    useApiQuery("patients", initialQuery, "patients");
  const {
    toggleAllRows,
    isRowSelected,
    toggleRow,
    clearSelectedRows,
    selectedRows
  } = useRowSelect(rows, "id", query, row => ({
    patient_name: `${row.first_name} ${row.last_name}`
  }));

  const tableOptions = usePatientsTableSettings(query, queryDispatcher);
  const {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings,
    columns
  } = tableOptions;
  return (
    <div className="patients-table-page">
      <PageHeader title="Patients" />
      <PatientsTableSettingsContext.Provider
        value={{
          columns,
          isColumnVisible,
          resetTableSettings,
          tableSettings,
          updateTableSettings
        }}
      >
        <SelectRowContext.Provider
          value={{
            toggleAllRows,
            toggleRow,
            selectedRows,
            isRowSelected,
            clearSelectedRows
          }}
        >
          <Table
            query={query}
            data={data}
            queryDispatcher={queryDispatcher}
            isFetching={isFetching}
            containerClass="query-table patients-table dynamic-columns"
            columns={[
              {
                header: "all",
                field: "all",
                Cell: RowSelectHeaderCell
              },
              {
                header: "Active",
                field: "active",
                filters: yesNoOptions,
                dropdownPosition: "right"
              },
              {
                header: "Name",
                field: "last_name"
              },
              {
                header: "Account #",
                field: "account_number"
              },
              {
                header: "Contact Type",
                field: "form_of_contact",
                filters: formOfContactOptions
              },
              {
                header: "Insurance",
                field: "insurance_company"
              },
              {
                header: "Sleep Coach",
                field: "sleep_coach",
                filters: sleepCoaches,
                filterDownSelect: true,
                SubFilter: SleepCoachTeamSelect
              },
              {
                header: "Company",
                field: "company",
                filters: companies,
                filterDownSelect: true,
                SubFilter: CompanyTeamSelect
              },
              {
                title: "Insurance Eligibility Date",
                header: "Insurance Eligibility Date",
                field: "patient_insurance_eligible"
              },
              {
                title: "Sort by outreach date",
                header: "Outreach Date",
                field: "dateEligibleForAutomation",
                Cell: () => (
                  <DateRangeHeaderColumn
                    header="Outreach Date"
                    field="dateEligibleForAutomation"
                  />
                )
              },
              {
                header: "Outreach Cycle",
                field: "cycle_count",
                filters: outreachCycleOptions
              },
              {
                header: "Delayed Date",
                field: "delayed_activation_date"
              },
              {
                header: "Date Created",
                field: "date_added",
                Cell: () => (
                  <DateRangeHeaderColumn
                    header="Date Created"
                    field="date_added"
                  />
                )
              },
              {
                header: "Last Updated",
                field: "last_updated",
                Cell: () => (
                  <DateRangeHeaderColumn
                    header="Last Updated"
                    field="last_updated"
                  />
                )
              },
              {
                header: "Language",
                field: "language",
                filters: [
                  { value: "English", text: "English" },
                  { value: "Spanish", text: "Spanish" }
                ]
              },
              {
                header: "Ordered",
                field: "has_order_after_added",
                filters: yesNoOptions
              },
              {
                header: "New Setup",
                field: "new_setup",
                filters: yesNoOptions
              },
              {
                header: "Remote Setup",
                field: "remote_setup",
                filters: yesNoOptions
              },
              {
                header: "On Hold",
                field: "on_hold",
                filters: yesNoOptions
              },
              {
                header: "Has Recalled Machine",
                field: "has_recalled_machine",
                filters: yesNoOptions
              },
              {
                header: "Recall Acknowledged",
                field: "has_acknowledged_recall",
                filters: yesNoOptions
              },
              {
                header: "Recall Status",
                field: "recall_action_status",
                filters: recallStatusOptions
              },
              {
                header: "Repap Eligible",
                field: "repap_eligible",
                filters: yesNoOptions
              },
              {
                header: "Repap Requested",
                field: "wants_to_repap",
                filters: yesNoOptions
              },
              {
                header: "Patient Type/s",
                field: "patient_type",
                filters: [
                  { value: "1", text: "Sleep Resupply" },
                  { value: "2", text: "Compliance" },
                  { value: "3", text: "Remote Set Up" }
                ]
              }
            ].filter(isColumnVisible)}
            rows={rows}
            TableRow={PatientsTableRow}
            rowKey="id"
            perPageOptions={[
              { text: "50", value: 50 },
              { text: "150", value: 150 },
              { text: "500", value: 500 }
            ]}
            Controls={() => (
              <PatientsTableControls
                query={query}
                selectedRows={selectedRows}
                clearSelectedRows={clearSelectedRows}
                isFetching={isFetching}
                refreshData={() => {
                  fetchData(query);
                }}
              />
            )}
          />
        </SelectRowContext.Provider>
      </PatientsTableSettingsContext.Provider>
    </div>
  );
};

PatientsTable.propTypes = {
  sleepCoaches: PropTypes.arrayOf(PropTypes.object).isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  formOfContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(state => ({
  companies: selectors.getFormOptionsCompany(state),
  sleepCoaches: getFormOptionsSleepCoachWithUserFirst(state),
  formOfContactOptions: selectors.getEnumFormOfContact(state)
}))(PatientsTable);
