import React from "react";

export default function ConfirmationModal({ title, closeModal, yesAction, notNowAction }) {
    return <div>
        <span style={{ fontSize: '20px' }}> 
           {title}  
        </span>

        <div className='dd_col-12'>
            <div className='dd_col-6'>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <button type="button" className="button alert" onClick={() => { closeModal(); notNowAction(); }}> Not Now </button>
                </div>
            </div>
            <div className='dd_col-6'>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <button type="button" className="button" onClick={() => { closeModal(); yesAction(); }}> Yes </button>
                </div>

            </div>
        </div>
    </div>
}