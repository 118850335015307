import React from "react";
import PropTypes from "prop-types";

const PatientStatus = ({ patientInfo }) => {

  return (
    <div className={`patient-status-bar ${patientInfo?.active ? "patient-active" : "patient-inactive"}`}>
        <h6 className='general-status-container'>{patientInfo?.active ? "Active" : "Inactive"}</h6>
        {
            patientInfo?.deactivationReasons &&
            <h4 className="deactivation-reason">{patientInfo?.deactivationReasons[0]?.text}</h4>
        }
    </div>
  )
};

PatientStatus.propTypes = {
  patientInfo: PropTypes.object.isRequired
};

export default PatientStatus;
