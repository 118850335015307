import React from "react";
import { Link, useHistory, useParams  } from 'react-router-dom';
import { get } from "utils/api";
import { InlineWaiting, FieldTable } from 'components/ui'
import { useFetch } from 'hooks';
import { path } from "ramda";
import moment from "moment";
import CopyFormToClipboard from './CopyFormToClipboardBtn';
import QuestionCard from './QuestionCard';
import PrintFormBtn from './PrintFormBtn';
import "./style.scss";

const formatDate = date =>
  date ? moment(date).format("MMMM Do YYYY, h:mm a") : null;

export default function FormResponseInformation({ modalPatientId, modalFormAssignmentId, isModal }){

    const history = useHistory();
    const params = useParams();

    const patientId = params.patientId ? params.patientId: modalPatientId;
    const formAssignmentId = params.formAssignmentId ? params.formAssignmentId : modalFormAssignmentId;

    const { from } = history.location.state ? history.location.state : "";
    
    const handleClick = () => {
        if("from" in history.location.state && from === 'PatientProfile'){
            const patientRoute = {
                pathname: `/patients/${patientId}`,
                search: "?selectedTab=Forms%20Data"
              };
              history.replace(patientRoute);
        }else{
            history.goBack()
        }
      };

    const {
        response : formInfo,
        fetchData: fetchFormInfo,
        isFetching: fetchingFormInfo,
      } = useFetch({
        apiFn: () => get(`forms/patients/${patientId}/answered-form/${formAssignmentId}`),
        defaultValue: null,
        transformError: path(["response", "body", "status"]),
      });

      React.useEffect(() => {
        fetchFormInfo();
      }, []);

    const formRef = React.useRef();
    return(

        <div className="form-profile-container" ref={formRef} style={{zIndex:9}} >
            <div className="form-profile">
                <div className="form-profile-page">
                    <div className="profile-header">
                        <div className="main-header">
                            <h1>
                                <Link to={`/patients/${patientId}`}>{formInfo?.PatientName?? "Loading"}</Link>
                            </h1>
                            <div className="form-action-buttons">
                                <PrintFormBtn formRef={ formRef } fromInfo = {formInfo}/>
                                <CopyFormToClipboard formRef={ formInfo} formAssignmentId={ formAssignmentId } />
                                
                                { !isModal && <button className="button small" onClick={handleClick}>
                                    Go Back
                                </button>}
                            </div>
                        </div>
                    </div>

                    {fetchingFormInfo && <InlineWaiting />}

                    {!fetchingFormInfo &&
                        <div className="form-information">
                            <h4>Form information</h4>
                            <FieldTable
                                columnCount={1}
                                rows={[
                                    ["Name", formInfo?.Name],
                                    ["Description", formInfo?.Description],
                                    ["Patient type", formInfo?.PatientType],
                                    ["Answered date", formatDate(formInfo?.AnsweredDate)]
                                ].map(([field, value]) => ({ field, value }))}
                            />
                        </div>
                    }

                    {!fetchingFormInfo &&
                        <div className="form-information">
                            <div className='form-profile-header form-information-header'>
                                <h4>Form questions and answers</h4>
                            </div>
                            {(!formInfo || formInfo.Questions?.length <1 ) &&
                                <div
                                    style={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "left",
                                        alignItems: "left",
                                        padding: 0
                                    }}
                                >
                                    <span>the form hasn't associated questions</span>
                            </div>
                            }

                            {formInfo && formInfo.Questions?.length > 0 &&
                                formInfo.Questions.map((question, index) =>
                                (
                                    <QuestionCard question= {question} index= {index} key={index}/>
                                )
                            )}
                        </div>
                    }
                </div>
            </div>
        </div>
     )
}