import React, { Component } from "react";
import PropTypes from "prop-types";
import PrintMonthlyPatients from "./monthly/print-monthly-patients";
import { formatMoney } from "accounting";
import { PrintPageHeader } from "components/ui";
import moment from "moment";
import isEmpty from "lodash/isEmpty"
import { ItemsRequested } from "components/ui/statistics";
import PrintCompletedOrders from "components/ui/statistics/print-completed-orders";
import { prop } from "ramda";

export const PrintSectionTitle = ({ title }) => (
  <div className="row section-header">
    <div className="small-12 columns print-company-monthly-report_section-title-container">
      <h3 className="print-company-monthly-report_section-title">{title}</h3>
    </div>
  </div>
)
PrintSectionTitle.propTypes = { 
  title: PropTypes.string
}

const statusRows = [
  { label: "Accepted", status: "accepted" },
  { label: "Backordered", status: "backordered" },
  { label: "Coach Follow Up", status: "coach_followup" },
  { label: "Completed", status: "completed" },
  { label: "Internal Processing", status: "internal_processing" },
  { label: "New", status: "new" },
  { label: "On Hold", status: "on_hold" },
  { label: "Pending", status: "pending" },
  { label: "Pending Buildout", status: "pending_buildout" },
  { label: "Pending Fulfillment", status: "pending_fulfillment" },
  { label: "Pending Shipment", status: "pending_shipment" },
  { label: "Incorrect", status: "incorrect" },
  {
    label: "Location Assistance Needed",
    status: "location_assistance_needed"
  },
  { label: "Ready For Review", status: "ready_for_review" },
  { label: "Rejected", status: "rejected" }
];

const OrderStatusRow = ({ statuses, status, label }) => (
    <tr className='print-company-monthly-report_table-row'>
      <td>{label}</td>
      <td className="text-right">
        {prop(`${status}_count`, statuses)}
      </td>
      <td className="text-right">
        {formatMoney(prop(`${status}_revenue`, statuses))}
      </td>
    </tr>
);

OrderStatusRow.propTypes = {
  statuses: PropTypes.object,
  status: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export class PrintCompanyMonthlyReport extends Component {
  componentDidMount = () => {
    document.getElementById('app').classList.add('print-company-monthly-report_hide-root')
    setTimeout(() => {
      window.print()
    }, 1000);
  }
  componentWillUnmount = () => {
    document.getElementById('app').classList.remove('print-company-monthly-report_hide-root')
  }

  render() {
    const { monthly, orders, togglePrintModal } = this.props;
    const statuses = orders && orders.statuses;
    const dateString = `${moment(this.props.from).format("MMM Do YYYY")} - ${moment(this.props.to).format("MMM Do YYYY")}`

    return (
      <div>
        <div className='print-company-monthly-report_container'>
          <div className='row'>
            <div
              className='hide-on-print print-company-monthly-report_toggle-print-view'
              onClick={togglePrintModal}>
              Back
          </div>
          </div>
          <div className="row">
            <PrintPageHeader title={monthly.get("name")} dateString={dateString} />
          </div>
          {(!isEmpty(orders))
            && <div>
              <div className="print-company-monthly-report_page-1-container">
                <div className="print-company-monthly-report_row">
                  <div className="print-company-monthly-report_sum-block">
                    <div className="print-company-monthly-report_sum-block-header">Platform Orders</div>
                    <div className="print-company-monthly-report_sum-block-row">{orders.total_orders.toLocaleString()}</div>
                  </div>
                  <div className="print-company-monthly-report_sum-block">
                    <div className="print-company-monthly-report_sum-block-header">Average Order Revenue</div>
                    <div className="print-company-monthly-report_sum-block-row">{formatMoney(orders.average_revenue)}</div>
                  </div>
                  <div className="print-company-monthly-report_sum-block">
                    <div className="print-company-monthly-report_sum-block-header">Potential Revenue</div>
                    <div className="print-company-monthly-report_sum-block-row">{formatMoney(orders.potential_revenue)}</div>
                  </div>
                </div>
                <div className="print-company-monthly-report_row">
                  <div className="print-company-monthly-report_sum-block">
                    <div className="print-company-monthly-report_sum-block-header">Additional Walk-in Orders</div>
                    <div className="print-company-monthly-report_sum-block-row">{orders.walk_in_orders.toLocaleString()}</div>
                  </div>
                  <div className="print-company-monthly-report_sum-block">
                    <div className="print-company-monthly-report_sum-block-header">Walk-in Order Revenue</div>
                    <div className="print-company-monthly-report_sum-block-row">{formatMoney(orders.walk_in_order_revenue)}</div>
                  </div>
                  <div className="print-company-monthly-report_sum-block">
                    <div className="print-company-monthly-report_sum-block-header">Combined Revenue</div>
                    <div className="print-company-monthly-report_sum-block-row">{formatMoney(
                      orders.potential_revenue +
                      orders.walk_in_order_revenue
                    )}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="large-12" style={{ marginBottom: 50 }}>
                    {this.props.orders && <ItemsRequested equipment={this.props.orders.equipment} />}
                  </div>
                </div>
              </div>
              {/*footer is necessary to trigger page break when printing */}
              <footer className='print-company-monthly-report_footer'> 1/3 pages</footer>
              <div className="row">
                <PrintPageHeader title={monthly.get("name")} dateString={dateString} />
              </div>
              <div className="print print-company-monthly-report_table-container">
                <PrintSectionTitle title='Orders' />
                <div className="row">
                  <table className="large-12 print">
                    <tbody>
                      <tr className='print-company-monthly-report_table-header-row'>
                        <th>Status</th>
                        <th className="text-right">Count</th>
                        <th className="text-right">Revenue</th>
                      </tr>
                      {statusRows.map(({ label, status }) => (
                        <OrderStatusRow
                          key={status}
                          statuses={statuses}
                          label={label}
                          status={status}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <PrintMonthlyPatients patients={monthly.get("patients")} />
              {/*footer is necessary to trigger page break when printing */}
              <footer className='print-company-monthly-report_footer'> 2/3 pages</footer>
              <div className="row">
                <PrintPageHeader title={monthly.get("name")} dateString={dateString} />
              </div>
              <PrintCompletedOrders data={monthly.get("completed_order_stats")} />
              {/*footer is necessary to trigger page break when printing */}
              <footer className='print-company-monthly-report_footer'> 3/3 pages</footer>
            </div>}
        </div>
      </div>
    )
  }
}

PrintCompanyMonthlyReport.propTypes = {
  monthly: PropTypes.object.isRequired,
  orders: PropTypes.object,
  params: PropTypes.object.isRequired,
  togglePrintModal: PropTypes.func.isRequired,
  from: PropTypes.string,
  to: PropTypes.string
};
