import moment from "moment";
import C from "action-types";
import { browserHistory } from "browser-history";
import { makeFetchCreator } from "actions/fetch";
import { errorMessage } from "actions/message";
import { post, put } from "utils/api";
import { transformUiQuery } from "utils/query";
import omit from "lodash/omit";

export const EXPORTS_START_FETCH_INTERVAL = "EXPORTS_START_FETCH_INTERVAL";
export const startExportFetchInterval = (id, delayTime = 15000 /* ms */) => ({
  type: EXPORTS_START_FETCH_INTERVAL,
  payload: {
    id,
    delayTime
  }
});

export const EXPORTS_END_FETCH_INTERVAL = "EXPORTS_END_FETCH_INTERVAL";
export const endExportFetchInterval = () => ({
  type: EXPORTS_END_FETCH_INTERVAL
});

export const EXPORTS_LAST_UPDATED = "EXPORTS_LAST_UPDATED";
export const exportsLastUpdated = date => ({
  type: EXPORTS_LAST_UPDATED,
  payload: {
    time: moment(date).format("MMM D, h:mm:ss")
  }
});

const postExportAccessLogs = makeFetchCreator({
  url: () => `tools/access`,
  baseType: C.EXPORTS.POST,
  method: post
});

export const addExportAccessLogs =
  ({ payload }) =>
  dispatch =>
    dispatch(
      postExportAccessLogs({
        payload: {
          payload
        }
      })
    )
      .then(() => {
        browserHistory.push("/exports");
      })
      .catch(() => {
        dispatch(errorMessage("Something went wrong"));
      });

export const exportPatientsReport =
  (query, columnsToExport = []) =>
  async dispatch => {
    try {
      await put("patients/export", {
        ...omit(transformUiQuery(query), "pagination"),
        columnsToExport
      });
      browserHistory.push("/exports");
    } catch (error) {
      dispatch(errorMessage("Failed to export patient report."));
    }
  };

export const exportOrderTasks =
  (query, columnsToExport = []) =>
  async dispatch => {
    try {
      await put("tasks/orders/export", {
        ...omit(transformUiQuery(query), "pagination"),
        columnsToExport
      });
      browserHistory.push("/exports");
    } catch (error) {
      dispatch(errorMessage("Failed to export order tasks."));
    }
  };
