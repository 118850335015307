import React from "react";

export default function ModalAssignFormToPatients({ closeModal, onYes }) {

    const onYesAction = ()=>{
        closeModal();
        if (typeof onYes === 'function') {
            onYes();
        }
    };

    return <div>
        <div style={{textAlign:'center' , paddingTop:'10px'}}>
        <span  style={{ fontSize: '20px'}}>
           Would you like to assign this form to existing patients?
        </span>
        </div>

        <div className='dd_col-12'>
            <div className='dd_col-6'>
                <div style={{ display: "flex", justifyContent: "flex-start", paddingLeft:'50px' }}>
                    <button type="button" className="button alert" onClick={() => { closeModal(); }}> No </button>
                </div>
            </div>
            <div className='dd_col-6'>
                <div style={{ display: "flex", justifyContent: "flex-end", paddingRight:'50px' }}>
                    <button type="button" className="button" onClick={() => { onYesAction(); }}> Yes </button>
                </div>

            </div>
        </div>
    </div>
}