import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createContactRecord } from "action-creators";
import { PageHeader, InlineWaiting } from "components/ui";
import {
  invalidatePatientContactRecords,
  fetchPatientContactRecords
} from "actions/patient";
import MdInfo from "react-icons/lib/md/info";

const contactCategoryOptions = [
  { value: "Declined_Supplies", text: "Declined Supplies" },
  { value: "Left_Voicemail_Failed", text: "Could Not Leave Voicemail" },
  { value: "Other", text: "Other" },
  { value: "Follow_Up", text: "Follow Up" },
  { value: "Left_Voicemail", text: "Left Custom Voicemail" },

  { value: "Booked_Order", text: "Booked Order" },
  { value: "Number_Busy", text: "Number Busy" },
  { value: "Deceased", text: "Deceased" },
  { value: "Do_Not_Call", text: "Do Not Call" },
  { value: "Left_Message_Person", text: "Left Message Person" },
  { value: "No_Answer", text: "No Answer" },
  { value: "Not_Using_Machine", text: "Not Using Machine" },
  { value: "Returned_Machine", text: "Returned Machine" }
];

const transformCategory = category => {
  switch (category) {
    case "Left_Voicemail_Failed":
      return {
        category: "Left_Voicemail",
        success: false
      };
    case "Left_Voicemail":
      return {
        category: "Left_Voicemail",
        success: true
      };
    default:
      return {
        category
      };
  }
};

const ContactRecord = ({
  closeSelf,
  createContactRecord,
  fetchPatientContactRecords,
  invalidatePatientContactRecords,
  patientId
}) => {
  const [type, setType] = useState("Phone");
  const [category, setCategory] = useState("Declined_Supplies");
  const [notes, setNotes] = useState("");
  const [isSubmitting, setIsSubmitting] = useState("");
  return (
    <div style={{ padding: 5 }}>
      <PageHeader title="Create Contact Record">
        <div style={{ display: "flex", alignItems: "center", color: "#666" }}>
          <MdInfo size={20} color="rgb(66, 129, 166)" />
          <span style={{ paddingLeft: 5, fontSize: "1.1em" }}>
            System generated voicemails and emails automatically creates contact
            records
          </span>
        </div>
      </PageHeader>
      <form
        onSubmit={async e => {
          e.preventDefault();
          try {
            const data = {
              type,
              notes,
              ...transformCategory(category)
            };
            setIsSubmitting(true);
            await createContactRecord(patientId, data, () => {
              invalidatePatientContactRecords({ patientId });
              fetchPatientContactRecords({ patientId });
            });
            setIsSubmitting(false);
            if (closeSelf) {
              closeSelf();
            }
          } catch (e) {
            setIsSubmitting(false);
          }
        }}
      >
        <div className="create-contact-record-form">
          <label>
            Type
            <select
              name="type"
              onChange={({ target }) => {
                setType(target.value);
                if (target.value === "Email") setCategory("Other");
                else setCategory("Left_Voicemail_Failed");
              }}
              value={type}
            >
              <option value="Phone">Phone Call</option>
              <option value="Email">Email</option>
            </select>
          </label>
          {type === "Phone" && (
            <label>
              Category
              <select
                name="category"
                onChange={({ target }) => {
                  setCategory(target.value);
                }}
                value={category}
              >
                {contactCategoryOptions.map(({ text, value }) => (
                  <option value={value} key={value}>
                    {text}
                  </option>
                ))}
              </select>
            </label>
          )}
          <label>
            Notes
            <textarea
              name="notes"
              value={notes}
              onChange={({ target }) => {
                setNotes(target.value);
              }}
            />
          </label>
          <div className="contact-form-actions">
            {isSubmitting ? (
              <InlineWaiting />
            ) : (
              <button type="submit" style={{ margin: 0 }}>
                Add contact record
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

ContactRecord.propTypes = {
  createContactRecord: PropTypes.func.isRequired,
  closeSelf: PropTypes.func,
  patientId: PropTypes.string.isRequired,
  invalidatePatientContactRecords: PropTypes.func.isRequired,
  fetchPatientContactRecords: PropTypes.func.isRequired
};

export default connect(() => ({}), {
  createContactRecord,
  invalidatePatientContactRecords,
  fetchPatientContactRecords
})(ContactRecord);
