import * as R from "ramda";
import moment from "moment";
import { getEquipmentSupplyInfo } from "components/order-form/options";

export const cmnIsNotExpired = item =>
  item.expDate &&
  !item.inactive &&
  moment(item.expDate, "YYYYMMDD").isAfter(moment(), "day");

export const hasExpiredCmn = cmns => {
  return !R.any(cmnIsNotExpired)(cmns);
};

export const getCmnErrors = (cmns, patientsInsurance, requestedEquipment) => {
  if (cmns.length === 0) return ["No Cmns found on file for patient"];

  const hasNonExpiredCmn = R.any(cmnIsNotExpired)(cmns);
  if (!hasNonExpiredCmn) return ["No valid Cmns found on file for patient"];

  let errors = [];

  const validHcpcs = getValidHcpcsFromCmns(cmns, patientsInsurance);
  const noCmnForRequestedEq = getCmnErrorForRequestedEq(
    validHcpcs,
    requestedEquipment
  );
  if (noCmnForRequestedEq) errors.push(noCmnForRequestedEq);

  return errors;
};

export const getValidHcpcsFromCmns = cmns => {
  const validCmns = R.pipe(
    R.filter(cmnIsNotExpired),
    R.reduce((acc, { hcpcs }) => R.uniq([...acc, ...hcpcs]), [])
  )(cmns);
  return validCmns;
};

const hcpcEqTypeMapping = {
  mask: ["A7030", "A7034", "A7044", "A7027"],
  headgear: ["A7035"],
  seals: ["A7031", "A7032", "A7033", "A7028", "A7029"],
  tubing: ["A7037", "A4604"],
  filter: ["A7038", "A7039"],
  disposable_filter: ["A7039"],
  nondisposable_filter: ["A7038"],
  chinstrap: ["A7036"],
  waterchamber: ["A7046"],
  water_chamber: ["A7046"]
};

export const getCmnErrorForRequestedEq = (validHcpcs, requestedEquipment) => {
  const getEqHasValidCmn = eq => {
    const hpcsForEq = R.propOr([], eq.replace(" ", "_"), hcpcEqTypeMapping);
    return R.any(hcpc => validHcpcs.includes(hcpc))(hpcsForEq);
  };
  const eqNeedingCmn = R.pipe(
    R.filter(eq => !getEqHasValidCmn(eq)),
    R.map(eq => {
      const { text } = getEquipmentSupplyInfo(eq);
      return text;
    })
  )(requestedEquipment);
  if (eqNeedingCmn.length > 0)
    return (
      "The following requested equipment requires a CMN: " +
      eqNeedingCmn.join(", ")
    );
};

export const getInsuranceType = index => {
  switch (index) {
    case 0:
      return "Primary";
    case 1:
      return "Secondary";
    case 2:
      return "Tertiary";
    default:
      return "";
  }
};

export const sortInsurances = insurances => {
  return R.sortBy(
    R.compose(getInsurancePriority, R.toLower, R.prop("insorder"))
  )(insurances);
};

const getInsurancePriority = insorder => {
  switch (insorder) {
    case "primary":
      return -2;
    case "secondary":
      return -1;
    case "tertieary":
      return 1;
    default:
      return 2;
  }
};

export const getRemainingDeductible = responseBody => {
  try {
    return R.propOr("", "remainingDeductible", JSON.parse(responseBody));
  } catch (e) {
    return "";
  }
};

export const getPatientIsEligible = eligStatus => {
  return ["activity", "active", "eligible"].includes(eligStatus?.toLowerCase());
};

export const lineItemsAuthIsValid = lineItems => {
  return lineItems.every(
    ({ authNumber, authStartDate, authEndDate }) =>
      !!authNumber && !!authStartDate && !!authEndDate
  );
};
