import * as R from "ramda";

export const getPendingOrderTasksHoldingOrder = (tasks, orderId) => {
  return R.pipe(
    R.filter(
      t => t.is_order_hold && t.order_id == orderId && t.status !== "Completed"
    ),
    R.length
  )(tasks);
};

export const getPendingS3Tasks = tasks => {
  return R.pipe(
    R.filter(
      t => t.is_order_hold && t.owner == "S3" && t.status !== "Completed"
    ),
    R.length
  )(tasks);
};
export const getPendingDMETasks = tasks => {
  return R.pipe(
    R.filter(
      t => t.is_order_hold && t.owner == "DME" && t.status !== "Completed"
    ),
    R.length
  )(tasks);
};
