import React from "react";
import PropTypes from "prop-types";
import SelectInput from "components/forms/select-input";
import Radium from "radium";
import TextInput from "components/forms/text-input";

const InventoryForm = ({
  handleSubmit,
  onChange,
  form,
  editing,
  hcpcsOptions,
  manufacturerOptions,  
  equipmentTypeOptions,  
  errors
}) => {
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <SelectInput
          name="manufacturer_id"
          label="Manufacturer *"
          value={form.manufacturer_id}
          options={manufacturerOptions}
          onChange={onChange}
          error={errors}
        />
        <SelectInput
          name="product_type"
          label="Product Type *"
          value={form.product_type}
          defaultOption=""
          options={equipmentTypeOptions}
          onChange={onChange}
          error={errors}
        />
        <SelectInput
          name="product_style"
          label="Product Style *"
          value={form.product_style}
          defaultOption=""
          options={[
            { value: "Full Face", text: "Full Face" },
            { value: "Heated Tubing", text: "Heated Tubing" },
            { value: "Machine", text: "Machine" },
            { value: "Nasal Pillow", text: "Nasal Pillow" },
            { value: "Nasal Cushion", text: "Nasal Cushion" },
            { value: "Standard Tubing", text: "Standard Tubing" },
            { value: "Other", text: "Other" }
          ]}
          onChange={onChange}
          error={errors}
        />
        <TextInput
          name="product_id"
          label="Product ID *"
          value={form.product_id}
          onChange={onChange}
          error={errors}
        />
        <SelectInput
          name="hcpcs"
          label="HCPCS *"
          value={form.hcpcs}
          onChange={onChange}
          options={hcpcsOptions}
          error={errors}
        />
        <TextInput
          name="name"
          label="Name *"
          value={form.name}
          onChange={onChange}
          error={errors}
        />
        <TextInput
          name="description"
          label="Description *"
          value={form.description}
          onChange={onChange}
          error={errors}
        />
        <TextInput
          name="avgReimbursment"
          label="Average Reimbursement *"
          type="number"
          value={
            form.avgReimbursment
              ? form.avgReimbursment.toString()
              : ""
          }
          onChange={onChange}
          error={errors}
        />
        <TextInput
          name="quantity"
          label="Quantity *"
          type="number"
          value={
            form.quantity
              ? form.quantity.toString()
              : ""
          }
          onChange={onChange}
          error={errors}
        />
        <SelectInput
          blankFirstOption= {false}
          name="productActive"
          label="Product Active *"
          value={form.productActive.toString()}
          defaultOption={form.productActive.toString()}
          options={[
            { value: "true", text: "Active" },
            { value: "false", text: "Inactive" }
            
          ]}
          onChange={onChange}
          error={errors}
        />
        <button type="submit">{editing ? "Update Item" : "Add item"}</button>
      </form>
    </div>
  );
};

export default Radium(InventoryForm);

InventoryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  form: PropTypes.shape({
    productActive: PropTypes.bool,
    avgReimbursment: PropTypes.number,
    quantity: PropTypes.number,
    manufacturer_id: PropTypes.string,
    product_type: PropTypes.string,
    product_style: PropTypes.string,
    product_id: PropTypes.string,
    hcpcs: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  editing: PropTypes.object,
  manufacturers: PropTypes.array.isRequired,  
  equipmentTypeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  hcpcsOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  errors: PropTypes.object.isRequired,
  manufacturerOptions: PropTypes.arrayOf(PropTypes.object)
};
