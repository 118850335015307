import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SelectDropdown from "components/forms/select-dropdown";
import MultiSelectDropdown from "components/forms/multi-select-dropdown";
import { post, get } from "utils/api";
import { errorMessage, message as sendToasterMessage } from "actions/message";
import { useFetch } from "hooks";
import { path } from "ramda";
import { selectors } from "reducers";
import { getGoogleApiAccesToken } from "features/google-api/actions";
import { formatPhone, SplitOnCamelCase, getInitials } from "utils/misc";
import { InlineWaiting } from "components/ui";

const lineFeed = "\n";
const carriageReturn = lineFeed + lineFeed;
const greetings = "Hello," + carriageReturn;
const typeValues = {
  PATIENT: 1,
  OTHER: 2
};

const typeOptions = [
  { text: "Send To Patient", value: typeValues.PATIENT },
  { text: "Send To DME/Other", value: typeValues.OTHER }
];
const emailTemplateValues = {
  TRACKING: 1,
  BAD_PHONE_NUMBER: 2,
  BILLING_ISSUES: 3,
  BILLING_QUESTIONS: 4,
  MACHINE_RETURNS: 5,
  MACHINE_ISSUES: 6,
  PATIENT_NOT_IN_S3: 7,
  MISSING_OR_INCORRECT_ITEMS: 8,
  HIGH_PRIORITY_ORDER: 9,
  PLAIN_TEXT: 10,
  PATIENT_BAD_PHONE_NUMBER: 11,
};
const emailTemplateOptions = [
  {
    text: "Tracking",
    value: emailTemplateValues.TRACKING,
    types: [typeValues.OTHER]
  },
  {
    text: "Bad Phone Number",
    value: emailTemplateValues.BAD_PHONE_NUMBER,
    types: [typeValues.OTHER]
  },
  {
    text: "Billing Issues",
    value: emailTemplateValues.BILLING_ISSUES,
    types: [typeValues.OTHER]
  },
  {
    text: "Billing Questions",
    value: emailTemplateValues.BILLING_QUESTIONS,
    types: [typeValues.OTHER]
  },
  {
    text: "Machine Returns",
    value: emailTemplateValues.MACHINE_RETURNS,
    types: [typeValues.OTHER]
  },
  {
    text: "Machine Issues",
    value: emailTemplateValues.MACHINE_ISSUES,
    types: [typeValues.OTHER]
  },
  {
    text: "Patient Not In S3",
    value: emailTemplateValues.PATIENT_NOT_IN_S3,
    types: [typeValues.OTHER]
  },
  {
    text: "Missing Or Incorrect Items",
    value: emailTemplateValues.MISSING_OR_INCORRECT_ITEMS,
    types: [typeValues.OTHER]
  },
  {
    text: "High Priority Order",
    value: emailTemplateValues.HIGH_PRIORITY_ORDER,
    types: [typeValues.OTHER]
  },
  {
    text: "Plain Text",
    value: emailTemplateValues.PLAIN_TEXT,
    types: [typeValues.PATIENT]
  },
  {
    text: "Bad Phone Number",
    value: emailTemplateValues.PATIENT_BAD_PHONE_NUMBER,
    types: [typeValues.PATIENT]
  },
];

const CreateTemplateMessage = (
  selectedTemplate,
  patientId,
  user,
  companyName,
  callBackNumber,
  patientNumber,
  patientAddress,
  patientAccountNumber,
  patientFirstName,
  patientLastName
) => {
  var signature = CreatePlainTextSignature(
    user.first_name + " " + user.last_name,
    user.role,
    companyName,
    callBackNumber
  );

  var link = CreateEmailLinkToPatientProfile(patientId, true);
  var end = `${signature}${link}`;
  const formattedAddress = `${
    patientAddress.street_address
  }${` ${patientAddress.street_address2}`.trim()}, ${patientAddress.city}, ${
    patientAddress.state
  } ${patientAddress.zip}`;

  switch (selectedTemplate) {
    case emailTemplateValues.TRACKING:
      return (
        greetings +
        "This patient called in regarding their order that was placed on __________. Do you have tracking information for this order?" +
        carriageReturn +
        end
      );
    case emailTemplateValues.BAD_PHONE_NUMBER:
      return (
        greetings +
        "This patient has bad contact phone numbers." +
        carriageReturn +
        `Patient Account Number: ${patientAccountNumber}` +
        carriageReturn +
        `Patient Initials: ${getInitials(
          `${patientFirstName || ""} ${patientLastName || ""}`
        )}` +
        carriageReturn +
        "Bad Phone Number: " +
        carriageReturn +
        "Reason this is a bad number: " +
        carriageReturn +
        end
      );
    case emailTemplateValues.BILLING_ISSUES:
      return (
        greetings +
        "This patient states that they received a bill and are unsure why, could someone please check into this?" +
        carriageReturn +
        end
      );
    case emailTemplateValues.BILLING_QUESTIONS:
      return (
        greetings +
        `This patient states they have a question regarding a bill they received could someone please reach out to patient at ${formatPhone(
          patientNumber
        )}.` +
        carriageReturn +
        end
      );
    case emailTemplateValues.MACHINE_RETURNS:
      return (
        greetings +
        `This patient states they are no longer using their machine and would like to return it. Could someone please contact this patient at ${formatPhone(
          patientNumber
        )}.` +
        carriageReturn +
        end
      );
    case emailTemplateValues.MACHINE_ISSUES:
      return (
        greetings +
        `Patient states they are having issues with their machine, patients machine is (tell what is going on with machine) could someone please reach out to patient at ${formatPhone(
          patientNumber
        )} regarding this?` +
        carriageReturn +
        end
      );
    case emailTemplateValues.PATIENT_NOT_IN_S3:
      return greetings + carriageReturn + end;
    case emailTemplateValues.MISSING_OR_INCORRECT_ITEMS:
      return (
        greetings +
        "This patient called in today and reported receiving incorrect or missing supplies." +
        carriageReturn +
        "Missing Items (Item number, description, size, qty):" +
        carriageReturn +
        "Incorrect Items (Item number, description, size, qty):" +
        lineFeed +
        "Were they opened?" +
        lineFeed +
        "What Items/how many?" +
        carriageReturn +
        `Good call back number for the patient: ${formatPhone(patientNumber)}` +
        carriageReturn +
        `Correct shipping address: ${formattedAddress}` +
        carriageReturn +
        "Additional Notes:" +
        lineFeed +
        carriageReturn +
        end
      );
    case emailTemplateValues.HIGH_PRIORITY_ORDER:
      return (
        greetings +
        "Hello, Patient needs their order processed quickly. ____(this space will be used for the reasoning the order is HP)" +
        carriageReturn +
        end
      );
    case emailTemplateValues.PLAIN_TEXT:
      return (
        greetings + 
        carriageReturn + 
        end
      );
    case emailTemplateValues.PATIENT_BAD_PHONE_NUMBER:
      return (
        `Hello, ${patientFirstName} ${patientLastName}` + 
        carriageReturn +
        `This is ${user.first_name} ${user.last_name} with ${companyName}.` +
        carriageReturn +
        `We are having trouble reaching you at the number we have listed on your file.` +
        carriageReturn +
        `We are trying to contact you in regard to your supplies. You may reply to this email to update your phone number or call us back at ${formatPhone(callBackNumber)}.` +
        carriageReturn +
        `We look forward to hearing from you.` +
        carriageReturn + 
        end
      );
    default:
      break;
  }
};

const CreateEmailLinkToPatientProfile = (patientId, toDME) => {
  return toDME
    ? `Patient's Profile: https://sleepsolutionsandservices.com/patients/${patientId}`
    : `Your Patient Profile: https://papresupply.com/login`;
};

const CreatePlainTextSignature = (
  senderName,
  userType,
  companyName,
  callBackNumber
) => {
  var signatureBody = `Regards, ${carriageReturn}`;

  if (senderName != "" && senderName != null) {
    signatureBody += `${senderName}`;
    signatureBody += `${lineFeed}`;
  }

  if (userType != "" && userType != null) {
    signatureBody += `${SplitOnCamelCase(userType)}`;
    signatureBody += `${lineFeed}`;
  }

  if (companyName != "" && companyName != null) {
    signatureBody += `${companyName}`;
    signatureBody += `${lineFeed}`;
  }

  if (callBackNumber != "" && callBackNumber != null) {
    signatureBody += `${formatPhone(callBackNumber)}`;
    signatureBody += `${lineFeed}`;
  }

  return signatureBody;
};

// const ReplaceLink = (message, patientId, toDME) => {
//     let newMessage = message;
//     var linkForPatient = CreateEmailLinkToPatientProfile(patientId, false);
//     var linkAboutPatient = CreateEmailLinkToPatientProfile(patientId, true);
//     var messageContainsLinkForPatient = Contains(newMessage, linkForPatient);
//     var messageContainsLinkAboutPatient = Contains(
//       newMessage,
//       linkAboutPatient
//     );
//
//     var firstPassOverMessage =
//       !messageContainsLinkForPatient && !messageContainsLinkAboutPatient;
//     if (firstPassOverMessage) {
//       return `${newMessage}${toDME ? linkAboutPatient : linkForPatient}`;
//     } else {
//       if (toDME) {
//         return messageContainsLinkAboutPatient
//           ? newMessage
//           : newMessage.replace(linkForPatient, linkAboutPatient);
//       } else {
//         return messageContainsLinkForPatient
//           ? newMessage
//           : newMessage.replace(linkAboutPatient, linkForPatient);
//       }
//     }
// };

const Composer = ({
  patientId,
  patientFirstName,
  patientLastName,
  patientPrimaryNumber,
  patientSecondaryNumber,
  patientAddress,
  callBackNumber,
  companyName,
  branchOfficeName,
  user,
  patientEmail,
  canEmailPatient,
  patientAccountNumber,
  sendToasterMessage,
  errorMessage,
  getGoogleApiAccesToken
}) => {
  const [showRecipientDropdown, setShowRecipientDropdown] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  //const [showEmailBody, setShowEmailBody] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [attachments] = useState([
    //{ fileName: "Joshua.jpeg", fileSize: "1010" },
    //{ fileName: "Blaylock.jpeg", fileSize: "20000" }
  ]);
  const [gmailAccessToken, setGmailAccessToken] = useState(null);
  const [showEmailComponent, setShowEmailComponent] = useState(false);
  const [emailSubject, setEmailSubject] = useState("Email");
  const [message, setMessage] = useState("");
  const [highPriority, setHighPriority] = useState(false);

  const {
    response: possibleEmailRecipients,
    // isFetching,
    // setRequestParams,
    // error,
    fetchData
  } = useFetch({
    defaultValue: [],
    apiFn: data => post("mail/message/possible_recipients", data),
    transformError: path(["response", "body", "status"]),
    onError: error => {
      errorMessage(
        `Failed to pull possible email recipients: ${error.message}`
      );
    }
  });
  const { response: possibleEmailGroupRecipients, fetchData: getEmailGroups } =
    useFetch({
      defaultValue: [],
      apiFn: () => get(`mail/email_groups/${patientId}`),
      transformError: path(["response", "body", "status"]),
      onError: error => {
        errorMessage(`Failed to pull possible email groups: ${error.message}`);
      }
    });
  // const CreateBodyToSendMessage = () => {
  //   let formData = new FormData();
  //   formData.append("subject", emailSubject);
  //   formData.append("subject", []);
  //   attachments.forEach(attachment => {
  //     formData.append(
  //       attachment.fileName,
  //       attachment.file,
  //       attachment.fileName
  //     );
  //   });
  //
  //   return formData;
  // };
  // const onFileSelect = event => {
  //   event.persist();
  //   let files = [...event.target.files];
  //   var newAttachments = attachments;
  //   if (files && files.length > 0) {
  //     for (let index = 0; index < files.length; index++) {
  //       const reader = new FileReader();
  //       reader.addEventListener("load", () => {
  //         newAttachments.push({
  //           file: reader.result,
  //           fileName: files[index].name,
  //           name: files[index].name,
  //           fileSize: files[index].size
  //         });
  //         setAttachments([...newAttachments]);
  //       });
  //       reader.readAsDataURL(files[index]);
  //     }
  //   }
  // };
  // const returnFileSize = number => {
  //   if (number < 1024) {
  //     return number + "bytes";
  //   } else if (number >= 1024 && number < 1048576) {
  //     return (number / 1024).toFixed(1) + "KB";
  //   } else if (number >= 1048576) {
  //     return (number / 1048576).toFixed(1) + "MB";
  //   }
  // };
  // const removeAttachment = index => {
  //   setAttachments(attachments.filter((x, i) => i != index));
  // };
  // const removeAllAttachments = () => {
  //   setAttachments([]);
  // };
  const getGmailToken = async () => {
    let token = await getGoogleApiAccesToken();
    setGmailAccessToken(token);
    return token;
  };

  const {
    // response: sendMailResponse,
    isFetching: sendMailIsSending,
    // setRequestParams: setSendMailRequestParams,
    // error: sendEmailError,
    fetchData: sendEmail
  } = useFetch({
    defaultValue: true,
    apiFn: data => post("mail/message/send", data),
    transformError: path(["response", "body", "status"]),
    onError: error => {
      errorMessage(`Failed to send email: ${error.message}`);
    },
    onSuccess: () => {
      setMessage("");
      setSelectedType(null);
      setSelectedRecipients([]);
      setSelectedTemplate(null);
      setSelectedGroups([]);
      sendToasterMessage("Sent Email Successfully");
    }
  });

  useEffect(() => {
    setMessage("");
    setSelectedType(null);
    setSelectedRecipients([]);
    setSelectedTemplate(null);
    setSelectedGroups([]);
    setEmailSubject("Email");
    if (patientId && showEmailComponent) {
      fetchData({ patient_with_data_exposed: patientId });
      getEmailGroups();
    }
  }, [patientId, showEmailComponent]);

  useEffect(() => {
    let companyPrefix =
      companyName && branchOfficeName
        ? `${companyName} - ${branchOfficeName}`
        : `${companyName}`;
    let highPriorityMessage = highPriority ? "HIGH PRIORITY " : "";
    setEmailSubject(
      selectedType === typeValues.PATIENT
        ? `C-PAP Email from ${companyName}`
        : `${highPriorityMessage}${companyPrefix} ${getInitials(
            `${patientFirstName || ""} ${patientLastName || ""}`
          )} ${patientAccountNumber}`
    );
  }, [highPriority]);

  useEffect(() => {
    let companyPrefix =
      companyName && branchOfficeName
        ? `${companyName} - ${branchOfficeName}`
        : `${companyName}`;
    let highPriorityMessage = highPriority ? "HIGH PRIORITY " : "";
    setEmailSubject(
      selectedType === typeValues.PATIENT
        ? `C-PAP Email from ${companyName}`
        : `${highPriorityMessage}${companyPrefix} ${getInitials(
            `${patientFirstName || ""} ${patientLastName || ""}`
          )} ${patientAccountNumber}`
    );

    setShowRecipientDropdown(selectedType === typeValues.OTHER);
    setMessage("");
    if (selectedType === typeValues.PATIENT) {
      setHighPriority(false);
    }
    setSelectedTemplate(selectedType === typeValues.PATIENT?emailTemplateValues.PLAIN_TEXT:null);
  }, [selectedType]);

  useEffect(() => {
    if (selectedTemplate != null) {
      if (selectedTemplate === emailTemplateValues.HIGH_PRIORITY_ORDER) {
        setHighPriority(true);
      }
      setMessage(
        CreateTemplateMessage(
          selectedTemplate,
          patientId,
          user,
          companyName,
          callBackNumber,
          patientPrimaryNumber
            ? patientPrimaryNumber
            : patientSecondaryNumber
            ? patientSecondaryNumber
            : "We Have No Good Number For This Patient. ",
          patientAddress,
          patientAccountNumber,
          patientFirstName,
          patientLastName
        )
      );
    }
  }, [selectedTemplate]);

  return (
    <div>
      <div
        title="Open Email Composer"
        className="show-email-button"
        style={{ display: showEmailComponent ? "none" : "" }}
        onClick={() => {
          getGmailToken();
          setShowEmailComponent(true);
        }}
      >
        <div className="email-button">
          <span className="email-button-icon">
            <svg
              width="34"
              height="29"
              viewBox="0 0 34 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.015873 28.5714L33.3333 14.2857L0.015873 0L0 11.1111L23.8095 14.2857L0 17.4603L0.015873 28.5714Z"
                fill="url(#paint0_linear)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="16.6667"
                  y1="0"
                  x2="16.6667"
                  y2="28.5714"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#2F80ED" />
                  <stop offset="1" stopColor="#56CCF2" stopOpacity="0.44" />
                </linearGradient>
              </defs>
            </svg>
          </span>{" "}
          <span className="email-button-text">Email</span>
        </div>
      </div>
      <div
        className="email-container"
        style={{ display: showEmailComponent ? "" : "none" }}
      >
        <span
          className="close-compose-email-button"
          onClick={() => {
            setShowEmailComponent(false);
          }}
          title="Close Email Composer"
        >
          X
        </span>
        <div className="email-label-container">
        <div className="email-label">
            Type:
            <SelectDropdown
              placeHolder="Choose An Option..."
              options={
                canEmailPatient 
                  ? typeOptions
                  : typeOptions.filter(x => x != typeValues.PATIENT)
              }
              value={selectedType}
              onChange={value => {
                setSelectedType(value);
              }}
            />
          </div>
          <div
            className="email-label margin-left-20"
            style={{
              display: [typeValues.PATIENT, typeValues.OTHER].indexOf(selectedType) >= 0 ? "" : "none"
            }}
          >
            Email Template:
            <SelectDropdown
              searchable={true}
              onChange={item => {
                setSelectedTemplate(item);
              }}
              placeHolder="Choose An Email Template..."
              value={selectedTemplate}
              options={emailTemplateOptions.filter(x => x.types.indexOf(selectedType) >= 0 )}
            />
          </div>
          <div
            className="email-label margin-left-20"
            style={{
              display: selectedType === typeValues.PATIENT ? "" : "none",
              marginTop: "10px"
            }}
          >
            <div>
              To:{" "}
              <span style={{ fontWeight: "100" }}>
                {patientFirstName} {patientLastName} {patientEmail}
              </span>
            </div>
          </div>
          <div
            className="email-label margin-left-20"
            style={{
              display: showRecipientDropdown && possibleEmailRecipients?.length > 0 ? "" : "none"
            }}
          >
            To:
            <MultiSelectDropdown
              searchable={true}
              onChange={item => {
                setSelectedRecipients(item);
              }}
              placeHolder="Choose Atleast One Recipient..."
              values={selectedRecipients}
              options={possibleEmailRecipients}
              />
          </div>
          <div
            className="email-label margin-left-20"
            style={{ 
                      display: showRecipientDropdown && 
                        possibleEmailGroupRecipients?.length > 0 ? "" : "none"
            }}
          >
            Email Groups:
            <MultiSelectDropdown
              searchable={true}
              onChange={item => {
                setSelectedGroups(item);
              }}
              placeHolder="Email Groups..."
              values={selectedGroups}
              options={possibleEmailGroupRecipients}
            />
          </div>
          <div
            className="email-label margin-left-20"
            style={{
              display: selectedType ? "" : "none",
              marginTop: "10px"
            }}
          >
            <div>
              Subject: <span style={{ fontWeight: "100" }}>{emailSubject}</span>
              <span
                onClick={() => {
                  setHighPriority(!highPriority);
                }}
                title={
                  highPriority
                    ? "Click To Make This Email Normal Priority"
                    : "Click To Make This Email High Priority"
                }
                className={
                  highPriority ? "highPriorityButton" : "lowPriorityButton"
                }
                style={{
                  display:
                    selectedType === typeValues.PATIENT ||
                    selectedTemplate === emailTemplateValues.HIGH_PRIORITY_ORDER
                      ? "none"
                      : ""
                }}
              >
                {highPriority ? "Make Low Priority" : "Make High Priority"}
              </span>
            </div>
          </div>
        </div>
        <div className="text-controls"></div>
        <div
          className="text-box-container"
          style={{
            visibility:
              (selectedType === typeValues.OTHER && selectedTemplate != null) ||
              selectedType === typeValues.PATIENT
                ? "visible"
                : "hidden"
          }}
        >
          <textarea
            className="text-box"
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
        </div>
        {/* <div className="attachments-container">
          {attachments.map((x, i) => (
            <React.Fragment key={i}>
              <div
                style={{
                  background: "#e6e6e6",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  width: "50%",
                  marginTop: "5px",
                  marginBottom: "5px"
                }}
              >
                <span
                  style={{
                    justifySelf: "left",
                    color: "#25A8E0",
                    marginLeft: "5px",
                    fontWeight: "700"
                  }}
                >
                  {x.fileName}{" "}
                  <span style={{ color: "black" }}>
                    ({returnFileSize(x.fileSize)})
                  </span>
                </span>
                <span
                  title="Remove Attachment"
                  style={{
                    cursor: "pointer",
                    justifySelf: "right",
                    marginRight: "5px"
                  }}
                  onClick={() => removeAttachment(i)}
                >
                  x
                </span>
              </div>
            </React.Fragment>
          ))}
          <div>
            <label htmlFor="addAttachmentInput">
              <svg
                style={{ position: "relative", height: "14px", top: "2px" }}
                width="17"
                height="31"
                viewBox="0 0 17 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9223 6.92787V22.862C13.9223 25.9242 11.4261 28.4044 8.34424 28.4044C5.26237 28.4044 2.7662 25.9242 2.7662 22.862V5.54229C2.7662 3.63019 4.32805 2.07834 6.25247 2.07834C8.1769 2.07834 9.73875 3.63019 9.73875 5.54229V20.0909C9.73875 20.8529 9.11122 21.4765 8.34424 21.4765C7.57726 21.4765 6.94973 20.8529 6.94973 20.0909V6.92787H4.85797V20.0909C4.85797 22.003 6.41982 23.5548 8.34424 23.5548C10.2687 23.5548 11.8305 22.003 11.8305 20.0909V5.54229C11.8305 2.48016 9.33434 -3.05176e-05 6.25247 -3.05176e-05C3.17061 -3.05176e-05 0.674438 2.48016 0.674438 5.54229V22.862C0.674438 27.0742 4.10493 30.4827 8.34424 30.4827C12.5835 30.4827 16.014 27.0742 16.014 22.862V6.92787H13.9223Z"
                  fill="#7D7B7B"
                />
              </svg>
              Add Attachment
            </label>
            <input
              style={{ display: "none" }}
              id="addAttachmentInput"
              name="addAttachmentInput"
              ref={FileRef}
              type="file"
              accept="image/*"
              onChange={e => onFileSelect(e)}
              multiple
            />
          </div>
        </div> */}

        <div
          className="button-container"
          style={{
            visibility:
              (selectedType === typeValues.PATIENT && message != "") ||
              (selectedType === typeValues.OTHER &&
                selectedTemplate != null &&
                (selectedRecipients.length != 0 || selectedGroups.length != 0))
                ? "visible"
                : "hidden"
          }}
        >
          {sendMailIsSending ? (
            <InlineWaiting />
          ) : (
            <button
              className="send-email-button"
              disabled={sendMailIsSending}
              onClick={() =>
                sendEmail({
                  subject: emailSubject,
                  to:
                    selectedType === typeValues.OTHER
                      ? selectedRecipients
                      : [patientId],
                  groups: selectedGroups,
                  body: message,
                  patient: patientId,
                  gmail_access_token: gmailAccessToken,
                  attachments: attachments
                })
              }
            >
              Send
            </button>
          )}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default connect(
  state => ({
    user: selectors.getUser(state)
  }),
  {
    errorMessage,
    sendToasterMessage,
    getGoogleApiAccesToken
  }
)(Composer);
Composer.propTypes = {
  errorMessage: PropTypes.func.isRequired,
  sendToasterMessage: PropTypes.func.isRequired,
  patientId: PropTypes.string,
  patientAccountNumber: PropTypes.string,
  patientFirstName: PropTypes.string,
  patientLastName: PropTypes.string,
  patientPrimaryNumber: PropTypes.string,
  patientSecondaryNumber: PropTypes.string,
  patientAddress: PropTypes.object,
  companyName: PropTypes.string,
  branchOfficeName: PropTypes.string,
  callBackNumber: PropTypes.string,
  senderID: PropTypes.string,
  senderName: PropTypes.string,
  user: PropTypes.object,
  patientEmail: PropTypes.string,
  canEmailPatient: PropTypes.bool,
  getGoogleApiAccesToken: PropTypes.func.isRequired
};
