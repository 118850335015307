import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { TableButton } from "components/ui";
import IoMdRefresh from "react-icons/lib/io/refresh";
import IoMdPerson from "react-icons/lib/io/person";
import { setPatientBatchIds } from "actions/batch";
// import BulkUpdateTasks from "../bulk-update-tasks";
import { createTab } from "actions/system-tray";
// import { exportPatientsReport } from "actions/exports";
// import FaFileExcelO from "react-icons/lib/fa/file-excel-o";
// import IoMdEdit from "react-icons/lib/io/edit";
import { TableSettingsNew } from "hooks/useTableSettings";
// import { put } from "utils/api";
// import { useFetch } from "hooks";
// import { errorMessage } from "actions/message";
// import * as R from "ramda";

const TasksTableControls = ({
  // rows,
  refreshData,
  isFetching,
  selectedRows,
  clearSelectedRows,
  // query,
  tableSettings,
  resetTableSettings,
  updateTableSettings
}) => {
  const dispatch = useDispatch();

  // const bulkUpdatePatientTasksAPI = useFetch({
  //   apiFn: payload => put("tasks/bulk-update", payload),
  //   onError: () => dispatch(errorMessage("Failed to update tasks"))
  // });
  return (
    <div className="query-table-controls" style={{ borderRadius: 0 }}>
      <TableButton
        onClick={() => {
          refreshData();
        }}
        disabled={isFetching}
      >
        <IoMdRefresh />
      </TableButton>
      {/* <TableButton */}
      {/*   title="Export patients to excel file" */}
      {/*   onClick={async () => { */}
      {/*     const columnsToExport = R.pipe( */}
      {/*       R.values, */}
      {/*       R.filter(R.prop("active")), */}
      {/*       R.map(R.prop("field")) */}
      {/*     )(tableSettings); */}
      {/*     await dispatch(exportPatientsReport(query, columnsToExport)); */}
      {/*   }} */}
      {/* > */}
      {/*   <FaFileExcelO /> */}
      {/* </TableButton> */}
      <TableButton
        title={`Process Tasks: (${selectedRows.length} selected)`}
        disabled={selectedRows.length === 0}
        onClick={() => {
          const patientIds = selectedRows.map(x => x.patient_id);
          const patientNames = selectedRows.map(x => x.patient_name);
          dispatch(setPatientBatchIds(patientIds, patientNames));
          dispatch(
            createTab({
              type: "patients",
              key: "patients",
              label: `Patients (${patientIds.length})`,
              meta: { ids: patientIds }
            })
          );
          clearSelectedRows();
        }}
      >
        <IoMdPerson size={24} />
      </TableButton>
      {/* <PureModal */}
      {/*   renderTrigger={({ openModal }) => ( */}
      {/*     <TableButton */}
      {/*       disabled={selectedRows.length === 0} */}
      {/*       onClick={openModal} */}
      {/*       title={`Bulk update Tasks - (${selectedRows.length} selected)`} */}
      {/*     > */}
      {/*       <IoMdEdit /> */}
      {/*     </TableButton> */}
      {/*   )} */}
      {/*   renderContent={({ closeModal }) => ( */}
      {/*     <BulkUpdateTasks */}
      {/*       tasks={selectedRows} */}
      {/*       clearSelectedRows={clearSelectedRows} */}
      {/*       closeModal={closeModal} */}
      {/*       refreshData={refreshData} */}
      {/*       handleBulkUpdateTasks={async payload => { */}
      {/*         await bulkUpdatePatientTasksAPI.fetchData(payload); */}
      {/*         clearSelectedRows(); */}
      {/*         refreshData(); */}
      {/*         closeModal(); */}
      {/*       }} */}
      {/*     /> */}
      {/*   )} */}
      {/* /> */}
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {selectedRows.length > 0 && (
          <span>Selected {selectedRows.length} Tasks</span>
        )}
      </div>
      <div className="action-buttons companies-table-action-buttons">
        <TableSettingsNew
          tableSettings={tableSettings}
          updateTableSettings={updateTableSettings}
          resetTableSettings={resetTableSettings}
        />
      </div>
    </div>
  );
};

export default TasksTableControls;

TasksTableControls.propTypes = {
  refreshData: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.object),
  clearSelectedRows: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  tableSettings: PropTypes.object.isRequired,
  resetTableSettings: PropTypes.func.isRequired,
  updateTableSettings: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired
};
