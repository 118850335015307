import React, { useContext } from "react";
import { connect } from "react-redux";
import { selectors } from "reducers";
import PropTypes from "prop-types";
import { getInitials } from "utils/misc";
import { requestUpdateOrderClaimedBy } from "actions/order-status";
import MdClose from "react-icons/lib/md/close";
import { QueryTableContext } from "hooks/useQuery/api-query";

const ClaimOrder = ({
  claimed_by,
  id,
  userId,
  userRole,
  requestUpdateOrderClaimedBy
}) => {
  const { query, fetchData } = useContext(QueryTableContext);
  const isClaimed = claimed_by && claimed_by.value !== " ";
  const canClaim = [
    "OfficeAdministrator",
    "CompanyAdministrator",
    "ServiceAdmin",
    "ServiceCoach",
    'ContractorAdministrator'
  ].includes(userRole);
  const canClaimThis = canClaim && userId !== (claimed_by && claimed_by.key);
  const canUnclaim =
    userRole === "CompanyAdministrator" || userRole === "Administrator";
  return (
    <span
      className={`${isClaimed && "claimed-by"} ${canClaimThis && "claim"}`}
      onClick={async () => {
        if (canClaimThis) {
          await requestUpdateOrderClaimedBy(id, userId);
          await fetchData(query);
        }
      }}
      title={`${isClaimed && canClaimThis ? "Claim Order From" : ""} ${
        isClaimed ? claimed_by.value : ""
      }`}
    >
      {isClaimed && canUnclaim && (
        <span
          style={{ paddingRight: 5, cursor: "pointer" }}
          title="Unclaim order"
          onClick={async e => {
            e.stopPropagation();
            await requestUpdateOrderClaimedBy(id, null);
            await fetchData(query);
          }}
        >
          <MdClose color="red" />
        </span>
      )}
      {isClaimed ? getInitials(claimed_by.value) : canClaimThis ? "claim" : ""}
    </span>
  );
};

ClaimOrder.propTypes = {
  canClaim: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  requestUpdateOrderClaimedBy: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  claimed_by: PropTypes.object,
  refreshData: PropTypes.func
};

export default connect(
  state => ({
    userId: selectors.getUserId(state),
    userRole: selectors.getUserRole(state)
  }),
  {
    requestUpdateOrderClaimedBy
  }
)(ClaimOrder);
