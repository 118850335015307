import React from "react";
import PropTypes from "prop-types";
import { InlineWaiting } from "components/ui";
import MdRefresh from "react-icons/lib/md/refresh";
import MdWarning from "react-icons/lib/md/warning";
import HasRolePermission from "components/shared/has-role-permission";

const BonafideFooter = ({
  handleRefresh,
  handleCreatePreOrder,
  handleCreateOrderWithAuth,
  handleCreateOrder,
  currentStep,
  canOverrideOrderCreation,
  editAuth,
  isFetching,
  hasCmnHold
}) => {
  const [createAuth, setCreateAuth] = React.useState(false);
  return (
    <div className="bonafide-footer">
      {isFetching ? (
        <InlineWaiting />
      ) : (
        <React.Fragment>
          <i title="Refresh" className="refresh-button" onClick={handleRefresh}>
            <MdRefresh />
          </i>
          {currentStep < 3 ? (
            <React.Fragment>
              <div
                className={`bonafide-request-order-info ${
                  currentStep === 1 ? "current" : "unavailable"
                }`}
                onClick={handleCreatePreOrder}
              >
                <span className="step-number">1</span>
                <span className="step-label">Verify Order</span>
              </div>

              <HasRolePermission
                allowedRoles={[
                  "Administrator",
                  "OfficeAdministrator",
                  "CompanyAdministrator",
                  "ServiceCoach",
                  "ServiceAdmin",
                  'ContractorAdministrator'
                ]}
              >
                <div
                  className={`bonafide-request-order-info ${
                    currentStep === 2 && !hasCmnHold ? "current" : "unavailable"
                  }`}
                  style={{ fontWeight: editAuth ? "bold" : "normal" }}
                  onClick={() => {
                    if (editAuth) return;
                    if (createAuth) {
                      handleCreateOrderWithAuth();
                    } else handleCreateOrder();
                  }}
                >
                  <span className="step-number">2</span>
                  <span className="step-label">Send Order For Processing</span>
                </div>
              </HasRolePermission>
              {currentStep === 2 && hasCmnHold && (
                <div className="cmn-warning">
                  <MdWarning size={16} /> <span>Order has cmn hold</span>
                </div>
              )}
              {canOverrideOrderCreation ||
                (currentStep == 2 && !hasCmnHold && (
                  <HasRolePermission
                    allowedRoles={[
                      "Administrator",
                      "OfficeAdministrator",
                      "CompanyAdministrator",
                      "ServiceCoach",
                      "ServiceAdmin",
                      'ContractorAdministrator'
                    ]}
                  >
                    <label
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        userSelect: "none"
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={createAuth}
                        disabled={editAuth}
                        onChange={({ target }) => setCreateAuth(target.checked)}
                        style={{ margin: "0 5px" }}
                      />
                      <span>Create Auth</span>
                    </label>
                  </HasRolePermission>
                ))}
              {canOverrideOrderCreation && (
                <HasRolePermission
                  allowedRoles={[
                    "Administrator",
                    "OfficeAdministrator",
                    "CompanyAdministrator",
                    "ServiceCoach",
                    "ServiceAdmin",
                    'ContractorAdministrator'
                  ]}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-end"
                    }}
                    className="bonafide-request-order-info current"
                    onClick={() => {
                      if (createAuth) {
                        handleCreateOrderWithAuth();
                      } else handleCreateOrder();
                    }}
                  >
                    <div className="step-label">
                      Manual Override - Send Order For Processing
                    </div>
                  </div>
                </HasRolePermission>
              )}
            </React.Fragment>
          ) : (
            <div
              className="bonafide-request-order-info unavailable"
              style={{ background: "unset" }}
            >
              Waiting For Processing
            </div>
          )}
          {currentStep === 2 && (
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end"
              }}
            >
              <div
                className="bonafide-request-order-info current"
                onClick={async () => {
                  if (isFetching) return;
                  await handleCreatePreOrder();
                }}
              >
                <span className="step-label">Re-Verify Order</span>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

BonafideFooter.propTypes = {
  currentStep: PropTypes.number.isRequired,
  handleCreateOrder: PropTypes.func.isRequired,
  handleCreateOrderWithAuth: PropTypes.func.isRequired,
  handleCreatePreOrder: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  canOverrideOrderCreation: PropTypes.bool,
  hasCmnHold: PropTypes.bool,
  isFetching: PropTypes.bool,
  editAuth: PropTypes.bool
};
export default BonafideFooter;
