import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import styler from "react-styling";
import IoIosInfoCircleOutline from "react-icons/lib/io/ios-information";
import { Tooltip } from "components/ui/tool-tip/tooltip";

export const SingleStatCard = ({ value, title, subtitle, tooltipText, classProp, isCompliantPatientsCard }) => {
  return (
    <div className={`${classProp}`} style={{ ...S.base }}>
      <div style={S.title}>
        <span>{title}</span>
        {tooltipText && (
          <span style={S.infoCircle}>
            <Tooltip
              id={`single-stat-tooltip-${title}`}
              className="single-stat-tooltip"
              effect="solid"
              message={tooltipText}
            >
              <IoIosInfoCircleOutline size="1rem" />
            </Tooltip>
          </span>
        )}
      </div>
      <div style={S.subtitle}><span>{subtitle}</span></div>
      
      {
        isCompliantPatientsCard ?
        <div style={S.value}>
            <div style={{width: "65%", display: "flex", justifyContent: "space-between"}}>
                <span>{value.count ? value.count.toLocaleString() : 0}</span>
                <span>{value.percentage ? value.percentage.toLocaleString() + "%" : 0}</span>
            </div>
        </div>
        :
        <div style={S.value}>
            <span title={value}>{value ? value.toLocaleString() : 0}</span>
        </div>
      }
    </div>
  );};

export default Radium(SingleStatCard);

SingleStatCard.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  tooltipText: PropTypes.string,
  classProp: PropTypes.string,
  subLabel: PropTypes.string,
  tooltip: PropTypes.string,
  styles: PropTypes.object
};

SingleStatCard.defaultProps = {
  styles: {},
  value: 0
};

const S = styler`
  base
    width: 100%
    text-align: center
    border: 2px solid lightgray
    font-size: 1rem
    border-radius: 7px
    padding: 0 1rem
    backgroundColor: white
    color: rgb(102, 102, 102)
    display: flex
    flex-direction: column
    justify-content: space-between
  infoCircle
    cursor: pointer
    position: relative;
    left: 0.25rem;
    bottom: 0.5rem;
  value
    font-size: 2.8em
    display: flex
    justify-content: center
    padding: 0.5rem
    overflow: hidden
    text-overflow: ellipsis
  subLabel
    font-size: 1.35em
    color: #f2f2f2
    display: flex
    justify-content: center
    padding: 0 5px
    overflow: hidden
    text-overflow: ellipsis
  title
    font-weight: bold;
    margin-top: 0.75rem;
    font-size: 1.5rem;
    color: rgb(134, 135, 134);
    padding: 0 0.40rem
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
  subtitle
    margin-top: -0.15rem;
    font-size: 1.2rem;
    color: rgb(134, 135, 134);
    padding: 0 0.40rem
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    height: 20px
`;
