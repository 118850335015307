import React from 'react'
import DataColumnFilterTable from 'components/ui/data-column-filter-table';
import '../pages/companyForms.css'
import IoEdit from "react-icons/lib/io/edit";
import { Link } from 'react-router-dom';
import IoEye from "react-icons/lib/io/eye";
import FormTableCheckbox from './FormTableCheckbox';
import CopyForm from './copy-form';
import AssignFormToPatients from './assign-form-to-patient-confirmation-modal';

export default function Table({ formList, handleView, idCompany, getForms, updateRowArray, assignFormConfirmation, setFormToBeAssignedId }) {

    return (
        <div>
            <DataColumnFilterTable
                columns={[
                    {
                        title: 'Sort by name',
                        header: 'Name',
                        field: frm => frm.Name,
                        formKey: "formName"
                    },
                    {
                        title: 'Sort by Description',
                        header: 'Description',
                        field: frm => frm.Description,
                        formKey: "formDescription"
                    },
                    {
                        title: 'Sort By Company',
                        header: 'Company',
                        field: frm => frm.Company,

                    },
                    {
                        title: 'Sort By Patient Type',
                        header: 'Patient Type',
                        field: frm => frm.PatientType,

                    },
                    {
                        title: 'Sort By Patients count',
                        header: 'Assigned To Patients',
                        field: frm => frm.PatientsAssignedToForm,
                    },
                    {
                        title: 'Sort by Order',
                        header: 'Order',
                        field: frm => frm.Order
                    },
                    {
                        title: 'Sort by Active',
                        header: 'Active',
                        field: ({ FormId, IsActive, CompanyManagesPatientType }) => 
                            <FormTableCheckbox 
                                FormId={FormId} 
                                IsActive={IsActive} 
                                formGetterFunction={getForms} updateRowArray={updateRowArray} 
                                CompanyManagesPatientType={CompanyManagesPatientType} 
                                setFormToBeAssignedId={setFormToBeAssignedId}
                            />,
                        formKey: "formIsActive"
                    },
                    {
                        title: 'View Form',
                        header: '',
                        field: ({ FormId }) => <IoEye style={{ color: "#007bff", fontSize: "1.5em", cursor: 'pointer' }} onClick={() => handleView(FormId)} />,
                    },
                    {
                        title: 'Edit Form',
                        header: '',
                        field: ({ FormId, CompanyManagesPatientType }) => CompanyManagesPatientType && <Link to={`/companies/${idCompany}/forms/editForm/${FormId}`}><IoEdit style={{ color: "#28a745", fontSize: "1.5em", cursor: 'pointer' }} /></Link>,
                        formKey: "editForm"
                    },
                    {
                        title: 'Copy Form',
                        header: '',
                        field: ({ FormId, Name, Order, PatientTypeId }) => <CopyForm formId={FormId} companyId={idCompany} name={Name} order={Order} patientType={PatientTypeId} iconSize='1.5em' successAction={ ()=> assignFormConfirmation() } setFormToBeAssignedId={(formId) => setFormToBeAssignedId(formId) } />,
                        formKey: "copyForm"
                    },
                    {
                        title: 'Assign Form',
                        header: '',
                        field: ({ FormId, CanAssignPatients }) => CanAssignPatients && <AssignFormToPatients openModal={ ()=> assignFormConfirmation(FormId) } />,
                        formKey: "assignForm"
                    }
                ]}
                rows={formList}
            />
        </div>
    )
}