import React, { useState } from 'react';
import PureModal from "components/ui/modal/pure-modal";
import ModalAskForCompliantMet from "./modal-ask-for-compliant-met";

const YesNoCheckbox = ({ defaultValue, disabled, onChange, hasMinimumTherapyDays }) => {

  const[changeValue,setChangeValue]  = useState(defaultValue);
  const [yesChecked, setYesChecked] = useState(false);
  const [noChecked, setNoChecked] = useState(false);
  const [intentionToSetAsCompliant, setIntentionToSetAsCompliant] = useState(false)

  const updateCheckboxes = () =>{
    setYesChecked(changeValue === true);
    setNoChecked(changeValue === false);
  }

  const handleYesChange = () => {
    if (!disabled) {
      if (!yesChecked) {
        setChangeValue(true);
        setIntentionToSetAsCompliant(true)
      } else {
        setChangeValue(null);
        setIntentionToSetAsCompliant(false)
      }
      compliantMetConfirm();
    }
  };

  const handleNoChange = () => {
    if (!disabled) {
      if (!noChecked) {
        setChangeValue(false);
      } else {
        setChangeValue(null);
      }
      setIntentionToSetAsCompliant(false)
      compliantMetConfirm();
    }
  };

  const compliantMetConfirm = () => {
    document.getElementById("compliantMetConfirmId").click();
  }

  const handleChange = () => {
    if (!disabled) {
      updateCheckboxes();
      onChange(changeValue);
    }
  };

  React.useEffect(() => {
    updateCheckboxes();
  }, [defaultValue])

  return (
    <div>
      <span style={{display:'flex', marginBottom: '0.6rem', alignItems: 'center', width: 'auto'}}>
        <label style={{marginRight: '2.75rem'}}>
          Is Compliant?
        </label>
        <span style={{display: 'inline-flex', width: "auto"}}>
          <label style={{marginRight: '0.75rem'}}>
            Yes
          </label>
        </span>

        <input
          type="checkbox"
          checked={yesChecked}
          onChange={handleYesChange}
          disabled={disabled}
          style={{margin: '0 0.75rem 0 0', display: 'inline-flex', alignSelf: 'center'}}
        />
        <span style={{display: 'inline-flex', width: "auto"}}>
          <label style={{marginRight: '0.75rem'}}>
            No
          </label>
        </span>
        <input
          type="checkbox"
          checked={noChecked}
          onChange={handleNoChange}
          disabled={disabled}
          style={{margin: '0', marginRight: '0.75rem', display: 'inline-flex', alignSelf: 'center'}}
        />
      </span>

      <PureModal
        renderTrigger={({ openModal }) => (<div id="compliantMetConfirmId" onClick={openModal}></div>)}
        renderContent={({ closeModal }) => { return <ModalAskForCompliantMet style={{ backgroundColor: 'red' }} isCompliant={changeValue === null} closeModal={closeModal} updateCompliantMet={handleChange} hasMinimumTherapyDays={hasMinimumTherapyDays} intentionToSetAsCompliant={intentionToSetAsCompliant}/> }}
      ></PureModal>
    </div>
  );
};

export default YesNoCheckbox;