import {
  take,
  call,
  put
} from 'redux-saga/effects'
import {
  IMPORT_TRACKING_REQUEST,
  responseImportTracking,
  errorImportTracking
} from 'actions/import-tracking'
import {
  message,
  errorMessage
} from 'actions/message'
import * as api from 'utils/api'

const SUBMIT_URL = 'imports/tracking'
const SUBMIT_SUCCESS_MESSAGE = 'Your fulfillment tracking was successfully submitted!'
const SUBMIT_ERROR_MESSAGE = 'An error occurred submitting your fulfillment tracking.'

function* submitTracking({ payload: { trackingData } }) {
  try {
    const response = yield call(api.post, SUBMIT_URL, trackingData)
    yield put(responseImportTracking(response))
    yield put(message(SUBMIT_SUCCESS_MESSAGE))
  } catch(error) {
    yield put(errorImportTracking(error))
    yield put(errorMessage(SUBMIT_ERROR_MESSAGE))
  }
}

export default function* watchImportTracking() {
  while (true) {
    const action = yield take(IMPORT_TRACKING_REQUEST)
    yield call(submitTracking, action)
  }
}
