import React, { Component } from "react";
import PropTypes from "prop-types";
import TwilioChat from "twilio-chat";
import "./App.css";

class TextChatGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userToken: {},
      messages: [],
      username: null,
      channel: null,
      participantName: "",
      participantSid: ""
    };
  }

  componentDidMount() {
    const index = this.props.greeting;
    if (index === 0) {
      this.addMessage({
        body:
          "Please wait, connecting to room(s) as " + this.props.userName + "..."
      });
    }

    const roomDetails = this.props.roomDetail;
    const tokenDetails = {
      identity: this.props.userName,
      jwt: this.props.twilioToken
    };

    this.setState(
      {
        roomDetails: roomDetails,
        userToken: tokenDetails,
        username: this.props.userName,
        APIMembers: this.props.members
      },
      () => {
        this.initiateChat();
      }
    );
  }

  initiateChat = () => {
    if (!this.state.chatInitiate) {
      this.getToken()
        .then(this.createChatClient)
        .then(this.joinChatChannel)
        .then(this.configureChannelEvents)
        .then(this.restoreMessages)
        .catch(error => {
          this.props.setTwilioError(error.message);
          this.addMessage({ body: error.message });
        });
    }
  };

  getToken = () => {
    return new Promise(resolve => {
      this.setState({ username: this.state.userToken.identity });
      resolve(this.state.userToken);
    });
  };

  createChatClient = token => {
    return new Promise((resolve, reject) => {
      TwilioChat.create(token.jwt)
        .then(client => {
          resolve(client);
        })
        .catch(error => reject(Error(error)));
    });
  };

  joinChatChannel = async chatClient => {
    try {
      this.addMessage({ body: "Joining chat room" });
      const channel = await chatClient.getChannelBySid(
        this.state.roomDetails.channel_sid
      );
      this.setState({ channel });
      if (channel.channelState.status !== "joined") {
        await channel.join();
      }
      this.addMessage({
        body: `Joined ${this.state.roomDetails.unique_name} channel...`
      });
      window.addEventListener("beforeunload", () => channel.leave());
      this.setState({
        chatInitiate: true
      });
      return channel;
    } catch (err) {
      console.error(err);
    }
  };

  addMessage = async message => {
    if (message.author && message.author.length > 0) {
      const participantDetail =
        this.state.APIMembers &&
        this.state.APIMembers.length > 0 &&
        this.state.APIMembers.filter(m => m.id === message.author) &&
        this.state.APIMembers.filter(m => m.id === message.author).length > 0 &&
        this.state.APIMembers.filter(m => m.id === message.author)[0];
      if (participantDetail) {
        message.author = participantDetail.name;
      }
    }
    const messageData = { ...message };
    this.props.addMessage(messageData);
  };

  sendErrorMessage = message => {
    this.props.fetchError(message);
  };

  handleNewMessage = async text => {
    if (this.state.channel) {
      await this.state.channel.sendMessage(text, {
        authorName: this.props.userName
      });
    }
  };

  configureChannelEvents = channel => {
    channel.on("messageAdded", message => {
      this.addMessage({
        author: message.author,
        ...message.state
      });
    });

    channel.on("memberJoined", member => {
      let PatientName = "Patient";
      const participantDetail =
        this.state.APIMembers &&
        this.state.APIMembers.length > 0 &&
        this.state.APIMembers.filter(m => m.id === member.identity) &&
        this.state.APIMembers.filter(m => m.id === member.identity).length >
          0 &&
        this.state.APIMembers.filter(m => m.id === member.identity)[0];
      if (participantDetail) {
        PatientName = participantDetail.name;
      }
      this.addMessage({ body: PatientName + ` has joined the channel.` });
    });

    channel.on("memberLeft", member => {
      let PatientName = "Patient";
      const participantDetail =
        this.state.APIMembers &&
        this.state.APIMembers.length > 0 &&
        this.state.APIMembers.filter(m => m.id === member.identity) &&
        this.state.APIMembers.filter(m => m.id === member.identity).length >
          0 &&
        this.state.APIMembers.filter(m => m.id === member.identity)[0];
      if (participantDetail) {
        PatientName = participantDetail.name;
      }
      this.addMessage({ body: PatientName + ` has left the channel.` });
    });
  };

  render() {
    return <div style={{ display: "none" }}>&nbsp;</div>;
  }
}

export default TextChatGeneral;

TextChatGeneral.propTypes = {
  greeting: PropTypes.string,
  userName: PropTypes.string,
  twilioToken: PropTypes.string,
  members: PropTypes.arrayOf(PropTypes.object),
  roomDetail: PropTypes.object,
  userId: PropTypes.string.isRequired,
  setTwilioError: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
  fetchError: PropTypes.func.isRequired
};
