export const FULFILLMENT_INTERNAL = "Internal";
export const FULFILLMENT_MCKESSON = "McKesson";
export const FULFILLMENT_VGM = "VGM";
export const FULFILLMENT_INDEPENDENCE_MEDICAL = "Independence Medical";
export const FULFILLMENT_PPM = "PPM";
export const FULFILLMENT_CPAP_DROPSHIP = "CPAP Dropship";

// TODO should probably move alot of this stuff to backend form lists table

export const FULFILLMENT = [
  {
    text: "CPAP DROPSHIP",
    value: FULFILLMENT_CPAP_DROPSHIP
  },
  {
    text: "Independence Medical",
    value: FULFILLMENT_INDEPENDENCE_MEDICAL
  },
  {
    text: "Internal",
    value: FULFILLMENT_INTERNAL
  },
  {
    text: "VGM",
    value: FULFILLMENT_VGM
  },
  {
    text: "PPM",
    value: FULFILLMENT_PPM
  },
  {
    text: "McKesson",
    value: FULFILLMENT_MCKESSON
  }
];

export const ELIGIBILITY_ELIGIBLE = "Eligible";
export const ELIGIBILITY_FOLLOW_UP = "Follow Up";
export const ELIGIBILITY_SECOND_FOLLOW_UP = "Second Follow Up";
export const ELIGIBILITY_CLEARED_DATE = "Cleared Date";

export const ELIGIBILITY = [
  {
    text: "Eligible",
    value: ELIGIBILITY_ELIGIBLE
  },
  {
    text: "Follow Up",
    value: ELIGIBILITY_FOLLOW_UP
  },
  {
    text: "Second Follow Up",
    value: ELIGIBILITY_SECOND_FOLLOW_UP
  },
  {
    text: "Cleared Date",
    value: ELIGIBILITY_CLEARED_DATE
  }
];

export const STATES = [
  { value: "AL", text: "Alabama" },
  { value: "AK", text: "Alaska" },
  { value: "AZ", text: "Arizona" },
  { value: "AR", text: "Arkansas" },
  { value: "AA", text: "Armed Forces Americas" },
  { value: "AE", text: "Armed Forces Others" },
  { value: "AP", text: "Armed Forces Pacific" },
  { value: "CA", text: "California" },
  { value: "CO", text: "Colorado" },
  { value: "CT", text: "Connecticut" },
  { value: "DE", text: "Delaware" },
  { value: "DC", text: "District Of Columbia" },
  { value: "FL", text: "Florida" },
  { value: "GA", text: "Georgia" },
  { value: "HI", text: "Hawaii" },
  { value: "ID", text: "Idaho" },
  { value: "IL", text: "Illinois" },
  { value: "IN", text: "Indiana" },
  { value: "IA", text: "Iowa" },
  { value: "KS", text: "Kansas" },
  { value: "KY", text: "Kentucky" },
  { value: "LA", text: "Louisiana" },
  { value: "ME", text: "Maine" },
  { value: "MD", text: "Maryland" },
  { value: "MA", text: "Massachusetts" },
  { value: "MI", text: "Michigan" },
  { value: "MN", text: "Minnesota" },
  { value: "MS", text: "Mississippi" },
  { value: "MO", text: "Missouri" },
  { value: "MT", text: "Montana" },
  { value: "NE", text: "Nebraska" },
  { value: "NV", text: "Nevada" },
  { value: "NH", text: "New Hampshire" },
  { value: "NJ", text: "New Jersey" },
  { value: "NM", text: "New Mexico" },
  { value: "NY", text: "New York" },
  { value: "NC", text: "North Carolina" },
  { value: "ND", text: "North Dakota" },
  { value: "OH", text: "Ohio" },
  { value: "OK", text: "Oklahoma" },
  { value: "OR", text: "Oregon" },
  { value: "PA", text: "Pennsylvania" },
  { value: "RI", text: "Rhode Island" },
  { value: "SC", text: "South Carolina" },
  { value: "SD", text: "South Dakota" },
  { value: "TN", text: "Tennessee" },
  { value: "TX", text: "Texas" },
  { value: "UT", text: "Utah" },
  { value: "VT", text: "Vermont" },
  { value: "VA", text: "Virginia" },
  { value: "WA", text: "Washington" },
  { value: "WV", text: "West Virginia" },
  { value: "WI", text: "Wisconsin" },
  { value: "WY", text: "Wyoming" },
  { value: "PR", text: "Puerto Rico" }
];

export const outreachCycleOptions = [...Array(36).keys()].map(value => ({
  value: value + 1,
  text: `Cycle ${value + 1}`
}));

export const yesNoOptions = [
  { value: true, text: "Yes" },
  { value: false, text: "No" }
];

export const yesNoNullOptions = [
  { value: "yes", text: "Yes" },
  { value: "no", text: "No" },
  { value: "empty", text: "Empty" }
];

export const platformOptions = [
  {
    text: "Web App",
    value: "Web App"
  },
  {
    text: "IVR",
    value: "IVR"
  },
  {
    text: "Mobile - Patient Portal",
    value: "Mobile App"
  },
  {
    text: "Web - Patient Portal",
    value: "Patient Portal"
  },
  {
    text: "Email Order",
    value: "Patient Portal From Email"
  },
  {
    text: "SMS Order",
    value: "Patient Portal From SMS"
  },
  {
    text: "Philips Import",
    value: "Philips Import"
  }
];

export const recallStatusOptions = [
  { value: "Not Started", text: "Not Started" },
  { value: "Pending", text: "Pending" },
  { value: "Completed", text: "Completed" },
  { value: "Not Affected", text: "Not Affected" },
  { value: "Not Affected - Automation", text: "Not Affected - Automation" }
];

export const taskHeaderOptions = [
  { value: "Insurance Change", text: "Insurance Change", active: true },
  { value: "Address Change", text: "Address Change", active: true },
  { value: "Order Update", text: "Order Update", active: true },
  { value: "Inquiry", text: "Inquiry", active: true }
];
