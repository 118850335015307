import React, { useEffect, useState } from "react";
import { PieChart, Pie, Tooltip } from "recharts";
import { PropTypes } from 'prop-types';
import HeaderChart from './header-chart';

export default function ComplianceDashboardPieChart({ data, onItemSelected, startDate, endDate, companyId, isFetching }) {

    const [usingIndividualInfo, setUsingIndividualInfo] = useState(false)
    const [chartData, setChartData] = useState([])
    const [dataForCheckboxList, setDataForCheckboxList] = useState([])

    useEffect(() => {
        if (data) {
            var newData = getDataWithColors(data.Results);
            setChartData(newData);

            const checkItems = data.GrouperOptions.map(({ Text, Value}) =>{
                return {value: Value, text: Text, elementId: "item" + Value}
            });
            setDataForCheckboxList(checkItems);

        }
    }, [data]);

    const updateChartData = (individualData) => {
        setUsingIndividualInfo(true);
        setChartData(getDataWithColors(individualData));
      };


      const getDataWithColors = (basicData) =>{
        return basicData.map((value) => {
            return {
                label: value.Grouper.Text + ", " + value.Count + ", " + value.Percentage + "%",
                value: parseInt(value.Percentage),
                fill: getRandomBluishColor(49, 130, 189),
                doctorNpi: value.Grouper.Value
            };
            }).filter(element => element.value > 0);
      }

    const getRandomBluishColor = (firstValue, secondValue, thirdValue) => {
        return `rgb(${firstValue + (50 * Math.random())}, ${secondValue + (70 * Math.random())}, ${thirdValue + (100 * Math.random())})`;
    }

    const CustomTooltip = (data) => {
        if (data.active && data.payload && data.payload.length) {
          return (
            <div className="bar-charts-custom-tooltip">
              <p className="custom-tooltip-label">{data.payload[0].payload.label}</p>
            </div>
          );
        }
        return null;
    };

    const splitString = (labelString) => {
        let searchedLength = 25;
        if (labelString.length > searchedLength) {
          let aux = labelString.slice(0, searchedLength);
          aux += "...";
          return aux;
        } else {
          return labelString;
        }
    };

    let RenderLabel = function (props) {
        const RADIAN = Math.PI / 180;
        const radius = 25 + props.innerRadius + (props.outerRadius - props.innerRadius);
        const x = props.cx + radius * Math.cos(-props.midAngle * RADIAN);
        const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN);

        return (
            props.value != 0 ?
                <text
                    x={x}
                    y={y}
                    fontSize='14'
                    dominantBaseline="central"
                    fill={props.fill}
                    textAnchor={props.x > props.cx ? 'start' : 'end'}
                >
                    {splitString(props.label)}
                </text>
            :
                <g><text x={500} y={y} fill="#transparent" rotate="90"></text></g>
       )
    }

    return (
        <div className="interactive-graph-container">
            <HeaderChart
                categoryName={data.CategoryName}
                category={data.Category}
                startDate={startDate}
                endDate={endDate}
                companyId={companyId}
                updateData={updateChartData}
                dataForCheckboxList={dataForCheckboxList}
                isFetching={isFetching}
            />
            {
                !isFetching && chartData?.length === 0?
                    <div>
                        <h5 style={{textAlign: "center", color: "red"}}>
                        {usingIndividualInfo?
                            "No registers found for the selected filters"
                            : "No registers found for this chart"
                        }
                        </h5>
                    </div>
                :
                    <PieChart width={700} height={400}>
                        <Pie data={chartData}
                            dataKey="value"
                            nameKey="label"
                            cx="50%"
                            cy="50%"
                            isAnimationActive={false}
                            onClick={({ doctorNpi, label }) => {
                                onItemSelected(doctorNpi, label.split(',')[0]);
                            }}
                            label={RenderLabel}
                        />
                        <Tooltip content={<CustomTooltip />}/>
                    </PieChart>
            }
            </div>
    )
}

ComplianceDashboardPieChart.propTypes = {
    data: PropTypes.object,
    onItemSelected: PropTypes.func,
    startDate: PropTypes.date,
    endDate: PropTypes.date,
    companyId: PropTypes.string,
    isFetching: PropTypes.bool
}