import React, { useEffect } from 'react'
import { useState } from 'react'
import IoTrashA from "react-icons/lib/io/trash-a";
import IoPlus from "react-icons/lib/io/plus";
import SectionHeader from 'components/profile/section_header.jsx';
import '../pages/companyForms.css'

export default function ModalOptionQuestion({handleCancel, questionsOptions, saveQuestionOptions, showTaskActions, taskActions, toEditQuestion, questionData}) {

    const [alreadyExistsOption, setAlreadyExistsOption] = useState(false);

    const [questionsOptionsSelect, setQuestionsOptionsSelect] = useState([]);

    const [countOption, setCountOption] = useState(questionData ? questionData.Options.length : 0);

    const [questionOption, setQuestionOption] = useState({id:'0', name:''});

    const [newQuestionOption, setNewQuestionOption] = useState(
        {Text:'', 
        AlreadyExists:false,
        SpanishText:'',
        TwilioKeyPadOption:'0',
        Order:0,
        TaskActionId:'0'
        }
    );

    const [newQuestionText, setNewQuestionText] = useState(toEditQuestion ? {newText: questionData.Text, newSpanishText: questionData.SpanishText} : {newText: "", newSpanishText: ""})

    const [newRequiredAndNAStatus, setNewRequiredAndNAStatus] = useState(toEditQuestion ? {IsRequired: questionData.IsRequired, HasNAOption: questionData.HasNAOption} : {IsRequired: true, HasNAOption: false})

    const [error, setError] = useState(false);

    const [isTwilioDisabled, setIstwilioDisabled] = useState(false)

    useEffect(() => {
      
        toEditQuestion && questionData ? setQuestionsOptionsSelect((questionsOptionsSelect) => [...questionsOptionsSelect, ...questionData.Options.map(obj => {return{...obj, TwilioKeyPadOption: obj.TwilioKeyPadOption.toString(), TaskActionId: obj.TaskActionId ? obj.TaskActionId : null}})]) : null
     
    }, [taskActions])
    

    const showQuestionsOptions=()=>{
        return questionsOptions.map((op, i) => (
            <option  key={i} value={op.ItemId}>{op.ItemValue}</option>
        ))
    }

    const showTwilioKeyPadOption=()=>{

        const nnumber= [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

        return nnumber.map((op, i) => {
                return (
                    <option key={i} value={op}>{op}</option>
                ) 
        })
    }

    const addQuestionsOptions=()=>{

        const options={
            FormQuestionOptionId: questionOption.id,
            Text:questionOption.name,
            Order: countOption +1,
            AlreadyExists:alreadyExistsOption,
            TaskActionId:newQuestionOption.TaskActionId!=='0' ?  newQuestionOption.TaskActionId : null,
            TwilioKeyPadOption:newQuestionOption.TwilioKeyPadOption
        }

        if (validateOptionsTable()){
            setQuestionsOptionsSelect([...questionsOptionsSelect, options ]);
            setCountOption(options.Order);
            setError(false);
        }
        else{
            setError(true);
        }
        setQuestionOption({...questionOption, id:'0'})
    }

    const addNewQuestionOption = () =>{

        if (validateOptionsTable()){

            const options={
                FormQuestionOptionId: null,
                Text:newQuestionOption.Text,
                SpanishText:newQuestionOption.SpanishText,
                Order: countOption +1,
                AlreadyExists:alreadyExistsOption,
                TwilioKeyPadOption:newQuestionOption.TwilioKeyPadOption,
                TaskActionId:newQuestionOption.TaskActionId!=='0' ?  newQuestionOption.TaskActionId : null
            }
            setQuestionsOptionsSelect([...questionsOptionsSelect, options ]); 
            setCountOption(options.Order);
            setNewQuestionOption({...newQuestionOption, Text:'', SpanishText:'', QuestionType:'0', TaskActionId:'0', TwilioKeyPadOption:'-1'});
            
        }
        else
           setError(true);

        
    }


    const handleChangeQuestionOption =(e)=>{

        const option = +e.target.value

        if (option){
            const find = questionsOptions.find(x=>x.ItemId=== option);   
            setQuestionOption({id:find.ItemId, name:find.ItemValue, TwilioKeyPadOption:find.TwilioKeyPadOption ? find.TwilioKeyPadOption : 4});
            setNewQuestionOption({...newQuestionOption, TwilioKeyPadOption: find.TwilioKeyPadOption ? find.TwilioKeyPadOption : 4});
            setIstwilioDisabled(true)
        }
        else    
            setQuestionOption({id:'0', name:''})

        setError(false);
    }

    const deleteQuestionOption=(id)=>{

        const findDelete= questionsOptionsSelect.filter(x=>x.Order!== id)

        const optionsDelete = findDelete.map((item,i) => ({
            Text:item.Text,
            FormQuestionOptionId:item.FormQuestionOptionId,
            Order: i+1,
            TaskActionId:item.TaskActionId,
            TwilioKeyPadOption:item.TwilioKeyPadOption
        }));

        if (optionsDelete.length)
            setCountOption(optionsDelete[optionsDelete.length-1].Order);  
        else
            setCountOption(0);  

        setQuestionsOptionsSelect(optionsDelete);
    }

    const handleChangeNewQuestionOption = (e)=>{

        const option= e.target.name === "TaskActionId" ? Number(e.target.value) : e.target.value;
        const name= e.target.name;

        setNewQuestionOption({...newQuestionOption, [name]: option});

        setError(false);
    }

    const handleChangeNewText = (e)=>{

        const option= e.target.value;
        const name= e.target.name;

        setNewQuestionText({...newQuestionText, [name]: option});

        setError(false);
    }

    const handleChangeRequiredAndNA = (e)=>{
        
        const name = e.target.name;
        if(name == "IsRequired"){
            setNewRequiredAndNAStatus({ ...newRequiredAndNAStatus, ["IsRequired"]: !newRequiredAndNAStatus.IsRequired });
        } else if(name == "HasNAOption") {
            setNewRequiredAndNAStatus({ ...newRequiredAndNAStatus, ["HasNAOption"]: !newRequiredAndNAStatus.HasNAOption });
        }
    }

    const showTableQuestionsOptions = ()=>{
        
        return (
            questionsOptionsSelect?.length ?
                <div className='scrollTableAQuestion'>
                    <table>
                        <thead>
                            <tr>{showHeadersQuestionOption()}</tr>
                        </thead>
                        <tbody>{showQuestionsOptionsSelect()}</tbody>
                    </table>
                </div>
            : <p><span>No Found Options</span></p>
        )
    }

    const showHeadersQuestionOption = () => {

        const headers = ["ORDER", "OPTION","TWILIO KEY PAD OPTION","TASK ACTION",""];
           return headers.map((h, i) => <th key={i}>{h}</th>);     
    };

    const getNameList = (list, id) => {
        return id ? list.find(x => x.ItemId == id).ItemValue : '';
    }
    
    const showQuestionsOptionsSelect=()=>{
        return questionsOptionsSelect?.map((item, i) => (
            <tr key={i}>
                <td>{item.Order}</td>
                <td>{item.Text}</td>
                <td>{item.TwilioKeyPadOption}</td>
                <td>{taskActions && getNameList(taskActions, item.TaskActionId)}</td>
                <td><IoTrashA style = {{ color: "red", fontSize: "1.5em",  cursor: 'pointer' }} onClick={()=>deleteQuestionOption(item.Order)}></IoTrashA></td>
            </tr>
        ))

    }


    const validateOptionsTable = () =>{

        const  findQ=questionsOptionsSelect.find(x=>x.Text=== (alreadyExistsOption ?  questionOption.name : newQuestionOption.Text) || x.TwilioKeyPadOption===newQuestionOption.TwilioKeyPadOption);

        return !findQ;
    }

    return (
        <div className='patient-types-statuses-container'>
            <div className="section-title">
                <div className="dd_col-12">
                    <SectionHeader>MULTIPLECHOICE QUESTION</SectionHeader>
                </div>
                {
                    toEditQuestion &&
                    <div>
                        <div className="dd_col-6">
                            <label>Question Text</label>
                            <input 
                                name="newText"                          
                                type="text"
                                value={newQuestionText.newText}
                                onChange={handleChangeNewText} 
                                disabled={questionData.AlreadyExists}
                            />
                        </div>
                        <div className="dd_col-6">  
                            <label>Question Spanish Text</label>                    
                            <input 
                                name="newSpanishText"                          
                                type="text"
                                value={newQuestionText.newSpanishText}
                                onChange={handleChangeNewText}  
                                disabled={questionData.AlreadyExists}
                            />
                        </div>
                    </div>
                }
                {
                    questionData?.AlreadyExists &&
                    <div className="dd_col-12">   
                        <span style={{color:'red'}}>Cannot change text of an already existing question</span>
                    </div> 
                }
                {
                    toEditQuestion &&
                    <div className="dd_col-12">
                        <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <input type="checkbox" value={newRequiredAndNAStatus.IsRequired} name="IsRequired" onChange={handleChangeRequiredAndNA} checked={newRequiredAndNAStatus.IsRequired} />
                        <label>Required</label>
                        <input type="checkbox" value={newRequiredAndNAStatus.HasNAOption} name="HasNAOption" onChange={handleChangeRequiredAndNA} checked={newRequiredAndNAStatus.HasNAOption} />
                        <label>Add N/A</label>
                    </div>
                }
                <div className='dd_col-12'>
                    <fieldset>
                        <legend>Choice Construction</legend>
                            <div className="dd_col-12">
                                    <input type="checkbox" name="alreadyExistsOption" value="null" onChange={()=> { setAlreadyExistsOption(!alreadyExistsOption); setIstwilioDisabled(false)}}  checked= {alreadyExistsOption} />
                                    <label>Already Exists</label><br/>
                                </div>
                                {alreadyExistsOption ? 

                                    <div>
                                        <div className="dd_col-4">
                                            <label>Select Option</label>
                                            <select name="idQuestionOption" value={questionOption.id} onChange={handleChangeQuestionOption}>
                                                <option value='0'></option>
                                                {showQuestionsOptions()}
                                            </select>
                                        </div>
                                    </div>
                                :
                            
                                <div>
                                    <div className="dd_col-6">
                                        <label>Text</label>
                                        <input 
                                            name="Text"                          
                                            type="text"
                                            value={newQuestionOption.Text}
                                            onChange={handleChangeNewQuestionOption}  
                                        />
                                    </div>

                                    <div className="dd_col-6">  
                                        <label>Spanish Text</label>                    
                                        <input 
                                            name="SpanishText"                          
                                            type="text"
                                            value={newQuestionOption.SpanishText}
                                            onChange={handleChangeNewQuestionOption}  
                                        />
                                    </div>  
                                </div>
                                }
                                <div className="dd_col-2">

                                    <label>Twilio Key Pad Option</label> 
                                    <select disabled={isTwilioDisabled} name="TwilioKeyPadOption" value={newQuestionOption.TwilioKeyPadOption} onChange={handleChangeNewQuestionOption}>
                                        <option value='-1'></option>
                                        {showTwilioKeyPadOption()}
                                    </select>
                                </div>      
                                <div className="dd_col-6">

                                    <label>Task Action Id</label> 
                                    <select name="TaskActionId" value={newQuestionOption.TaskActionId} onChange={handleChangeNewQuestionOption}>
                                        <option value='0'></option>
                                        {showTaskActions()}
                                    </select>
                                </div>
                                
                                <div hidden={alreadyExistsOption} className="dd_col-2">   
                                        <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>                                           
                                        <IoPlus hidden={newQuestionOption.Text==='' || newQuestionOption.SpanishText===''} 
                                        style = {{ color: "#007bff", fontSize: "2em",   }} 
                                        onClick={addNewQuestionOption} > </IoPlus>                                           
                                </div>
                                {questionOption.id!=='0' ?
                                    <div> 
                                        <div className="dd_col-2">   
                                            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>                                           
                                            <IoPlus style = {{ color: "#007bff", fontSize: "2.3em",  cursor: 'pointer' }} onClick={addQuestionsOptions}> </IoPlus>                                           
                                        </div> 
                                    </div> 
                                :null}
                                <div className="dd_col-12">   
                                    <span style={{color:'red'}} hidden={!error}>An option with the same Text or Twilio Key Pad Option already exists</span>
                                </div> 
                    </fieldset>
                </div>
                <div className='dd_col-12'>
                    <fieldset>
                        <legend>Table Options</legend>
                        {showTableQuestionsOptions()}
                    </fieldset>
                </div>
                <div className='dd_col-12'>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <button
                            type="button"
                            className="button"
                            disabled={!questionsOptionsSelect?.length || (toEditQuestion && (!newQuestionText.newText.length > 0 || !newQuestionText.newSpanishText.length > 0))}
                            onClick={()=>{
                                saveQuestionOptions(questionsOptionsSelect, [], questionData, toEditQuestion, newQuestionText, newRequiredAndNAStatus)
                                handleCancel()
                            }}
                        >
                            Save
                        </button>
                    </div>    
                </div>
            </div>
        </div>
    )
}
