import { createReducer } from "utils/reducer";
import { createSelector } from "reselect";
import { ENUM_LOAD } from "actions/enum";
import * as R from "ramda";

const load = (state, { payload }) => ({
  ...state,
  ...payload
});

const enumRoot = createReducer(
  {
    formOfContact: [],
    fulfillmentTypes: [],
    orderStatuses: [],
    admins: [],
    inactivationReasons: [],
    rotechInactivationReasons: [],
    orderRejectedReasons: [],
    contactActions: [],
    patient_email_templates: {}
  },
  {
    [ENUM_LOAD]: load
  }
);

export default enumRoot;

const getEnumOrderStatuses = createSelector(
  [state => state.get("enums")],
  R.propOr([], "orderStatuses")
);

const getOrderStatusId = createSelector(
  [getEnumOrderStatuses, (_, name) => name],
  (statuses, statusName) => 
    statuses.find(x => x.text == statusName)?.value
);

const getEnumOrderStatusText = createSelector(
  [getEnumOrderStatuses, (_, statusValue) => statusValue],
  (statuses, statusValue) => {
    const status =
      statuses && statuses.find(({ value }) => value === statusValue);
    return status && status.text;
  }
);

const getEnumFulfillmentTypes = createSelector(
  [state => state.get("enums")],
  enumState => R.propOr([], "fulfillmentTypes", enumState)
);

const getEnumFormOfContact = createSelector(
  [state => state.get("enums")],
  R.propOr([], "formOfContact")
);

const getFormOfContactOptions = createSelector(
  [getEnumFormOfContact],
  R.map(({ value, text, active }) => ({
    value: text,
    text,
    key: value,
    active
  }))
);

const getEnumContactActions = createSelector(
  [state => state.get("enums")],
  R.propOr([], "contactActions")
);

const getContactActionsOptions = createSelector(
  [getEnumContactActions],
  R.map(({ value, text }) => ({
    value: text,
    text,
    key: value
  }))
);

const getEnumGenderTypes = createSelector(
  [state => state.get("enums")],
  R.propOr([], "genderTypes")
);

const getGenderOptions = createSelector(
  [getEnumGenderTypes],
  R.map(({ value, text }) => ({
    value: text,
    text,
    key: value
  }))
);

const getEnumInsuranceRelationshipTypes = createSelector(
  [state => state.get("enums")],
  R.propOr([], "insuranceRelationshipTypes")
);

const getInsuranceRelationshipOptions = createSelector(
  [getEnumInsuranceRelationshipTypes],
  R.map(({ value, text }) => ({
    value: text,
    text,
    key: value
  }))
);

const getEnumInsuranceCompanies = createSelector(
  [state => state.get("enums")],
  R.propOr([], "insuranceCompanies")
);

const getInsuranceCompanyOptions = createSelector(
  [getEnumInsuranceCompanies],
  R.map(({ value, text }) => ({
    value: text,
    text,
    key: value
  }))
);

export const getEnumAdmins = createSelector(
  [state => state.get("enums")],
  R.propOr([], "admins")
);

const getEnumInactivationReasons = createSelector(
  [state => state.get("enums")],
  R.propOr([], "inactivationReasons")
);

const getEnumRotechInactivationReasons = createSelector(
  [state => state.get("enums")],
  R.propOr([], "rotechInactivationReasons")
);

const getEnumOrderRejectedReasons = createSelector(
  [state => state.get("enums")],
  R.propOr([], "orderRejectedReasons")
);

const getPatientEmailTemplates = createSelector(
  state => R.propOr({}, "patient_email_templates", state.get("enums")),
  R.values
);

const getPatientEmailTemplate = createSelector(
  [
    state => R.propOr({}, "patient_email_templates", state.get("enums")),
    (_, template_id) => template_id
  ],
  (email_templates, template_id) => R.propOr({}, template_id, email_templates)
);

export const selectors = {
  getEnumFormOfContact,
  getEnumContactActions,
  getEnumFulfillmentTypes,
  getEnumOrderStatuses,
  getOrderStatusId,
  getEnumAdmins,
  getEnumOrderStatusText,
  getEnumInactivationReasons,
  getEnumRotechInactivationReasons,
  getFormOfContactOptions,
  getContactActionsOptions,
  getGenderOptions,
  getPatientEmailTemplates,
  getPatientEmailTemplate,
  getEnumOrderRejectedReasons,
  getEnumGenderTypes,
  getEnumInsuranceRelationshipTypes,
  getInsuranceRelationshipOptions,
  getInsuranceCompanyOptions
};
