import React from "react";
import PropTypes from "prop-types";
import useMousePosition from "./use-mouse-position";
import { formatTimeFromHour } from "utils/dates";
import {
  convertDatesToGridSpan,
  convertGridSpanToDates,
  gridPosTodate,
  isInvalidSlot,
  isBefore
} from "./scheduler-helpers";
import { TimeSlotContext } from "./use-availabilityScheduler";

const Times = Array.from(Array(24).keys()).filter(t => t >= 5 && t <= 19);
const CellHeight = 10; // 10px
const CellWidth = 135; // 135px
const getRow = y => Math.floor(y / CellHeight);
const getCol = x => Math.floor(x / CellWidth);

const Calandar = React.memo(({ children }) => {
  const [mousePosition, schedulerRef] = useMousePosition();
  const {
    setSelected,
    week,
    editSlot,
    setEditSlot,
    selected,
    userTimeSlots,
    setPendingTimeSlot
  } = React.useContext(TimeSlotContext);
  return (
    <div className="calendar">
      <div
        style={{
          borderBottom: "1px solid rgba(0,0,0,.25)"
        }}
      />
      <div className="calendar-daysofweek-header">
        <div>Sunday</div>
        <div>Monday</div>
        <div>Tuesday</div>
        <div>Wednesday</div>
        <div>Thursday</div>
        <div>Friday</div>
        <div>Saturday</div>
      </div>
      <div className="times">
        {Times.map(t => (
          <div key={t} style={{ gridRow: t - 4 }}>
            {formatTimeFromHour(t)}
          </div>
        ))}
      </div>
      <div
        ref={schedulerRef}
        className="time-slots-container"
        onClick={e => {
          e.preventDefault();
        }}
        onMouseLeave={() => {
          setSelected({
            row1: null,
            col: null
          });
        }}
        onMouseDown={() => {
          const row1 = getRow(mousePosition.y);
          const col = getCol(mousePosition.x);
          const selectedStartTime = gridPosTodate(row1, col, week);
          if (!isBefore(selectedStartTime)) {
            setSelected(() => ({
              row1,
              col
            }));
          }
          if (editSlot) setEditSlot();
        }}
        onMouseUp={() => {
          if (selected.col !== null && selected.row1 !== null) {
            const row2 = getRow(mousePosition.y + CellHeight);
            const startRow = selected.row1 <= row2 ? selected.row1 : row2 - 1;
            const endRow = row2 >= selected.row1 ? row2 : selected.row1 + 1;
            const overlappingAppointment = userTimeSlots
              .map(convertDatesToGridSpan)
              .filter(({ col }) => col === selected.col)
              .find(slot =>
                isInvalidSlot(
                  { row1: slot.row1, row2: slot.row2 },
                  { row1: startRow, row2: endRow }
                )
              );

            const newTimeSlot = {
              ...convertGridSpanToDates({
                row1: startRow,
                row2: endRow,
                col: selected.col,
                week
              })
            };
            if (!overlappingAppointment && !isBefore(newTimeSlot.startTime)) {
              setPendingTimeSlot(newTimeSlot);
            }
            setSelected({
              row1: null,
              col: null
            });
          }
        }}
      >
        {children}
      </div>
    </div>
  );
});

Calandar.propTypes = {
  children: PropTypes.node
};

export default Calandar;
