import React from "react";
import PropTypes from "prop-types";
import { PageHeader } from "components/ui";
import useApiQuery, { Table } from "hooks/useQuery/api-query";
import { connect } from "react-redux";
import { getFormOptionsSleepCoachWithUserFirst, selectors } from "reducers";
import { useLocation } from "react-router-dom";
import { pathOr } from "ramda";
import OrderTableRow from "./table-row";
import OrdersTableControls from "./table-controls";
import useRowSelect, {
  SelectRowContext,
  RowSelectHeaderCell
} from "hooks/useRowSelectNew";
import useOrderTableSettings, { OrdersTableSettingsContext } from "./settings";
import CompanyTeamSelect from "components/shared/company-team-select";
import SleepCoachTeamSelect from "components/shared/coach-team-select";
import { platformOptions, yesNoOptions } from "utils/enum";
import { AssignmentFilterContainer } from "components/screens/dashboard/sleep-coach-dashboard/patients-table/sleepcoach-assignemtn-filter";
import DateRangeHeaderColumn from "hooks/useQuery/api-query/table-header/date-range-header-filter";

const OrdersTable = ({
  orderStatusOptions,
  companies,
  admins,
  sleepCoaches
}) => {
  const location = useLocation();
  const initialFilters = pathOr({}, ["state", "filters"], location);
  const initialQuery = {
    sort: {},
    filters: initialFilters,
    pagination: { page: 1, per: 150 }
  };
  const { query, queryDispatcher, rows, data, isFetching, fetchData } =
    useApiQuery("orders", initialQuery, "orders");
  const {
    toggleAllRows,
    isRowSelected,
    toggleRow,
    clearSelectedRows,
    selectedRows
  } = useRowSelect(rows, "id", query, row => ({
    invoice_number: row.invoice_number
  }));

  const tableOptions = useOrderTableSettings(query, queryDispatcher);
  const {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings,
    columns
  } = tableOptions;

  return (
    <div className="orders-table-page">
      <PageHeader title="Orders" />
      <OrdersTableSettingsContext.Provider
        value={{
          columns,
          isColumnVisible,
          resetTableSettings,
          tableSettings,
          updateTableSettings
        }}
      >
        <SelectRowContext.Provider
          value={{
            toggleAllRows,
            toggleRow,
            selectedRows,
            isRowSelected,
            clearSelectedRows
          }}
        >
          <Table
            query={query}
            data={data}
            queryDispatcher={queryDispatcher}
            isFetching={isFetching}
            containerClass="query-table orders-table dynamic-columns"
            columns={[
              {
                header: "all",
                field: "all",
                Cell: RowSelectHeaderCell
              },
              {
                header: "Invoice Number",
                field: "invoice_number"
              },
              {
                header: "Patient",
                field: "patient"
              },
              {
                header: "Account #",
                field: "patient_account_number"
              },
              {
                header: "Status",
                field: "status",
                filters: orderStatusOptions
              },
              {
                header: "Admin",
                field: "claimed_by",
                filterDownSelect: true,
                filters: admins
              },
              {
                header: "Company",
                field: "company",
                filters: companies,
                filterDownSelect: true,
                SubFilter: CompanyTeamSelect
              },
              {
                header: "Sleep Coach",
                field: "sleep_coach",
                filters: sleepCoaches,
                filterDownSelect: true,
                SubFilter: SleepCoachTeamSelect
              },
              {
                header: "Assignment",
                field: "sleep_coach_assignment",
                FilterComponent: AssignmentFilterContainer,
                filterKey: "sleep_coach_assignment",
                filterDownSelect: true
              },
              {
                header: "Team",
                field: "team_name"
              },
              {
                header: "Submitted",
                field: "submitted",
                Cell: () => (
                  <DateRangeHeaderColumn header="Submitted" field="submitted" />
                )
              },
              {
                header: "Platform",
                field: "platform",
                filters: platformOptions
              },
              {
                header: "Status changed",
                field: "status_changed"
              },
              {
                header: "Order Progress",
                field: "hold_count_open"
              },
              {
                header: "Last Updated",
                field: "last_updated"
              },
              {
                header: "Delayed Date",
                field: "pending_delayed_date"
              },
              {
                header: "Insurance",
                field: "insurance"
              },

              {
                header: "Priority",
                field: "high_priority"
              },
              {
                header: "Follow Up Reason",
                field: "followUpReason"
              },
              {
                header: "On Hold Reason",
                field: "onHoldReason"
              },
              {
                header: "Reason Rejected",
                field: "reason"
              },
              {
                header: "Pending Info",
                field: "pending_info"
              },
              {
                header: "Order Loading",
                field: "order_loading",
                filters: yesNoOptions
              }
              // ,{
              //   header: "Order Loading - Web App",
              //   field: "order_loading_web_app",
              //   filters: yesNoOptions
              // }
              // ,{
              //   header: "Order Loading - IVR",
              //   field: "order_loading_IVR",
              //   filters: yesNoOptions
              // }
              // ,{
              //   header: "Order Loading - Email Orders",
              //   field: "order_loading_email",
              //   filters: yesNoOptions
              // }
              // ,{
              //   header: "Order Loading - Patient Portal",
              //   field: "order_loading_patient_portal",
              //   filters: yesNoOptions
              // }
              // ,{
              //   header: "Order Loading = Mobile App",
              //   field: "order_loading_mobile_app",
              //   filters: yesNoOptions
              // }
            ].filter(isColumnVisible)}
            rows={rows}
            TableRow={OrderTableRow}
            rowKey="id"
            perPageOptions={[
              { text: "50", value: 50 },
              { text: "150", value: 150 },
              { text: "500", value: 500 }
            ]}
            Controls={() => (
              <OrdersTableControls
                query={query}
                selectedRows={selectedRows}
                clearSelectedRows={clearSelectedRows}
                isFetching={isFetching}
                refreshData={() => {
                  fetchData(query);
                }}
              />
            )}
          />
        </SelectRowContext.Provider>
      </OrdersTableSettingsContext.Provider>
    </div>
  );
};

OrdersTable.propTypes = {
  orderStatusOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  admins: PropTypes.arrayOf(PropTypes.object).isRequired,
  sleepCoaches: PropTypes.arrayOf(PropTypes.object).isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(state => ({
  orderStatusOptions: selectors.getOrderStatusTextOptions(state),
  companies: selectors.getFormOptionsCompany(state),
  sleepCoaches: getFormOptionsSleepCoachWithUserFirst(state),
  admins: selectors.getEnumAdmins(state),
  sleepCoachOptions: selectors.getFormOptionsSleepCoachWithUserFirst(state)
}))(OrdersTable);
