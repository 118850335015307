import C from "action-types";
import { makeFetchTuple } from "actions/fetch";
import { put, get, post } from "utils/api";
import { browserHistory } from "browser-history";
import { errorMessage, message } from "actions/message";
import { propOr } from "ramda";

const makeApiTypes = name => ({
  [`${name}`]: `${name}`,
  [`${name}_REQUEST`]: `${name}_REQUEST`,
  [`${name}_RESPONSE`]: `${name}_RESPONSE`,
  [`${name}_FAILURE`]: `${name}_FAILURE`
});

export const Types = {
  UPDATE_COMPANY_EMAIL_TEMPLATE: "UPDATE_COMPANY_EMAIL_TEMPLATE",
  GET_COMPANY_EMAIL_TEMPLATE: "GET_COMPANY_EMAIL_TEMPLATE",
  SET_COMPANY_EMAIL_TEMPLATE_FORM: "SET_COMPANY_EMAIL_TEMPLATE_FORM",
  RECEIVE_GET_COMPANY_MONTHLY_REPORT: "RECEIVE_GET_COMPANY_MONTHLY_REPORT",
  GET_COMPANY_MONTHLY_REPORT: "GET_COMPANY_MONTHLY_REPORT",
  RECEIVE_GET_MONTHLY_REPORT: "RECEIVE_GET_MONTHLY_REPORT",
  GET_MONTHLY_ACTIVATION_REPORT: "GET_MONTHLY_ACTIVATION_REPORT",
  REQUEST_EXPORT_COMPANY_INSIGHTS: "REQUEST_EXPORT_COMPANY_INSIGHTS",
  RESPONSE_EXPORT_COMPANY_INSIGHTS: "RESPONSE_EXPORT_COMPANY_INSIGHTS",
  RECEIVE_GET_MONTHLY_ACTIVATION_REPORT:
    "RECEIVE_GET_MONTHLY_ACTIVATION_REPORT",
  ...makeApiTypes("GET_COMPANY_PROFILE"),
  ...makeApiTypes("GET_COMPANY_PATIENT_REPORT"),
  ...makeApiTypes("GET_COMPANY_PATIENT_DEMOGRAPHIC_LOGS"),
  ...makeApiTypes("GET_COMPANY_PATIENT_DEMOGRAPHIC_LOGS_COUNT"),
  ...makeApiTypes("GET_COMPANY_PHYSICIAN_INSURANCE_REPORT"),
  ...makeApiTypes("GET_COMPANY_PATIENTS_MACHINE_COUNT"),
  ...makeApiTypes("GET_COMPANY_PATIENTS_MASK_COUNT"),
  ...makeApiTypes("GET_COMPANY_PATIENTS_INACTIVATION_COUNT"),
  ...makeApiTypes("GET_COMPANY_PATIENTS_INSURANCE_COUNT"),
  ...makeApiTypes("TOGGLE_PAUSE_COMPANY_IVR"),
  ...makeApiTypes("KILL_COMPANY_IVR_JOB"),
  ...makeApiTypes("START_COMPANY_IVR_JOB"),
  ...makeApiTypes("GET_COMPANY_ACTIVE_INVENTORY"),
  ...makeApiTypes("UPDATE_COMPANY_ACTIVE_INVENTORY"),
  ...makeApiTypes("GET_COMPANY_MANUFACTURER_WEIGHTS"),
  ...makeApiTypes("UPDATE_COMPANY_MANUFACTURER_WEIGHTS"),
};

export const getCompanyPatientReport = (companyId, start_date, end_date) => ({
  type: Types.GET_COMPANY_PATIENT_REPORT_REQUEST,
  payload: {
    companyId,
    start_date,
    end_date
  }
});

export const getCompanyPatientDemographicLogs = (companyId, indexParams) => ({
  type: Types.GET_COMPANY_PATIENT_DEMOGRAPHIC_LOGS_REQUEST,
  payload: {
    companyId,
    indexParams
  }
});

export const getCompanyPatientDemographicLogsCount = (companyId, startDate, endDate) => ({
  type: Types.GET_COMPANY_PATIENT_DEMOGRAPHIC_LOGS_COUNT_REQUEST,
  payload: {
    companyId,
    startDate,
    endDate
  }
});

export const updateEmailTemplate = (id, template) => ({
  type: Types.UPDATE_COMPANY_EMAIL_TEMPLATE,
  callAPI: () => put(`companies/${id}/email-template`, template)
});

export const getEmailTemplate = id => ({
  type: Types.GET_COMPANY_EMAIL_TEMPLATE,
  callAPI: () => get(`companies/${id}/email-template`)
});

export const setEmailTemplateForm = emailForm => ({
  type: Types.SET_COMPANY_EMAIL_TEMPLATE_FORM,
  emailForm
});
export const [
  fetchCompanyMonthlyReport,
  invalidateCompanyMonthlyReport
] = makeFetchTuple({
  url: ({ id, startDate, endDate }) =>
    `companies/${id}/monthly_report?start_date=${startDate}&end_date=${endDate}`,
  baseType: Types.GET_COMPANY_MONTHLY_REPORT,
  mapActionToKeyIn: () => []
});
export const [
  fetchCompanyActivationReport,
  invalidateCompanyActivationReport
] = makeFetchTuple({
  url: ({ id, month }) => `companies/${id}/monthly_report?month=${month}`,
  baseType: Types.GET_MONTHLY_ACTIVATION_REPORT,
  mapActionToKeyIn: () => []
});

export const getCompanyContactsReport = ({ id, start, end }) => ({
  type: C.COMPANY.GET_CONTACTS_REPORT,
  callAPI: () => get(`companies/${id}/contacts`, { start, end })
});

export const getCompany = id => ({
  type: Types.GET_COMPANY_PROFILE,
  callAPI: () => get(`companies/${id}`)
});

export const updateCompany = company => ({
  type: C.COMPANY.SET,
  callAPI: () => put(`companies/${company.id}`, company),
  callback: () => {
    browserHistory.push(`/companies/${company.id}/profile`);
  },
  customMsg: "Company updated!"
});

export const createCompany = company => ({
  type: C.COMPANY.POST,
  callAPI: () => post(`companies`, company),
  callback: (dispatch, response) => {
    browserHistory.push(`/companies/${response.id}/profile`);
  },
  customMsg: "Company added!"
});

/* NOTE params is date range */
export const getCompanyOrdersReport = (id, params) => ({
  type: C.COMPANY.GET_ORDERS_REPORT,
  callAPI: () =>
    params
      ? get(`companies/${id}/orders`, params)
      : get(`companies/${id}/orders`)
});

export const createBranch = ({ companyId, branch }) => ({
  type: C.COMPANY.SET_BRANCH,
  callAPI: () => post(`companies/${companyId}/branches`, branch),
  callback: dispatch => {
    dispatch(getCompany(companyId));
    browserHistory.push(`/companies/${companyId}/profile`);
  },
  customMsg: "Branch created!"
});

export const updateBranch = ({ companyId, branch, branchId }) => ({
  type: C.COMPANY.UPDATE_BRANCH,
  callAPI: () => put(`companies/${companyId}/branches/${branchId}`, branch),
  callback: dispatch => {
    dispatch(getCompany(companyId));
    browserHistory.push(`/companies/${companyId}/profile`);
  },
  customMsg: "Branch updated!"
});

export const togglePauseCompanyIVR = companyId => async dispatch => {
  try {
    dispatch({
      type: Types.TOGGLE_PAUSE_COMPANY_IVR_REQUEST,
      meta: { companyId }
    });
    const response = await post(`ivr/Toggle_IVR_Pause/${companyId}`, {});
    dispatch({
      type: Types.TOGGLE_PAUSE_COMPANY_IVR_RESPONSE,
      meta: { companyId },
      response
    });
  } catch (error) {
    dispatch(errorMessage("Something went wrong!"));
    dispatch({
      type: Types.TOGGLE_PAUSE_COMPANY_IVR_FAILURE,
      error
    });
  }
};

export const killCompanyIVRJob = companyId => async dispatch => {
  try {
    dispatch({ type: Types.KILL_COMPANY_IVR_JOB_REQUEST });
    const response = await post(`ivr/KILL_IVR_Thread/${companyId}`, {});
    dispatch({
      type: Types.KILL_COMPANY_IVR_JOB_RESPONSE,
      response,
      meta: { companyId }
    });
    dispatch(message(propOr("Company IVR jobs killed", "status", response)));
  } catch (error) {
    dispatch(errorMessage(propOr("Something went wrong!", "status", error)));
    dispatch({ type: Types.KILL_COMPANY_IVR_JOB_FAILURE, error });
  }
};

export const startCompanyIVRThread = (
  companyGuid,
  options = {}
) => async dispatch => {
  try {
    dispatch({ type: Types.START_COMPANY_IVR_JOB_REQUEST });
    const response = await post("IVR/StartIVRCalls", {
      companyGuid,
      ...options
    });
    dispatch({
      type: Types.START_COMPANY_IVR_JOB_RESPONSE,
      response,
      meta: { companyGuid }
    });
    dispatch(message(propOr("Company IVR job started", "status", response)));
  } catch (error) {
    dispatch(errorMessage(propOr("Something went wrong!", "status", error)));
    dispatch({ type: Types.START_COMPANY_IVR_JOB_FAILURE, error });
  }
};

export const exportCompanyInsights = (payload) => ({
  type: Types.REQUEST_EXPORT_COMPANY_INSIGHTS,
  payload
});


// export const getCompanyActiveInventory = (companyId) => ({
//   type: Types.GET_COMPANY_ACTIVE_INVENTORY,
//   payload: { companyId }
// });

// export const updateCompanyActiveInventory = (companyId, inventory) => ({
//   type: Types.UPDATE_COMPANY_ACTIVE_INVENTORY,
//   payload: { companyId, inventory }
// });

export const getCompanyManufacturerWeights = (companyId) => ({
  type: Types.GET_COMPANY_MANUFACTURER_WEIGHTS,
  payload: { companyId }
});

export const updateCompanyManufacturerWeights = (companyId, weights) => ({
  type: Types.UPDATE_COMPANY_MANUFACTURER_WEIGHTS,
  payload: { companyId, weights }
});
