import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getPDFTemplate,
  deletePDFTemplate
} from "components/dd_newModules/Redux/Actions/pdftemplate";
import { setPdfWindowOpenStatus } from "actions/app-layout";
import moment from "moment";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import { selectors } from "reducers";
import { PHP_Domain } from "environment";
import "./style.scss";

const ManagePdfs = ({
  getPDFTemplate,
  deletePDFTemplate,
  PDFTemplates,
  setPdfWindowOpenStatus,
  user,
  isLoading
}) => {
  const { rows, query, queryDispatcher, count } = useLocalQuery(
    PDFTemplates ? PDFTemplates : [],
    {
      sort: { by: "", direction: "asc" },
      filters: {},
      pagination: { page: 1, per: 100 }
    }
  );
  const [pdfWindowOpen, setPdfWindowOpen] = React.useState(false);
  React.useEffect(() => {
    getPDFTemplate();
  }, []);
  function openPDFWindow() {
    document.body.style.pointerEvents = "none";
    document.body.style.cursor = "wait";
    document.body.style.filter = "brightness(80%)";
    setPdfWindowOpen(true);
    // const encoded_token = window.btoa(user.authentication_token);
    var new_window = window.open(
      `${PHP_Domain}/create-template?token=${user.authentication_token}`,
      "_blank",
      "height=" + screen.height + ", width=" + screen.width
    );
    setPdfWindowOpenStatus(true);
    var timer = setInterval(() => {
      if (new_window.closed) {
        clearInterval(timer);
        onUnload();
        setPdfWindowOpenStatus(false);
      }
    }, 1000);
  }
  function onUnload() {
    getPDFTemplate(false);
    setPdfWindowOpen(false);
    document.body.style.pointerEvents = "inherit";
    document.body.style.cursor = "default";
    document.body.style.filter = "brightness(100%)";
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "relative"
      }}
    >
      <Table
        containerClass="query-table manage-pdf-table"
        isFetching={isLoading}
        rowKey="id"
        TableRow={row => (
          <tr>
            <td>{row.document_title}</td>
            <td>
              {moment(moment.tz(row.date_of_delivery, "US/Central")).format(
                "M/D/YYYY"
              )}
            </td>
            <td>{row.patients.length}</td>
            <td>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "flex-end"
                }}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={row.document_template_url}
                >
                  View
                </a>
                <span
                  style={{
                    color: "red",
                    cursor: "pointer",
                    margin: "0px 20px"
                  }}
                  onClick={() => {
                    let result = confirm(
                      `Are You Sure you want to archive PDF template: ${row.document_title}?`
                    );
                    if (result) {
                      deletePDFTemplate(
                        row.id,
                        row.templateId,
                        user.authentication_token
                      );
                    }
                  }}
                >
                  Archive
                </span>
              </div>
            </td>
          </tr>
        )}
        count={count}
        columns={[
          {
            header: "Title",
            field: "document_title"
          },
          {
            header: "Delivery Date",
            field: "date_of_delivery"
          },
          {
            header: "Assigned Patients",
            field: "  "
          },
          {
            field: "controls",
            header: " "
          }
        ]}
        rows={rows}
        query={query}
        queryDispatcher={queryDispatcher}
      />
      <button
        disabled={pdfWindowOpen}
        type="button"
        onClick={() => {
          openPDFWindow();
        }}
        style={{
          margin: "10px 0px",
          marginTop: 25,
          radius: 3,
          borderRadius: 3,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          display: "flex"
        }}
      >
        Create New PDF Template
      </button>
    </div>
  );
};

export default connect(
  state => ({
    PDFTemplates: state.getIn(["newRootReducer"]).rdc_PDFTemplate.PDFTemplate,
    isLoading: state.getIn(["newRootReducer"]).rdc_loader.isLoader,
    user: selectors.getUser(state)
  }),
  { getPDFTemplate, deletePDFTemplate, setPdfWindowOpenStatus }
)(ManagePdfs);

ManagePdfs.propTypes = {
  isLoading: PropTypes.bool,
  PDFTemplates: PropTypes.arrayOf(PropTypes.object),
  getPDFTemplate: PropTypes.func.isRequired,
  deletePDFTemplate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  setPdfWindowOpenStatus: PropTypes.func.isRequired
};
