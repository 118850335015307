import React from "react";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import { PageHeader } from "components/ui";
import useApiQuery, { Table } from "hooks/useQuery/api-query";
import { useLocation } from "react-router-dom";
import { pathOr } from "ramda";
import TasksTableControls from "./table-controls";
import useRowSelectNew, { SelectRowContext } from "hooks/useRowSelectNew";
import { useTaskTable } from "./useTable";

const TasksTable = () => {
  const location = useLocation();
  const userId = useSelector(selectors.getUserId);
  // const userRole = useSelector(selectors.getUserRole);
  const initialFilters = pathOr(
    {
      // owner: ["SleepCoach"].includes(userRole)
      //   ? ["S3"]
      //   : ["OfficeAdministrator", "CompanyAdministrator"].includes(userRole)
      //   ? ["DME"]
      //   : undefined,
      assigned_to: [userId],
      status: ["Created", "Pending"]
    },
    ["state", "filters"],
    location
  );
  const initialQuery = {
    sort: {},
    filters: initialFilters,
    pagination: { page: 1, per: 150 }
  };
  const { query, queryDispatcher, rows, data, isFetching, fetchData } =
    useApiQuery("tasks", initialQuery, "tasks");
  const {
    toggleAllRows,
    isRowSelected,
    toggleRow,
    clearSelectedRows,
    selectedRows
  } = useRowSelectNew(rows, "task_id", query, row => ({
    ...row
  }));
  const {
    tableColumns,
    tableRows,
    tableSettings,
    resetTableSettings,
    updateTableSettings
  } = useTaskTable({ query, queryDispatcher });
  return (
    <div className="tasks-page">
      <PageHeader title="All Tasks" />
      <SelectRowContext.Provider
        value={{
          toggleAllRows,
          toggleRow,
          selectedRows,
          isRowSelected,
          clearSelectedRows
        }}
      >
        <Table
          columns={tableColumns}
          rows={rows}
          query={query}
          data={data}
          queryDispatcher={queryDispatcher}
          isFetching={isFetching}
          containerClass="query-table tasks-table dynamic-columns"
          TableRow={row => (
            <tr>
              {tableRows.map((Row, key) => (
                <Row {...row} key={key} />
              ))}
            </tr>
          )}
          rowKey="task_id"
          perPageOptions={[
            { text: "50", value: 50 },
            { text: "150", value: 150 },
            { text: "500", value: 500 }
          ]}
          Controls={() => (
            <TasksTableControls
              rows={rows}
              query={query}
              queryDispatcher={queryDispatcher}
              selectedRows={selectedRows}
              clearSelectedRows={clearSelectedRows}
              isFetching={isFetching}
              refreshData={() => {
                fetchData(query);
              }}
              tableSettings={tableSettings}
              resetTableSettings={resetTableSettings}
              updateTableSettings={updateTableSettings}
            />
          )}
        />
      </SelectRowContext.Provider>
    </div>
  );
};

export default TasksTable;
