import React from 'react'
import { useState } from 'react'
import SectionHeader from 'components/profile/section_header.jsx';
import '../pages/companyForms.css'

export default function ModalFreeTextQuestion({handleCancel, saveConditions, toEditQuestion, questionData}) {

    const [newQuestionText, setNewQuestionText] = useState(toEditQuestion ? {newText: questionData.Text, newSpanishText: questionData.SpanishText} :  {newText: "", newSpanishText: ""})

    const [newRequiredAndNAStatus, setNewRequiredAndNAStatus] = useState(toEditQuestion ? {IsRequired: questionData.IsRequired, HasNAOption: questionData.HasNAOption} : {IsRequired: true, HasNAOption: false})

    const handleChangeNewText = (e)=>{

        const option= e.target.value;
        const name= e.target.name;

        setNewQuestionText({...newQuestionText, [name]: option});
    }

    const handleChangeRequiredAndNA = (e)=>{

        const name = e.target.name;
        if(name == "IsRequired"){
            setNewRequiredAndNAStatus({ ...newRequiredAndNAStatus, ["IsRequired"]: !newRequiredAndNAStatus.IsRequired });
        } else if(name == "HasNAOption") {
            setNewRequiredAndNAStatus({ ...newRequiredAndNAStatus, ["HasNAOption"]: !newRequiredAndNAStatus.HasNAOption });
        }
    }

    return (
        <div className='patient-types-statuses-container'>
            <div className="section-title">
                <div className="dd_col-12">
                    <SectionHeader>FREETEXT QUESTION</SectionHeader>
                </div>
                {
                    toEditQuestion &&
                    <div>
                        <div className="dd_col-6">
                            <label>Question Text</label>
                            <input 
                                name="newText"                          
                                type="text"
                                value={newQuestionText.newText}
                                onChange={handleChangeNewText}  
                                disabled={questionData.AlreadyExists}
                            />
                        </div>
                        <div className="dd_col-6">  
                            <label>Question Spanish Text</label>                    
                            <input 
                                name="newSpanishText"                          
                                type="text"
                                value={newQuestionText.newSpanishText}
                                onChange={handleChangeNewText}  
                                disabled={questionData.AlreadyExists}
                            />
                        </div>
                    </div>
                }
                {
                    questionData?.AlreadyExists &&
                    <div className="dd_col-12">   
                        <span style={{color:'red'}}>Cannot change text of an already existing question</span>
                    </div> 
                }
                <div className="dd_col-12">
                    <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <input type="checkbox" value={newRequiredAndNAStatus.IsRequired} name="IsRequired" onChange={handleChangeRequiredAndNA} checked={newRequiredAndNAStatus.IsRequired} />
                    <label>Required</label>     
                    <input type="checkbox" value={newRequiredAndNAStatus.HasNAOption} name="HasNAOption" onChange={handleChangeRequiredAndNA} checked={newRequiredAndNAStatus.HasNAOption} />
                    <label>Add N/A</label>
                </div>
                <div className='dd_col-12'>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}> 
                        {
                            
                            <button
                                type="button"
                                className="button"
                                disabled={!newQuestionText.newText.length > 0 || !newQuestionText.newSpanishText.length > 0}
                                onClick={()=>{
                                    saveConditions([], [], questionData, toEditQuestion, newQuestionText, newRequiredAndNAStatus)
                                    handleCancel()
                                }}
                            >
                                Save
                            </button>  
                        }           
                    </div>    
                </div>
            </div>
        </div>
    )
}