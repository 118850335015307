import React, { useEffect,  useState } from 'react';
import { BarChart, XAxis, YAxis ,Tooltip, Bar, LabelList } from "recharts";
import { PropTypes } from 'prop-types';
import HeaderChart from './header-chart';

export default function ComplianceDashboardBarChart ({data, onItemSelected, startDate, endDate, companyId, isFetching}) {

  const [dataForCheckboxList, setDataForCheckboxList] = useState([])
  const [usingIndividualInfo, setUsingIndividualInfo] = useState(false)
  const [chartData, setChartData] = useState([])

  useEffect(()=>{
    if(data){
      const checkItems = data.GrouperOptions.map(({ Text, Value}) =>{
        return {value: Value, text: Text, elementId: "item" + Value}
      });

      setChartData(data.Results);
      setDataForCheckboxList(checkItems);
    }
  },[data])

  const updateChartData = (individualData) => {
    setUsingIndividualInfo(true);
    setChartData(individualData);
  };

  const splitString = (data) => {
    let searchedLength = 15;
    if (data?.Grouper?.Text?.length > searchedLength) {
      let aux = data?.Grouper?.Text?.slice(0, searchedLength);
      aux += "...";
      return aux;
    } else {
      return data?.Grouper?.Text;
    }
  };

  const CustomTooltip = (data) => {
    if (data.active && data.payload && data.payload.length) {
      return (
        <div className="bar-charts-custom-tooltip">
          <p className="custom-tooltip-label">{data.payload[0].payload.Grouper.Text}</p>
        </div>
      );
    }
    return null;
  };

  const renderLabel = (prop) => {
    const index = prop.index;
    const target =  chartData[index];
    return `${target?.Count} - ${target?.Percentage}%`;
  };

  return (
    <div>
      <HeaderChart
        categoryName={data.CategoryName}
        category={data.Category}
        startDate={startDate}
        endDate={endDate}
        companyId={companyId}
        updateData={updateChartData}
        dataForCheckboxList={dataForCheckboxList}
        isFetching={isFetching}
      />

      {
        !isFetching && chartData?.length === 0?
          <div>
            <h5 style={{textAlign: "center", color: "red"}}>
              {usingIndividualInfo?
                "No registers found for the selected filters"
                : "No registers found for this chart"
              }
            </h5>
          </div>
        :
          <BarChart width={400} height={300} data={ chartData } layout="vertical">
            <XAxis type="number" dataKey="Percentage" domain={[0, 100]} ticks={[0,25,50,75,100]} />
            <Tooltip content={<CustomTooltip />}/>
            <YAxis dataKey={splitString} type="category" name="Grouper.Text" width={115} interval={0}/>
            <Bar fill="rgb(49, 130, 189)" dataKey="Percentage" barSize={30} onClick={(values) => {
                onItemSelected({ value: values.Grouper.Value, category: data.Category, filterName: values.Grouper.Text, categoryName: data.CategoryName })
            }}>
              <LabelList
                content={props => renderLabel(props)}
                position="insideLeft"
                style={{pointerEvents: 'none'}}
              />
            </Bar>
          </BarChart>
      }
    </div>
  );
}

ComplianceDashboardBarChart.propTypes = {
    data: PropTypes.object,
    onItemSelected: PropTypes.func,
    startDate: PropTypes.date,
    endDate: PropTypes.date,
    companyId: PropTypes.string,
    isFetching: PropTypes.bool
}