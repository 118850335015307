import Export from "components/screens/exports/exports";
import PhoneQueues from "components/screens/phone-queues";
import PhoneBook from "components/screens/phone-book";
import Support from "components/screens/support/support";
import GoogleDriveFiles from "features/google-api/components/drive-files";
import MyProfile from "components/screens/my-profile";
import InventoryTable from "components/screens/inventory/inventory-table";
import InventoryProfile from "components/screens/inventory/inventory-profile";
import ManageInventory from "components/screens/inventory/manage-inventory";
import IVRWorkTableContainer from "components/ivr-control-center/ivr-work-table-container";
import VoicemailContainer from "components/screens/vmail-table/voicemailContainer";
import CallRecordingContainer from "components/screens/callrecording/callrecordingContainer";
import VMailWorkPage from "components/screens/vmail-profile/vmail-work-page";
import VoicemailsTable from "components/voicemails-table";
import VoicemailProfile from "components/voicemail-profile";
import ChatsContainer from "components/screens/chats";
import Email from "components/screens/email";
import PdfSignatures from "components/pdf-signatures";
import Tasks from "components/tasks";
import Cmns from "components/cmns";
import Questionnaires from "components/questionnaires";

export default [
  {
    path: "/phones/queues",
    component: PhoneQueues,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "ServiceCoach",
      "ServiceAdmin", 
      "ContractorAdministrator"
    ]
  },
  {
    path: "/phone-book",
    component: PhoneBook,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "ServiceAdmin",
      "ServiceCoach", 
      "ContractorAdministrator"
    ],
    exact: true
  },
  {
    path: "/exports",
    component: Export,
    allowedRoles: [
      "Administrator",
      "ServiceAdmin",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceCoach", 
      "ContractorAdministrator"
    ]
  },
  {
    path: "/support",
    component: Support
  },
  {
    path: "/google-drive",
    component: GoogleDriveFiles,
    allowedRoles: ["Administrator", "SleepCoach"]
  },
  {
    path: "/user/profile",
    component: MyProfile
  },
  {
    path: "/inventory",
    component: InventoryTable,
    exact: true
  },
  {
    path: "/inventory/new",
    component: ManageInventory,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/inventory/:id/edit",
    component: ManageInventory
  },
  {
    path: "/inventory/:id",
    component: InventoryProfile,
    exact: true
  },
  {
    path: "/ivr-work",
    component: IVRWorkTableContainer,
    exact: true,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "ServiceCoach",
      "ServiceAdmin",
      "CompanyAdministrator",
      "OfficeAdministrator", 
      "ContractorAdministrator"
    ]
  },
  {
    path: "/voicemails",
    component: VoicemailsTable,
    exact: true,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "ServiceAdmin",
      "ServiceCoach", 
      "ContractorAdministrator"
    ]
  },
  {
    path: "/voicemails/:id",
    component: VoicemailProfile,
    exact: true,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "ServiceAdmin",
      "ServiceCoach", 
      "ContractorAdministrator"
    ]
  },
  {
    path: "/phones/voicemails/:id",
    component: VMailWorkPage,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "ServiceAdmin",
      "ServiceCoach", 
      "ContractorAdministrator"
    ]
  },
  {
    path: "/phones/voicemails",
    component: VoicemailContainer,
    exact: true,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "ServiceAdmin",
      "ServiceCoach", 
      "ContractorAdministrator"
    ]
  },
  {
    path: "/callrecordings",
    component: CallRecordingContainer,
    exact: true,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "ServiceAdmin",
      "ServiceCoach", 
      "ContractorAdministrator"
    ]
  },
  {
    path: "/chats",
    component: ChatsContainer,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceAdmin",
      "ServiceCoach",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/emails",
    component: Email,
    allowedRoles: ["Administrator"]
  },
  {
    path: "/pdf-signatures",
    component: PdfSignatures,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceAdmin",
      "ServiceCoach", 
      "ContractorAdministrator"
    ]
  },
  {
    path: "/tasks",
    component: Tasks,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceAdmin",
      "ServiceCoach",
      "ComplianceCoach",
      "ContractorAdministrator"
    ]
  },
  {
    path: "/cmns",
    component: Cmns,
    allowedRoles: [
      "Administrator",
      "SleepCoach",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceAdmin",
      "ServiceCoach", 
      "ContractorAdministrator"
    ]
  },
  {
    path: "/questionnaires",
    component: Questionnaires,
    allowedRoles: [
      "Administrator",
      "CompanyAdministrator",
      "OfficeAdministrator",
      "ServiceAdmin",
      "ServiceCoach", 
      "ContractorAdministrator"
    ]
  }
];
