import React, { useState, useRef } from 'react';
import SectionHeader from '../../components/profile/section_header.jsx';
import { Ul } from 'components/lists/index.js';
import { IconButton, InlineWaiting } from 'components/ui';
import { MdEdit } from 'react-icons/lib/md/index.js';
import { useFetch } from 'hooks/index.jsx';
import { get } from 'utils/api.js';
import { Form, Formik } from 'formik';
import { path } from "ramda";
import PropTypes from "prop-types";
import FormikFieldStatuses from "./Presentation/formik-field-statuses.jsx";
import { useDispatch } from 'react-redux';
import * as msg from "actions/message";
import CancelIcon from 'react-icons/lib/md/cancel';
import SaveIcon from 'react-icons/lib/md/save';

import '../PatientProfile/Presentation/components/patientProfile.css'

export default function PatientTypesAndStatuses({ patientId, patientStatuses, updatePatientTypesAndStatuses, companyManagesCompliance, complianceMet }) {

    const dispatch = useDispatch();
    const [isUpdating, setIsUpdating] = useState(false)

    const [fieldsAreEditable, setFieldsAreEditable] = useState(false)

    const [userFieldSelection, setUserFieldSelection] = useState({
        compliance_patient: true,
        compliance_status: "On Hold",
        compliance_inactivation_reason: null,
        resupply_patient: false,
        resupply_status: "Delayed",
        remote_patient: false,
        remote_status: "Inactive"
    })

    const [initialFields, setInitialFields] = useState({
        compliance_patient: true,
        compliance_status: "On Hold",
        compliance_inactivation_reason: null,
        resupply_patient: false,
        resupply_status: "Delayed",
        remote_patient: false,
        remote_status: "Inactive"
    })

    const [complianceUpdated, setComplianceUpdated] = useState();

    const submitPatientChanges = async () => {
        formikRef.current.validateForm();
        if(!formikRef.current.isValid){
            return;
        }

        if(userFieldSelection.compliance_status == 4){
            const errorMessage = "Please, before inactivating the patient, complete the Compliance Data Tab";
            const inactivationReason = possibleInactivationReasons.find(x => x.Value == userFieldSelection.compliance_inactivation_reason);

            const compliantError = inactivationReason.Text == 'Compliance Met' && (complianceMet == null || !complianceMet);
            const nonCompliantError = inactivationReason.Text == 'Compliance Not Met' && (complianceMet == null || complianceMet);

            if(compliantError || nonCompliantError){
                dispatch(msg.errorMessage(errorMessage));
                setUserFieldSelection(initialFields);
                setFieldsAreEditable(false)
                return;
            }
        }

        setIsUpdating(true);
        await updatePatientTypesAndStatuses(userFieldSelection, complianceUpdated)

        setInitialFields( {
            compliance_patient: userFieldSelection.compliance_patient,
            compliance_status: userFieldSelection.compliance_status,
            compliance_inactivation_reason: userFieldSelection.compliance_inactivation_reason,
            resupply_patient: userFieldSelection.resupply_patient,
            resupply_status: userFieldSelection.resupply_status,
            remote_patient: userFieldSelection.remote_patient,
            remote_status: userFieldSelection.remote_status,
        })
        setFieldsAreEditable(false)
        setIsUpdating(false);
    }

    async function handleFieldChange(e, value) {
        await setUserFieldSelection({
            ...userFieldSelection,
            [e.target.name] : value
        })
    }

    const {
        response : possibleStatuses,
        fetchData : fetchPossibleStatuses
    } = useFetch({
        apiFn: () => get(`patients/PatientStatuses`),
        defaultValue: [],
        transformError: path(["response", "body", "status"])
        });

    const {
        response : possibleInactivationReasons,
        fetchData : fetchPossibleInactivationReasons
    } = useFetch({
        apiFn: () => get(`patients/compliance-inactivation-reasons`),
        defaultValue: [],
        transformError: path(["response", "body", "status"])
        });

    const formikRef = useRef();

    const validateInactivationReason = value => {
        let errorMessage;
        if(initialFields.compliance_status != 4 && userFieldSelection.compliance_status == 4){
           if (!value){
            errorMessage = 'Required';
           }
        }

        return errorMessage;
    }

    React.useEffect(() => {
        fetchPossibleStatuses();
        fetchPossibleInactivationReasons();
        if(patientStatuses){
            setUserFieldSelection({
                compliance_patient: patientStatuses.compliance_patient,
                compliance_status: patientStatuses.compliance_status,
                compliance_inactivation_reason: patientStatuses.compliance_inactivation_reason,
                resupply_patient: patientStatuses.resupply_patient,
                resupply_status: patientStatuses.resupply_status,
                remote_patient: patientStatuses.remote_patient,
                remote_status: patientStatuses.remote_status
            })
            setInitialFields({
                compliance_patient: patientStatuses.compliance_patient,
                compliance_status: patientStatuses.compliance_status,
                compliance_inactivation_reason: patientStatuses.compliance_inactivation_reason,
                resupply_patient: patientStatuses.resupply_patient,
                resupply_status: patientStatuses.resupply_status,
                remote_patient: patientStatuses.remote_patient,
                remote_status: patientStatuses.remote_status
            })
        }
    }, [patientId]);

    function defineStatusValue(value){
        return value == "true"? 1 : null;
    }

  return (
    <div className='patient-types-statuses-container'>
        <div className="section-title">
            <SectionHeader>Types and statuses</SectionHeader>
            {
                !fieldsAreEditable ?
                    <span style={{ position: "absolute", right: "0px", top: "0px" }}>
                        <IconButton
                        icon={<MdEdit />}
                        text="Edit"
                        style={{ fontSize: "12px" }}
                        disabled={false}
                        onClick={() => {
                            setFieldsAreEditable(true)
                        }}
                        />
                    </span>
                :
                    <div style={{position: "absolute", right: "0px", top: "0px" }}>
                        {
                            isUpdating ?
                                <InlineWaiting />
                            :
                                <>
                                    <IconButton
                                        onClick={() => {
                                            setFieldsAreEditable(false)
                                            setUserFieldSelection(initialFields)
                                        }}
                                        text="Cancel"
                                        style={{ color: "gray" }}
                                        icon={<CancelIcon />}
                                        type="button"
                                    />
                                    <IconButton
                                        text="Save"
                                        style={{ color: "green" }}
                                        icon={<SaveIcon />}
                                        onClick={submitPatientChanges}
                                    />
                                </>

                        }
                    </div>
            }
        </div>
        <Ul>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    compliance_patient: initialFields.compliance_patient,
                    compliance_status: initialFields.compliance_status,
                    compliance_inactivation_reason: initialFields.compliance_inactivation_reason,
                    resupply_patient: initialFields.resupply_patient,
                    resupply_status: initialFields.resupply_status,
                    remote_patient: initialFields.remote_patient,
                    remote_status: initialFields.remote_status
                }}
                innerRef={formikRef}
                validate={validateInactivationReason}
            >
                {({
                    handleChange
                }) => (
                    <Form>
                        <FormikFieldStatuses
                            name="compliance_patient"
                            component={fieldsAreEditable && companyManagesCompliance? "select" : "span"}
                            label="Compliance patient:"
                            className={"setting-patient-profile"}
                            disabled={!fieldsAreEditable || !companyManagesCompliance || initialFields?.compliance_patient}
                            onChange={(e) => {
                                setUserFieldSelection({
                                    ...userFieldSelection,
                                    [e.target.name] : /^true$/i.test(e.target.value),
                                    ["compliance_status"] : defineStatusValue(e.target.value)
                                })
                                setComplianceUpdated(true);
                                handleChange(e)
                            }}
                            value={userFieldSelection.compliance_patient}
                        >
                            {
                                fieldsAreEditable && companyManagesCompliance?
                                    [<option value={true}>Yes</option>].concat([<option value={false}>No</option>])
                                    : initialFields.compliance_patient? "Yes" : "No"
                            }
                        </FormikFieldStatuses>

                        <FormikFieldStatuses
                            name="compliance_status"
                            component={fieldsAreEditable && companyManagesCompliance? "select" : "span"}
                            label="Compliance status:"
                            className={"setting-patient-profile"}
                            disabled={!fieldsAreEditable || !companyManagesCompliance}
                            onChange={(e) => {
                                handleFieldChange(e, parseInt(e.target.value))
                                if(userFieldSelection.compliance_status != e.target.value){
                                    setComplianceUpdated(true);
                                }
                                handleChange(e)
                                formikRef.current.setFieldTouched('compliance_inactivation_reason', true);
                            }}
                            value={userFieldSelection.compliance_status}
                        >
                            {
                                fieldsAreEditable && companyManagesCompliance?
                                    patientStatuses ?
                                        patientStatuses.compliance_patient ?
                                            possibleStatuses.map(({ text, value }) => (
                                                <option key={value} value={value}>
                                                    {text}
                                                </option>
                                            ))
                                        :
                                        userFieldSelection.compliance_patient ?
                                            <option value={1}>Active</option>
                                        :
                                            null
                                    :
                                    null
                                :
                                possibleStatuses?.find(({value}) => value == initialFields.compliance_status)?.text
                            }
                        </FormikFieldStatuses>


                        {(!fieldsAreEditable && initialFields.compliance_status == 4) || (fieldsAreEditable && userFieldSelection.compliance_status == 4) &&
                            <FormikFieldStatuses
                                name="compliance_inactivation_reason"
                                component={fieldsAreEditable && companyManagesCompliance
                                    && initialFields.compliance_status != 4 && userFieldSelection.compliance_status == 4? "select" : "span"}
                                label="Inactivation reason:"
                                className={"setting-patient-profile"}
                                disabled={!fieldsAreEditable || !companyManagesCompliance}
                                onChange={(e) => {
                                    handleFieldChange(e, parseInt(e.target.value))
                                    handleChange(e)
                                }}
                                validate={validateInactivationReason}
                                value={userFieldSelection.compliance_inactivation_reason}
                            >
                                {
                                    fieldsAreEditable && companyManagesCompliance && initialFields.compliance_status != 4 && userFieldSelection.compliance_status == 4?
                                    [<option key="default" value={null}></option>].concat(possibleInactivationReasons?.map(({ Text, Value }) => (
                                            <option key={Value} value={Value}>
                                                {Text}
                                            </option>
                                        )))
                                    :
                                    possibleInactivationReasons?.find(({Value}) => Value == initialFields.compliance_inactivation_reason)?.Text
                                }
                            </FormikFieldStatuses>
                        }

                        <FormikFieldStatuses
                            name="resupply_patient"
                            component={fieldsAreEditable? "select" : "span"}
                            label="Sleep resupply patient:"
                            className={"setting-patient-profile"}
                            disabled={!fieldsAreEditable || initialFields?.resupply_patient}
                            onChange={(e) => {
                                setUserFieldSelection({
                                    ...userFieldSelection,
                                    [e.target.name] : /^true$/i.test(e.target.value),
                                    ["resupply_status"] : defineStatusValue(e.target.value)
                                })
                                handleChange(e)
                            }}
                            value={userFieldSelection.resupply_patient}
                        >
                            {
                                fieldsAreEditable?
                                [<option value={true}>Yes</option>].concat([<option value={false}>No</option>])
                                : initialFields.resupply_patient? "Yes" : "No"
                            }
                        </FormikFieldStatuses>

                        <FormikFieldStatuses
                            name="resupply_status"
                            component={fieldsAreEditable? "select" : "span"}
                            label="Sleep resupply status:"
                            className={"setting-patient-profile"}
                            disabled={!fieldsAreEditable}
                            onChange={(e) => {
                                handleFieldChange(e, parseInt(e.target.value))
                                handleChange(e)
                            }}
                            value={userFieldSelection.resupply_status}
                        >
                            {
                                fieldsAreEditable?
                                    patientStatuses ?
                                        patientStatuses.resupply_status ?
                                            possibleStatuses.map(({ text, value }) => (
                                                <option key={value} value={value}>
                                                    {text}
                                                </option>
                                            ))
                                        :
                                        userFieldSelection.resupply_patient ?
                                            <option value={1}>Active</option>
                                        :
                                            null
                                    :
                                    null
                                :
                                    possibleStatuses?.find(({value}) => value == initialFields.resupply_status)?.text
                            }
                        </FormikFieldStatuses>

                        <FormikFieldStatuses
                            name="remote_patient"
                            component={fieldsAreEditable? "select" : "span"}
                            label="Remote set up patient:"
                            className={"setting-patient-profile"}
                            disabled={!fieldsAreEditable || initialFields?.remote_patient}
                            onChange={(e) => {
                                setUserFieldSelection({
                                    ...userFieldSelection,
                                    [e.target.name] : /^true$/i.test(e.target.value),
                                    ["remote_status"] : defineStatusValue(e.target.value)
                                })
                                handleChange(e)
                            }}
                            value={userFieldSelection.remote_patient}
                        >
                            {
                                fieldsAreEditable?
                                [<option value={true}>Yes</option>].concat([<option value={false}>No</option>])
                                : initialFields.remote_patient? "Yes" : "No"
                            }
                        </FormikFieldStatuses>

                        <FormikFieldStatuses
                            name="remote_status"
                            component={fieldsAreEditable? "select" : "span"}
                            label="Remote set up status:"
                            className={"setting-patient-profile"}
                            disabled={!fieldsAreEditable}
                            onChange={(e) => {
                                handleFieldChange(e, parseInt(e.target.value))
                                handleChange(e)
                            }}
                            value={userFieldSelection.remote_status}
                        >
                            {
                                fieldsAreEditable?
                                    patientStatuses ?
                                        patientStatuses.remote_patient ?
                                            possibleStatuses.map(({ text, value }) => (
                                                <option key={value} value={value}>
                                                    {text}
                                                </option>
                                            ))
                                        :
                                        userFieldSelection.remote_patient ?
                                            <option value={1}>Active</option>
                                        :
                                            null
                                    :
                                    null
                                :
                                    possibleStatuses?.find(({value}) => value == initialFields.remote_status)?.text
                            }
                        </FormikFieldStatuses>

                    </Form>
                )}
            </Formik>

        </Ul>
    </div>
  )
}

PatientTypesAndStatuses.propTypes = {
    patientId: PropTypes.string.isRequired,
    patientStatuses: PropTypes.object,
    updatePatientTypesAndStatuses: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    companyManagesCompliance: PropTypes.bool
};

PatientTypesAndStatuses.defaultProps = {
    readOnly: false
};