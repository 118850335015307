import React, { createContext } from "react";
import PropTypes from "prop-types";
import { get } from "utils/api";
import useFetch from "hooks/useFetch";
import useTasks from "features/tasks/use-tasks";

export const OrderProfileContext = createContext();

const OrderProfileProvider = ({ patientId, children }) => {
  const {
    tasks,
    createTask,
    createTaskNote,
    getPatientTasks,
    updateTask,
    isFetchingTasks
  } = useTasks(patientId);
  const patientFormAttributesAPI = useFetch({
    apiFn: patientId => get(`patients/${patientId}/form-attributes`),
    defaultValue: {
      company_insurances: [],
      accessible_users: { dmes: [], s3_users: [] }
    }
  });

  React.useEffect(() => {
    if (patientId) {
      patientFormAttributesAPI.fetchData(patientId);
      getPatientTasks(patientId);
    }
  }, [patientId]);
  return (
    <OrderProfileContext.Provider
      value={{
        companyInsurances:
          patientFormAttributesAPI.response.company_insurances ?? [],
        accessible_users: patientFormAttributesAPI.response
          .accessible_users ?? { dmes: [], s3_users: [] },
        tasks,
        createTask,
        createTaskNote,
        getPatientTasks,
        updateTask,
        isFetchingTasks
      }}
    >
      {children}
    </OrderProfileContext.Provider>
  );
};

OrderProfileProvider.propTypes = {
  patientId: PropTypes.string,
  children: PropTypes.node
};

export default OrderProfileProvider;
