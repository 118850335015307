import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CompatibleItemsTable from "./compatible-items-table";
import InventoryMedia from "./inventory-media";
import InventoryFaqs from "./inventory-faqs";

const InventoryInfoTabs = ({ inventoryItem, isLoading, media }) => {
  const [, setSelectedTabIndex] = useState(0);
  return (
    <Tabs onSelect={index => setSelectedTabIndex(index)}>
      <div className="row">
        <TabList>
          <div>
            <Tab>
              <div>Compatability</div>
            </Tab>
            <Tab>
              <div>Media</div>
            </Tab>
            <Tab>
              <div>FAQs</div>
            </Tab>
          </div>
        </TabList>
      </div>
      <div className="row">
        <TabPanel>
          <CompatibleItemsTable inventoryItem={inventoryItem} />
        </TabPanel>
        <TabPanel>
          <InventoryMedia
            media={media}
            isLoading={isLoading}
            inventoryItem={inventoryItem}
          />
        </TabPanel>
        <TabPanel>
          <InventoryFaqs inventoryItem={inventoryItem} />
        </TabPanel>
      </div>
    </Tabs>
  );
};

export default InventoryInfoTabs;

InventoryInfoTabs.propTypes = {
  inventoryItem: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  media: PropTypes.object
};
