import React from "react";
import { TimeSlotContext } from "./use-availabilityScheduler";

const CreateTimeSlot = () => {
  const {
    setPendingTimeSlot,
    getUserTimeSlots,
    pendingTimeSlot,
    createTimeSlot,
    week
  } = React.useContext(TimeSlotContext);
  const [schedulerOptions, setSchedulerOptions] = React.useState({
    description: "",
    max_number_of_invitees: 10,
    chat_type: "Product_Demo"
  });

  const handleSave = async () => {
    try {
      await createTimeSlot(
        pendingTimeSlot.start_time,
        pendingTimeSlot.end_time,
        schedulerOptions
      );
    } finally {
      setPendingTimeSlot(null);
      getUserTimeSlots(week);
    }
  };
  return (
    <div className="scheduler-new-slot-form">
      <label style={{ gridColumn: "-1/1" }}>
        Type
        <select
          value={schedulerOptions.chat_type}
          onChange={({ target }) =>
            setSchedulerOptions({
              ...schedulerOptions,
              chat_type: target.value
            })
          }
        >
          <option value="Text_Chat">Text Only Chat</option>
          <option value="Video_Chat">Video Chat</option>
          <option value="Product_Demo">Product Demo</option>
        </select>
      </label>
      <label style={{ gridColumn: "-1/1" }}>
        Description
        <input
          type="text"
          value={schedulerOptions.description}
          onChange={({ target }) => {
            setSchedulerOptions({
              ...schedulerOptions,
              description: target.value
            });
          }}
        />
      </label>
      {schedulerOptions.chat_type === "Product_Demo" && (
        <label style={{ gridColumn: "-1/1" }}>
          Maximum Users
          <input
            type="number"
            value={schedulerOptions.max_number_of_invitees}
            onChange={({ target }) =>
              setSchedulerOptions({
                ...schedulerOptions,
                max_number_of_invitees: target.value
              })
            }
          />
        </label>
      )}
      <button
        className="warning"
        onClick={() => {
          setPendingTimeSlot(null);
        }}
        disabled={!pendingTimeSlot}
      >
        Cancel
      </button>
      <button onClick={handleSave} disabled={!pendingTimeSlot}>
        Save
      </button>
    </div>
  );
};

export default CreateTimeSlot;
