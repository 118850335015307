import React from "react";
import { connect } from "react-redux";
import ModalLink from "components/shared/modal-link";
import compose from "lodash/fp/compose";
import PropTypes from "prop-types";
import { withModal, InlineWaiting } from "components/ui";
import { deSnakeCase } from "utils/misc";
import { selectors } from "reducers";
import { mapDispatchToProps } from "actions/order-profile";
import Notes from "./notes";
import UpdateStatus from "./update-status";
import PendingInfo from "components/order-status/pending-info";
import ReasonRejected from "./reason-rejected";
import DelayedPendingField from "./delayed-pending-field";
import FollowUpDateField from "./follow-up-date-field";
import CopyToClipboard from "./copy-to-clipboard";
import PriorityBtn from "./order-priority-btn";
import SendOrderToNHButton from "./send-to-NH-button";
import UpdateOrderFromNHButton from "./update-from-NH-button";
import AcceptIcon from "react-icons/lib/io/checkmark";
import FollowUpIcon from "react-icons/lib/md/replay";
import DeclineIcon from "react-icons/lib/io/close";
import ClipboardIcon from "react-icons/lib/io/clipboard";
import PrintOrderBtn from "./print-order-button";
import RefreshOrderBtn from "./refresh-order-button";
import TaskNotifiction from "./task-notification";
import InsuranceVerification from "./insurance-verifiction";
import HasRolePermission from "components/shared/has-role-permission";

const ProfileHeader = ({
  orderId,
  patientId,
  patientInfo,
  patientName,
  hasNotes,
  companyInfo,
  supply_period,
  orderStatus,
  canUpdateStatus,
  orderInfo,
  isFetching,
  isUpdating,
  canAcceptOrder,
  canDeclineOrder,
  requestStatusUpdate,
  followUpStatusId,
  canSendTofollowUp,
  canSendToPending,
  requestUpdateOrder,
  hasUnresolvedHoldReasons,
  openModal,
  closeModal,
  hasPendingIvrWork,
  orderRef,
  setShowNotes
}) => {
  React.useEffect(() => {
    return () => {
      // close modals when changing orders
      closeModal();
    };
  }, [orderId]);
  return (
    <div className="profile-header">
      {supply_period && <div className="thirty-day">{`Patient requested ${supply_period}-day supplies.`}</div>}
      <div className="main-header">
        <h1>
          <ModalLink to={`/patients/${patientId}`}>{patientName}</ModalLink>
          <span
            className={`order-notes-button ${hasNotes && "has-notes"}`}
            title="Order notes"
            onClick={() =>
              openModal(<Notes orderId={orderId} closeSelf={closeModal} />)
            }
          >
            <ClipboardIcon />
          </span>
        </h1>
        <div className="order-action-buttons">
          <RefreshOrderBtn orderId={orderId} />
          <PrintOrderBtn orderRef={orderRef} setShowNotes={setShowNotes} />
          <CopyToClipboard orderId={orderId} />
          <PriorityBtn
            high_priority={orderInfo.high_priority}
            togglePriority={() => {
              requestUpdateOrder({ high_priority: !orderInfo.high_priority });
            }}
          />
          <HasRolePermission
            allowedRoles={[
              "Administrator",
              "CompanyAdministrator",
              "OfficeAdministrator",
              "ServiceAdmin",
              "ServiceCoach",
              'ContractorAdministrator'
            ]}
            renderElse={<div className="small-3 columns" />}
          >
            {!isFetching && orderInfo?.niko_info?.niko_order ? (
              orderInfo?.niko_info?.niko_order_id === "" ? (
                <SendOrderToNHButton
                  orderId={orderId}
                  isFetching={isFetching}
                />
              ) : (
                <UpdateOrderFromNHButton
                  orderId={orderId}
                  isFetching={isFetching}
                />
              )
            ) : (
              ""
            )}
          </HasRolePermission>
          {!isFetching && orderInfo?.niko_info?.niko_order ? (
            orderInfo?.niko_info?.niko_order_id === "" ? (
              <SendOrderToNHButton orderId={orderId} isFetching={isFetching} />
            ) : (
              <UpdateOrderFromNHButton
                orderId={orderId}
                isFetching={isFetching}
              />
            )
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="order-status-container">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            alignItems: "center"
          }}
        >
          <span
            onClick={() => {
              if (canUpdateStatus)
                openModal(
                  <UpdateStatus orderId={orderId} closeSelf={closeModal} />
                );
            }}
            className={`order-status${canUpdateStatus ? " modal-link" : ""}`}
          >
            {orderStatus && deSnakeCase(orderStatus).toUpperCase()}
          </span>
          <HasRolePermission contractorCheck="false">

          {!isUpdating && (
            <div className="accept-decline-container">
              {!hasUnresolvedHoldReasons && canAcceptOrder && (
                <span
                  className="accept-order"
                  title="Accept Order"
                  onClick={() => requestStatusUpdate("orderAccepted")}
                >
                  <AcceptIcon />
                </span>
              )}
              {canSendToPending && (
                <span
                  className="accept-order"
                  title="Accept Order"
                  onClick={() => requestStatusUpdate("pending")}
                >
                  <AcceptIcon />
                </span>
              )}
              {orderStatus==="Order Loading" && (
                <span>
                  <span
                    className="accept-order"
                    title="Accept Order"
                    onClick={() => requestStatusUpdate("orderAccepted")}
                    >
                    <AcceptIcon />
                  </span>
                  <span
                    className="decline-order"
                    onClick={() => requestStatusUpdate("orderDeclined")}
                    title="Decline Order"
                  >
                    <DeclineIcon />
                  </span>
                </span>

              )}
              {!isFetching &&
                patientInfo?.creation_method !== "Philips Import" &&
                canSendTofollowUp && (
                  <span
                    className="send-follow-up"
                    title="Send To Coach Follow Up"
                    onClick={() => requestStatusUpdate(followUpStatusId)}
                  >
                    <FollowUpIcon />
                  </span>
                )}
              {canDeclineOrder && (
                <span
                  className="decline-order"
                  onClick={() => requestStatusUpdate("orderDeclined")}
                  title="Decline Order"
                >
                  <DeclineIcon />
                </span>
              )}
            </div>
          )}

          <InsuranceVerification orderId={orderId} companyInfo={companyInfo} />
          <TaskNotifiction orderId={orderId} />

          </HasRolePermission>

        </div>
        <div
          className="no-print"
          style={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            alignItems: "flex-end"
          }}
        >
          {isFetching ? (
            <InlineWaiting />
          ) : (
            <React.Fragment>
              {orderStatus === "Rejected" || orderStatus === "Voided" ? (
                <div className="pending-info-container no-print">
                  <span className="subheader-text">Reason Rejected:</span>
                  <ReasonRejected
                    orderId={orderId}
                    value={orderInfo.reason_not_accepted}
                  />
                </div>
              ) : (
                <div className="pending-info-container no-print">
                  <span className="subheader-text">Pending Info:</span>
                  <PendingInfo
                    orderId={orderId}
                    value={orderInfo.pending_info}
                    disabled={hasPendingIvrWork}
                  />
                </div>
              )}
              <DelayedPendingField
                delayed_pending_date={orderInfo.delayed_pending_date}
                delayed_pending={orderInfo.delayed_pending}
                requestUpdateOrder={requestUpdateOrder}
                fulfillment_type={orderInfo.fulfillment_type}
                hasPendingIvrWork={hasPendingIvrWork}
              />
              {!["Completed", "Rejected", "Voided"].includes(orderStatus) && (
                <FollowUpDateField
                  follow_up_date={orderInfo.follow_up_date}
                  requestUpdateOrder={requestUpdateOrder}
                />
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default compose(
  withModal,
  connect(
    () => (state, props) => {
      const { data: notes, isFetching: isFetchingOrderNotes } =
        selectors.getOrderNotes(state, props);
      const { data: companyInfo } = selectors.getOrderCompanyInfo(state, props);
      const { data: patientInfo = {}, isFetching: isFetchingPatientInfo } =
        selectors.getOrderPatientInfo(state, props);
      const {
        data: orderInfo = {
        },
        isFetching: isFetchingOrderInfo
      } = selectors.getOrderInfo(state, props);
      const isUpdating = selectors.getIsUpdatingOrder(state, props);
      return {
        patientInfo,
        orderInfo,
        companyInfo,
        patientName: patientInfo.patient_name,
        patientId: patientInfo.patient_id,
        hasNotes: !!notes.length,
        supply_period: orderInfo.supply_period,
        orderStatus: selectors.getEnumOrderStatusText(state, orderInfo.status),
        hasUnresolvedHoldReasons: selectors.getOrderHasUnresolvedHoldReason(
          state,
          props
        ),
        canUpdateStatus: selectors.getCanUpdateOrderStatus(state, props),
        isFetching:
          isFetchingOrderNotes ||
          isFetchingPatientInfo ||
          isFetchingOrderInfo ||
          isUpdating,
        canAcceptOrder: selectors.getCanAcceptOrder(state, props),
        canSendTofollowUp: selectors.getCanSendToFollowUp(state, props),
        canSendToPending: selectors.getCanSendToPending(state, props),
        canDeclineOrder: selectors.getCanDeclineOrder(state, props),
        hasPendingIvrWork: selectors.getIsPendingIvrWork(state, props),
        followUpStatusId: selectors.getOrderStatusId(state, "Coach Follow Up"),
        isUpdating
      };
    },
    mapDispatchToProps
  )
)(ProfileHeader);

ProfileHeader.propTypes = {
  setShowNotes: PropTypes.func.isRequired,
  hasUnresolvedHoldReasons: PropTypes.bool,
  orderId: PropTypes.string.isRequired,
  patientId: PropTypes.string,
  patientName: PropTypes.string,
  hasNotes: PropTypes.bool,
  supply_period: PropTypes.number,
  orderStatus: PropTypes.string,
  canUpdateStatus: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  orderInfo: PropTypes.object,
  isFetching: PropTypes.bool,
  isUpdating: PropTypes.bool,
  canAcceptOrder: PropTypes.bool,
  canDeclineOrder: PropTypes.bool,
  requestStatusUpdate: PropTypes.func.isRequired,
  requestOrderProfile: PropTypes.func.isRequired,
  canSendTofollowUp: PropTypes.bool,
  canSendToPending: PropTypes.bool,
  followUpStatusId: PropTypes.string,
  requestUpdateOrder: PropTypes.func.isRequired,
  hasPendingIvrWork: PropTypes.bool,
  orderRef: PropTypes.shape({ current: PropTypes.object }).isRequired,
  companyInfo: PropTypes.object,
  patientInfo: PropTypes.object
};
