import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useOutsideClick } from "hooks";

const DropdownComplianceBarChartFilter = ({ style, children, trigger, position, onClose, disableTrigger }) => {
    const ref = useRef();
    const [isOpen, setIsOpen] = useState(false);
    useOutsideClick(ref, () => {
      if (isOpen) {
        setIsOpen(false);
        if (onClose) onClose();
      }
    });
    return (
      <div className="dropdown" ref={ref}>
        <span style={disableTrigger ? {pointerEvents: "none"} : undefined} onClick={() => setIsOpen(true)}>{trigger}</span>
        {isOpen ? (
          <div className={`dropdown-content ${position}`} style={style}>
            {children}
          </div>
        ) : null}
      </div>
    );
};

export default DropdownComplianceBarChartFilter;

DropdownComplianceBarChartFilter.defaultProps = {
  style: {},
  position: ""
};

DropdownComplianceBarChartFilter.propTypes = {
  position: PropTypes.oneOf(["", "left", "right", "middle"]),
  trigger: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  onClose: PropTypes.func
};

