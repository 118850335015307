import React from "react";
import PropTypes from "prop-types";
import { getCmnErrors } from "features/bonafide/helpers";

const PendingWorkListItems = ({
  lmns,
  patientsInsurance,
  requestedEquipment
}) => {
  const cmnErrors = getCmnErrors(lmns, patientsInsurance, requestedEquipment);
  return (
    <div className="order-summary">
      {cmnErrors && cmnErrors.length > 0 ? (
        cmnErrors.map(error => (
          <div key={error} className="order-form-prompt order-form-warning">
            {error}
          </div>
        ))
      ) : (
        <div className="order-form-prompt order-form-success">
          <div style={{ fontSize: "1.15rem" }}>
            Patient has valid CMN&apos;s on file for all requested equipment
          </div>
        </div>
      )}
    </div>
  );
};

export default PendingWorkListItems;

PendingWorkListItems.propTypes = {
  lmns: PropTypes.arrayOf(PropTypes.object).isRequired,
  patientsInsurance: PropTypes.string,
  requestedEquipment: PropTypes.arrayOf(PropTypes.string).isRequired
};
