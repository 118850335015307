import { useReducer, useEffect, useCallback } from "react";
import useFetch from "hooks/useFetch";
import { transformUiQuery } from "utils/query";
import queryReducer from "../reducer";
import { propOr } from "ramda";
import { apiRequest } from "utils/api";
import useSafeSetState from "hooks/useSafeSetState";

export { default as Table } from "./table";

export { default as QueryTableContext } from "./context";

let controller = new AbortController();

const useApiQuery = (route, initialQuery = {}, responseKey) => {
  const [query, queryDispatcher] = useReducer(queryReducer, initialQuery);
  const [isLoading, setIsLoading] = useSafeSetState(false);
  const apiFn = useCallback(async ({ query, controller }) => {
    try {
      setIsLoading(true);
      const result = await apiRequest(route, transformUiQuery(query), {
        signal: controller.signal
      });
      return result;
    } finally {
      if (!controller.signal.aborted) {
        setIsLoading(false);
      }
    }
  }, []);
  const { response, fetchData } = useFetch({
    apiFn,
    clearDataOnFetch: false,
    defaultValue: {
      rows: [],
      data: {
        count: 0,
        page: 1,
        per: 150
      }
    },
    transformResponse: response => ({
      rows: propOr([], responseKey, response),
      data: propOr({}, "data", response)
    })
  });
  
  const makeApiCall = query => {
    if (controller) controller.abort();
    controller = new AbortController();
    return fetchData({ query, controller });
  };

  useEffect(() => {
    makeApiCall(query);
  }, [query]);

  useEffect(() => {
    return () => {
      if (controller) controller.abort();
    };
  }, []);
  return {
    query,
    queryDispatcher,
    isFetching: isLoading,
    fetchData: makeApiCall,
    rows: response.rows,
    data: response.data
  };
};

export default useApiQuery;
