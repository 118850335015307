import React, { Component } from 'react'
import PropTypes from 'prop-types'
import includes from 'lodash/includes'
import filter from 'lodash/filter'
import omit from 'lodash/omit'
import noop from 'lodash/noop'
import Table from 'components/ui/impl/table'
import { ExportButton } from 'components/ui'
import MdRefresh from 'react-icons/lib/md/refresh'

const maxPage = (count, per) => Math.ceil(count / per)

const enumeratePages = upperBound => {
  const pageNums = new Array(upperBound)
  for (let i = 0; i < upperBound; ++i) {
    pageNums[i] = i + 1
  }
  return pageNums
}

class QueryTable extends Component {

  handlePer = ({ target: { value } }) => {
    const {
      query,
      query: { pagination },
      onQueryUpdate
    } = this.props
    const updatedQuery = {
      ...query,
      pagination: {
        ...pagination,
        per: isNaN(parseInt(value)) ? '' : parseInt(value),
        page: 1
      }
    }
    onQueryUpdate(updatedQuery)
  }

  handlePrevious = event => {
    event.stopPropagation()
    this.setPage(this.props.query.pagination.page - 1)
  }

  handleNext = event => {
    event.stopPropagation()
    this.setPage(this.props.query.pagination.page + 1)
  }

  handlePageSelect = ({ target: { value } }) => {
    value = parseInt(value)
    this.setPage(value)
  }

  setPage = nextPage => {
    const {
      query,
      query: {
        pagination,
      },
      onQueryUpdate
    } = this.props
    const updatedQuery = {
      ...query,
      pagination: {
        ...pagination,
        page: nextPage
      }
    }
    onQueryUpdate(updatedQuery)
  }

  handleHeaderClick = ({ sort, field }) => {
    const {
      query,
      query: { sort: querySort },
      onQueryUpdate
    } = this.props
    if (querySort) {
      const { by, direction } = querySort
      const sortField = sort || field
      const updatedQuery = {
        ...query,
        sort: {
          by: sortField,
          direction: by === sortField && direction === 'asc' ? 'desc' : 'asc'
        }
      }
      onQueryUpdate(updatedQuery)
    }
  }

  handleAddFilter = (filter, column) => {
    const {
      query,
      query: {
        filters,
        pagination
      },
      onQueryUpdate
    } = this.props
    const filterKey = column.filter || column.field
    const filterValues = filters[filterKey] || []
    const updatedQuery = {
      ...query,
      filters: {
        ...filters,
        [filterKey]: filterValues.concat(filter.value)
      }
    }
    if (pagination) {
      updatedQuery.pagination = {
        ...pagination,
        page: 1
      }
    }
    onQueryUpdate(updatedQuery)
  }

  handleRemoveFilter = (removedFilter, column) => {
    const {
      query,
      query: {
        filters,
        pagination
      },
      onQueryUpdate
    } = this.props
    const filterKey = column.filter || column.field
    const filterValues = filters[filterKey] || []
    const updatedQuery = {
      ...query,
      filters: {
        ...filters,
        [filterKey]: filter(filterValues, v => v !== removedFilter.value)
      }
    }
    if (pagination) {
      updatedQuery.pagination = {
        ...pagination,
        page: 1
      }
    }
    onQueryUpdate(updatedQuery)
  }

  handleCustomFilter = ({ target: { name, value } }) => {
    const {
      query,
      query: {
        filters,
        pagination
      },
      onQueryUpdate
    } = this.props
    const updatedQuery = {
      ...query,
      filters: {
        ...filters,
        [name]: [value]
      }
    }
    if (pagination) {
      updatedQuery.pagination = {
        ...pagination,
        page: 1
      }
    }
    onQueryUpdate(updatedQuery)
  }

  handleClearFilters = column => {
    const {
      query,
      query: {
        filters,
        pagination
      },
      onQueryUpdate
    } = this.props
    const filterKey = column.filter || column.field
    const updatedQuery = {
      ...query,
      filters: omit(filters, filterKey)
    }
    if (pagination) {
      updatedQuery.pagination = {
        ...pagination,
        page: 1
      }
    }
    onQueryUpdate(updatedQuery)
  }

  handleInvertFilters = column => {
    const {
      query,
      query: {
        filters,
        pagination
      },
      onQueryUpdate
    } = this.props
    const filterKey = column.filter || column.field
    const activeFilters = filters[filterKey]
    const availableFilters = column.filters
    const updatedQuery = {
      ...query,
      filters: {
        ...filters,
        [filterKey]: availableFilters
          .filter(({ value }) => !includes(activeFilters, value))
          .map(({ value }) => value)
      }
    }
    if (pagination) {
      updatedQuery.pagination = {
        ...pagination,
        page: 1
      }
    }
    onQueryUpdate(updatedQuery)
  }

  handleRefresh = () => {
    const {
      onRefresh,
      query
    } = this.props
    onRefresh(query)
  }

  handleExport = () => {
    const {
      onExport,
      query
    } = this.props
    onExport(query)
  }

  render() {
    // let { columns } = this.props
    const {
			columns,
      rows,
      perPageOptions,
      query: {
        pagination,
        sort,
        filters
      },
      count,
      isLoading,
      customFilters,
      onRefresh,
      onExport,
      customDisplay,
      customButtons,
      disabledsort
    } = this.props
    return (
      <div className='s3-ui-query-table'>
        {((pagination && perPageOptions) || (customFilters && filters) || onRefresh || onExport || customButtons) && (
          <div className='pagination-per-container'>
            <div>
              {onRefresh && <i className='s3-ui-custom-button' title='Refresh' onClick={this.handleRefresh} ><MdRefresh /></i>}
              {onExport && <ExportButton title='Export' onClick={this.handleExport} />}
              {customButtons && customButtons.map(({ icon, title, onClick }, index) => (
                <i
                  key={index}
                  className="s3-ui-custom-button"
                  title={title}
                  onClick={onClick}
                >
                  {icon}
                </i>
              ))}
              {pagination && perPageOptions && (
                <select
                  title='Rows per page'
                  value={pagination.per}
                  onChange={this.handlePer}>
                  {perPageOptions.map(({ value, text }) => (
                    <option key={value} value={value}>{text}</option>
                  ))}
                </select>
              )}
              {customDisplay ? customDisplay : null}
            </div>
            <div>
              {customFilters && filters && customFilters.map(({ options, field }) => (
                <select
                  key={field}
                  name={field}
                  value={filters[field][0]}
                  onChange={this.handleCustomFilter}>
                  {options.map(({ value, text }) => (
                    <option key={value} value={value}>{text}</option>
                  ))}
                </select>
              ))}
            </div>
          </div>
        )}
				<Table
					columns={columns.map(c => ({
                        ...c,
                        disabledsort : c.disabledsort,
                        sorted: sort && !disabledsort && (c.sort || c.field) === sort.by ? sort.direction : undefined,
                        filters:
                            filters &&
                            c.filters &&
                            c.filters.map(f => ({
                                ...f,
                                active: includes(filters[c.filter || c.field] || [], f.value)
                            }))
                    }))}
                    disabledsort={disabledsort}
					rows={rows}
					onHeaderClick={this.handleHeaderClick}
					onAddFilter={this.handleAddFilter}
					onRemoveFilter={this.handleRemoveFilter}
					onClearFilters={this.handleClearFilters}
					onInvertFilters={this.handleInvertFilters}
					isLoading={isLoading}
				/>
        {(pagination && count) && (
          <div className='query-table-footer'>
            {pagination && count && (
              <div className='pagination-page-container'>
                {pagination.page > 1 && (
                  <div
                    className='pagination-nav-step'
                    onClick={this.handlePrevious}>
                    Previous
                  </div>
                )}
                <div className='pagination-page-range'>
                  <select
                    value={pagination.page}
                    onChange={this.handlePageSelect}>
                    {enumeratePages(maxPage(count, pagination.per)).map(num => (
                      <option key={num} value={num}>{num}</option>
                    ))}
                  </select>
                  <div>
                    of {maxPage(count, pagination.per)}
                  </div>
                </div>
                {pagination.page < maxPage(count, pagination.per) && (
                  <div
                    className='pagination-nav-step'
                    onClick={this.handleNext}>
                    Next
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

QueryTable.propTypes = {
  query: PropTypes.shape({
    filters: PropTypes.object,
    sort: PropTypes.shape({
      by: PropTypes.oneOfType([ PropTypes.func, PropTypes.string]),
      direction: PropTypes.string.isRequired
    }),
    pagination: PropTypes.shape({
      per: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      page: PropTypes.number.isRequired
    })
  }).isRequired,
  perPageOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string
  }),),
  customFilters: PropTypes.arrayOf(PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })).isRequired,
    field: PropTypes.string.isRequired
  })),
  onRefresh: PropTypes.func,
  onExport: PropTypes.func,
  customButtons: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.object.isRequired,
    title: PropTypes.string,
    onClick: PropTypes.func
  })),
  onQueryUpdate: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  customDisplay: PropTypes.node,
  isLoading: PropTypes.bool,
  disabledsort : PropTypes.bool,
  count: PropTypes.number,
}

QueryTable.defaultProps = {
  perPageOptions: [
    { value: "15", text: "15" },
    { value: "150", text: "150" },
    { value: "300", text: "300" },
    { value: "500", text: "500" }
  ],
  onQueryUpdate: noop
};

export default QueryTable
