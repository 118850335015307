import React from "react";
import PropTypes from "prop-types";
import { ListItem } from "components/ui";

const EditTimeSlot = React.memo(
  ({
    master_chat_type,
    slots_remaining,
    start_time,
    end_time,
    active_appointments,
    active,
    max_invitees
  }) => {
    return (
      <div
        style={{
          padding: 5,
          display: "flex",
          flexDirection: "column"
        }}
      >
        <ListItem label="Type" value={master_chat_type} />
        <ListItem label="Start Time" value={start_time} />
        <ListItem label="End Time" value={end_time} />
        <ListItem label="Current Appointments" value={active_appointments} />
        <ListItem label="Slots Remaining" value={slots_remaining} />
        <ListItem label="Max Invitees" value={max_invitees} />
        <ListItem label="Active" value={active ? "Yes" : "No"} />
      </div>
    );
  }
);

EditTimeSlot.propTypes = {
  master_chat_type: PropTypes.string.isRequired,
  slots_remaining: PropTypes.number,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
  active_appointments: PropTypes.number,
  active: PropTypes.bool,
  max_invitees: PropTypes.number,
  company: PropTypes.string
};

export default EditTimeSlot;
