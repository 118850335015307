import React from "react";
import PropTypes from "prop-types";
import { formatTimeFromTimeSlot, getGridRowSpan } from "./scheduler-helpers";
import { TimeSlotContext } from "./use-availabilityScheduler";
import MdPeopleOutline from "react-icons/lib/md/people-outline";
// import MdVideoCall from "react-icons/lib/md/video-call";
// import MdChat from "react-icons/lib/md/chat";
// import MdOndemandVideo from "react-icons/lib/md/ondemand-video";

// const getChatTypeIcon = master_chat_type => {
//   switch (master_chat_type) {
//     case "Text Chat":
//       return <MdChat size={20} />;
//     case "Product Demo":
//       return <MdOndemandVideo size={20} />;
//     case "Video Chat":
//       return <MdVideoCall size={20} />;
//     default:
//       return null;
//   }
// };
const getChatTypeClass = (master_chat_type = "") =>
  master_chat_type.replace(" ", "_").toLowerCase();

const ScheduledSlot = React.memo(({ slot }) => {
  const { editSlot, setEditSlot, setPendingTimeSlot } = React.useContext(
    TimeSlotContext
  );
  const [mouseDown, setMouseDown] = React.useState(false);
  const gridSpan = getGridRowSpan(slot.row1, slot.row2);

  return (
    <div
      key={slot.slot_id}
      className={`scheduled-slot active ${
        editSlot && editSlot.slot_id === slot.slot_id ? "selected" : ""
      } ${getChatTypeClass(slot.master_chat_type)}`}
      style={{
        gridColumn: `${slot.col + 1} / span 1`,
        gridRow: `${slot.row1 + 1} / span ${gridSpan}`
      }}
      title={slot.master_chat_type}
      onMouseUp={() => {
        if (mouseDown) {
          setPendingTimeSlot(null);
          if (editSlot && editSlot.slot_id === slot.slot_id) setEditSlot(null);
          else {
            setEditSlot(slot);
          }
        }
        setMouseDown(false);
      }}
      onMouseDown={e => {
        e.stopPropagation();
        setMouseDown(true);
      }}
    >
      <div className="scheduled-slot-info">
        {gridSpan > 2 && (
          <div className="available-slots">
            <MdPeopleOutline size={14} />{" "}
            <span>
              {slot.slots_remaining - slot.max_invitees} / {slot.max_invitees}
            </span>
          </div>
        )}
        <div className="slot-time">
          {formatTimeFromTimeSlot(slot.row1)}-
          {formatTimeFromTimeSlot(slot.row2)}
        </div>
      </div>
    </div>
  );
});

ScheduledSlot.propTypes = {
  slot: PropTypes.shape({
    slot_id: PropTypes.string.isRequired,
    col: PropTypes.number.isRequired,
    master_chat_type: PropTypes.string.isRequired,
    row1: PropTypes.number.isRequired,
    row2: PropTypes.number.isRequired,
    start_time: PropTypes.string.isRequired,
    end_time: PropTypes.string.isRequired,
    slots_remaining: PropTypes.number,
    max_invitees: PropTypes.number
  }).isRequired
};

export default ScheduledSlot;
