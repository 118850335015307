import React from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import styler from "react-styling";

const SelectInput = ({
  name,
  label,
  onChange,
  value,
  error,
  options,
  onBlur,
  blankFirstOption = true,
  // defaultOption
}) => {
  let wrapperClass = "form_group";
  if (error && error[name]) {
    wrapperClass += "_has_error";
  } else {
    wrapperClass = "form_group";
  }
  return (
    <div style={styles[wrapperClass]} className="form-group">
      <label htmlFor={name}>{label}</label>
      <div style={styles.field_div} className="field">
        {/* Note, value is set here rather than on the option - docs: https://facebook.github.io/react/docs/forms.html */}
        <select
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          className="form-control"
          //defaultValue= {defaultOption}
        >
          {blankFirstOption && <option value="" />}
          {options.map((option, i) => {
            return (
              <option key={i} value={option.value}>
                {option.text}
              </option>
            );
          })}
        </select>
        {error &&
          error[name] &&
          <div style={styles.error_text} className="alert alert-danger">
            {error[name]}
          </div>}
      </div>
    </div>
  );
};

SelectInput.propTypes = {
  defaultOption: PropTypes.string,
  blankFirstOption: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.object,
  options: PropTypes.object,
  value: PropTypes.string,
  onBlur: PropTypes.func
};

const styles = styler`
  error_text
    color: red
    padding: 0 0 10px 0
  form_group_has_error
    border: 2px solid red
    padding: 10px 0 0
  field_div
    margin: 2px
`;

export default Radium(SelectInput);
