import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { formatDate } from "utils/dates";
import { Link } from "react-router-dom";
import { InlineWaiting, Checkbox, IconButton } from "components/ui";
import { Formik, Form } from "formik";
import { MdEdit, MdSave, MdCancel } from "react-icons/lib/md";
import FormikField from "components/forms/formik-field";
import MdWarning from "react-icons/lib/md/warning";
import {
  fetchPatientOrderHistory,
  invalidatePatientOrderHistory,
  updatePatientOrderHistory
} from "actions/patient";

const HcpcRow = ({
  eligiblity,
  expanded,
  toggleExpanded,
  editRow,
  setEditRow,
  patientId
}) => {
  return (
    <>
      <div className="eligibility-table-row" onClick={toggleExpanded}>
        <div style={{ color: eligiblity.isEligible ? "green" : "default" }}>
          {eligiblity.productType}
        </div>
        <div>{formatDate(eligiblity.eligibilityDate)}</div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <span>{formatDate(eligiblity.lastOrderDate)}</span>
          {eligiblity.lastOrderedItemZeroBilled && (
            <span
              style={{
                paddingLeft: 5,
                dispaly: "flex",
                alignItems: "baseline"
              }}
            >
              <span title="Last Item Ordered was Zero Billed">
                <MdWarning size={18} color="#ff7800" />
              </span>
            </span>
          )}
          {eligiblity.adjustedItemQty > 0 && (
            <span
              style={{
                paddingLeft: 5,
                dispaly: "flex",
                alignItems: "baseline"
              }}
            >
              <span
                title={`Adjusted eligible quantity of last item to ${eligiblity.adjustedItemQty}`}
              >
                <MdWarning size={18} color="#dd9830" />
              </span>
            </span>
          )}
        </div>
        <div style={{ marginRight: 15, fontSize: "0.9rem" }}>
          {eligiblity.lastOrderedItemName}
        </div>
        <div style={{ marginRight: 15, fontSize: "0.9rem" }}>
          {eligiblity.lastOrderedItemId}
        </div>
        {eligiblity.insRuleDaysToPush == null ? (
          <div />
        ) : (
          <div>
            {eligiblity.insRuleDaysToPush}|{eligiblity.insRuleQtyAllowed}|
            {eligiblity.companyOffsetDays}
          </div>
        )}
        <div></div>
      </div>
      {expanded && (
        <div className="hcpc-history-table">
          {!eligiblity.history || eligiblity.history?.length == 0 ? (
            <div style={{ paddingLeft: 60 }}>No Order History</div>
          ) : (
            <div className="hcpc-history-table-header">
              <div>HCPC</div>
              <div>Order Date</div>
              <div>QTY</div>
              <div>Zero Billed</div>
              <div>Origin</div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-start"
                }}
              >
                Item Name
              </div>
              <div>Item ID</div>
            </div>
          )}
          {eligiblity.history?.map((x, i) => (
            <div className="eligibility-history-row-container" key={i}>
              {editRow === x.hcpcOrderedID ? (
                <EditHcpcHistoryRow
                  history={x}
                  setEditRow={setEditRow}
                  patientId={patientId}
                />
              ) : (
                <div className="hcpc-history-table-row">
                  <div>{x.hcpc}</div>
                  <div>{formatDate(x.service_date)}</div>
                  <div>{x.qty}</div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      width: "100%",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <Checkbox
                      value={x.zeroBilled}
                      color={x.zeroBilled ? "#ff7800" : "default"}
                    />
                  </div>
                  <div>
                    {x.creationMethod == "S3" && x.orderId ? (
                      <Link to={`/orders/${x.orderId}`}>
                        {x.creationMethod}
                      </Link>
                    ) : (
                      x.creationMethod
                    )}{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-start"
                    }}
                  >
                    {x.productName}
                  </div>
                  <div>{x.productID}</div>
                  {/* Only allow editing if the row was not generated from S3 Order */}
                  {x.creationMethod !== "S3" && !editRow && (
                    <IconButton
                      icon={<MdEdit />}
                      onClick={() => {
                        setEditRow(x.hcpcOrderedID);
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

HcpcRow.propTypes = {
  eligiblity: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  toggleExpanded: PropTypes.func.isRequired,
  editRow: PropTypes.number,
  setEditRow: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired
};

export default HcpcRow;

const EditHcpcHistoryRow = ({ history, setEditRow, patientId }) => {
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        hcpcOrderedID: history.hcpcOrderedID,
        quantity: history.qty,
        zeroBilled: history.zeroBilled
      }}
      onSubmit={async values => {
        await dispatch(updatePatientOrderHistory(patientId, values));
        await dispatch(invalidatePatientOrderHistory({ patientId }));
        await dispatch(fetchPatientOrderHistory({ patientId }));
        setEditRow(null);
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form>
          <div className="hcpc-history-table-row">
            <div>{history.hcpc}</div>
            <div>{formatDate(history.service_date)}</div>
            <div>
              <FormikField name="quantity" type="number" />
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                width: "100%",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <FormikField label="" name="zeroBilled" type="checkbox" />
            </div>
            <div>
              {history.creationMethod == "S3" && history.orderId ? (
                <Link to={`/orders/${history.orderId}`}>
                  {history.creationMethod}
                </Link>
              ) : (
                history.creationMethod
              )}{" "}
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-start"
              }}
            >
              {history.productName}
            </div>
            <div>{history.productID}</div>
            {/* Only allow editing if the row was not generated from S3 Order */}
            {isSubmitting ? (
              <InlineWaiting />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "space-between"
                }}
              >
                <IconButton
                  type="button"
                  icon={<MdCancel color="orange" size={18} />}
                  onClick={() => {
                    setEditRow(null);
                  }}
                />
                <IconButton
                  type="submit"
                  icon={<MdSave color="green" size={18} />}
                  onClick={submitForm}
                />
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

EditHcpcHistoryRow.propTypes = {
  history: PropTypes.object.isRequired,
  editRow: PropTypes.number,
  setEditRow: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired
};
