import React from "react";
import { put, post, del } from "utils/api";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { message, errorMessage } from "actions/message";

const useAvailabilityScheduler = () => {
  const dispatch = useDispatch();
  const [userTimeSlots, setUserTimeSlots] = React.useState([]);
  const [pendingTimeSlot, setPendingTimeSlot] = React.useState(null);
  const [week, selectWeek] = React.useState(moment().week());
  const [selected, setSelected] = React.useState({
    row1: null,
    col: null
  });
  const [editSlot, setEditSlot] = React.useState();

  React.useEffect(() => {
    getUserTimeSlots(week);
  }, [week]);

  const getUserTimeSlots = async week => {
    setUserTimeSlots([]);
    const startDate = moment().week(week).startOf("week").format("L");
    const endDate = moment().week(week).endOf("week").format("L");
    const { slots = [] } = await put(
      "/dme_portal/available_slots_by_date_range",
      {
        startDate,
        endDate
      }
    );
    setUserTimeSlots(
      slots?.map(s => ({
        ...s,
        start_time: moment
          .tz(s.start_time, "US/Central")
          .local()
          .format("L LT"),
        end_time: moment.tz(s.end_time, "US/Central").local().format("L LT")
      })) ?? []
    );
  };
  const createTimeSlot = async (
    start_time,
    end_time,
    { description, max_number_of_invitees, service_ids, product_ids, chat_type }
  ) => {
    try {
      const { success, message: responseMsg } = await post(
        "/dme_portal/slots",
        {
          start_time: moment(start_time, "L LT")
            .tz("US/Central")
            .format("L LT"),
          end_time: moment(end_time, "L LT").tz("US/Central").format("L LT"),
          description,
          max_number_of_invitees,
          service_ids,
          product_ids,
          chat_type
        }
      );
      if (success) dispatch(message(responseMsg));
      else dispatch(errorMessage(responseMsg));
    } catch (err) {
      dispatch(
        errorMessage(
          err.response?.body?.message ?? "Failed to create time slot"
        )
      );
    }
  };

  const deleteTimeSlotById = async timeSlotId => {
    const result = await del(`/dme_portal/slot/${timeSlotId}`);
    return result;
  };

  return {
    userTimeSlots,
    getUserTimeSlots,
    createTimeSlot,
    deleteTimeSlotById,
    pendingTimeSlot,
    setPendingTimeSlot,
    week,
    selectWeek,
    selected,
    setSelected,
    editSlot,
    setEditSlot
  };
};

export default useAvailabilityScheduler;

export const TimeSlotContext = React.createContext();
