import React from "react";
import PropTypes from "prop-types";
import PureModal from "components/ui/modal/pure-modal";
import PatientLmnsOnFile from "features/bonafide/components/order-form/patient-lmns";
import PatientGuarantorOnFile from "features/bonafide/components/order-form/patient-guarantor";
import PatientPriorAuth from "features/bonafide/components/order-form/patient-prior-auth";
import { BonafidePatientContext } from "features/bonafide/use-bonafide";
import MdInfo from "react-icons/lib/md/info";
import { InlineWaiting, IconButton } from "components/ui";
import BonafidePendingWorklistItems from "./pending-worklist-items";

const OrderPatientVerification = ({ values }) => {
  const { insurances, lmns } = React.useContext(BonafidePatientContext);
  const patientInsurance = values.primary_insurance?.insurance_company_name;
  return (
    <div className="bonafide-order-form-patient-summary">
      <h3>Patient Verification</h3>
      <div
        style={{
          display: "grid",
          gridTemplateRows: "auto auto auto",
          gridGap: 10
        }}
      >
        <PureModal
          renderTrigger={({ openModal }) => (
            <div>
              <IconButton
                icon={<MdInfo />}
                text="LMNS On File"
                type="button"
                onClick={openModal}
              />
            </div>
          )}
          renderContent={() => <PatientLmnsOnFile />}
        />
        <PureModal
          renderTrigger={({ openModal }) => (
            <div>
              <IconButton
                icon={<MdInfo />}
                text="Patient Guarantor On File"
                type="button"
                onClick={openModal}
              />
            </div>
          )}
          renderContent={() => (
            <PatientGuarantorOnFile insurances={insurances} />
          )}
        />
        <PureModal
          renderTrigger={({ openModal }) => (
            <div>
              <IconButton
                icon={<MdInfo />}
                text="Prior Auth"
                type="button"
                onClick={openModal}
              />
            </div>
          )}
          renderContent={() => <PatientPriorAuth />}
        />
      </div>
      <ul className="pending-patient-updates">
        {values.changed_insurance && (
          <li
            className="order-form-prompt order-form-info"
            style={{
              border: "1px solid #d7d7d7",
              padding: 10
            }}
          >
            Updated Patients Insurance
          </li>
        )}
        {values.changed_physician && (
          <li
            className="order-form-prompt order-form-info"
            style={{
              border: "1px solid #d7d7d7",
              padding: 10
            }}
          >
            Updated Patients Physician
          </li>
        )}
      </ul>
      <div>
        <BonafidePendingWorklistItems
          lmns={lmns}
          patientsInsurance={patientInsurance}
          requestedEquipment={values.requested_equipment}
        />
      </div>
    </div>
  );
};

OrderPatientVerification.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default OrderPatientVerification;

export const PatientVerificationNextButton = ({
  handleNext,
  formProps,
  updatePatientInfo
}) => {
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [submittedPatientInfo, setSubmittedPatientInfo] = React.useState(false);
  async function submitUpdatedPatientInfo() {
    try {
      if (submittedPatientInfo === false) {
        setIsUpdating(true);
        await updatePatientInfo(formProps.values);
        setSubmittedPatientInfo(true);
      }
    } catch (err) {
      alert(
        "There was an error sending updated patient info to external system"
      );
    } finally {
      setIsUpdating(false);
      handleNext();
    }
  }
  return isUpdating ? (
    <InlineWaiting />
  ) : (
    <button type="button" onClick={submitUpdatedPatientInfo}>
      Next
    </button>
  );
};
PatientVerificationNextButton.propTypes = {
  handleNext: PropTypes.func.isRequired,
  formProps: PropTypes.object,
  updatePatientInfo: PropTypes.func.isRequired
};
