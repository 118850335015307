import React from "react";
import PropTypes from "prop-types";
import MdWarning from "react-icons/lib/md/warning";

const ConfirmationDialog = ({
  warningMsg,
  continueMsg,
  onCancel,
  onConfirm,
  children
}) => (
  <div style={{ padding: 5 }}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: 20
      }}
    >
      {warningMsg && (
        <React.Fragment>
          <span style={{ padding: "0 10px", color: "rgb(232, 145, 12)" }}>
            <MdWarning size={32} />
          </span>
          <span
            style={{
              fontSize: "1.15rem"
            }}
          >
            {warningMsg}
          </span>
        </React.Fragment>
      )}
      {children}
    </div>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 10
      }}
    >
      <button onClick={onCancel} className="secondary">
        Cancel
      </button>
      <button onClick={onConfirm}>{continueMsg}</button>
    </div>
  </div>
);

ConfirmationDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  warningMsg: PropTypes.string,
  continueMsg: PropTypes.string,
  children: PropTypes.node
};

export default ConfirmationDialog;
