import * as msg from "actions/message";
import { errorMessage } from "actions/message";
import { browserHistory } from "browser-history";
import FormikField from "components/forms/formik-field";
import ContactLogicTable from "components/screens/company-contact-logic/contact-logic-table";
import { PageHeader, PureModal, Spinner } from 'components/ui';
import { Form, Formik } from "formik";
import { useFetch } from 'hooks';
import { clone, path } from "ramda";
import React, { useEffect, useState } from 'react';
import { IoEdit } from 'react-icons/lib/io';
import IoPlus from "react-icons/lib/io/plus";
import IoTrashA from "react-icons/lib/io/trash-a";
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { selectors } from "reducers";
import { getApiErrorMessage } from "utils/misc";
import ModalFreeTextQuestion from '../components/modalFreeTextQuestion';
import ModalLogicalQuestion from '../components/modalLogicalQuestion';
import '../pages/companyForms.css';
import { get, put, post } from '../../../../utils/api';
import CopyForm from './copy-form';
import ModalConditionalQuestion from './modalConditionalQuestion';
import ModalOptionQuestion from './modalOptionQuestion';
import ConfirmationModal from "components/shared/confirmation-modal";
import ModalAssignFormToPatients from "./assign-form-to-patient-confirmation";
import AssignablePatientsFormModal, { AssignablePatientsFormOpenModal } from "./../../../../components/company-assignable-patients-forms/open-modal"

const CreateEditForm = ({ params, formOfContactOptions }) => {

    const contactLogicDefaultValue = [{ companyId: params.id, isFetching: false, cycles: [] }];

    const history = useHistory();
    const dispatch = useDispatch();

    const { formId } = useParams()
    const [stateFormId, setStateFormId] = useState(formId);

    const [patienType, setPatientType] = useState([]);

    const [questions, setQuestions] = useState([]);

    const [questionsTypes, setQuestionsTypes] = useState([]);

    const [question, setQuestion] = useState({ id: '0', name: '', type: '0' });

    const [questionsSelect, setQuestionsSelect] = useState([]);

    const [comparisons, setComparisons] = useState([]);

    const [binaryOperatorType, setBinaryOperatorType] = useState([]);

    const [count, setCount] = useState(0);

    const [newQuestion, setNewQuestion] = useState(
        {
            Text: '',
            AlreadyExists: false,
            SpanishText: '',
            QuestionType: '0',
            Order: 0,
            Conditions: [],
            IsRequired: true,
            HasNAOption: false
        }
    );

    const [condition, setCondition] = useState(
        {
            Order: 0,
            Value: 1,
            TaskActionId: '0',
        }
    );

    const [questionsOptions, setQuestionsOptions] = useState([]);

    const [taskActions, setTaskActions] = useState([]);

    const [error, setError] = useState(false);

    const [errorQuestionReq, setErrorQuestionReq] = useState(false);

    const [alreadyExists, setAlreadyExists] = useState(false);

    const [formInfo, setFormInfo] = useState({
        Name: "",
        Description: "",
        PatientType: null,
        AvailableDays: 0,
        IsActive: true,
        CompanyId: params.id,
        Order: 0,
        Questions: questionsSelect,
        UsesCustomContactLogic: false,
        ContactLogics: [],
    });

    const [usesCustomContactLogic, setUsesCustomContactLogic] = useState(false);

    const [customContactLogic, setCustomContactLogic] = useState(clone(contactLogicDefaultValue));

    const [selectedFormOfContactOption, setSelectedFormOfContactOption] = useState("");

    const [filteredContactLogic, setFilteredContactLogic] = useState(clone(contactLogicDefaultValue));

    const [selectedPatientType, setSelectedPatientType] = useState("0");

    const [availableDays, setAvailableDays] = useState(0);

    const [initialAvailableDays, setInitialAvailableDays] = useState(0);

    const [submittedFormData, setSubmittedFormData] = useState();
    const [patientTypeSelected, setPatientTypeSelected] = useState(false);

    const [orderNumber, setOrderNumber] = useState(0);

    const [wasInitialized, setWasInitialized] = useState(false);

    const {
        fetchData: fetchPatientType,
    } = useFetch({
        apiFn: () => get(`forms/patientTypes/${params.id}`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        fetchData: fetchEditForm,
        isFetching: isFetchingEditForm,
    } = useFetch({
        apiFn: (data) => put(`forms/${formId}`, data),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        fetchData: fetchCreateForm,
        isFetching: isFetchingCreateForm,
    } = useFetch({
        apiFn: (data) => post(`forms`, data),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        fetchData: fetchQuestions,
    } = useFetch({
        apiFn: () => get(`forms/questions/${params.id}`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        fetchData: fetchQuestionsTypes,
    } = useFetch({
        apiFn: () => get(`/forms/questionsTypes`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        fetchData: fetchQuestionsOptions,
    } = useFetch({
        apiFn: () => get(`/forms/questions/options/${params.id}`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        fetchData: fetchTaskActions,
    } = useFetch({
        apiFn: () => get(`/tasks`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        fetchData: fetchComparison,
    } = useFetch({
        apiFn: () => get(`/forms/comparison-operator-types`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        fetchData: fetchBinaryOperatorType,
    } = useFetch({
        apiFn: () => get(`/forms/binary-operator-types`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        fetchData: fetchFormInfo,
        isFetching: fetchingFormInfo,
    } = useFetch({
        apiFn: () => get(`forms/${formId}`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        fetchData: fetchAvailableDays,
        isFetching: isFetchingAvailableDays,
    } = useFetch({
        apiFn: () => get(`contactlogic/company/${params.id}/outreach-days/${selectedPatientType}/${orderNumber}`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Available Days: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        fetchData: fetchShouldWarnFormReschedule,
    } = useFetch({
        apiFn: () => get(`forms/${formId}/will-reschedule`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get check form rescheduling: ${getApiErrorMessage(error)}`);
        }
    })

    useEffect(() => {
        Promise.all(
            [getPatientsType(),
            getQuestions(),
            getQuestionsTypes(),
            getQuestionsOptions(),
            getTaskActions(),
            getComparison(),
            getBinaryOperatorType(),
            formId && getFormInfo()
            ]).then(() => {
                setWasInitialized(true);
            })
    }, [])

    useEffect(() => {
        const mutableContactLogicArray = clone(customContactLogic);

        mutableContactLogicArray.forEach((element) => {
            element.cycles = element.cycles?.filter(
                cycle =>
                    !selectedFormOfContactOption ||
                    cycle.actionId === selectedFormOfContactOption)
        }
        );

        setFilteredContactLogic(mutableContactLogicArray);
    }, [selectedFormOfContactOption, customContactLogic]);

    useEffect(() => {
        // set initial selected form of contact
        if (formOfContactOptions) {
            const callId =
                formOfContactOptions.find(x => x.text === "Manual Call")?.key ?? "";
            setSelectedFormOfContactOption(callId);
        }

        if (customContactLogic.length > 0 || filteredContactLogic.length > 0) {
            setCustomContactLogic(contactLogicDefaultValue);
            setFilteredContactLogic(contactLogicDefaultValue)
        }

    }, [formOfContactOptions]);

    // disabled customContactLogics BON-1142
    // useEffect(() => {
    //     if(wasInitialized){ 
    //         if (formId && !initialAvailableDays && formInfo){
    //             setInitialAvailableDays(formInfo.AvailableDays);
    //         }
    //         setAvailableDays(0);
    //         if (usesCustomContactLogic){
    //             updateAvailableDaysBasedOnCustomContactLogic();
    //         }
    //         else if (selectedPatientType != "0" && orderNumber != 0){
    //             getAvailableDays();
    //         }
    //     }
    // }, [usesCustomContactLogic, customContactLogic])

    // disabled customContactLogics  BON-1142
    // useEffect(() => {
    //     if(wasInitialized){
    //         if (usesCustomContactLogic){ 
    //             updateAvailableDaysBasedOnCustomContactLogic();
    //         }
    //     }
    // }, [availableDays])

    useEffect(() => {
        if (wasInitialized) {
            if ((patientTypeSelected && orderNumber != 0) | (formId && !fetchingFormInfo) && selectedPatientType != "0") {
                getAvailableDays();
            }
        }
    }, [selectedPatientType, orderNumber]);

    const updateAvailableDaysBasedOnCustomContactLogic = () => {
        let filteredCycles = {};
        const cycles = clone(customContactLogic[0].cycles);

        cycles.reduce(function (_, cycle) {
            const isUndefined = filteredCycles[cycle.actionId] == undefined;
            filteredCycles[cycle.actionId] = isUndefined ? cycle.daysToPushOutreachDate : filteredCycles[cycle.actionId] + cycle.daysToPushOutreachDate;
        }, Object.create(null));

        var value = Math.max(...Object.values(filteredCycles))
        if (value !== -Infinity) {
            setAvailableDays(value);
        } else {
            setAvailableDays(0)
        }
    }

    const getPatientsType = async () => {

        const patientsTypeResponse = await fetchPatientType();
        if (patientsTypeResponse)
            setPatientType(patientsTypeResponse.Values);
    }

    const getQuestions = async () => {

        const response = await fetchQuestions();
        if (response)
            setQuestions(response.Questions);
    }

    const getQuestionsTypes = async () => {

        const response = await fetchQuestionsTypes();
        if (response)
            setQuestionsTypes(response.Values);
    }

    const getQuestionsOptions = async () => {

        const response = await fetchQuestionsOptions();
        if (response)
            setQuestionsOptions(response.Values);
    }

    const getTaskActions = async () => {

        const response = await fetchTaskActions();
        if (response) {
            setTaskActions(response.Values);
        }
    }

    const getComparison = async () => {

        const response = await fetchComparison();
        if (response) {
            setComparisons(response.Values);
        }
    }

    const getBinaryOperatorType = async () => {

        const response = await fetchBinaryOperatorType();

        if (response) {
            setBinaryOperatorType(response.Values);
        }
    }

    function formatQuestions(questionsArray) {
        if (questionsArray) {
            return questionsArray?.map(questionElement =>
                questionElement.Conditions?.map(condition => condition.TaskActionId = questionElement.Options[0]?.TaskActionId)
            )
        }
    }

    const getFormInfo = async () => {

        const response = await fetchFormInfo();

        if (response) {
            setFormInfo({
                ...formInfo,
                Name: response.Name,
                Description: response.Description,
                PatientType: response.PatientType,
                AvailableDays: response.AvailableDays,
                IsActive: response.IsActive,
                CompanyId: params.id,
                Order: response.Order,
                Questions: formatQuestions(response.Questions),
                UsesCustomContactLogic: response.UsesCustomContactLogic,
                ContactLogics: response.ContactLogic,
            });

            let retrievedContactLogic = clone(contactLogicDefaultValue);
            retrievedContactLogic[0].cycles = response.ContactLogic;
            setCustomContactLogic(retrievedContactLogic);
            setUsesCustomContactLogic(response.UsesCustomContactLogic);
            setQuestionsSelect(response.Questions)
            setCount(response.Questions?.length);
            setSelectedPatientType(response.PatientType);
            setAvailableDays(response.AvailableDays)
            setOrderNumber(response.Order)
        }
    }

    const getAvailableDays = async () => {
        if ((patientTypeSelected || (formId && formInfo.PatientType != null)) && orderNumber != 0) {
            const availableDaysResponse = await fetchAvailableDays();
            if (availableDaysResponse && availableDaysResponse.success && !usesCustomContactLogic)
                setAvailableDays(availableDaysResponse.Days);
            else {
                setAvailableDays(0);
                const errorMessage = availableDaysResponse && availableDaysResponse.message ? availableDaysResponse.message : "An error occurred while fetching the available days.";
                dispatch(msg.errorMessage(errorMessage))
            }
        }
    }

    const showPatientType = () => {

        return patienType.map((op, i) => (
            <option key={i} value={op.ItemId}>{op.ItemValue}</option>
        ))
    }

    const showQuestions = () => {

        return questions.map((op, i) => (
            <option key={i} value={op.Id}>{op.Text}</option>
        ))
    }

    const showQuestionsTypes = () => {

        return questionsTypes.map((op, i) => (
            <option key={i} value={op.ItemId}>{op.ItemValue}</option>
        ))
    }

    const showTaskActions = () => {

        return taskActions.map((op, i) => (
            <option key={i} value={op.ItemId}>{op.ItemValue}</option>
        ))
    }

    const validateBeforeSubmitting = async (data) => {

        if (!questionsSelect.length) {
            setErrorQuestionReq(true);
        }
        else {
            if (availableDays != initialAvailableDays && formId) {
                const result = await fetchShouldWarnFormReschedule();
                if (result && result.success) {
                    if (result.FormsWillBeRescheduled)
                        document.getElementById("reschedulingWarning").click();
                    else
                        await editForm(data);
                }
            }
            else {
                await editForm(data);
            }
        }
    }

    const editForm = async (data) => {
        if (!questionsSelect.length) {
            setErrorQuestionReq(true);
        }
        else {
            const info = data ? data : submittedFormData;
            const newData = {
                Name: info.Name,
                Description: info.Description,
                PatientType: info.PatientType,
                AvailableDays: availableDays,
                IsActive: info.IsActive,
                CompanyId: info.CompanyId,
                Order: info.Order,
                Questions: questionsSelect,
                UsesCustomContactLogic: usesCustomContactLogic,
                ContactLogics: usesCustomContactLogic && customContactLogic[0].cycles || null
            }

            let response

            if (formId) {
                response = await fetchEditForm(newData);
            } else {
                response = await fetchCreateForm(newData);
            }

            if (response) {
                if (response.success) {
                    if (formId)
                        goToFormsList();
                    else {
                        setStateFormId(response.FormId)
                        document.getElementById("assignPatientsConfirmationModal").click();
                    }
                } else {
                    dispatch(msg.errorMessage(response.message))
                }
            }
        }
    }

    const goToFormsList = () => history.replace(`/companies/${params.id}/profile/complianceForms`);

    const validateNull = value => {

        let errorMessage;
        if (!value)
            errorMessage = 'You must select an option';
        return errorMessage;
    }

    const validateEmpty = value => {
        let errorMessage;

        if (value === '' || value === 0)
            errorMessage = 'the field must not be empty';
        return errorMessage;
    }

    const validateEmptyAvailableDays = () => {
        let errorMessage;

        if (!availableDays || availableDays === undefined || availableDays === 0)
            errorMessage = 'Available days can not be 0, please configure Contact Logic for the Company or the form.';
        return errorMessage;
    }

    const handleChangeQuestion = (e) => {

        const option = +e.target.value

        if (option) {
            const find = questions.find(x => x.Id === option);
            setQuestion({ id: find.Id, name: find.Text, type: find.Type });

            setNewQuestion({ ...newQuestion, QuestionType: find.Type.toString() })
        }
        else {
            setQuestion({ id: '0', name: '', type: '0' })
            setNewQuestion({ ...newQuestion, QuestionType: '0' })
        }

        setError(false);
    }

    const addQuestions = (options, conditions) => {

        const questionObj = {
            QuestionId: question.id,
            Text: question.name,
            SpanishText: question.name,
            Order: count + 1,
            AlreadyExists: alreadyExists,
            InsuranceId: null,
            Options: options,
            Conditions: setConditionsArray(conditions),
            QuestionType: Number(newQuestion.QuestionType),
            IsRequired: newQuestion.IsRequired,
            HasNAOption: newQuestion.HasNAOption
        }

        if (validateQuestionTable(question.id)) {
            setQuestionsSelect([...questionsSelect, questionObj]);
            setCount(questionObj.Order);
            setError(false);
        }
        else
            setError(true);

        setQuestion({ ...question, id: '0', type: '0' });
        setCondition({ ...condition, Value: 1, TaskActionId: '0', Order: 0 });
        setNewQuestion({ ...newQuestion, QuestionType: '0' });
        setErrorQuestionReq(false);
    }

    const editQuestions = (options, conditions, questionToEditData, newQuestionText, newRequiredAndNAStatus) => {
        let newState
        if (questionToEditData.QuestionType == "2") {
            newState = questionsSelect.map(obj => {
                if ((obj.QuestionId && obj.QuestionId === questionToEditData.QuestionId) || obj.Text === questionToEditData.Text) {
                    return {
                        ...obj,
                        Options: options,
                        Text: newQuestionText.newText,
                        SpanishText: newQuestionText.newSpanishText,
                        IsRequired: newRequiredAndNAStatus.IsRequired,
                        HasNAOption: newRequiredAndNAStatus.HasNAOption
                    };
                }
                return obj;
            })
        } else if (questionToEditData.QuestionType == "4") {
            newState = questionsSelect.map(obj => {
                if ((obj.QuestionId && obj.QuestionId === questionToEditData.QuestionId) || obj.Text === questionToEditData.Text) {
                    return {
                        ...obj,
                        Conditions: conditions,
                        Text: newQuestionText.newText,
                        SpanishText: newQuestionText.newSpanishText,
                        IsRequired: newRequiredAndNAStatus.IsRequired,
                        HasNAOption: newRequiredAndNAStatus.HasNAOption
                    };
                }
                return obj;
            })
        } else if (questionToEditData.QuestionType == "1") {
            newState = questionsSelect.map(obj => {
                if ((obj.QuestionId && obj.QuestionId === questionToEditData.QuestionId) || obj.Text === questionToEditData.Text) {
                    return {
                        ...obj,
                        Text: newQuestionText.newText,
                        SpanishText: newQuestionText.newSpanishText,
                        IsRequired: newRequiredAndNAStatus.IsRequired,
                        HasNAOption: newRequiredAndNAStatus.HasNAOption
                    };
                }
                return obj;
            })
        } else if (questionToEditData.QuestionType == "3") {
            newState = questionsSelect.map(obj => {
                if ((obj.QuestionId && obj.QuestionId === questionToEditData.QuestionId) || obj.Text === questionToEditData.Text) {
                    return {
                        ...obj,
                        Conditions: conditions,
                        Text: newQuestionText.newText,
                        SpanishText: newQuestionText.newSpanishText,
                        IsRequired: newRequiredAndNAStatus.IsRequired,
                        HasNAOption: newRequiredAndNAStatus.HasNAOption
                    };
                }
                return obj;
            })
        }
        setQuestionsSelect(newState);
    }


    const showTableQuestions = (isSubmitting) => {
        return (
            questionsSelect.length ?
                <div className='scrollTableAQuestion'>
                    <table>
                        <thead>
                            <tr> {showHeadersQuestion()}</tr>
                        </thead>
                        <tbody>  {showQuestionsSelect(isSubmitting)}</tbody>
                    </table>

                </div>
                : <p><span>No Found Questions</span></p>
        )
    }

    const showHeadersQuestion = () => {

        const headers = ["ORDER", "QUESTION", "TYPE", "", ""];
        return headers.map((h, i) => <th key={i}>{h}</th>);
    };

    const showQuestionType = (typeValue) => {
        switch (typeValue) {
            case 1:
                return "FreeText"
            case "1":
                return "FreeText"
            case 2:
                return "MultipleChoice"
            case "2":
                return "MultipleChoice"
            case 3:
                return "Logical"
            case "3":
                return "Logical"
            case 4:
                return "Conditional"
            case "4":
                return "Conditional"
            default:
                break;
        }
    }

    const showQuestionsSelect = (isSub) => {
        return questionsSelect.map((item, i) => (
            <tr key={i}>
                <td>{item.Order}</td>
                <td>{item.Text}</td>
                <td>{showQuestionType(item.QuestionType)}</td>
                <td>
                    {!isSub ?
                        <IoTrashA style={{ color: "red", fontSize: "1.5em", cursor: 'pointer' }} onClick={() => deleteQuestion(item.Order)}></IoTrashA>
                        : null}
                </td>
                <td>
                    {!isSub ?
                        <PureModal
                            renderTrigger={({ openModal }) => (
                                <IoEdit style={{ color: "#28a745", fontSize: "1.5em", cursor: 'pointer' }} onClick={openModal} />

                            )}
                            renderContent={({ closeModal }) => (
                                item.QuestionType == '2' ?
                                    <ModalOptionQuestion
                                        handleCancel={closeModal}
                                        questionsOptions={questionsOptions}
                                        showTaskActions={showTaskActions}
                                        saveQuestionOptions={addQ}
                                        taskActions={taskActions}
                                        toEditQuestion={true}
                                        questionData={item}
                                    />
                                    : item.QuestionType == '4' ?
                                        <ModalConditionalQuestion
                                            handleCancel={closeModal}
                                            comparisons={comparisons}
                                            binaryOperatorType={binaryOperatorType}
                                            saveConditions={addQ}
                                            showTaskActions={showTaskActions}
                                            taskActions={taskActions}
                                            toEditQuestion={true}
                                            questionData={item}
                                        />
                                        : item.QuestionType == '1' ?
                                            <ModalFreeTextQuestion
                                                handleCancel={closeModal}
                                                comparisons={comparisons}
                                                binaryOperatorType={binaryOperatorType}
                                                saveConditions={addQ}
                                                showTaskActions={showTaskActions}
                                                taskActions={taskActions}
                                                toEditQuestion={true}
                                                questionData={item}
                                            />
                                            : item.QuestionType == '3' ?
                                                <ModalLogicalQuestion
                                                    handleCancel={closeModal}
                                                    comparisons={comparisons}
                                                    binaryOperatorType={binaryOperatorType}
                                                    saveConditions={addQ}
                                                    showTaskActions={showTaskActions}
                                                    taskActions={taskActions}
                                                    toEditQuestion={true}
                                                    questionData={item}
                                                />
                                                : null
                            )}
                        >
                        </PureModal>
                        : null
                    }
                </td>
            </tr>
        ));
    }

    const deleteQuestion = (id) => {

        const findDelete = questionsSelect.filter(x => x.Order !== id)

        const optionsDelete = findDelete.map((item, i) => ({
            Text: item.Text,
            QuestionId: item.QuestionId,
            Order: i + 1,
            InsuranceId: item.InsuranceId,
            Conditions: item.Conditions,
            QuestionType: item.QuestionType,
            SpanishText: item.SpanishText,
            Options: item.Options,
            IsRequired: item.IsRequired,
            HasNAOption: item.HasNAOption
        }));

        if (optionsDelete.length)
            setCount(optionsDelete[optionsDelete.length - 1].Order);
        else
            setCount(0);

        setQuestionsSelect(optionsDelete);
    }

    const handleChangeQ = (e) => {

        const option = e.target.value;
        const name = e.target.name;
        setNewQuestion({ ...newQuestion, [name]: option });

        setError(false);
    }

    const handleChangeQuestionRequiredNA = (e) => {

        const name = e.target.name;
        if (name == "IsRequired") {
            setNewQuestion({ ...newQuestion, ["IsRequired"]: !newQuestion.IsRequired });
        } if (name == "HasNAOption") {
            setNewQuestion({ ...newQuestion, ["HasNAOption"]: !newQuestion.HasNAOption });
        }

        setError(false);
    }

    const addNewQuestion = (options, conditions) => {

        if (validateQuestionTable(newQuestion.Text)) {

            let arrayCondition = [];

            if (newQuestion.QuestionType === '3')

                arrayCondition.push(condition);
            else
                arrayCondition = conditions

            saveQuestions(options, arrayCondition);
        }
        else
            setError(true);

        setNewQuestion({ ...newQuestion, Text: '', SpanishText: '', QuestionType: '0', IsRequired: true, HasNAOption: false });
        setErrorQuestionReq(false);
    }

    const setConditionsArray = (conditions) => {

        let arrayCondition = [];

        if (newQuestion.QuestionType === '3')

            arrayCondition.push(condition);
        else
            arrayCondition = conditions

        return arrayCondition;
    }

    const validateQuestionTable = (value) => {

        let findQ = null;

        findQ = questionsSelect.find(x => x.Text === value);

        return !findQ;
    }

    const validateModal = () => {

        if (alreadyExists)
            return validateQuestionTable(question.name);
        else
            return validateQuestionTable(newQuestion.Text)
    }

    const saveQuestions = (options, conditions) => {

        const questionObj = {
            QuestionId: null,
            Text: newQuestion.Text,
            SpanishText: newQuestion.SpanishText,
            Order: count + 1,
            AlreadyExists: alreadyExists,
            QuestionType: newQuestion.QuestionType,
            Conditions: conditions,
            Options: options,
            IsRequired: newQuestion.IsRequired,
            HasNAOption: newQuestion.HasNAOption
        }
        setQuestionsSelect([...questionsSelect, questionObj]);
        setCount(questionObj.Order);
    }

    const handleChangeTask = (e) => {

        setCondition({ ...condition, [e.target.name]: e.target.value });
    }

    const handleChangeLogica = (e) => {

        setCondition({ ...condition, Value: e.target.value === 'true' ? 1 : 0, Order: 1 });
    }

    const addQ = (options, conditions, questionToEditData, isEdition, newQuestionText, newRequiredAndNAStatus) => {

        if (isEdition) {
            editQuestions(options, conditions, questionToEditData, newQuestionText, newRequiredAndNAStatus)
        } else {
            if (alreadyExists) {
                addQuestions(options, conditions);
            } else {
                addNewQuestion(options, conditions);
            }
        }
    }


    const handleChangeExistsQuestion = () => {

        setAlreadyExists(!alreadyExists);

        if (alreadyExists)
            setNewQuestion({ ...newQuestion, Text: '', SpanishText: '', QuestionType: '0', IsRequired: true, HasNAOption: false });
        else {
            setNewQuestion({ ...newQuestion, QuestionType: '0' });
            setQuestion({ ...question, id: '0' })
        }
    }

    const handleChangeUsesCustomContactLogic = () => {
        setUsesCustomContactLogic(!usesCustomContactLogic);
        if (!usesCustomContactLogic)
            setCustomContactLogic(contactLogicDefaultValue);
    }

    const addCycles = (cycle) => {
        const patientTypeId = parseInt(selectedPatientType, 10);

        cycle.PatientTypeId = patientTypeId;

        const contactLogic = [...customContactLogic];
        contactLogic[0].cycles = [...contactLogic[0].cycles, cycle]
        setCustomContactLogic(contactLogic);
    }

    const updateCycle = (cycle) => {
        const allCycles = clone(customContactLogic);
        const modifiedCycle = allCycles[0].cycles.findIndex(x => x.actionId === selectedFormOfContactOption && x.cycleCount == cycle.cycleCount);
        allCycles[0].cycles[modifiedCycle] = cycle;
        setCustomContactLogic(allCycles);
    }

    const handlePatientTypeChange = (e) => {
        if (e.target.value === "") {
            setAvailableDays(0);
            setSelectedPatientType("0");
        }
        else {
            setSelectedPatientType(e.target.value);
        }
        setPatientTypeSelected(true);
    }

    const handleOrderNumberChange = (e) => {
        setOrderNumber(e.target.value);
    }

    const removeCycle = (cycle) => {
        const allCycles = clone(customContactLogic);
        if (formId) {
            allCycles[0].cycles = allCycles[0].cycles.filter(x => x.id !== cycle.id);
        } else {
            allCycles[0].cycles = allCycles[0].cycles.filter(x => x.actionId === selectedFormOfContactOption && x.cycleCount != cycle.cycleCount);
        }
        setCustomContactLogic(allCycles);
    }

    const onYes = (closeModal) => {
        closeModal();
        AssignablePatientsFormOpenModal();
    }

    const availableDaysChange = (e) => {
        setAvailableDays(e.target.value);
    }

    return (
        <div>
            {fetchingFormInfo && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Spinner />
                    Loading Data....
                </div>)
            }
            <PageHeader title={formId ? "Edit Form" : "Create Form"}>
                {
                    formId && formInfo &&
                    <CopyForm
                        companyId={params.id}
                        formId={formId}
                        iconSize='3em'
                        name={formInfo.Name}
                        order={formInfo.Order}
                        patientType={formInfo.PatientType}
                        successAction={() => { document.getElementById("assignPatientsConfirmationModal").click(); }}
                        setFormToBeAssignedId={(formId) => setStateFormId(formId)}
                    />
                }
            </PageHeader>

            <Formik
                initialValues={{
                    Name: formInfo.Name,
                    Description: formInfo.Description,
                    PatientType: formInfo.PatientType,
                    AvailableDays: formInfo.AvailableDays,
                    IsActive: formInfo.IsActive,
                    CompanyId: params.id,
                    Order: formInfo.Order,
                    Questions: questionsSelect,
                    UsesCustomContactLogic: formInfo.UsesCustomContactLogic,
                    ContactLogics: formInfo.ContactLogic,
                }}
                enableReinitialize={formId ? true : false}
                onSubmit={(data) => { setSubmittedFormData(data); return validateBeforeSubmitting(data); }}
            >
                {({
                    handleChange,
                    isSubmitting
                }) => (

                    <Form>
                        <fieldset>
                            <legend>Complete Form</legend>

                            <div className="dd_col-6">
                                <FormikField
                                    name="Name"
                                    label="Name"
                                    type="text"
                                    validate={validateEmpty}
                                    disabled={isSubmitting || fetchingFormInfo || isFetchingEditForm || isFetchingCreateForm}
                                    onChange={e => { handleChange(e) }}
                                />
                            </div>

                            <div className="dd_col-6">

                                <FormikField
                                    name="Description"
                                    label="Description"
                                    type="text"
                                    validate={validateEmpty}
                                    disabled={isSubmitting || fetchingFormInfo || isFetchingEditForm || isFetchingCreateForm}
                                    onChange={e => { handleChange(e) }}
                                />
                            </div>

                            <div className="dd_col-4">
                                <FormikField
                                    name="PatientType"
                                    component="select"
                                    label="Patient Type"
                                    validate={validateNull}
                                    disabled={isSubmitting || fetchingFormInfo || isFetchingAvailableDays || isFetchingEditForm || isFetchingCreateForm}
                                    onChange={e => { handleChange(e); handlePatientTypeChange(e); }}
                                >
                                    <option value={null}></option>
                                    {showPatientType()}

                                </FormikField>
                            </div>

                            <div className="dd_col-4">
                                <FormikField name="Order"
                                    label="Order"
                                    type="number"
                                    validate={validateEmpty}
                                    disabled={isSubmitting || fetchingFormInfo || isFetchingEditForm || isFetchingCreateForm}
                                    onChange={e => { handleChange(e); handleOrderNumberChange(e) }}
                                />
                            </div>

                            <div className="dd_col-4">
                                <FormikField name="AvailableDays"
                                    label="Available Days"
                                    type="number"
                                    validate={validateEmptyAvailableDays}
                                    value={availableDays}
                                    onChange={e => { availableDaysChange(e); handleChange(e) }}
                                />
                            </div>

                            <div className="dd_col-6">

                                <FormikField
                                    name="IsActive"
                                    label="Active"
                                    type="checkbox"
                                    disabled={isSubmitting || fetchingFormInfo || isFetchingEditForm || isFetchingCreateForm}
                                    onChange={e => { handleChange(e) }}
                                />
                            </div>

                            {/*Custom contact logic is hidden for new forms, see story BON#971, ticket BON#1111, and ticket CP-273*/}
                            <div className="dd_col-3" disabled={isSubmitting || fetchingFormInfo || isFetchingEditForm || isFetchingCreateForm} style={{ display: "none" }} >
                                <span className="form-field">
                                    <label htmlFor="UsesCustomContactLogic">Uses Custom Contact Logic</label>
                                    <input name="UsesCustomContactLogic" type="checkbox" id="UsesCustomContactLogic" className="checkbox" value="null" onChange={handleChangeUsesCustomContactLogic} checked={usesCustomContactLogic} />
                                </span>
                            </div>
                        </fieldset>

                        <fieldset hidden={!usesCustomContactLogic} style={!formId ? { display: "none" } : undefined}>
                            <legend>Set Contact Logic</legend>
                            <div className="contact-logic-table-manager">
                                <div className="contact-logic-container">
                                    <ContactLogicTable
                                        key={0}
                                        companyOptions={[]}
                                        onCompanySelect={() => { }}
                                        formOfContactOptions={formOfContactOptions}
                                        selectedFormOfContact={selectedFormOfContactOption || ""}
                                        onFormOfContactSelect={(value) => setSelectedFormOfContactOption(value)}
                                        contactLogicArray={filteredContactLogic}
                                        index={0}
                                        onCycleChanged={(_, cycle) => { updateCycle(cycle) }}
                                        onClose={() => { }}
                                        onSave={() => { }}
                                        onFormCreation={true}
                                        onCycleAdded={(_, cycle) => { addCycles(cycle) }}
                                        onCycleDelete={(_, cycle) => { removeCycle(cycle) }}
                                    />
                                </div>
                            </div>
                        </fieldset>


                        <fieldset>
                            <legend>Complete Questions</legend>

                            <input type="checkbox" disabled={isSubmitting || fetchingFormInfo || isFetchingEditForm || isFetchingCreateForm} name="AlreadyExists" value="null" onChange={handleChangeExistsQuestion} checked={alreadyExists} />
                            <label>Already Exists</label><br></br>

                            <div>
                                <div hidden={!alreadyExists} className="dd_col-6">
                                    <label>Select Question</label>
                                    <select name="idQuestion" disabled={isSubmitting || isFetchingEditForm || isFetchingCreateForm} value={question.id} onChange={handleChangeQuestion}>
                                        <option value='0'></option>
                                        {showQuestions()}
                                    </select>
                                </div>
                            </div>


                            <div hidden={alreadyExists} >
                                <div className="dd_col-6">

                                    <label>Text</label>
                                    <input
                                        name="Text"
                                        type="text"
                                        disabled={isSubmitting || fetchingFormInfo || isFetchingEditForm || isFetchingCreateForm}
                                        value={newQuestion.Text}
                                        onChange={handleChangeQ}
                                    />

                                </div>

                                <div className="dd_col-6">
                                    <label>Spanish Text</label>
                                    <input
                                        name="SpanishText"
                                        type="text"
                                        disabled={isSubmitting || fetchingFormInfo || isFetchingEditForm || isFetchingCreateForm}
                                        value={newQuestion.SpanishText}
                                        onChange={handleChangeQ}
                                    />
                                </div>
                            </div>

                            <div className={alreadyExists && newQuestion.QuestionType !== '2' && newQuestion.QuestionType !== '4' && newQuestion.QuestionType !== '1' ? "dd_col-4" : "dd_col-3"}>
                                <label>Select Question Type</label>
                                <select name="QuestionType" disabled={alreadyExists || isSubmitting || isFetchingEditForm || isFetchingCreateForm || fetchingFormInfo} value={newQuestion.QuestionType} onChange={handleChangeQ}>
                                    <option value='0'></option>
                                    {showQuestionsTypes()}
                                </select>
                            </div>


                            {newQuestion.QuestionType === '3' ?

                                <>
                                    <div>
                                        <br></br>
                                        <div className="dd_col-2">
                                            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                            <input type="radio" disabled={isSubmitting || isFetchingEditForm || isFetchingCreateForm} name="Yes" value={true} onChange={handleChangeLogica} checked={condition.Value === 1} />
                                            <label>Yes</label>
                                            <input type="radio" disabled={isSubmitting || isFetchingEditForm || isFetchingCreateForm} name="No" value={false} onChange={handleChangeLogica} checked={condition.Value === 0} />
                                            <label>No</label>
                                        </div>
                                        <div className="dd_col-3">

                                            <label>Task Action</label>
                                            <select name="TaskActionId" disabled={isSubmitting || isFetchingEditForm || isFetchingCreateForm} value={condition.TaskActionId} onChange={handleChangeTask}>
                                                <option value='0'></option>
                                                {showTaskActions()}
                                            </select>
                                        </div>
                                    </div>
                                </>

                                : null
                            }

                            <div className="dd_col-3">
                                <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                {(newQuestion.QuestionType === '1' || newQuestion.QuestionType === '3') && !isSubmitting ?
                                    <IoPlus hidden={(newQuestion.Text === '' || newQuestion.SpanishText === '') && !alreadyExists} style={{ color: "#007bff", fontSize: "2em", }} onClick={() => alreadyExists ? addQuestions([], []) : addNewQuestion([], [])} > </IoPlus>
                                    : null}

                                {(newQuestion.QuestionType === '2' || newQuestion.QuestionType === '4') && !isSubmitting ?
                                    <PureModal
                                        renderTrigger={({ openModal }) => (

                                            <IoPlus hidden={(newQuestion.Text === '' || newQuestion.SpanishText === '') && !alreadyExists} style={{ color: "#007bff", fontSize: "2em", }} onClick={validateModal() ? openModal : setError(true)} > </IoPlus>
                                        )}
                                        renderContent={({ closeModal }) => (
                                            newQuestion.QuestionType === '2' ?
                                                <ModalOptionQuestion
                                                    handleCancel={closeModal}
                                                    questionsOptions={questionsOptions}
                                                    showTaskActions={showTaskActions}
                                                    taskActions={taskActions}
                                                    saveQuestionOptions={addQ}
                                                    toEditQuestion={false}
                                                />
                                                :
                                                <ModalConditionalQuestion
                                                    handleCancel={closeModal}
                                                    comparisons={comparisons}
                                                    binaryOperatorType={binaryOperatorType}
                                                    saveConditions={addQ}
                                                    showTaskActions={showTaskActions}
                                                    taskActions={taskActions}
                                                    toEditQuestion={false}
                                                />
                                        )}
                                    >
                                    </PureModal> : null}
                            </div>

                            {
                                newQuestion.QuestionType !== '0' ?
                                    <div className="dd_col-12">
                                        <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                        <input type="checkbox" disabled={isSubmitting || isFetchingEditForm || isFetchingCreateForm} value={newQuestion.IsRequired} name="IsRequired" onChange={handleChangeQuestionRequiredNA} checked={newQuestion.IsRequired} />
                                        <label>Required</label>
                                        <input type="checkbox" disabled={isSubmitting || isFetchingEditForm || isFetchingCreateForm} value={newQuestion.HasNAOption} name="HasNAOption" onChange={handleChangeQuestionRequiredNA} checked={newQuestion.HasNAOption} />
                                        <label>Add N/A</label>
                                    </div>
                                    :
                                    null
                            }

                            <div className="dd_col-12">
                                <span style={{ color: 'red' }} hidden={!error}>The question is added</span>
                                <span style={{ color: 'red' }} hidden={!errorQuestionReq}>Add for at least one question</span>
                            </div>

                            <div className='dd_col-12'>
                                <fieldset>
                                    <legend>Aggregate questions table</legend>
                                    {showTableQuestions(isSubmitting)}
                                </fieldset>
                            </div>

                        </fieldset>

                        <PureModal
                            renderTrigger={({ openModal }) => <div onClick={() => openModal()} id="reschedulingWarning"></div>}
                            renderContent={({ closeModal }) => <ConfirmationModal title="Are you sure you want to change the available days of this form? Patients assigned forms will be re-scheduled." closeModal={closeModal} yesAction={() => editForm()} notNowAction={() => { }} />}
                        >
                        </PureModal>
                        <PureModal
                            renderTrigger={({ openModal }) => <div onClick={() => openModal()} id="assignPatientsConfirmationModal"></div>}
                            renderContent={({ closeModal }) => <ModalAssignFormToPatients formId={formId} closeModal={() => closeModal()} onYes={() => onYes(closeModal)} onNo={() => goToFormsList()} />}
                        >
                        </PureModal>

                        <AssignablePatientsFormModal formId={stateFormId} additionalFunc={goToFormsList}></AssignablePatientsFormModal>

                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <button
                                type="button"
                                style={{ marginRight: 30 }}
                                className="button warning"
                                disabled={isSubmitting || isFetchingEditForm || isFetchingCreateForm}
                                onClick={e => {
                                    e.preventDefault();
                                    browserHistory.goBack();
                                }}
                            >
                                Cancel
                            </button>

                            <button
                                type="submit"
                                className="button"
                                disabled={isSubmitting || fetchingFormInfo || isFetchingAvailableDays || isFetchingEditForm || isFetchingCreateForm}
                            >
                                {(isSubmitting || isFetchingEditForm || isFetchingCreateForm) ? <Spinner /> : "Save"}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default connect(
    state => ({
        formOfContactOptions: selectors.getFormOfContactOptions(state)
    }),
    null
)(CreateEditForm);