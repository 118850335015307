import { propOr, pathOr, nth } from "ramda";
import { formatDate } from "utils/misc";

const getDefaultValues = patient => {
  return {
    first_name: propOr("", "first_name", patient),
    last_name: propOr("", "last_name", patient),
    account_number: propOr("", "account_number", patient),
    email: propOr("", "email", patient),
    date_of_birth: propOr("", "date_of_birth", patient),
    setup_date: propOr("", "setup_date", patient),
    gender: propOr("", "gender", patient),
    phone_number: propOr("", "phone_number", patient),
    mobile_number: propOr("", "mobile_number", patient),
    form_of_contact: propOr("", "form_of_contact", patient),
    can_email: propOr(true, "can_email", patient),
    speaks_spanish: propOr(false, "speaks_spanish", patient),
    active: propOr(true, "active", patient),
    on_hold: propOr(false, "on_hold", patient),
    primary_insurance: {
      group_number: patient?.primary_insurance?.group_number ?? "",
      primary_order: patient?.primary_insurance?.primary_order ?? 1,
      policy_number: patient?.primary_insurance?.policy_number ?? "",
      insurance_company_id:
        patient?.primary_insurance?.insurance_company_id ?? "",
      insurance_company_name:
        patient?.primary_insurance?.insurance_company_name ?? "",
      insurance_type_id: patient?.primary_insurance?.insurance_type_id ?? "",
      insurance_type_name:
        patient?.primary_insurance?.insurance_type_name ?? "",
      guarantor_relation: patient?.primary_insurance?.guarantor_relation ?? "",
      policy_start_date: formatDate(
        patient?.primary_insurance?.policy_start_date,
        "YYYY-MM-DD"
      ),
      policy_end_date: formatDate(
        patient?.primary_insurance?.policy_end_date,
        "YYYY-MM-DD"
      )
    },
    secondary_insurance: {
      group_number: patient?.secondary_insurance?.group_number ?? "",
      primary_order: patient?.secondary_insurance?.primary_order ?? 2,
      policy_number: patient?.secondary_insurance?.policy_number ?? "",
      insurance_company_id:
        patient?.secondary_insurance?.insurance_company_id ?? "",
      insurance_company_name:
        patient?.secondary_insurance?.insurance_company_name ?? "",
      insurance_type_id: patient?.secondary_insurance?.insurance_type_id ?? "",
      insurance_type_name:
        patient?.secondary_insurance?.insurance_type_name ?? "",
      guarantor_relation:
        patient?.secondary_insurance?.guarantor_relation ?? "",
      policy_start_date: formatDate(
        patient?.secondary_insurance?.policy_start_date,
        "YYYY-MM-DD"
      ),
      policy_end_date: formatDate(
        patient?.secondary_insurance?.policy_end_date,
        "YYYY-MM-DD"
      )
    },
    collections_history: propOr(false, "collections_history", patient),
    new_setup: propOr(true, "new_setup", patient),
    // insurance_company_id: propOr("", "insurance_company_id", patient),
    address: {
      street_address: pathOr("", ["address", "street_address"], patient),
      street_address2: pathOr("", ["address", "street_address2"], patient),
      city: pathOr("", ["address", "city"], patient),
      state: pathOr("", ["address", "state"], patient),
      zip: pathOr("", ["address", "zip"], patient)
    },
    password: { password: "", password_confirmation: "" },
    company_id: propOr("", "company_id", patient),
    branch_office_id: propOr("", "branch_office_id", patient),
    therapist_id: propOr("", "therapist_id", patient),
    sleep_coach_id: propOr("", "sleep_coach_id", patient),
    physician: {
      npi: pathOr("", ["physician", "npi"], patient),
      name: pathOr("", ["physician", "name"], patient),
      id: "",
      address: {
        street_address: pathOr(
          "",
          ["physician", "address", "street_address"],
          patient
        ),
        city: pathOr("", ["physician", "address", "city"], patient),
        state: pathOr("", ["physician", "address", "state"], patient),
        zip: pathOr("", ["physician", "address", "zip"], patient)
      },
      phone: pathOr("", ["physician", "phone"], patient),
      fax: pathOr("", ["physician", "fax"], patient)
    },
    inactiveReasonId: nth(0, patient.deactivationReasons ?? [])?.value ?? "",
    PatientTypeData:{
        resupply_patient: patient?.patientTypeData?.resupply_patient ?? false,
        compliance_patient: patient?.patientTypeData?.compliance_patient ?? false,
        remote_patient: patient?.patientTypeData?.remote_patient ?? false,
        compliance_status: patient?.patientTypeData?.compliance_status ? parseInt(patient?.patientTypeData?.compliance_status) : 1,
        remote_status: patient?.patientTypeData?.remote_status ? parseInt(patient?.patientTypeData?.remote_status) : 1,
        resupply_status: patient?.patientTypeData?.resupply_status ? parseInt(patient?.patientTypeData?.resupply_status) : 1,
        compliance_inactivation_reason: patient?.patientTypeData?.compliance_inactivation_reason
    },
    compliance_coach_id: propOr("", "compliance_coach_id", patient)
  };
};

export default getDefaultValues;
