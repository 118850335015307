import React from "react";
import PropTypes from "prop-types";
import { InlineWaiting, PageHeader } from "components/ui/";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import { useFetch } from "hooks";
import { get, put } from "utils/api";

const CompanyAppPermissions = ({ companyId }) => {
  const {
    response: appPermissions,
    isFetching,
    fetchData
  } = useFetch({
    apiFn: companyId => get(`companies/${companyId}/app_permissions`),
    defaultValue: {},
    clearDataOnFetch: false
  });

  const { fetchData: updateAppPermissions } = useFetch({
    apiFn: permissions =>
      put(`companies/${companyId}/app_permissions`, permissions),
    defaultValue: {}
  });
  React.useEffect(() => {
    fetchData(companyId);
  }, []);
  return (
    <div
      style={{ padding: 10, display: "flex", flexDirection: "column", flex: 1 }}
    >
      <PageHeader title="App Permissions">
        {isFetching && <InlineWaiting />}
      </PageHeader>
      <Formik
        initialValues={appPermissions}
        onSubmit={async values => {
          await updateAppPermissions(values);
          await fetchData(companyId);
        }}
        enableReinitialize={true}
      >
        {({ values, handleReset, isSubmitting, dirty }) => (
          <Form style={{ padding: 10 }}>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  justifyContent: "center"
                }}
              >
                <FormikField
                  name="orderSupplies"
                  checked={!!values.orderSupplies}
                  label={
                    <CheckBoxLabel
                      label="Order Supplies"
                      subLabel="Allow users to create orders, display order insights, allow outreach emails/txts"
                    />
                  }
                  type="checkbox"
                  style={{ display: "flex" }}
                />
                <FormikField
                  name="myOrders"
                  checked={!!values.myOrders}
                  type="checkbox"
                  label={
                    <CheckBoxLabel
                      label="View Orders"
                      subLabel="Mobile app view order history"
                    />
                  }
                  style={{ display: "flex" }}
                />
                <FormikField
                  name="myProducts"
                  checked={!!values.myProducts}
                  type="checkbox"
                  label={
                    <CheckBoxLabel
                      label="My Products"
                      subLabel="Mobile app view patients current equipment"
                    />
                  }
                  style={{ display: "flex" }}
                />
                <FormikField
                  name="documents"
                  checked={!!values.documents}
                  type="checkbox"
                  label={
                    <CheckBoxLabel
                      label="Documents"
                      subLabel="View and upload patient documents"
                    />
                  }
                  style={{ display: "flex" }}
                />
                <FormikField
                  name="signatures"
                  checked={!!values.signatures}
                  type="checkbox"
                  label={
                    <CheckBoxLabel
                      label="Sign Documents"
                      subLabel="View signature history and sign documents"
                    />
                  }
                  style={{ display: "flex" }}
                />
                <FormikField
                  name="chatNow"
                  checked={!!values.chatNow}
                  type="checkbox"
                  label={
                    <CheckBoxLabel
                      label="Chat Now"
                      subLabel="Mobile App - request immediate text chat with coach"
                    />
                  }
                  style={{ display: "flex" }}
                />
                <FormikField
                  name="scheduleChat"
                  checked={!!values.scheduleChat}
                  type="checkbox"
                  label={
                    <CheckBoxLabel
                      label="Schedule Chat"
                      subLabel="Schedule text/video/product demo chat with dme"
                    />
                  }
                  style={{ display: "flex" }}
                />
                <FormikField
                  name="questionaires"
                  checked={!!values.questionaires}
                  label={
                    <CheckBoxLabel
                      label="Questionnaires"
                      subLabel="View and complete assigned questionnaires"
                    />
                  }
                  type="checkbox"
                  style={{ display: "flex" }}
                />
                <FormikField
                  name="maskFitting"
                  checked={!!values.maskFitting}
                  label={
                    <CheckBoxLabel
                      label="Mask Fittings"
                      subLabel="Request and manage sleepglad patient mask fitting scans"
                    />
                  }
                  type="checkbox"
                  style={{ display: "flex" }}
                />
                <FormikField
                  name="patientTasks"
                  checked={!!values.patientTasks}
                  label={
                    <CheckBoxLabel
                      label="Patient Tasks"
                      subLabel="Ability to assign/create and view patient Tasks"
                    />
                  }
                  type="checkbox"
                  style={{ display: "flex" }}
                />
                <FormikField
                  name="new_hcpc_eligibility"
                  checked={!!values.new_hcpc_eligibility}
                  label={
                    <CheckBoxLabel
                      label="New HCPC Eligibility"
                      subLabel="Ability to view patient HCPC order history tab and eligiblity"
                    />
                  }
                  type="checkbox"
                  style={{ display: "flex" }}
                />
                <FormikField
                  name="dmeAccessRecordings"
                  checked={!!values.dmeAccessRecordings}
                  label={
                    <CheckBoxLabel
                      label="Allow Recording Access"
                      subLabel="Ability for company admins to view patient call recordings"
                    />
                  }
                  type="checkbox"
                  style={{ display: "flex" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1
                }}
              >
                <h4>Integrations</h4>
                <FormikField
                  name="ra_cmn_integration"
                  checked={!!values.ra_cmn_integration}
                  label={
                    <CheckBoxLabel
                      label="Cmn Workflow - Referral Answer"
                      subLabel="Access to RA Cmns"
                    />
                  }
                  type="checkbox"
                  style={{ display: "flex" }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                margin: 5,
                flex: 1,
                justifyContent: "space-between"
              }}
            >
              <button
                type="button"
                className="button warning"
                disabled={!dirty}
                onClick={handleReset}
              >
                Reset
              </button>
              <button type="submit" disabled={!dirty || isSubmitting}>
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

CompanyAppPermissions.propTypes = { companyId: PropTypes.string.isRequired };

const CheckBoxLabel = ({ label, subLabel }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginRight: 20,
      marginBottom: "1.5em"
    }}
  >
    <span style={{ fontSize: 18 }}>{label}</span>
    <sub style={{ fontSize: 14, marginBottom: 5 }}>{subLabel}</sub>
  </div>
);

CheckBoxLabel.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string
};

export default CompanyAppPermissions;
