import React, { useState, useEffect } from "react";
import { useFetch } from "hooks";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { selectors } from "reducers";
import moment from "moment";
import SectionHeader from "./section_header";
import { Ul, AttributeItem } from "components/lists";
import { updatePatient } from "actions/patient";
import DayPicker from "react-day-picker";
import CalenderControls from "components/ui/date-picker/calender-controls";
import HasRolePermission from "components/shared/has-role-permission";
import { PureModal } from "components/ui";
import ModalAskForFormsRescheduling from "./modal-ask-for-form-reschedule";
import { get } from "utils/api";
import { path } from "ramda";
import { errorMessage } from "actions/message";

const ScheduledDates = ({
  patientInfo,
  updatePatient,
  patientId,
  readOnly
}) => {
  const [calenderIsOpen, setCalenderIsOpen] = React.useState();
  const [data, setData] = useState(null);
  const [outreachDate, setOutreachDate] = useState(null);
  const dispatch = useDispatch();

  const { fetchData: fetchFormsDateValidation } = useFetch({
    apiFn: date => get(`patients/${patientId}/validate-forms-dates/${date}`),
    defaultValue: {},
    transformError: path(["response", "body", "status"])
  });

  const formsDateValidation = async date => {
    const response = await fetchFormsDateValidation(date);
    if (response) setData(response);
  };

  const askBeforeSubmitting = async date => {
    await formsDateValidation(date);
  };

  useEffect(() => {
    if (data) {
      if (data.success && data.FormId != 0)
        document.getElementById("chartItemClicked").click();
      else if (data.success && data.FormId == 0) updatePatientAndForm(false);
      else if (!data.success) dispatch(errorMessage(data.message));
    }
  }, [data]);

  const updatePatientAndForm = date => {
    updatePatient({
      patientId,
      updates: {
        outreach_date: date,
        update_forms: false
      }
    });
  };

  return (
    <div>
      <div className="patient-schedule-dates-container">
        <SectionHeader>Scheduled Dates</SectionHeader>
        <Ul>
          <AttributeItem title="Outreach Date">
            {moment(patientInfo.outreach_date).format("MM/DD/YYYY")}
          </AttributeItem>
          <AttributeItem title="Outreach Cycle">
            {patientInfo.outreach_cycle_count}
          </AttributeItem>
          <AttributeItem title="Override Outreach Date:">
            <div className="overide-date-checkbox-container">
              <input
                type="checkbox"
                checked={patientInfo.overrideEligibilityDate || false}
                disabled={readOnly}
                onChange={({ target: { checked } }) => {
                  updatePatient({
                    patientId,
                    updates: {
                      overrideEligibilityDate: checked
                    }
                  });
                }}
              />
            </div>
          </AttributeItem>

          {!patientInfo.overrideEligibilityDate && (
            <React.Fragment>
              <div className="patient-outreach-date-controls">
                <HasRolePermission
                  allowedRoles={[
                    "Administrator",
                    "SleepCoach",
                    "ServiceCoach",
                    "ServiceAdmin",
                    "ContractorAdministrator"
                  ]}
                >
                  <button
                    className="button secondary icon-button"
                    disabled={readOnly}
                    onClick={() => {
                      setOutreachDate(null);
                      updatePatientAndForm(null);
                      // askBeforeSubmitting();
                    }}
                  >
                    Push Outreach
                  </button>
                </HasRolePermission>
                <button
                  className="button secondary icon-button"
                  disabled={readOnly}
                  onClick={() => setCalenderIsOpen(true)}
                >
                  Push Outreach Manual
                </button>
              </div>
              <div className="date-picker container">
                {calenderIsOpen && (
                  <div id={"datePicker"} className="entryContainer">
                    <div
                      className="outOfBounds"
                      onClick={() => setCalenderIsOpen(false)}
                    />
                    <div className="pickerContainer">
                      <DayPicker
                        onDayClick={day => {
                          if (!day) return;
                          const date = moment(day).format("yyyy-MM-DD");
                          // askBeforeSubmitting(date);
                          setOutreachDate(date);
                          setCalenderIsOpen(false);
                          updatePatientAndForm(date);
                        }}
                        containerProps={{ style: { padding: 0 } }}
                        selectedDay={patientInfo.outreach_date}
                      />
                      <CalenderControls
                        handleDayChange={day => {
                          if (!day) return;
                          const date = moment(day).format("yyyy-MM-DD");
                          // askBeforeSubmitting(date);
                          setOutreachDate(date);
                          setCalenderIsOpen(false);
                          updatePatientAndForm(date);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </Ul>
      </div>

      <PureModal
        renderTrigger={({ openModal }) => (
          <div id="chartItemClicked" onClick={openModal}></div>
        )}
        renderContent={({ closeModal }) => {
          return (
            <ModalAskForFormsRescheduling
              style={{ backgroundColor: "red" }}
              patientId={patientId}
              data={data}
              closeModal={closeModal}
              updatePatient={updatePatientAndForm}
            />
          );
        }}
      ></PureModal>
    </div>
  );
};

ScheduledDates.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientInfo: PropTypes.object.isRequired,
  updatePatient: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired
};

ScheduledDates.defaultProps = {
  readOnly: false
};

export default connect(
  (state, { patientId }) => ({
    patientInfo: selectors.getPatientInfo(state, patientId)
  }),
  { updatePatient }
)(ScheduledDates);
