const simpleOptions = strArr => strArr.map(text => ({ text, value: text }));

export const papIssuesOptions = [
  {
    value: "",
    text: "none"
  },
  {
    value: "humidification",
    text: "humidification"
  },
  {
    value: "machine",
    text: "machine"
  },
  {
    value: "mask_loose",
    text: "other"
  }
];

export const sawPhysicianOptions = simpleOptions([
  "",
  "0-3 months",
  "3-6 months",
  "6-12 months",
  "12 or more months"
]);

export const hoursPerNightOptions = simpleOptions([
  "",
  "less than 4 hours",
  "4-6 hours",
  "6+ hours"
]);

export const nightsPerWeekOptions = simpleOptions([
  "",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7"
]);

export const orderedByOptions = simpleOptions(["patient", "caregiver"]);

export const quantityOnHandOptions = simpleOptions([
  "None",
  "Only the singular items currently being used and needing replacement. No additional replacements on hand.",
  "I have additional stock on hand."
]);

export const orderEquipmentQuestions = [
  {
    equipment: "mask",
    key: "mask_dirty",
    text: "My Mask is dirty with risk of infection."
  },
  {
    equipment: "mask",
    key: "mask_broken",
    text: "My Mask is in poor and/or non-working condition."
  },
  {
    equipment: "mask",
    key: "mask_loose",
    text: "My Mask will not adequately seal due to wear."
  },
  {
    equipment: "headgear",
    key: "headgear_loose",
    text: "My Headgear is stretched limiting pressure support."
  },
  {
    equipment: "headgear",
    key: "headgear_broken",
    text: "My Headgear is in poor and/or non-working condition."
  },
  {
    equipment: "seals",
    key: "seal_loose",
    text: "My Seal/Cushion/Pillow will not adequately seal due to wear."
  },
  {
    equipment: "seals",
    key: "seal_broken",
    text: "My Seal/Cushion/Pillow is in poor and/or non-working condition."
  },
  {
    equipment: "filter",
    key: "filter_dirty",
    text: "My Filter is dirty with risk of infection."
  },
  {
    equipment: "tubing",
    key: "tubing_dirty",
    text: "My Tubing is dirty with risk of infection."
  },
  {
    equipment: "tubing",
    key: "tubing_broken",
    text: "My Tubing is in poor and/or non-working condition."
  },
  {
    equipment: "water chamber",
    key: "waterchamber_dirty",
    text: "My Waterchamber is dirty with risk of infection."
  },
  {
    equipment: "water chamber",
    key: "waterchamber_broken",
    text: "My Waterchamber is in poor and/or non-working condition."
  },
  {
    equipment: "chinstrap",
    key: "chinstrap_broken",
    text: "My Chinstrap Is in poor and/or non-working condition."
  },
  {
    equipment: "chinstrap",
    key: "chinstrap_loose",
    text: "My Chinstrap is stretched, limiting pressure support."
  }
];

export const getEquipmentQuestions = equipment =>
  orderEquipmentQuestions.filter(question => question.equipment === equipment);

export const supplyOptions = [
  {
    value: "mask",
    text: "Mask",
    description:
      "Includes the frame as well as the seal/cushion/pillow of your mask and the headgear when eligible",
    replacementSchedule: "Once every 3 months"
  },
  {
    value: "headgear",
    text: "Headgear",
    description:
      "Neoprene Straps that go around your head and holds the mask in place",
    replacementSchedule: "Included with mask when eligible"
  },
  {
    value: "seals",
    text: "Seal / Cushion / Pillow",
    description:
      "Soft silicone replacement portion of your mask that touches your face.",
    replacementSchedule:
      "Full face: 3 per 3 months, Nasal/Pillow: 6 per 3 months."
  },
  {
    value: "tubing",
    text: "Tubing",
    description:
      "Corrugated tubing that runs from your mask to your pap machine.",
    replacementSchedule: "Once every 3 months."
  },
  {
    value: "disposable filter",
    text: "Disposable Filters",
    description: "Disposable Machine filter",
    replacementSchedule: "Disposable: 6 per 3 months "
  },
  {
    value: "nondisposable filter",
    text: "Non-Disposable Filters",
    description: "Non-Disposable Machine filter",
    replacementSchedule: "Non-Disposable: 1 every 6 months."
  },
  {
    value: "chinstrap",
    text: "Chinstrap",
    description:
      "Neoprene strap that sets under the chin to help hold your mouth closed",
    replacementSchedule: "Once every 6 months"
  },
  {
    value: "water chamber",
    text: "Water Chamber",
    description: "Reservoir that holds water for humidifier",
    replacementSchedule: "Once every 6 to 12 months"
  }
];

export const getEquipmentSupplyInfo = equipment => {
  const result = supplyOptions.find(({ value }) => value === equipment);
  return result;
};

export const noMaskReasonOptions = simpleOptions([
  "",
  "Not Due",
  "Does Not Want Without Headgear",
  "Due to OOP cost",
  "Patient stated mask is not ready to be replaced yet",
  "Backorder"
]);
