import React from "react";

const CalandarLegend = () => (
  <div className="calendar-legend">
    <span className="color-block video-chat" />
    <span className="legend-text">Video Chat</span>
    <span className="color-block text-chat" />
    <span className="legend-text">Text Chat</span>
    <span className="color-block product-demo" />
    <span className="legend-text">Product Demo</span>
  </div>
);

export default CalandarLegend;
