import React, { useEffect, useState } from "react";
import './styles.scss'
import { useFetch } from 'hooks';
import { path } from "ramda";
import { get } from '../../../../../utils/api';
import { getApiErrorMessage } from "utils/misc";
import { PureModal, Spinner } from "components/ui";
import ComplianceDashboardActionViewCard from "../compliance-action-view-tab/compliance-dashboard-action-view-card";
import PatientDataByCategory from 'components/screens/dashboard/compliance-dashboard/patient-data-by-category';
import DateRange from "components/ui/date-range";
import useDateRange from 'hooks/useDateRange';
import { useDispatch } from 'react-redux';
import * as msg from "actions/message";
import { useHistory } from "react-router-dom";

const ComplianceActionViewTab = ({ companyId }) => {

    const history = useHistory()
    const dispatch = useDispatch()

    const [complianceActionViewData, setComplianceActionViewData] = useState([])
    const [selectedCardData, setSelectedCardData] = useState({});

    const {
        startDate,
        endDate,
        setStartDate,
        setEndDate,
    } = useDateRange({
        startDate: null,
        endDate: null
    });

    const [chosenStartDate, setChosenStartDate] = useState(null)
    const [chosenEndDate, setChosenEndDate] = useState(null)

    const { fetchData: fetchComplianceActionViewData, isFetching: fetchingComplianceActionViewData } = useFetch({
        apiFn: (filter) => get(`compliance-dashboard/datapoints-summary`,filter),
        defaultValue: [],
        transformError: path(["response", "body", "status"]),
        onError: error => {
            dispatch(msg.errorMessage(`Failed to get compliance dashboard action view data: ${getApiErrorMessage(error)}`))
        }
    });

    useEffect(() => {
        getComplianceActionViewData(startDate, endDate);
    }, [])

    const getComplianceActionViewData = async (startDate, endDate) => {
        await setChosenStartDate(startDate)
        await setChosenEndDate(endDate)
        const complianceActionViewDataResponse = await fetchComplianceActionViewData({dateFrom: startDate ? startDate : null, dateTo: endDate ? endDate : null, companyId: companyId ? companyId : ""});
        if (complianceActionViewDataResponse){
            setComplianceActionViewData(complianceActionViewDataResponse);
        }
    }

    const onCardSelected = (categoryId, categoryDescription) => {
        document.getElementById("chartItemClicked").click();
        setSelectedCardData({id: categoryId, description: categoryDescription});
    };

    const currentUnalteredRoute = history.location.pathname

    return (
        <>
            {/*Date filter, commented to be hidden, according to S3 request*/}
            {/*<div style={{display: "flex", marginBottom: "25px", padding: "0 5px", width: "100%"}}>
                <DateRange
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    handleSubmit={getComplianceActionViewData}
                    isValid={!fetchingComplianceActionViewData}
                />
            </div>*/}
            <div style={{display: "flex", justifyContent: "center"}}>
                {
                    fetchingComplianceActionViewData && <div style={{gridArea: "1/3"}}><Spinner/></div>
                }
            </div>
            <div className="compliance-action-view-tab">
                {
                    complianceActionViewData && complianceActionViewData.length > 0 ?
                        complianceActionViewData.map((data, i) => (
                            <div key={i}>
                                <ComplianceDashboardActionViewCard
                                    categoryId={data.id}
                                    title={data.title}
                                    description={data.description}
                                    color={data.color}
                                    value={data.quantity}
                                    helpText={data.helpText}
                                    onCardSelected={onCardSelected}
                                    fetchingActionViewData={fetchingComplianceActionViewData}
                                />
                            </div>
                        ))
                        : !fetchingComplianceActionViewData ?
                            <h3>No registers found</h3>
                        :
                            null
                }
            </div>
            <PureModal
                windowProps={{ large: true, modalZIndex: 6, modalBehindModal: true }}
                renderTrigger={({ openModal }) => (<div id="chartItemClicked" onClick={openModal}></div>)}
                renderContent={({ closeModal }) => (
                    <PatientDataByCategory
                        dateFrom={chosenStartDate}
                        dateTo={chosenEndDate}
                        companyId={companyId}
                        category={selectedCardData.id}
                        value={selectedCardData.description}
                        filterName={selectedCardData.description}
                        categoryName={selectedCardData.description}
                        closeModal={closeModal}
                        inActionView={true}
                    />
                )}
                onClose={() => history.replace(currentUnalteredRoute)}
            />
        </>
    )
};

export default ComplianceActionViewTab;