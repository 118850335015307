import React, { useEffect, useState } from 'react'
import ComplianceMetricsPatientsOverview from "./compliance-metrics-patients-overview";
import './styles.scss'
import { useFetch } from 'hooks';
import { path, clone } from "ramda";
import { get } from '../../../../../utils/api';
import { getApiErrorMessage } from "utils/misc";
import ComplianceDashboardPieChart from "./compliance-dashboard-pie-chart";
import PatientDataByCategory from 'components/screens/dashboard/compliance-dashboard/patient-data-by-category';
import { Spinner, PureModal } from 'components/ui';
import useDateRange from 'hooks/useDateRange';
import ComplianceDashboardBarChart from './compliance-dashboard-bar-chart';
import { useDispatch } from 'react-redux';
import * as msg from "actions/message";
import { useHistory } from "react-router-dom";

const ComplianceMetricsTab = ({ companyId }) => {

  const history = useHistory()
  const dispatch = useDispatch()

  const [complianceMetricsData, setComplianceMetricsData] = useState({})
  const [selectedChartData, setSelectedChartData] = useState({});
  const [infoAcquired, setInfoAcquired] = useState(false)

  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  } = useDateRange({
    startDate: null,
    endDate: null
  });

  const [chosenStartDate, setChosenStartDate] = useState(null)
  const [chosenEndDate, setChosenEndDate] = useState(null)

  const { fetchData: fetchComplianceMetricsData, isFetching: fetchingComplianceMetricData } = useFetch({
    apiFn: (filter) => get(`compliance-dashboard/percentage-categories`,filter),
    defaultValue: {},
    transformError: path(["response", "body", "status"]),
    onError: error => {
        dispatch(msg.errorMessage(`Failed to get compliance dashboard metrics data: ${getApiErrorMessage(error)}`))
    }
  });

  useEffect(() => {
    getComplianceMetricsData(startDate, endDate);
  }, [])

  const getComplianceMetricsData = async (startDate, endDate) => {
    await setChosenStartDate(startDate)
    await setChosenEndDate(endDate)
    const complianceMetricsDataResponse = await fetchComplianceMetricsData({dateFrom: startDate ? startDate : null, dateTo: endDate ? endDate : null, companyId: companyId ? companyId : ""});
    if (complianceMetricsDataResponse){
        setInfoAcquired(true)
       setComplianceMetricsData(complianceMetricsDataResponse);
    }
  }

  const onPieChartDoctorSelected = (doctorNpi, doctorName) => {
    document.getElementById("chartItemClicked").click();
    setSelectedChartData({ value: doctorNpi, category: '21', filterName: doctorName, categoryName: 'By Doctor' });
  };

  const onBarChartSelected = (data) => {
    document.getElementById("chartItemClicked").click();
    setSelectedChartData(data);
  };

  const currentUnalteredRoute = history.location.pathname

  return (
    <>
      {/*Date filter, commented to be hidden, according to S3 request*/} 
      {/*<div style={{display: "flex", marginBottom: "25px", padding: "0 5px", width: "100%"}}>
          <DateRange
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              handleSubmit={getComplianceMetricsData}
              isValid={!fetchingComplianceMetricData}
          />
      </div>*/}

      <div style={{display: "flex", justifyContent: "center"}}>
          {
              fetchingComplianceMetricData && <div><Spinner /></div>
          }
      </div>

      <div style={fetchingComplianceMetricData ? {pointerEvents: "none", opacity: "0.5"} : undefined}>
            <div className="compliance-metrics-tab">
                {
                    infoAcquired ?
                    <ComplianceMetricsPatientsOverview
                    complianceMetricsData={complianceMetricsData}
                    />
                    :
                    null
                }
            </div>
                {
                  infoAcquired ?
                    <div className='bar-charts-container'>
                        {complianceMetricsData.Charts?.map((chart) => (
                        <div key={chart.category} className='bar-charts' >
                          <ComplianceDashboardBarChart
                            data={chart}
                            onItemSelected={onBarChartSelected}
                            startDate={chosenStartDate}
                            endDate={chosenEndDate}
                            companyId={companyId}
                            isFetching={fetchingComplianceMetricData}
                          />
                        </div>
                        ))}
                    </div>
                  :
                  null
                }
            {
                infoAcquired && complianceMetricsData?.ChartReferralByDoctor?
                  <div className='patients-by-doctor-referral'>
                    <ComplianceDashboardPieChart
                      data={complianceMetricsData.ChartReferralByDoctor}
                      onItemSelected={onPieChartDoctorSelected}
                      startDate={chosenStartDate}
                      endDate={chosenEndDate}
                      companyId={companyId}
                      isFetching={fetchingComplianceMetricData}
                    />
                  </div>
                :
                  null
              }
            </div>
      <PureModal
        windowProps={{ large: true, modalZIndex: 6, modalBehindModal: true }}
        renderTrigger={({ openModal }) => (<div id="chartItemClicked" onClick={openModal}></div>)}
        renderContent={({ closeModal }) => (
          <PatientDataByCategory
            dateFrom={chosenStartDate}
            dateTo={chosenEndDate}
            companyId={companyId}
            category={selectedChartData.category}
            value={selectedChartData.value}
            filterName={selectedChartData.filterName}
            categoryName={selectedChartData.categoryName}
            closeModal={closeModal}
            inActionView={false}
          />
        )}
        onClose={() => history.replace(currentUnalteredRoute)}
      />
    </>
  )
};

export default ComplianceMetricsTab;