import React from 'react'
import PropTypes from "prop-types";
import { withStateHandlers } from 'recompose'
import PatientFinder from 'components/patient-finder'
import BulkPatientUpdate from 'components/bulk-patient-update'
import { PageHeader } from 'components/ui'

let PatientUpdateByGuid = ({
  handleGuidResponse,
  patientsNotFound,
  patientsFound,
  clearPatients
}) => (
  <div className='patient-update-by-guid'>
    <PatientFinder onGuidResponse={handleGuidResponse} />
    <div>
      {patientsNotFound.length > 0 && (
        <div className='patients-not-found' title={patientsNotFound.join('\n')}>
          No records were found for {patientsNotFound.length} IDs
          (hover for details).
        </div>
      )}
      {patientsFound.length > 0 && (
        <div className='patients-found' title={patientsFound.join('\n')}>
          {patientsFound.length} patients found. Updates will be
          applied to these patients (hover for details).
        </div>
      )}
      {patientsFound.length > 0 && (
        <BulkPatientUpdate
          patientIds={patientsFound}
          onUpdateSuccess={clearPatients} />
      )}
    </div>
  </div>
)

PatientUpdateByGuid.propTypes = {
  clearPatients: PropTypes.func.isRequired,
  handleGuidResponse: PropTypes.func.isRequired,
  patientsFound: PropTypes.arrayOf(PropTypes.string).isRequired,
  patientsNotFound: PropTypes.arrayOf(PropTypes.string).isRequired
};

PatientUpdateByGuid = withStateHandlers(
  { patientsFound: [], patientsNotFound: [] },
  {
    handleGuidResponse: () => ({ found, notFound }) => ({
      patientsFound: found || [],
      patientsNotFound: notFound || []
    }),
    clearPatients: () => () => (
      { patientsFound: [], patientsNotFound: [] }
    )
  }
)(PatientUpdateByGuid)

export default PatientUpdateByGuid

export const PatientUpdateByGuidScreen = props => (
  <div>
    <PageHeader title='Bulk Patient Update' />
    <PatientUpdateByGuid {...props} />
  </div>
)
