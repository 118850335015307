import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import {
  resupplyEmailToPatient,
  resetPasswordEmail,
  sendAppDownloadEmail,
  sendtextConsentEmailToPatient,
  sendRemoteSetupEmailToPatient,
  welcomeEmailToPatient
} from "actions/patient";
import MdKeyboardArrowRight from "react-icons/lib/md/keyboard-arrow-right";
import { MenuItem } from "components/ui/menu";
import PreviewEmail from "./preview-email";

const PatientEmailMenu = ({
  dispatch,
  email_templates,
  openModal,
  closeModal,
  patientEmail,
  patientId,
  disabled
}) => (
  <MenuItem
    disabled={disabled}
    sublist={[
      {
        label: "Resupply Email",
        onClick: () => dispatch(resupplyEmailToPatient({ patientId }))
      },
      {
        label: "Request Text Consent",
        onClick: () =>
          dispatch(
            sendtextConsentEmailToPatient({
              patientId
            })
          )
      },

      {
        label: "App Download Instructions",
        onClick: () =>
          dispatch(
            sendAppDownloadEmail({
              patientId
            })
          )
      },
      {
        label: "Reset Password Email",
        onClick: () => {
          dispatch(resetPasswordEmail(patientEmail));
        }
      },
      {
        label: "Welcome Email",
        onClick: () => {
          dispatch(welcomeEmailToPatient({ patientId }));
        }
      },
      {
        label: "Remote Setup Welcome Email",
        onClick: () =>
          dispatch(
            sendRemoteSetupEmailToPatient({
              patientId
            })
          )
      },
      ...email_templates.map(({ id, template_name }) => ({
        label: template_name,
        onClick: () => {
          openModal(
            <PreviewEmail
              template_id={id}
              patientId={patientId}
              closeSelf={closeModal}
            />
          );
        }
      }))
    ]}
  >
    Send Email
    <span className="menu-icon">
      <MdKeyboardArrowRight />
    </span>
  </MenuItem>
);

PatientEmailMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  email_templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  patientEmail: PropTypes.string.isRequired
};

export default connect(state => ({
  email_templates: selectors.getPatientEmailTemplates(state)
}))(PatientEmailMenu);
