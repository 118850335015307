import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import CircularProgress from "components/ui/circular-progress-bar";
import IoMdStopwatch from "react-icons/lib/io/android-stopwatch";

import { IconButton } from 'components/ui';
import PauseIcon from "react-icons/lib/md/pause";
import PlayIcon from "react-icons/lib/md/play-arrow";
import { setAutoDialerPaused} from "actions/auto-dialer"
import HasRolePermission from "components/shared/has-role-permission";

const AutoDialerTimer = ({ wrapupTime, wrapupTimeLimit, autoDialerActive, setAutoDialerPaused, isPaused }) => {

  if (!autoDialerActive || wrapupTime === 0) return null;

  return (

    <span
    style={{
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      alignItems: "center",
      padding: "0 10px",
      fontSize: "1.15em"
    }}
    >
      <span style={{ paddingRight: 10, display: "flex", alignItems: "center" }}>
        <IoMdStopwatch />
        <span style={{ paddingLeft: 5, fontSize: "1.25em" }}>Next Patient</span>
      </span>
      <CircularProgress
        strokeWidth={5}
        size={28}
        percentage={(wrapupTime / wrapupTimeLimit) * 100}
        circleText={wrapupTime > 5 ? " " : wrapupTime}
        color={wrapupTime > 5 ? "#25a8e0" : "red"}
      />
      <HasRolePermission allowedRoles={["Administrator", "ServiceAdmin"]}>
      {
        <IconButton
          onClick={() => {
            setAutoDialerPaused(!isPaused)
            }}
          text={isPaused ? "Play" : "Pause" }
          title={`Click Here to ${isPaused ? "start" : "pause"} the autodialer`}
          style={{ color: "gray" }}
          icon={isPaused ? <PlayIcon/> : <PauseIcon/>}
          type="button"
        />
      }
      </HasRolePermission>

    </span>
  );
};

export default connect(state => ({
  autoDialerActive: selectors.getIsAutoDialerEnabled(state),
  wrapupTime: selectors.getAutoDialerWrapupTime(state),
  wrapupTimeLimit: selectors.getAutoDialerWrapupLimit(state),
  isPaused: selectors.getAutoDialerPaused(state)
}), {setAutoDialerPaused})(AutoDialerTimer);

AutoDialerTimer.propTypes = {
  wrapupTime: PropTypes.number,
  wrapupTimeLimit: PropTypes.number,
  autoDialerActive: PropTypes.bool
};
