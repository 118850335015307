import React from "react";
import { MdError } from "react-icons/lib/md";
import { useDispatch } from "react-redux";
import { errorMessage, message } from "actions/message";
import { useFetch } from "hooks";
import { path } from "ramda";
import { put } from "utils/api";
import { Spinner } from "components/ui";

export default function ModalUnassignForm({ patientId, formId, closeModal, onSubmitAction }) {

    const dispatch = useDispatch();

    const {
        fetchData: unassignFormFromPatient,
        isFetching: isSubmitting
    } = useFetch({
        apiFn: () => put(`patients/${patientId}/unassign-form/${formId}`),
        defaultValue: null,
        transformError: path(["response", "body", "status"]),
    });

    const unassignForm = async () => {
        const response = await unassignFormFromPatient();

        if (response && response.success) {
            dispatch(message(`Form unassigned succesully`));
            onSubmitAction("All");
        }
        else {
            const message = response && response.errorMessage ? response.errorMessage : "Failed to unassign form.";
            dispatch(errorMessage(message));
        }

        closeModal();
    }

    return <div>
        <span style={{ fontSize: '20px' }}>
            <MdError size="3rem" style={{ color: 'orange' }} /> Are you sure you want to un-assign this form?
        </span>
        <br />
        <span style={{ fontSize: '20px', marginLeft: '40px' }}>The availability dates of the next forms will be updated. </span>

        <div className='dd_col-12'>
            <div className='dd_col-6'>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <button type="button" className="button alert" onClick={() => { closeModal(); }} disabled={isSubmitting}> Not Now </button>
                </div>
            </div>
            <div className='dd_col-6'>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <button type="button" className="button" onClick={() => { unassignForm() }} disabled={isSubmitting}> Yes { isSubmitting ? <Spinner/> : <></>} </button>
                </div>

            </div>
        </div>
    </div>
}