import { GET_TIMESLOTS, GET_HISTORY, GET_UPCOMING_CHAT, SET_SLOT, GET_APPOPINTMENT_TOPIC } from './../ActionTypes';
import API from "./../../utils/axios";
import { showLoader, hideLoader } from "./common";
import { errorMessage, message } from "./../../../../actions/message";
import moment from "moment";
import momentTz from 'moment-timezone';

export const getTimeSlots = (sDate, scheduleType) => async dispatch => {
    const url = "dme_portal/available_slots";
    const jsonObj = {
        "date": moment(sDate).format("MM/DD/YYYY"),
        "chat_type": scheduleType
    };
    await API().put(url, jsonObj).then(res => {
        if (res && res.data && res.data.success) {
            if (res.data.slots) {
                let convertedObj = [];
                res.data.slots.map((data) => {
                    const convertedStartTime = momentTz.tz(data.start_time, 'YYYY-MM-DDThh:mm:ss', 'America/Chicago');
                    const convertedEndTime = momentTz.tz(data.end_time, 'YYYY-MM-DDThh:mm:ss', 'America/Chicago');
                    convertedObj.push({ text: moment.utc(convertedStartTime).local().format("h:mm A") + " to " + moment.utc(convertedEndTime).local().format("h:mm A"), value: data.slot_id });
                });
                return dispatch({
                    type: GET_TIMESLOTS,
                    payload: convertedObj
                });
            }
        } else {
            dispatch(errorMessage(res.data.message));
            return dispatch({
                type: GET_TIMESLOTS,
                payload: []
            });
        }
    }).catch(err => {
        //console.log(err);
        console.log('error', err.response);
        const responseErrorMessage = err.response.data.message;
        console.log(responseErrorMessage);
        dispatch(errorMessage(responseErrorMessage));
        return dispatch({
            type: GET_TIMESLOTS,
            payload: []
        });
    });
}

export const createSchedule = (data) => dispatch => {
    let fields = [];
    if ((data.chat_type === "1" || data.chat_type === "2") && (data.patients.length === 0)) {
        fields.push("Patient detail");
    } else if (data.chat_type === "3" && data.patients.length === 0) {
        fields.push("Patients detail");
    }

    if (data.topic_category_id.length === 0) {
        fields.push("Appointment Topic");
    }

    if (data.slot_id.length === 0) {
        fields.push("Slot Detail");
    }

    if (data.is_product_topic && data.product_id.length === 0) {
        fields.push("Product Detail");
    }

    if (fields.length > 0) {
        const combinedfields = fields.join(', ');
        dispatch(errorMessage(combinedfields + " required!"));
        return;
    }

    let createObj = {};
    if (data.chat_type === "1" || data.chat_type === "2") {
        createObj = {
            "chat_type": parseInt(data.chat_type),
            "title": data.title,
            "description": data.description,
            "slot_id": data.slot_id,
            
            "topic_category_id": data.topic_category_id
        }
        createObj.patient_id = data.patients[0].value;
        if (data.is_product_topic) createObj.product_id = data.product_id;

        const url = "dme_portal/schedule_chat";
        API().post(url, createObj).then(res => {
            if (res && res.data && res.data.success) {
                dispatch(message(res.data.message));
                dispatch(setSlot(true));
            } else {
                dispatch(errorMessage(res.data.message));
            }
        }).catch(err => {
            console.log('error', err.response);
            const responseErrorMessage = err.response.data.message;
            dispatch(errorMessage(responseErrorMessage));
        });
    } else {
        let customPatientObj = [];
        data.patients.map((data) => {
            customPatientObj.push({ "patient_id": data.value });
        });

        if (data.is_product_topic) {
            createObj = {
                "chat_type": parseInt(data.chat_type),
                "product_id": data.product_id,
                "slot_id": data.slot_id,
                "patients": customPatientObj,
                "topic_category_id": data.topic_category_id
            }
        } else {
            createObj = {
                "chat_type": parseInt(data.chat_type),
                "slot_id": data.slot_id,
                "patients": customPatientObj,
                "topic_category_id": data.topic_category_id
            }
        }

        const url = "dme_portal/scheduleproductdemo";
        API().post(url, createObj).then(res => {
            if (res && res.data && res.data.success) {
                dispatch(message(res.data.message));
                dispatch(setSlot(true));
            } else {
                dispatch(errorMessage(res.data.message));
            }
        }).catch(err => {
            console.log('error', err.response);
            const responseErrorMessage = err.response.data.message;
            dispatch(errorMessage(responseErrorMessage));
        });
    }
}

export const setSlot = (status) => dispatch => {
    return dispatch({
        type: SET_SLOT,
        payload: status
    });
}

export const getChatHistory = () => dispatch => {
    dispatch(showLoader());
    const url = "dme_portal/history_chats";
    API().get(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            if (res.data.chats) {
                return dispatch({
                    type: GET_HISTORY,
                    payload: res.data.chats
                });
            }
        } else {
            dispatch(errorMessage(res.data.message));
            return dispatch({
                type: GET_HISTORY,
                payload: []
            });
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage = err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
        return dispatch({
            type: GET_HISTORY,
            payload: []
        });
    });
}

export const getUpcomingChat = () => dispatch => {
    dispatch(showLoader());
    const url = "/dme_portal/upcoming_chats";
    API().get(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            if (res.data.chats) {
                return dispatch({
                    type: GET_UPCOMING_CHAT,
                    payload: res.data.chats
                });
            }
        } else {
            dispatch(errorMessage(res.data.message));
            return dispatch({
                type: GET_UPCOMING_CHAT,
                payload: []
            });
        }
    }).catch(err => {
        console.log('error', err.response);
        const responseErrorMessage = err.response.data.message;
        dispatch(hideLoader());
        dispatch(errorMessage(responseErrorMessage));
        return dispatch({
            type: GET_UPCOMING_CHAT,
            payload: []
        });
    });
}

export const getAppointmentTopicCategories = () => dispatch => {
    dispatch(showLoader());
    const url = "/dme_portal/appointment_topic_categories";
    API().get(url).then(res => {
        dispatch(hideLoader());
        if (res && res.data && res.data.success) {
            if (res && res.data && res.data.topic_categories) {
                const filterObj = res.data.topic_categories.map((data) => {
                    return { text: data.topic_name,label: data.topic_name, value: data.topic_id, isProductTopic: data.is_product_topic };
                });
                return dispatch({
                    type: GET_APPOPINTMENT_TOPIC,
                    payload: filterObj
                });
            }
        } else {
            dispatch(errorMessage(res.data.message));
            return dispatch({
                type: GET_APPOPINTMENT_TOPIC,
                payload: []
            });
        }
    }).catch(err => {
        console.log('error', err);
        dispatch(hideLoader());
        return dispatch({
            type: GET_APPOPINTMENT_TOPIC,
            payload: []
        });
    })
}
