import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { withModal, StatusToggle, ConfirmationDialog } from "components/ui";
import moment from "moment";
import SectionHeader from "./section_header.jsx";
import { Ul, AttributeItem } from "../lists";
import DatePicker from "../ui/date-picker";
import { updatePatient } from "actions/patient";
import compose from "lodash/fp/compose";
import DeactivatePatient from "./modals/deactivate-patient";
import IoMdMinus from "react-icons/lib/io/minus";
import HasAppPermission from "components/shared/has-app-permission";
import PhilipsRecallResetToggle from "./philips-recall/reset-toggle";
import HasRolePermission from "components/shared/has-role-permission";

const style = {
  icon: {
    color: "#AAA",
    cursor: "pointer",
    marginLeft: 5,
    display: "inline-block",
    float: "right",
    width: 15
  },
  wait: {
    cursor: "wait"
  },
  clear: {
    marginRight: 10
  },
  datePicker: {
    marginLeft: 5,
    display: "inline-block",
    float: "right"
  }
};

class OtherDetails extends Component {
  render() {
    const {
      patientInfo,
      patientId,
      inactivationReasons,
      rotechInactivationReasons,
      openModal,
      closeModal,
      isUpdating,
      updatePatient,
      readOnly
    } = this.props;
    const patientIsRotech =
      patientInfo?.company_name === "Sleep Central a Rotech Healthcare Company";

    return (
      <div>
        <SectionHeader>Settings</SectionHeader>
        <Ul>
          <AttributeItem title="Active:">
            {patientInfo.active ? "yes" : "no"}
            {patientInfo.active ? (
              <span>
                <i
                  style={style.icon}
                  onClick={() =>
                    !readOnly &&
                    openModal(
                      <DeactivatePatient
                        patientId={patientId}
                        deactivatePatient={this.deactivatePatient.bind(this)}
                        inactivationReasons={
                          patientIsRotech
                            ? rotechInactivationReasons
                            : inactivationReasons
                        }
                        toggleStatus={e => this.toggleStatus("active", e)}
                        closeSelf={closeModal}
                      />
                    )
                  }
                >
                  <IoMdMinus />
                </i>
              </span>
            ) : (
              <StatusToggle
                value={patientInfo.active}
                toggleStatus={e => this.toggleStatus("active", e)}
                disabled={readOnly}
              />
            )}
          </AttributeItem>
          <HasAppPermission permission="orderSupplies">
            <AttributeItem
              title="Delayed activation:"
              actions={
                <DatePicker
                  css={style.datePicker}
                  disabled={readOnly}
                  showOutsideDays
                  onChange={e =>
                    this.handleDayChange("delayed_activation_date", e)
                  }
                />
              }
            >
              {patientInfo.delayed_activation_date
                ? moment(patientInfo.delayed_activation_date).format("ll")
                : "N/A"}
            </AttributeItem>
            <AttributeItem title="Compliance patient:">
              {patientInfo.compliance_participant ? "yes" : "no"}
              <StatusToggle
                value={patientInfo.compliance_participant}
                isUpdating={isUpdating}
                toggleStatus={e =>
                  this.toggleStatus("compliance_participant", e)
                }
                disabled={readOnly}
              />
            </AttributeItem>
            <AttributeItem title="Retention patient:">
              {patientInfo.retention ? "yes" : "no"}
              <StatusToggle
                value={patientInfo.retention}
                isUpdating={isUpdating}
                toggleStatus={this.toggleStatus.bind(this, "retention")}
                disabled={readOnly}
              />
            </AttributeItem>
            <AttributeItem title="Collection History:">
              {patientInfo.collections_history ? "Yes" : "Never"}
              <StatusToggle
                value={patientInfo.collections_history}
                isUpdating={isUpdating}
                toggleStatus={this.toggleStatus.bind(
                  this,
                  "collections_history"
                )}
                disabled={readOnly}
              />
            </AttributeItem>
            <AttributeItem title="New setup:">
              {patientInfo.new_setup ? "yes" : "no"}
              <StatusToggle
                value={patientInfo.new_setup}
                isUpdating={isUpdating}
                toggleStatus={this.toggleStatus.bind(this, "new_setup")}
                disabled={readOnly}
              />
            </AttributeItem>
            <AttributeItem title="Remote setup:">
              {patientInfo.remote_setup ? "yes" : "no"}
              <StatusToggle
                value={patientInfo.remote_setup}
                isUpdating={isUpdating}
                toggleStatus={this.toggleStatus.bind(this, "remote_setup")}
                disabled={readOnly}
              />
            </AttributeItem>
            <AttributeItem title="Hold status:">
              {patientInfo.on_hold ? "On hold" : "Not on hold"}
              <StatusToggle
                value={patientInfo.on_hold}
                isUpdating={isUpdating}
                toggleStatus={this.toggleStatus.bind(this, "on_hold")}
                disabled={readOnly}
              />
            </AttributeItem>
          </HasAppPermission>
          <AttributeItem title="Can email:">
            {patientInfo.can_email ? "Can email" : "Do not email"}
            {patientInfo.can_email === true &&
            patientInfo.form_of_contact === "Email" ? (
              <span>
                <i
                  style={style.icon}
                  onClick={() =>
                    openModal(
                      <ConfirmationDialog
                        onCancel={closeModal}
                        warningMsg="Cannot revoke resupply email permission if form of contact is set to Email"
                        continueMsg="Update patients form of contact to Manual Call and continue?"
                        onConfirm={() => {
                          updatePatient({
                            patientId,
                            updates: {
                              can_email: false,
                              form_of_contact: "Manual Call"
                            }
                          });
                          closeModal();
                        }}
                      />
                    )
                  }
                >
                  <IoMdMinus />
                </i>
              </span>
            ) : (
              <StatusToggle
                value={patientInfo.can_email}
                isUpdating={isUpdating}
                toggleStatus={this.toggleStatus.bind(this, "can_email")}
                disabled={readOnly}
              />
            )}
          </AttributeItem>
          <AttributeItem title="IVR consent:">
            {patientInfo.ivr_consent ? "yes" : "no"}
          </AttributeItem>
          {patientInfo.opt_in_phillips_recall && (
            <HasRolePermission allowedRoles={["Administrator"]}>
              <PhilipsRecallResetToggle
                patientInfo={patientInfo}
                patientId={patientId}
                readOnly={readOnly}
              />
            </HasRolePermission>
          )}
          {patientIsRotech && (
            <AttributeItem title="30 Day Payor:">
              {patientInfo.thirty_day_payor ? "Yes" : "No"}
            </AttributeItem>
          )}
        </Ul>
      </div>
    );
  }

  handleDayChange(field, event) {
    const { patientId, updatePatient } = this.props;
    updatePatient({
      patientId,
      updates: { [field]: event ? moment(event).format("YYYY-MM-DD") : "" }
    });
  }

  toggleStatus(field) {
    const { patientId, patientInfo, updatePatient } = this.props;
    updatePatient({
      patientId,
      updates: { [field]: !patientInfo[field] }
    });
  }

  activatePatient() {
    const { patientId, updatePatient } = this.props;
    updatePatient({
      patientId,
      updates: {
        active: true
      }
    });
  }

  deactivatePatient(inactiveReasonId) {
    const { patientId, updatePatient } = this.props;
    updatePatient({
      patientId,
      updates: { active: false, inactiveReasonId }
    });
  }
}

OtherDetails.propTypes = {
  inactivationReasons: PropTypes.array,
  rotechInactivationReasons: PropTypes.array,
  isUpdating: PropTypes.bool,
  patientInfo: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
  updatePatient: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired
};

OtherDetails.defaultProps = {
  readOnly: false
};

export default compose(
  withModal,
  connect(
    (state, { patientId }) => ({
      patientInfo: selectors.getPatientInfo(state, patientId),
      isUpdating: state.getIn([
        "fetch",
        "UPDATE_PATIENT",
        patientId,
        "isFetching"
      ]),
      inactivationReasons: selectors.getEnumInactivationReasons(state),
      rotechInactivationReasons:
        selectors.getEnumRotechInactivationReasons(state)
    }),
    { updatePatient }
  )
)(OtherDetails);
