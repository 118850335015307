import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import * as R from "ramda";
import { QueryTableContext } from "hooks/useQuery/api-query";
import { updateFilters } from "hooks/useQuery/actions";
import Dropdown from "components/ui/dropdown";
import { IconButton } from "components/ui";
import MdFilterList from "react-icons/lib/md/filter-list";

const CompanyBranchFilter = ({ companies }) => {
  const { query, queryDispatcher } = useContext(QueryTableContext);
  const [selectedCompanies, setSelectedCompanies] = React.useState(
    query.filters?.company ?? []
  );
  const [selectedBranches, setSelectedBranches] = React.useState(
    query.filters?.company_branch_guid ?? []
  );
  const [searchTerms, setSearchTerms] = useState("");

  const filterCompanies = company => {
    return (
      company.name.toLowerCase().includes(searchTerms.toLowerCase()) ||
      company.branches.some(x =>
        x.text.toLowerCase().includes(searchTerms.toLowerCase())
      )
    );
  };

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <td className="query-table-header-cell-container">
      <div className="query-table-header-cell">
        <div
          className={`header-text ${
            query.filters?.company?.length > 0 ? "active" : ""
          }`}
        >
          Company
        </div>
        <Dropdown
          trigger={
            <IconButton
              icon={<MdFilterList />}
              className="button secondary icon-button column-filter-trigger"
            />
          }
          onClose={() => {
            queryDispatcher(
              updateFilters({
                ...query.filters,
                company: selectedCompanies,
                company_branch_guid: selectedBranches
              })
            );
          }}
          style={{ marginTop: 35 }}
        >
          <div
            onClick={() => {
              setSelectedBranches([]);
              setSelectedCompanies([]);
            }}
            className="filter-pane-command controls"
          >
            Clear Selections
          </div>
          <input
            type="text"
            className="searchInputBox"
            onChange={e => {
              setSearchTerms(e.target.value);
            }}
            placeholder="Search By Company or Branch"
          />
          {companies
            .filter(filterCompanies)
            .map(c => ({
              ...c,
              branches: c.branches.filter(b =>
                b.text.toLowerCase().includes(searchTerms.toLowerCase())
              )
            }))
            .map(c => (
              <div key={c.GUID}>
                <label className="filter-entry">
                  <input
                    type="checkbox"
                    checked={selectedCompanies.includes(c.GUID)}
                    onChange={({ target }) => {
                      if (target.checked) {
                        setSelectedCompanies(
                          R.uniq([c.GUID, ...selectedCompanies])
                        );
                        // setSelectedBranches(
                        //   R.uniq(
                        //     R.concat(
                        //       [c.GUID, ...c.branches.map(b => b.value)],
                        //       selectedBranches
                        //     )
                        //   )
                        // );
                      } else {
                        setSelectedCompanies(
                          R.without([c.GUID], selectedCompanies)
                        );
                        setSelectedBranches(
                          R.without(
                            [c.GUID, ...c.branches.map(b => b.value)],
                            selectedBranches
                          )
                        );
                      }
                    }}
                  />
                  <span style={{ display: "flex", flex: "1 1 auto" }}>
                    {c.text}
                  </span>
                </label>
                {c.branches.map(b => (
                  <div key={b.value}>
                    <label className="filter-entry filter-sub-entry">
                      <input
                        type="checkbox"
                        checked={selectedBranches.includes(b.value)}
                        onChange={({ target }) => {
                          if (target.checked) {
                            setSelectedCompanies(
                              R.uniq([c.GUID, ...selectedCompanies])
                            );
                            setSelectedBranches(
                              R.uniq([b.value, ...selectedBranches])
                            );
                          } else {
                            setSelectedBranches(
                              R.without([b.value], selectedBranches)
                            );
                          }
                        }}
                      />
                      {b.text}
                    </label>
                  </div>
                ))}
                {c.branches.length > 0 && (
                  <div>
                    <label className="filter-entry filter-sub-entry">
                      <input
                        type="checkbox"
                        checked={selectedBranches.includes(c.GUID)}
                        onChange={({ target }) => {
                          if (target.checked) {
                            setSelectedCompanies(
                              R.uniq([c.GUID, ...selectedCompanies])
                            );
                            setSelectedBranches(
                              R.uniq([c.GUID, ...selectedBranches])
                            );
                          } else {
                            setSelectedBranches(
                              R.without([c.GUID], selectedBranches)
                            );
                          }
                        }}
                      />
                      No Branch
                    </label>
                  </div>
                )}
              </div>
            ))}
        </Dropdown>
      </div>
    </td>
  );
};

export default connect(state => ({
  companies: selectors.getFormOptionsCompany(state)
}))(CompanyBranchFilter);

CompanyBranchFilter.propTypes = {
  queryId: PropTypes.string.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired
};
