import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";

const FormikFieldStatuses = ({
  name,
  label,
  labelAppend,
  style,
  className,
  ...rest
}) => (
  <span className={className} style={style}>
    <span className="form-field-header">
      {label && (
        <label className="form-field-label" htmlFor={name}>
          {label}
        </label>
      )}
      {labelAppend && (
        <label className="form-field-label-append" htmlFor={labelAppend}>
          {`* ${labelAppend}`}
        </label>
      )}
    </span>
    <div style={{flexGrow: "1"}}>
        <span className="form-field-error">
            <ErrorMessage name={name} />
        </span>
        <Field name={name} {...rest} id={name} className="input-field" />
    </div>
  </span>
);
export default FormikFieldStatuses;

FormikFieldStatuses.propTypes = {
  name: PropTypes.string.isRequired,
  labelAppend: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
  className: PropTypes.string
};

FormikFieldStatuses.defaultProps = { style: {}, className: "form-field" };
