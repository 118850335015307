import { createContext } from "react";
import useTableSettings from "hooks/useTableSettings";

export const AssignablePatientsTableSettingsContext = createContext();

const useAssignablePatientsTableSettings = (query, queryDispatcher) => {
  const columns = [
    {
        header: "all",
        field: "all",
        active: true
    },
    {
        header: 'Name',
        field: frm => frm.Name,
        active: true
    },
    {
        header: 'Account#',
        field: frm => frm.AccountNumber,
        active: true
    },
    {
        header: 'Contact Type',
        field: frm => frm.ContactType,
        active: true
    },
    {
        header: 'Insurance',
        field: frm => frm.Insurance,
        active: true
    },
    {
        header: 'Doctor',
        field: frm => frm.Doctor,
        active: true
    },
    {
        header: 'Compliance Coach',
        field: frm => frm.ComplianceCoach,
        active: true
    },
    {
        header: 'Company',
        field: frm => frm.CompanyName,
        active: true
    },
    {
        header: 'Outreach Date',
        field: frm => frm.NextOutreachDate === null ?  "No Record" : moment(frm.NextOutreachDate).format('MMMM Do YYYY, h:mm:ss a'),
        active: true
    },
    {
        header: 'Outreach Cycle',
        field: frm => frm.OutreachCycle,
        active: true
    },
    {
        header: 'Created at',
        field: frm => frm.DateCreated,
        active: true
    }
  ];
  const {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    visibleColumns,
    resetTableSettings
  } = useTableSettings("assignable-patients-table-settings", columns);

   return {
    isColumnVisible,
    tableSettings,
    updateTableSettings,
    resetTableSettings,
    columns
  };
};

export default useAssignablePatientsTableSettings;