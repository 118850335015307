import React from "react";
import PropTypes from "prop-types";
import { InlineWaiting } from "components/ui";

const ResendPdfButton = ({
  patientId,
  resendPDFTemplate,
  getPDFTemplate,
  pdf_template_id
}) => {
  const [isFetching, setIsFetching] = React.useState(false);
  return isFetching ? (
    <InlineWaiting />
  ) : (
    <button
      style={{ margin: 0, padding: "5px 10px" }}
      className="secondary"
      onClick={async () => {
        try {
          setIsFetching(true);
          await resendPDFTemplate(pdf_template_id, [{ id: patientId }]);
          await getPDFTemplate();
        } finally {
          setIsFetching(false);
        }
      }}
    >
      Re-Send
    </button>
  );
};

ResendPdfButton.propTypes = {
  resendPDFTemplate: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  pdf_template_id: PropTypes.string.isRequired,
  getPDFTemplate: PropTypes.func.isRequired
};

export default ResendPdfButton;
