import "object-assign";
import { fromJS } from "immutable";
import flow from "lodash/fp/flow";
import sortBy from "lodash/fp/sortBy";
import map from "lodash/fp/map";
import _mapValues from "lodash/fp/mapValues";
import includes from "lodash/includes";
import get from "lodash/get";
import { reducer as formReducer } from "redux-form";
import C from "./action-types";
import { createSelector } from "reselect";
import initialState from "./initial-state";
import enumReducer, { selectors as localFromEnum } from "reducers/enum";
import entities, * as entitySelectors from "reducers/entities";
import inventory, { selectors as localFromInventory } from "reducers/inventory";
import { selectors as localFromPatient } from "reducers/patient";
import fetch from "reducers/fetch";
import * as fetchSelectors from "reducers/fetch-selectors";
import user, { selectors as userSelectors } from "reducers/user";
import userProfile, { selectors as localFromUserProfile } from "reducers/user-profile";
import login, { selectors as loginSelectors } from "reducers/login";
import table from "reducers/table";
import sleepCoachDashboard, { selectors as localFromScDashboard } from "reducers/sleep-coach-dashboard";
import companiesTable, { selectors as localFromCompaniesTable } from "reducers/companiesTable";
import orderProfile, {
  selectors as localFromOrderProfile,
  permissionSelectors as localFromOrderProfilePermissions,
  holdReasonSelectors as localFromOrderHoldReasons
} from "reducers/orderProfile";
import search from "reducers/search";
import users, * as u from "reducers/users";
import newRootReducer from "./../src/components/dd_newModules/Redux/RootReducer";
import company, { selectors as localFromCompany } from "reducers/company";
import contactLogic from "reducers/company-contact-logic";
import notifications, { selectors as localFromNotifications } from "reducers/notifications";
import patientVerification, { selectors as localFromPatientVerification } from "reducers/patient-verification";
import productMedia from "reducers/product-media"
import productFaqs from "reducers/product-faqs"
import patientStatus, { selectors as localFromPatientStatus } from "reducers/patient-status";
import batch, { selectors as localFromBatch } from "reducers/batch";
import importTracking, { selectors as importTrackingSelectors } from "reducers/import-tracking";
import orderStatus, { selectors as localFromOrderStatus } from "reducers/order-status";
import exports from "reducers/exports";
import phoneBook, { selectors as localFromPhoneBook } from "reducers/phone-book";
import { reducer as queryReducer, selectors as querySelectors } from "features/query";
import fulfillmentStatusReducer, { selectors as localFromFulfillmentStatus } from "reducers/fulfillment-status";
import phone, { selectors as localFromPhone } from 'reducers/phone'
import physicianSearch, { selectors as localFromPhysicianSearch } from 'reducers/physician-search'
import googleApi, { selectors as localFromGoogleApi } from 'features/google-api/reducers'
import systemTray, { selectors as localFromSystemTray } from 'reducers/system-tray'
import teamDashboard, { selectors as localFromTeamDashboard } from 'reducers/team-dashboard'
import autoDialer, { selectors as localFromAutoDialer } from 'reducers/auto-dialer'
import signalR, { selectors as localFromSignalR } from 'features/signalR/reducer'
import heatMaps, { selectors as localFromHeatMaps } from 'reducers/heat-map'
import phoneDashboard, {  selectors as localFromPhoneDashboard } from "reducers/phone-dashboard";
import ivrJobs, {  selectors as localFromIvrJobs } from "reducers/ivr-jobs";
import employeeOnlineTimes, {  selectors as localFromEmployeeOnlineTimes } from "reducers/employee-online-times";
import bonafideIntegration, {
  selectors as localFromBonafideIntegration
} from "features/bonafide/reducer";
import { REINITIALIZE_APP } from "actions/util";
import { Types as CompanyTypes } from "actions/company"
import { Types as QueryTypes } from "./features/query/actions";
// import dd_newModule_Reducer from './components/dd_newModules/reducer';
import twilioChat, { selectors as localFromTwilioChatSelectors } from "reducers/twilio-chat";
import appLayout, { selectors as localFromAppLayout } from "reducers/app-layout";
import backorderImport from "reducers/backorder-import";
import patientImport from "reducers/patient-import";
import insuranceImport from "reducers/insurance-import";
import s3Inventory from "reducers/company-inventory";
import appVersion, { selectors as localFromAppVersion } from "reducers/app-version";
import phoneQueues, { selectors as localFromPhoneQueue } from "reducers/phone-queue";
import importer, { selectors as localFromImporter } from "reducers/importer";
import * as R from "ramda";

const USER_REDUCER = "user";
const BATCH_REDUCER = "batch";
const ORDER_STATUS_REDUCER = "orderStatus";
const EXPORTS_REDUCER = "userExports";

export default function rootReducer(state = initialState, action) {
  // Must be first test since it depends on default value of state
  if (action.type === REINITIALIZE_APP && state !== initialState) {
    return rootReducer(undefined, action)
  }

  state = state.update("form", f => formReducer(f, action));
  state = state.update("enums", e => enumReducer(e, action));
  state = state.update("entities", e => entities(e, action));
  state = state.update("fetch", f => fetch(f, action));
  state = state.update(USER_REDUCER, u => user(u, action));
  state = state.update("login", l => login(l, action));
  state = state.update("table", tp => table(tp, action));
  state = state.update("companiesTable", state => companiesTable(state, action));
  state = state.update("sleepCoachDashboard", state => sleepCoachDashboard(state, action));
  state = state.update("search", state => search(state, action));
  state = state.update("userIndexes", state => users(state, action));
  state = state.update("newRootReducer", state => newRootReducer(state, action));
  state = state.update("orderProfile", state => orderProfile(state, action));
  state = state.update("patientStatus", state => patientStatus(state, action));
  state = state.update(BATCH_REDUCER, state => batch(state, action));
  state = state.update("importTracking", state => importTracking(state, action));
  state = state.update(ORDER_STATUS_REDUCER, state => orderStatus(state, action));
  state = state.update("company", state => company(state, action));
  state = state.update("contactLogic", state => contactLogic(state, action));
  state = state.update(EXPORTS_REDUCER, state => exports(state, action));
  state = state.update("query", state => queryReducer(state, action));
  state = state.update("fulfillmentStatus", state => fulfillmentStatusReducer(state, action));
  state = state.update("inventory", state => inventory(state, action));
  state = state.update("phoneBook", state => phoneBook(state, action));
  // state = state.update("officeAdminInsights", state => officeAdminInsights(state, action));
  state = state.update("phone", state => phone(state, action));
  state = state.update("physicianSearch", state => physicianSearch(state, action));
  state = state.update("googleApi", state => googleApi(state, action));
  state = state.update("system-tray", state => systemTray(state, action));
  state = state.update("teamDashboard", state => teamDashboard(state, action));
  state = state.update("autoDialer", state => autoDialer(state, action));
  state = state.update("notifications", state => notifications(state, action));
  state = state.update("patientVerification", state => patientVerification(state, action));
  state = state.update("signalR", state => signalR(state, action));
  state = state.update("user-profile", state => userProfile(state, action));
  state = state.update("heat-maps", state => heatMaps(state, action));
  state = state.update("phone-dashboard", state => phoneDashboard(state, action));
  state = state.update("ivr-jobs", state => ivrJobs(state, action));
  state = state.update("employee-online-times", state => employeeOnlineTimes(state, action));
  state = state.update("bonafide", state => bonafideIntegration(state, action));
  state = state.update("twilio-chat", state => twilioChat(state, action));
  state = state.update("media", state => productMedia(state, action));
  state = state.update("faqs", state => productFaqs(state, action));
  state = state.update("app-layout", state => appLayout(state, action));
  state = state.update("backorder-import", state => backorderImport(state, action));
  state = state.update("patient-import", state => patientImport(state, action));
  state = state.update("insurance-import", state => insuranceImport(state, action));
  state = state.update("company-inventory", state => s3Inventory(state, action));
  state = state.update("appVersion", state => appVersion(state, action));
  state = state.update("phone-queues", state => phoneQueues(state, action))
  state = state.update("importer", state => importer(state, action))
  switch (action.type) {
    case C.READY:
      if (typeof action.refs[0] === "string") {
        return state.setIn(action.refs, action.val);
      }
      return action.refs.reduce(
        (state, refs) => state.setIn(refs, action.val),
        state
      );
    case C.SEARCH.PUT:
      return state.setIn(["patients", "table"], fromJS(action.response));
    case C.SEARCH.GET_VALUES:
      return state.set("searchFormAttributes", fromJS(action.response));
    case C.SNAPSHOT.ORDERS:
      return state.set("ordersSnapshot", fromJS(action.response));
    case C.SNAPSHOT.PATIENTS:
      return state.set("patientsSnapshot", fromJS(action.response));
    case C.SEARCH.QUERY:
      return state.mergeIn(
        ["searchQuery"],
        fromJS({
          [action.table]: {
            table: action.table,
            field: action.field,
            searchText: action.searchText
          }
        })
      );
    case C.USER.GET:
      return state.setIn(["users", "profile"], fromJS(action.response));
    case C.USERS.GET:
      return state.setIn(["users", action.path], fromJS(action.response));
    case C.ORDER.TYPES_AND_MANUFACTURER:
      return state.setIn(["orders", "types"], fromJS(action.response));

    case C.IVR.QUESTIONS.RESPONSE_GET_ALL:
      return state.setIn(["ivr", "questions"], fromJS(action.response))
    case C.IVR.STATES.RESPONSE_GET_MASTER:
      return state.setIn(["ivr", "states", "master"], fromJS(action.response))
    case C.IVR.STATES.RESPONSE_GET_ALL_BY_CALLSID:
      return state.setIn(["ivr", "states", "calls", [action.response.callSid]], fromJS(action.response))
    case C.IVR.STATES.RESPONSE_GET_ALL_BY_PATIENT:
      return state.setIn(["ivr", "states", "patients", [action.response.fkiPatient]], fromJS(action.response))
    case C.EXPORTS.GET:
      return state.set("exports", fromJS(action.response));
    case C.INSIGHTS.RECEIVE:
      return state.setIn(["insights"], fromJS(action.response.insights));
    case CompanyTypes.RECEIVE_GET_COMPANY_MONTHLY_REPORT:
      return state.setIn(
        ["companies", "reports", "monthlyReport"],
        fromJS(action.response)
      );
    case CompanyTypes.RECEIVE_GET_MONTHLY_ACTIVATION_REPORT:
      return state.setIn(
        ["companies", "reports", "activationReport"],
        fromJS(action.response)
      );
    //THis is to update the Redux store so the with-API will trigger a call
    case QueryTypes.UPDATE_QUERY: {
      const oldQuery = state.get("query")
      const newQuery = {
        ...oldQuery,
        queryById: {
          [action.meta.queryId]: {
            filters: action.payload.query.filters,
            pagination: action.payload.query.pagination,
            sort: action.payload.query.sort
          }
        }
      }
      return state
        .setIn(["query"], newQuery);
    }
    case QueryTypes.RESET_API_AND_PARAMS: {
      const queryId = action.payload.queryId;
      let originalQueryById = state.getIn(["query"]).queryById;
      delete originalQueryById[queryId];
      let originalApiById = state.getIn(["query"]).apiById;
      delete originalApiById[queryId];

      const resetQuery = {
        queryById: originalQueryById,
        apiById: originalApiById
      }
      return state.setIn(["query"], resetQuery)
    }
    //This will be for the normal API call from a page visit
    case C.VMAILS.GET:
      return state
        .setIn(["vmails", "table"], fromJS(action.response.voicemails))
        .mergeIn(["vmails", "tableQuery"], action.response.data);

    case C.VMAILS.SET_QUERY:
      return state.mergeIn(["vmails", "tableQuery"], action.query);
    case C.VMAIL.GET:
      return state.setIn(["vmails", "profile"], fromJS(action.response));

    case C.VMAIL.WORKED:
      return state.setIn(["entities", "voicemailRows", action.currentVMGuid, "worked"], true);

    case "LISTENED_TO_VOICEMAIL":
      return state.setIn(["entities", "voicemailRows", action.currentVMGuid, "listened_to"], true);

    case C.VMAIL.SET_PROFILE:
      return state.setIn(["vmails", "profile"], action.vmail);

    case C.ORDERS.SET_QUERY:
      return state.mergeIn(["orders", "tableQuery"], action.query);
    //add cases below when only an update notice
    // is needed
    case C.PATIENT.IMPORT_FAILURE:
      return state.setIn(["patients", "importerStatus", "error"], action.error.response.body.status)
    case C.PATIENT.IMPORT_SUCCESS:
      return state
        .setIn(["patients", "importerStatus", "success"], true)
        .setIn(["patients", "importerStatus", "error"], null)
    case C.INSURANCE.IMPORT_FAILURE:
      return state.setIn(["insurance", "importerStatus", "error"], action.error.response.body.status)
    case C.INSURANCE.IMPORT_SUCCESS:
      return state
        .setIn(["insurance", "importerStatus", "success"], true)
        .setIn(["insurance", "importerStatus", "error"], null)
    default:
      return state;
  }
}

/**
 * SELECTORS
 */

// lifts selectors to be called with global state
const liftSelectors = sliceKey => _mapValues(selector => (state, ...args) => {
  if (state) {
    // if `state` exists, assume selector
    return selector(state.get(sliceKey), ...args)
  } else {
    // otherwise assume selector factory
    const selectorInst = selector()
    return (state, ...args) => selectorInst(state.get(sliceKey), ...args)
  }
})

export const makeGetEntity = (entityType, idKey) => createSelector(
  [(state, props) => state.getIn(['entities', entityType, get(props, idKey)])],
  entity => entity && entity.toJS()
)

export const makeGetEntities = entityType => createSelector(
  [(state) => state.getIn(['entities', entityType])],
  entities => entities && entities.toJS()
)

const makeEntitiesSelector = selector => createSelector(
  [state => selector(state.get('entities'))],
  entities => entities
)

const getPatientEntities = makeEntitiesSelector(entitySelectors.getPatientEntities)
const getPatientRowEntities = makeEntitiesSelector(entitySelectors.getPatientRowEntities)
const getOrderRowEntities = makeEntitiesSelector(entitySelectors.getOrderRowEntities)
const getFulfillmentRowEntities = makeEntitiesSelector(entitySelectors.getFulfillmentRowEntities)
const getBranchEntities = makeEntitiesSelector(entitySelectors.getBranchEntities)
const getInsuranceEntities = makeEntitiesSelector(entitySelectors.getInsuranceEntities)
const getSleepCoachEntities = makeEntitiesSelector(entitySelectors.getSleepCoachEntities)
const getTherapistEntities = makeEntitiesSelector(entitySelectors.getTherapistEntities)

const qualifyUser = roles => state => {
  const role = userSelectors.getUserRole(state)
  const doesQualify = includes(roles, role)
  return doesQualify
}

export const canUserExport = qualifyUser([
  'Administrator',
  'CompanyAdministrator',
  'OfficeAdministrator',
  'ServiceAdmin',
  'ServiceCoach',
  'ContractorAdministrator'
])

/**
 * User table selectors
 */

export const getUsers = (state, userType) => {

  //Using deconstruction to assign two constants at once (ids and entityType)
  //the return type of the action call getUsersIds is an array with ids, and entityType
  const [ids, entityType] = u.getUserIds(state.get('userIndexes'), userType)
  //The return for getUsers takes every id in Ids
  // and returns the entity where the id matches inside entities in the Redux store
  return ids.map(id => state.getIn(['entities', entityType, id]).toJS())
}

export const isUsersFetching = (state, userType) => {
  return fetchSelectors.isUsersFetching(state.get('fetch'), userType)
}

/**
 * Entity selectors (NOTE: duplicated code in reducers/entities)
 */

export const getEntity = (state, type, id) => {
  return entitySelectors.getEntity(state.get('entities'), type, id)
}

export const getEntities = (state, type) => {
  return entitySelectors.getEntities(state.get('entities'), type)
}

/**
 * Form option selectors
 */

const entityFormOptions = (key, name = 'name') => flow(
  sortBy('formAttributeIndex'),
  map(x => ({ ...x, text: x[name], value: x[key] }))
)
const guidFormOptions = entityFormOptions('GUID')

export const getFormOptionsSleepCoach = createSelector(
  getSleepCoachEntities,
  sleepCoaches => guidFormOptions(sleepCoaches)
)

const getUserIsSleepCoach = createSelector(
  [state => state.getIn(["entities", "sleepCoaches"]), (_, userId) => userId],
  (coaches, userId) => R.hasIn(userId, coaches.toJS())
);

export const getFormOptionsSleepCoachWithUserFirst = createSelector([
  getFormOptionsSleepCoach,
  userSelectors.getUser
], (sleepCoaches, { role, id }) => {
  const user = sleepCoaches.find( ({ value } ) => value === id)
  if (role === 'SleepCoach' && user) {
    return [
      { ...user },
      ...sleepCoaches.filter(c => c.value !== id)
    ]
  }
  return sleepCoaches
})

export const getFormOptionsBranch = createSelector(
  getBranchEntities,
  guidFormOptions
)

export const getFormOptionsInsurance = createSelector(
  getInsuranceEntities,
  guidFormOptions
)

export const getFormOptionsTherapist = createSelector(
  getTherapistEntities,
  guidFormOptions
)

/**
 * Patient status selectors
 */

const getCanViewSleepCoachForCurrentUser = state => {
  const userRole = userSelectors.getUserRole(state)
  return localFromPatientStatus.getCanViewSleepCoach(state, userRole)
}

const getCanBatchUpdateForCurrentUser = state => {
  const userRole = userSelectors.getUserRole(state)
  return localFromPatientStatus.getCanBatchUpdate(state, userRole)
}

/**
 * Order status selectors
 */
const fromOrderStatus = liftSelectors(ORDER_STATUS_REDUCER)(localFromOrderStatus)

const getCanCurrentUserClaimOrder = state => {
  const userRole = userSelectors.getUserRole(state)
  return fromOrderStatus.getCanUserClaimOrder(state, { userRole })
}

const getCanCurrentUserExportOrder = state => {
  const userRole = userSelectors.getUserRole(state)
  return fromOrderStatus.getCanUserExportOrder(state, { userRole })
}

/**
 * Order profile selectors
 */

// gets `orderStatus` and `userRole` props for use in permission
// selectors
const withOrderStatusAndRole = selector => (state, props) => {
  const userRole = userSelectors.getUserRole(state)
  const { data: { status: orderStatus } = {} } = fromOrderProfile.getOrderInfo(state, props)
  return selector(state, { userRole, orderStatus, ...props })
}

const liftOrderProfile = liftSelectors('orderProfile')
const fromOrderProfile = liftOrderProfile(localFromOrderProfile)
const fromOrderProfilePermissions = flow(
  liftOrderProfile,
  _mapValues(withOrderStatusAndRole)
)(localFromOrderProfilePermissions)

const getOrderStatusUpdateOptions = createSelector([
  localFromEnum.getEnumOrderStatuses,
  fromOrderProfile.getOrderInfo,
  fromOrderProfile.getOrderCompanyInfo
], (
  statuses,
  { data: { status: currStatus } = {} },
  { data: { acceptable_fulfillment = [] } = {} }
) => (
    statuses.filter(s => (
      // filter eligible update options
      s.isUpdateOption
      // filter out current status
      && s.value !== currStatus
      // filter out fulfillment only statuses if no acceptable
      // fulfillment
      && (!s.fulfillmentOnly || acceptable_fulfillment.length)
    ))
  ))

/**
 * Insight selectors
 */

const getPhoneBookUsers = createSelector(
  [
    state => localFromPhoneBook.getAllVoipUsers(state),
    state => localFromSignalR.getSignalrOnlineUsers(state)
  ],
  (voipUsers, onlineUsers) =>
    R.pipe(
      R.values,
      R.map(user =>
        R.assoc("online", onlineUsers[user.id] ? true : false, user)
      )
    )(voipUsers)
);

// will eventually be all available selectors; having a named export
// makes for easier import management
export const selectors = {
  ...localFromEnum,
  ...userSelectors,
  ...loginSelectors,

  getPatientEntities,
  getPatientRowEntities,
  getOrderRowEntities,
  getFulfillmentRowEntities,
  ...entitySelectors.selectors,
  ...localFromInventory,
  ...fetchSelectors.selectors,

  ...localFromPatientStatus,
  getCanViewSleepCoachForCurrentUser,
  getCanBatchUpdateForCurrentUser,

  ...localFromBatch,

  ...fromOrderStatus,
  getCanCurrentUserClaimOrder,
  getCanCurrentUserExportOrder,

  ...fromOrderProfile,
  ...fromOrderProfilePermissions,
  ...localFromOrderHoldReasons,
  getOrderStatusUpdateOptions,

  ...localFromPhoneBook,
  ...localFromPhone,
  ...localFromPhysicianSearch,
  ...localFromCompaniesTable,
  ...localFromScDashboard,
  ...localFromPatient,
  ...importTrackingSelectors,
  ...localFromCompany,
  ...localFromGoogleApi,
  ...localFromSystemTray,
  ...localFromTeamDashboard,
  ...localFromAutoDialer,
  ...querySelectors,
  ...localFromNotifications,
  ...localFromPatientVerification,
  ...localFromSignalR,
  ...localFromFulfillmentStatus,
  ...localFromUserProfile,
  ...localFromHeatMaps,
  ...localFromPhoneDashboard,
  ...localFromIvrJobs,
  ...localFromEmployeeOnlineTimes,
  ...localFromBonafideIntegration,
  ...localFromTwilioChatSelectors,
  ...localFromAppLayout,
  ...localFromAppVersion,
  ...localFromPhoneQueue,
  ...localFromImporter,
  getPhoneBookUsers,
  getFormOptionsBranch,
  getFormOptionsInsurance,
  getFormOptionsTherapist,
  getFormOptionsSleepCoach,
  getFormOptionsSleepCoachWithUserFirst,
  canUserExport,
  getUserIsSleepCoach
}
