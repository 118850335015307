import { combineReducers } from 'redux'
import {
  IMPORT_TRACKING_REQUEST,
  IMPORT_TRACKING_RESPONSE
} from 'actions/import-tracking'
import { path } from "ramda";
import { createSelector } from "reselect";

const submission = (state = {
  inProgress: false
}, action) => {
  switch (action.type) {
  case IMPORT_TRACKING_REQUEST:
    return {
      ...state,
      inProgress: true
    }
  case IMPORT_TRACKING_RESPONSE:
    return {
      ...state,
      inProgress: false
    }
  default:
    return state
  }
}

const rootImportTrackingReducer = combineReducers({
  submission
})

export default rootImportTrackingReducer

/**
 * Import tracking selectors
 */
const isImportTrackingSubmitInProgress = createSelector(
  [state => state.get("importTracking")],
  path(['submission', 'inProgress'])
);

export const selectors = {
  isImportTrackingSubmitInProgress
};
