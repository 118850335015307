import React from "react";
import PropTypes from "prop-types";

const DeactivatePatient = ({
  inactivationReasons,
  // patientId,
  deactivatePatient,
  closeSelf
}) => {
  const [selectedReason, setSelectedReason] = React.useState("");

  function handleSubmit() {
    deactivatePatient(selectedReason);
    closeSelf();
  }

  return (
    <div style={{ padding: 5 }}>
      <h3>Deactivation Reason</h3>
      <div>
        <select
          name="selectedReason"
          value={selectedReason}
          onChange={({ target }) => setSelectedReason(target.value)}
        >
          <option />
          {inactivationReasons.map(({ value, text }) => (
            <option key={value} value={value}>
              {text}
            </option>
          ))}
        </select>
        <button disabled={!selectedReason} onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

DeactivatePatient.propTypes = {
  deactivatePatient: PropTypes.func.isRequired,
  closeSelf: PropTypes.func.isRequired,
  inactivationReasons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string
    })
  )
};

DeactivatePatient.defaultProps = {
  inactivationReasons: []
};

export default DeactivatePatient;
