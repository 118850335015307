import React from 'react'
import { Spinner } from "components/ui";
import { useFetch } from "hooks";
import { put } from "utils/api";
import { path } from "ramda";
import { useDispatch } from 'react-redux';
import * as msg from "actions/message";

export default function FormTableCheckbox({ FormId, IsActive, updateRowArray, CompanyManagesPatientType, setFormToBeAssignedId }) {

    const dispatch = useDispatch();
    const {
        fetchData: changeActiveStatus,
        isFetching: fetchingActiveChange
    } = useFetch({
        apiFn: ({ idForm, params }) => put(`forms/toggle-active/${idForm}`, params, { "Content-Type": "application/json" }),
        defaultValue: [],
        transformError: path(["response", "body", "status"])
    })

    const handleChangeActive = async (idForm, params) => {
        const response = await changeActiveStatus({ idForm, params });
        if (response) {
            if (response.success) {
                updateRowArray(idForm, params)

                if(params)
                {
                    document.getElementById("assignPatientsConfirmationModal").click();
                    setFormToBeAssignedId(idForm)
                }
            }
            else {
                dispatch(msg.errorMessage(response.message))
            }
        } else {
            dispatch(msg.errorMessage("Failed to update forms active status"))
        }
    }

    const render = () => {
        if (!fetchingActiveChange) {
            return (
                <input id={FormId} type="checkbox" disabled={!CompanyManagesPatientType} value={IsActive} checked={IsActive} onChange={(e) => {
                    if (e.target.value == "true") {
                        handleChangeActive(FormId, false)
                    } else {
                        handleChangeActive(FormId, true)
                    }

                }} />
            )
        } else {
            return (
                <Spinner />
            )
        }
    }

    return <div>
        {render()}
    </div>
}
