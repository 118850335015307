import React from "react";

const QuestionCard = ({ question, index }) => {

    return (
        <div className="info-div"
            key={`question-${index}`}
            style={{ border: "1px solid #ccc", padding: 5, marginBottom: 10 }}
        >
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "1fr auto auto",
                    flex: 1,
                    justifyContent: "space-between",
                    fontSize: 18
                }}
            >
                <div style={{ marginBottom: 5 }}>
                    <div style={{display: "flex"}}>
                        <h6>Question {index + 1}{") "} {question.Text}</h6>
                        {
                            question.WasRequired ?
                            <h6 style={{color: "red", marginLeft: "5px"}}>*</h6>
                            :
                            null
                        }
                    </div>
                    
                    <div
                        style={{
                            display: "flex"
                        }}
                        >
                        {
                            question.MarkedAsNA ?
                            <h6 style={{ color: "gray", fontWeight: "bold", marginLeft: "5px"}}>N/A</h6>
                            :
                            <h6 style={{ color: "black", fontWeight: "bold"}}>Answer: {question.AnsweredValue}</h6>
                        }
                    </div>
                </div>

            </div>
            {question.TaskActionText != null && question.TaskActionText.length > 0 &&
                <div>
                    <h6>Generated task:</h6>
                    <div>
                        <p style={{ marginBottom: 0 }}>Action: {question.TaskActionText}</p>
                        <p style={{ marginBottom: 0 }}>Status: {question.TaskStatus}</p>
                        <p style={{ marginBottom: 0 }}>Assigned to: {question.AssignedTo}</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default QuestionCard;