import React, { useContext } from "react";
import PropTypes from "prop-types";
import { formatDate } from "utils/dates";
import { PureModal, Rows, InlineWaiting, IconButton } from "components/ui";
import { MdComment, MdAdd, MdLink, MdEdit } from "react-icons/lib/md";
import TaskForm from "features/tasks/task-form";
import AddTaskNoteForm from "./add-task-note";
import PatientTaskFilters from "./task-filters";
import useLocalQuery from "hooks/useQuery/local-query";
import useTasks from "features/tasks/use-tasks";
import { PatientProfileContext } from "components/profile/context";
import { Link } from "react-router-dom";
import AudioPlayer from "components/ui/audio-player";

const PatientTasks = ({ patientId }) => {
  const {
    tasks,
    createTask,
    createTaskNote,
    getPatientTasks,
    updateTask,
    isFetchingTasks
  } = useTasks(patientId);
  const { accessible_users } = useContext(PatientProfileContext);
  const { rows, query, queryDispatcher } = useLocalQuery(tasks, {
    filters: { type: "Patient" },
    sort: { by: "created_at", direction: "desc" }
  });
  React.useEffect(() => {
    getPatientTasks(patientId);
  }, [patientId]);

  return (
    <div className="profile-tasks">
      <div style={{ display: "grid", gridTemplateColumns: "200px 1fr" }}>
        <PureModal
          renderTrigger={({ openModal }) => (
            <div
              style={{
                display: "flex",
                padding: 5,
                flex: 1,
                alignItems: "center"
              }}
            >
              <IconButton
                onClick={openModal}
                icon={<MdAdd />}
                text="Create Task"
                style={{
                  margin: 0,
                  width: "100%",
                  padding: "10px 10px",
                  color: "#085b55"
                }}
              />
            </div>
          )}
          renderContent={({ closeModal }) => (
            <TaskForm
              accessible_users={accessible_users}
              patientId={patientId}
              handleSubmit={async values => {
                await createTask(values);
                closeModal();
              }}
            />
          )}
        />
        <PatientTaskFilters query={query} queryDispatcher={queryDispatcher} />
      </div>
      {isFetchingTasks && (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            padding: 30
          }}
        >
          <InlineWaiting reason="Fetching Patient Tasks" />
        </div>
      )}
      {rows?.map(task => (
        <div
          key={task.task_id}
          style={{ border: "1px solid #ccc", padding: 5 }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto auto",
              flex: 1,
              justifyContent: "space-between",
              fontSize: 18
            }}
          >
            <div style={{ marginBottom: 5 }}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 100px",
                  alignItems: "center"
                }}
              >
                <h4 style={{ margin: 3 }}>{task.action_text}</h4>
                {task.type == "Order" && (
                  <div>
                    <Link to={`/orders/${task.order_id}`}>
                      <MdLink />
                      Order
                    </Link>
                  </div>
                )}
                {task.signed_recording_url && (
                  <AudioPlayer
                    preloadAudio={"none"}
                    src={task.signed_recording_url}
                    showAllFeatures={false}
                  />
                )}
              </div>
              <div
                style={{
                  display: "flex"
                }}
              >
                <h5 style={{ margin: 0 }}>{task.text}</h5>
              </div>
            </div>
            <PureModal
              renderTrigger={({ openModal }) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flex: 1,
                    justifyContent: "flex-end"
                  }}
                >
                  <IconButton
                    onClick={openModal}
                    text="Edit"
                    icon={<MdEdit />}
                    style={{ padding: 3, marginRight: 5 }}
                    type="button"
                  />
                </div>
              )}
              renderContent={({ closeModal }) => (
                <TaskForm
                  accessible_users={accessible_users}
                  handleSubmit={async values => {
                    await updateTask({ ...values, patientId });
                    closeModal();
                  }}
                  patientId={patientId}
                  task={task}
                />
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between"
            }}
          >
            <div>
              <label>
                Status:
                <span style={{ fontWeight: "bold", paddingLeft: 5 }}>
                  {task.status}
                </span>
              </label>
              <label>
                Owner:
                <span style={{ fontWeight: "bold", paddingLeft: 5 }}>
                  {task.owner}
                </span>
              </label>
              <label>
                Assigned To:
                <span style={{ fontWeight: "bold", paddingLeft: 5 }}>
                  {task.assigned_to_name}
                </span>
              </label>
            </div>
            <div>
              <label>Created At: {formatDate(task.created_at)}</label>
              <label>Created By: {task.created_by_name}</label>

              <label>
                Created by form:
                <span style={{ fontWeight: "bold", paddingLeft: 5 }}>
                  {task.CreatedByForm ? "Yes" : "No"}
                </span>
              </label>
              {task.CreatedByForm && (
                <>
                  <label>
                    Created by question:
                    <span style={{ fontWeight: "bold", paddingLeft: 5 }}>
                      {task.CreatedByQuestion}
                    </span>
                  </label>
                  <label>
                    Created by answer:
                    <span style={{ fontWeight: "bold", paddingLeft: 5 }}>
                      {task.CreatedByAnswer}
                    </span>
                  </label>
                </>
              )}
            </div>
          </div>
          <div>
            <Rows>
              {task.notes?.map(({ id, text, created_by_name, created_at }) => (
                <div
                  className="worklist-item note"
                  key={id}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr auto",
                    alignItems: "center",
                    gridGap: "10px",
                    fontSize: "1.15em"
                  }}
                >
                  <span
                    className="order-note"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto 1fr",
                      alignItems: "center",
                      gridGap: "10px",
                      padding: "0 0px",
                      margin: "0 5px",
                      color: "#444"
                    }}
                  >
                    <MdComment />
                    <span
                      className="note-content"
                      style={{
                        padding: "0 7px",
                        marginRight: "10px",
                        color: "black"
                      }}
                    >
                      {text}
                    </span>
                  </span>
                  <div
                    className="worklist-item-meta"
                    style={{
                      flex: "1 1 auto",
                      fontSize: "0.8em",
                      textAlign: "right",
                      marginLeft: "10px",
                      marginRight: "10px"
                    }}
                  >
                    <div className="creator">{created_by_name}</div>
                    <div>{formatDate(created_at, "MMMM Do YYYY, h:mm a")}</div>
                  </div>
                </div>
              ))}
            </Rows>
            <AddTaskNoteForm
              task={task}
              patientId={patientId}
              createTaskNote={async values => {
                await createTaskNote(values);
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

PatientTasks.propTypes = { patientId: PropTypes.string.isRequired };

export default PatientTasks;
