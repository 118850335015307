export const IMPORT_TRACKING_REQUEST = 'IMPORT_TRACKING_REQUEST'

export const requestImportTracking = trackingData => ({
  type: IMPORT_TRACKING_REQUEST,
  payload: { trackingData }
})

export const IMPORT_TRACKING_RESPONSE = 'IMPORT_TRACKING_RESPONSE'

export const responseImportTracking = response => ({
  type: IMPORT_TRACKING_RESPONSE,
  payload: { response }
})

export const errorImportTracking = payload => ({
  type: IMPORT_TRACKING_RESPONSE,
  error: true,
  payload
})
