import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useOutsideClick } from "hooks";
import MdArrowDropUp from "react-icons/lib/md/arrow-drop-up";
import MdArrowDropDown from "react-icons/lib/md/arrow-drop-down";
import MdTrash from "react-icons/lib/md/delete-sweep";
import SelectedIcon from "react-icons/lib/io/ios-checkmark-outline";
import MdRemove from "react-icons/lib/md/close";

const MultiSelectDropdown = ({
  options,
  values,
  searchable,
  onChange,
  onClear,
  placeHolder,
  renderValues,
  shiftSelect,
  onRemove,
  onBlur,
  dropdownStyle
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [headerTooltip, setHeaderTooltip] = useState("");
  const [filterText, setFilterText] = useState("");
  React.useEffect(() => {
    setHeaderTooltip(`${values.length} Selected`);
  }, [values]);
  function clearSelected(e) {
    e.stopPropagation();
    if (onClear) onClear();
    onChange([]);
  }
  function filterByText(item) {
    if (filterText.length > 0 && typeof item.text === "string") {
      return item.text.toLowerCase().includes(filterText.toLowerCase());
    }
    return true;
  }
  function toggleSelected(value) {
    if (!values.includes(value)) {
      onChange([...values, value]);
    } else {
      onChange(values.filter(v => v !== value));
    }
  }
  const dropDownRef = useRef();
  useOutsideClick(dropDownRef, () => {
    if (isOpen) {
      setIsOpen(false);
      if (typeof onBlur === "function") onBlur();
    }
  });
  return (
    <div className="multi-select-dropDown-container" ref={dropDownRef}>
      <div
        className="select-dropDown-header"
        onClick={() => {
          if (!isOpen) setIsOpen(true);
        }}
      >
        {isOpen && searchable ? (
          <input
            className="select-dropDown-header-title"
            type="search"
            onClick={e => e.stopPropagation()}
            value={filterText}
            onChange={({ target }) => setFilterText(target.value)}
          />
        ) : (
          <React.Fragment>
            <div className="select-dropDown-header-title" title={headerTooltip}>
              {renderValues ? (
                <InputTitle
                  selected={options.filter(({ value }) =>
                    values.includes(value)
                  )}
                  toggleSelected={toggleSelected}
                  placeHolder={placeHolder}
                  onRemove={onRemove}
                />
              ) : (
                headerTooltip
              )}
            </div>
          </React.Fragment>
        )}
        {values.length > 0 ? (
          <MdTrash
            color="red"
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={clearSelected}
          />
        ) : isOpen ? (
          <MdArrowDropUp />
        ) : (
          <MdArrowDropDown />
        )}
      </div>
      {isOpen && (
        <ul className="select-dropDown-list" style={dropdownStyle} onClick={e => e.stopPropagation()}>
          {options.filter(filterByText).map(({ value, text }, index) => {
            const isSelected = values.includes(value);
            return (
              <li
                className={`select-dropDown-list-item ${
                  isSelected ? "active" : ""
                }`}
                key={value}
                onClick={e => {
                  if (shiftSelect && e.shiftKey) {
                    onChange([
                      ...options
                        .filter(filterByText)
                        .slice(0, index + 1)
                        .map(({ value }) => value)
                    ]);
                  } else toggleSelected(value);
                }}
              >
                {text}{" "}
                {isSelected && <SelectedIcon style={{ float: "right" }} />}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const InputTitle = ({ selected, toggleSelected, placeHolder, onRemove }) => {
  if (selected.length <= 0 && placeHolder) return placeHolder;
  else
    return selected.map(s => (
      <span
        key={s.value}
        style={{
          display: "inline-flex",
          alignItems: "center",
          flexWrap: "wrap"
        }}
      >
        {s.text}
        <i
          onClick={e => {
            e.stopPropagation();
            toggleSelected(s.value);
            if (typeof onRemove === "function") onRemove(s);
          }}
          className="flat-icon-button"
          style={{ fontSize: ".75rem", padding: "0 2px" }}
        >
          <MdRemove color="red" />
        </i>
      </span>
    ));
};

export default MultiSelectDropdown;

MultiSelectDropdown.defaultProps = {
  placeHolder: "",
  values: [],
  searchable: true,
  renderValues: true,
  dropdownStyle: {}
};

MultiSelectDropdown.propTypes = {
  shiftSelect: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  searchable: PropTypes.bool,
  placeHolder: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.any
    })
  ).isRequired,
  onBlur: PropTypes.func,
  onClear: PropTypes.func,
  renderValues: PropTypes.bool,
  onRemove: PropTypes.func,
  dropdownStyle: PropTypes.object
};
