import React, { useEffect } from 'react'
import { useState } from 'react'
import SectionHeader from 'components/profile/section_header.jsx';
import '../pages/companyForms.css'

export default function ModalLogicalQuestion({handleCancel, saveConditions, showTaskActions, taskActions, toEditQuestion, questionData}) {

    const [condition, setCondition] = useState(
        {
            Order: 0,
            Value: 1,
            TaskActionId: '0',
        }
    );

    const handleChangeTask = (e) => {

        setCondition({ ...condition, [e.target.name]: e.target.value });
    }

    const handleChangeLogica = (e) => {

        setCondition({ ...condition, Value: e.target.value === 'true' ? 1 : 0, Order: 1 });
    }


    const [newQuestionText, setNewQuestionText] = useState(toEditQuestion ? {newText: questionData.Text, newSpanishText: questionData.SpanishText} : {newText: "", newSpanishText: ""})

    const [newRequiredAndNAStatus, setNewRequiredAndNAStatus] = useState(toEditQuestion ? {IsRequired: questionData.IsRequired, HasNAOption: questionData.HasNAOption} : {IsRequired: true, HasNAOption: false})

    useEffect(() => {

        toEditQuestion && questionData ? setCondition((condition) => {return{...condition, Order: questionData.Conditions[0].Order, Value: questionData.Conditions[0].Value, TaskActionId: questionData.Conditions[0].TaskActionId}}) : null
     
    }, [taskActions])


    const handleChangeNewText = (e)=>{

        const option= e.target.value;
        const name= e.target.name;

        setNewQuestionText({...newQuestionText, [name]: option});
    }

    const handleChangeRequiredAndNA = (e)=>{

        const name = e.target.name;
        if(name == "IsRequired"){
            setNewRequiredAndNAStatus({ ...newRequiredAndNAStatus, ["IsRequired"]: !newRequiredAndNAStatus.IsRequired });
        } else if(name == "HasNAOption") {
            setNewRequiredAndNAStatus({ ...newRequiredAndNAStatus, ["HasNAOption"]: !newRequiredAndNAStatus.HasNAOption });
        }
    }

    return (
        <div className='patient-types-statuses-container'>
            <div className="section-title">
                <div className="dd_col-12">
                    <SectionHeader>LOGICAL QUESTION</SectionHeader>
                </div>
                {
                    toEditQuestion &&
                    <div>
                        <div className="dd_col-6">
                            <label>Question Text</label>
                            <input 
                                name="newText"                          
                                type="text"
                                value={newQuestionText.newText}
                                onChange={handleChangeNewText}  
                                disabled={questionData.AlreadyExists ? questionData.AlreadyExists : false}
                            />
                        </div>
                        <div className="dd_col-6">  
                            <label>Question Spanish Text</label>                    
                            <input 
                                name="newSpanishText"                          
                                type="text"
                                value={newQuestionText.newSpanishText}
                                onChange={handleChangeNewText}  
                                disabled={questionData.AlreadyExists ? questionData.AlreadyExists : false}
                            />
                        </div>
                    </div>
                }
                {
                    questionData?.AlreadyExists &&
                    <div className="dd_col-12">   
                        <span style={{color:'red'}}>Cannot change text of an already existing question</span>
                    </div> 
                }
                <div>
                    <br></br>
                    <div className="dd_col-2">
                        <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <input type="radio" name="Yes" value={true} onChange={handleChangeLogica} checked={condition.Value === 1} />
                        <label>Yes</label>
                        <input type="radio" name="No" value={false} onChange={handleChangeLogica} checked={condition.Value === 0} />
                        <label>No</label>
                    </div>
                    <div className="dd_col-3">

                        <label>Task Action</label>
                        <select name="TaskActionId" value={condition.TaskActionId} onChange={handleChangeTask}>
                            <option value='0'></option>
                            {showTaskActions()}
                        </select>
                    </div>
                </div>
                <div className="dd_col-12">
                    <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <input type="checkbox" value={newRequiredAndNAStatus.IsRequired} name="IsRequired" onChange={handleChangeRequiredAndNA} checked={newRequiredAndNAStatus.IsRequired} />
                    <label>Required</label>     
                    <input type="checkbox" value={newRequiredAndNAStatus.HasNAOption} name="HasNAOption" onChange={handleChangeRequiredAndNA} checked={newRequiredAndNAStatus.HasNAOption} />
                    <label>Add N/A</label>
                </div>
                <div className='dd_col-12'>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <button
                            type="button"
                            className="button"
                            disabled={!newQuestionText.newText.length > 0 || !newQuestionText.newSpanishText.length > 0}
                            onClick={()=>{
                                saveConditions([],[condition], questionData, toEditQuestion, newQuestionText, newRequiredAndNAStatus)
                                handleCancel()
                            }}
                        >
                            Save
                        </button>
                    </div>    
                </div>
            </div>
        </div>
    )
}