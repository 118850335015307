import React from "react";
import { BonafidePatientContext } from "features/bonafide/use-bonafide";
import MdErrorOutline from "react-icons/lib/md/error-outline";
import { InlineWaiting } from "components/ui";

const PatientGuarantor = () => {
  const { insurances, errors, isFetching } = React.useContext(
    BonafidePatientContext
  );
  return (
    <div style={{ padding: 10 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <h4>Patient Gaurantor on File(Bonafide)</h4>
        {errors.insurances && (
          <div style={{ color: "red" }}>
            <MdErrorOutline />
            <span style={{ paddingLeft: 10 }}>{errors.insurances}</span>
          </div>
        )}
        {isFetching && <InlineWaiting />}
      </div>
      <table
        style={{
          width: "100%"
        }}
      >
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Insurance</th>
            <th>Type</th>
            <th>Member #</th>
          </tr>
        </thead>
        <tbody>
          {insurances.map(ins => (
            <tr key={ins.memberno}>
              <td>{ins.firstname}</td>
              <td>{ins.lastname}</td>
              <td>{ins.insurance}</td>
              <td>{ins.insorder}</td>
              <td>{ins.memberno}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PatientGuarantor;
