import React from "react";
import { InlineWaiting } from "components/ui";
import PatientSearchForm from "components/patient-search-form";
import TimeSlotSearch from "./time-slot-search";
import { Formik } from "formik";
import { find, propOr } from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "reducers";
import { useFetch } from "hooks";
import { get, post } from "utils/api";
import { message as notification, errorMessage } from "actions/message";
import * as Yup from "yup";

const AssignChats = () => {
  const dispatch = useDispatch();
  const {
    response: topic_categories,
    fetchData,
    isFetchingTopicCategories
  } = useFetch({
    apiFn: () => get("dme_portal/appointment_topic_categories"),
    defaultValue: [],
    transformResponse: propOr([], "topic_categories")
  });

  const { fetchData: assignPatientToChat } = useFetch({
    apiFn: values => post("dme_portal/schedule_chat", values),
    onSuccess: ({ success, message }) => {
      if (success) {
        dispatch(notification(message));
      } else {
        dispatch(errorMessage(message));
      }
    },
    onError: err => {
      dispatch(errorMessage(err?.response?.body?.message));
    }
  });
  const inventoryOptions = useSelector(selectors.getInventoryItemOptions);

  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <div
      className="assign-chats-container"
      style={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr",
        flexDirection: "column"
      }}
    >
      <Formik
        initialValues={{
          chat_type: "",
          description: "",
          slot_id: "",
          topic_category_id: topic_categories[0]?.topic_id ?? "", //nth(0, topic_categories)?.topic_id ?? "",
          patient_id: "",
          product_id: ""
        }}
        onSubmit={async (values, { resetForm }) => {
          await assignPatientToChat(values);
          resetForm();
        }}
        validationSchema={Yup.object().shape({
          patient_id: Yup.string().required("Please select a patient"),
          slot_id: Yup.string().required("Please select a time slot")
        })}
        enableReinitialize={true}
      >
        {({
          values,
          setFieldValue,
          setValues,
          handleChange,
          handleSubmit,
          isValid,
          errors,
          isSubmitting
        }) => (
          <>
            <div style={{ padding: 10 }}>
              <PatientSearchForm
                selectedPatients={values.patient_id}
                setSelectedPatients={patient_id => {
                  setFieldValue("patient_id", patient_id);
                }}
                feature="scheduleChat"
                multiple={false}
              />
            </div>
            <div style={{ padding: 10 }}>
              <h4>Select Time Slot</h4>
              {isSubmitting ? (
                <InlineWaiting />
              ) : (
                <TimeSlotSearch
                  values={values}
                  setFieldValue={setFieldValue}
                  setValues={setValues}
                />
              )}
            </div>
            <div
              style={{
                gridColumn: "1 / 3",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                padding: 10,
                flex: 1
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  padding: 5,
                  flexDirection: "column"
                }}
              >
                {isFetchingTopicCategories ? (
                  <InlineWaiting />
                ) : (
                  <>
                    <div className="form-field">
                      <label className="form-field-header">
                        Appointment Topic
                      </label>
                      <select
                        className="input-field"
                        value={values.topic_category_id}
                        onChange={({ target }) => {
                          setFieldValue("topic_category_id", target.value);
                          if (
                            !find(
                              ({ topic_id }) =>
                                topic_id === values.topic_category_id,
                              topic_categories
                            )?.is_product_topic
                          ) {
                            setFieldValue("product_id", "");
                          }
                        }}
                      >
                        {topic_categories.map(({ topic_id, topic_name }) => (
                          <option value={topic_id} key={topic_id}>
                            {topic_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {find(
                      ({ topic_id }) => topic_id === values.topic_category_id,
                      topic_categories
                    )?.is_product_topic && (
                      <div className="form-field">
                        <label className="form-field-header">Product</label>
                        <select
                          className="input-field"
                          value={values.product_id}
                          onChange={handleChange("product_id")}
                        >
                          <option />
                          {inventoryOptions.map(({ text, value }) => (
                            <option key={value} value={value}>
                              {text}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </>
                )}
                <div className="form-field">
                  <label className="form-field-header">Description</label>
                  <textarea
                    className="input-field"
                    value={values.description}
                    onChange={handleChange("description")}
                  />
                </div>
              </div>
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  flex: 1
                }}
              >
                <div
                  style={{
                    minHeight: 75,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    fontSize: 16,
                    color: "red"
                  }}
                >
                  {Object.keys(errors).map(key => (
                    <div key={key}>{errors[key]}</div>
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  {isSubmitting ? (
                    <InlineWaiting />
                  ) : (
                    <button
                      style={{ margin: 0, width: 200 }}
                      type="submit"
                      disabled={!isValid}
                      onClick={handleSubmit}
                    >
                      Schedule Chat
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default AssignChats;
