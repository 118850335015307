import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getUpcomingChat } from "components/dd_newModules/Redux/Actions/schedule";
import moment from "moment";
import momentTz from "moment-timezone";
import { Link } from "react-router-dom";
import JoinChatButton from "./join-chat-btn";

const UpcomingChats = ({ getUpcomingChat, UpcomingChat }) => {
  React.useEffect(() => {
    getUpcomingChat();
  }, []);
  return (
    <div className="upcoming-chats">
      {UpcomingChat.length == 0 ? (
        <div className="no-upcoming-chats-msg">
          You Have No Upcoming Chats/Demos
        </div>
      ) : (
        <ul className="upcoming-chats-container">
          {UpcomingChat.map(data => {
            const {
              start_time,
              slot_id,
              end_time,
              description,
              is_product_demo,
              title
            } = data.chats[0].chat_details;
            const { master_chat_type } = data;
            const filteredTopics =
              data.chats[0] &&
              data.chats[0].topic_details &&
              data.chats[0].topic_details.length > 0 &&
              data.chats[0].topic_details.map(function (m) {
                if (m.topic_name) {
                  const topicName = m.topic_name;
                  return topicName;
                }
              });
            const patients = data.chats[0]?.patient_details;
            return (
              <li key={slot_id} className="chat-info">
                <div>
                  <div className="chat-header">
                    <h5>{master_chat_type}</h5>
                    <div style={{ paddingLeft: 10 }}>
                      {moment
                        .utc(
                          momentTz.tz(
                            start_time,
                            "YYYY-MM-DDThh:mm:ss",
                            "America/Chicago"
                          )
                        )
                        .local()
                        .format("M/D/YYYY")}{" "}
                      {moment
                        .utc(
                          momentTz.tz(
                            start_time,
                            "YYYY-MM-DDThh:mm:ss",
                            "America/Chicago"
                          )
                        )
                        .local()
                        .format("h:mm A")}{" "}
                      to{" "}
                      {moment
                        .utc(
                          momentTz.tz(
                            end_time,
                            "YYYY-MM-DDThh:mm:ss",
                            "America/Chicago"
                          )
                        )
                        .local()
                        .format("h:mm A")}
                    </div>
                  </div>
                  <div>
                    <b>Title:</b> {title}
                  </div>
                  <div>
                    <b>Description:</b> {description}
                  </div>
                  <div>
                    <b>Topic Details: </b>
                    <span>
                      {filteredTopics &&
                        filteredTopics.filter(Boolean).join(", ")}
                    </span>
                  </div>
                  <div>
                    <b>Patients:</b>
                    <div style={{ paddingLeft: 5 }}>
                      {patients &&
                        patients.map(({ patient_name, patient_id }) => (
                          <Link key={patient_id} to={`/patients/${patient_id}`}>
                            {patient_name}
                          </Link>
                        ))}
                    </div>
                  </div>
                </div>
                <JoinChatButton
                  is_product_demo={is_product_demo}
                  master_chat_type={master_chat_type}
                  start_time={start_time}
                  slot_id={slot_id}
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

UpcomingChats.propTypes = {
  getUpcomingChat: PropTypes.func.isRequired,
  UpcomingChat: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool
};

export default connect(
  state => ({
    UpcomingChat: state.getIn(["newRootReducer"]).rdc_schedule.UpcomingChat,
    isLoading: state.getIn(["newRootReducer"]).rdc_loader.isLoader
  }),
  { getUpcomingChat }
)(UpcomingChats);
