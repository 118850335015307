import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { clone } from "ramda";
import { selectors } from "reducers";
import ClickableTextInput from "components/clickable-text-input";
import SearchableDropdown from "../../searchable-dropdown";
import { MdDelete, MdMailOutline } from "react-icons/lib/md";
import MdClose from "react-icons/lib/md/close";
import FaWrench from "react-icons/lib/fa/wrench";
import FaPlusCircle from "react-icons/lib/fa/plus-circle";
import SupportAgentSvg from "./images/support-agent-svg";
import SmsSvg from "./images/sms-svg";
import MobileScreenShareSvg from "./images/mobile-screen-share-svg";
import ToggleSlider from "components/toggle-slider";
import { PureModal } from "components/ui";
import ConfirmationModal from "components/shared/confirmation-modal";

const ContactLogicTable = ({
  index,
  companyOptions,
  onCompanySelect,
  contactActionsOptions,
  formOfContactOptions,
  selectedFormOfContact,
  onFormOfContactSelect,
  contactLogicArray,
  onCycleChanged,
  onCycleAdded,
  onCycleDelete,
  onClose,
  onSave,
  patientTypeId,
  onFormCreation,
  onFormVisualization,
  companyHasForms
}) => {

  const tableRef = useRef(null);
  const [editable, setEditable] = useState(false);
  const [initialDays, setInitialDays] = useState(0);
  const contactLogic = contactLogicArray[index];


  useEffect(() => {
    if (!editable) {
      if (patientTypeId != 2 || !companyHasForms) {
        onSave(contactLogic?.companyId);
      }
      else {
        if (!onFormCreation && !onFormVisualization) {
          const currentDays = getTotalDays();
          if (currentDays != initialDays)
            document.getElementById("modalDiv").click();
          else
            onSave(contactLogic?.companyId);
          
          setInitialDays(0);
        }
      }
    }
  }, [editable]);

  useEffect(() => {
    const currentDays = getTotalDays();

    if (initialDays == 0 && currentDays != 0)
      setInitialDays(currentDays);

  }, [contactLogic])

  useEffect(() => {
    setInitialDays(0);
  }, [selectedFormOfContact])

  const updateCycleAction = (cycle, actionId, isActive) => {
    if (editable) {
      const cycleClone = clone(cycle);
      const actions = cycleClone.actions;
      actions.find(cc => cc.actionId == actionId).isActive = isActive;
      cycleClone.shouldUpdate = !cycleClone.shouldCreate;
      onCycleChanged(contactLogic?.companyId, cycleClone);
    }
  };

  const updateCycleDays = (cycle, daysToPushOutreachDate) => {
    const cycleClone = clone(cycle);
    cycleClone.daysToPushOutreachDate = daysToPushOutreachDate;
    cycleClone.shouldUpdate = !cycleClone.shouldCreate;
    onCycleChanged(contactLogic?.companyId, cycleClone);
  };

  const renderContactLogicCellData = (
    cycle,
    idx,
    fieldName,
    numeric = true
  ) => {
    const showExclamation = shouldShowExclamationPointForDays(cycle, idx);


    return (
      <div className="table-cell-content">
        <ClickableTextInput
          height="83%"
          numeric={numeric}
          value={cycle[fieldName].toString()}
          onEditingFinish={value => updateCycleDays(cycle, value)}
          clickable={editable}
        />
        {showExclamation && <div className="exclamation">!</div>}
      </div>
    );
  };

  const renderContactIcon = text => {
    if (["Manual Call", "Place Manual Call"].includes(text))
      return <SupportAgentSvg size="2em" />;
    if (["Email", "Send Resupply Email", "Send Form Pending Answer Reminder Email"].includes(text))
      return <MdMailOutline size="2em" />;
    if (["Text Message", "Send Resupply SMS", "Send Form Pending Answer Reminder SMS"].includes(text))
      return <SmsSvg size="2em" />;
    if (["Push Notification", "Send Resupply Push Notification", "Send Form Pending Answer Reminder Push Notification"].includes(text))
      return <MobileScreenShareSvg size="2em" />;
    if (["IVR", "Place IVR Call"].includes(text)) return "IVR";
    return null;
  };

  const renderContactActionButtonsInline = (cycle, idx, patientTypeIdentifier) => {

    if ((onFormCreation !== undefined && onFormCreation) || (onFormVisualization !== undefined && onFormVisualization))
      return renderComplianceContactActionButtonsInline(cycle, idx);
    else {
      switch (patientTypeIdentifier) {
        case 1:
          return renderResupplyContactActionButtonsInline(cycle, idx);
        case 2:
          return renderComplianceContactActionButtonsInline(cycle, idx);
        case 3:
          return renderResupplyContactActionButtonsInline(cycle, idx);
      }
    }
  }

  const renderResupplyContactActionButtonsInline = (cycle, idx) => {
    return (
      <div className="table-cell">

        {renderContactActionButtonInline(cycle, idx, "Place Manual Call")}
        {renderContactActionButtonInline(cycle, idx, "Send Resupply Email")}
        {renderContactActionButtonInline(cycle, idx, "Send Resupply SMS")}
        {renderContactActionButtonInline(
          cycle,
          idx,
          "Send Resupply Push Notification"
        )}
        {renderContactActionButtonInline(cycle, idx, "Place IVR Call")}

      </div>
    );
  }

  const renderComplianceContactActionButtonsInline = (cycle, idx) => {
    return (
      <div className="table-cell">

        {renderContactActionButtonInline(cycle, idx, "Place Manual Call")}
        {renderContactActionButtonInline(cycle, idx, "Send Form Pending Answer Reminder Email")}
        {renderContactActionButtonInline(cycle, idx, "Send Form Pending Answer Reminder SMS")}
        {renderContactActionButtonInline(
          cycle,
          idx,
          "Send Form Pending Answer Reminder Push Notification"
        )}
        {renderContactActionButtonInline(cycle, idx, "Place IVR Call")}

      </div>
    )
  }

  const renderContactActionButtonInline = (cycle, idx, contactAction) => {
    const actionId = contactActionsOptions.find(x => x.text == contactAction).key;

    const action = cycle.actions.find(x => x.actionId == actionId);

    const active = action ? action.isActive : false;

    const className = `contact-action-button-inline ${active ? "active" : ""} ${editable ? "hoverable-color" : ""}`;

    const showExclamation = shouldShowExclamationPoint(cycle, idx, actionId);

    const style = { cursor: editable ? "pointer" : "default" };

    return (
      <button
        type="button"
        title={contactAction}
        onClick={() => updateCycleAction(cycle, actionId, !active)}
        className={className}
        style={style}
      >
        {renderContactIcon(contactAction)}
        {showExclamation && <div className="exclamation">!</div>}
      </button>
    );
  };

  const shouldShowExclamationPoint = (cycle, idx, actionId) => {
    const leftMostActions = contactLogicArray[0].cycles[idx]?.actions
      .filter(x => x.isActive)
      .map(x => x.actionId);
    const currentActions = cycle.actions
      .filter(x => x.isActive)
      .map(x => x.actionId);

    return (
      leftMostActions.includes(actionId) !== currentActions.includes(actionId)
    );
  };

  const shouldShowExclamationPointForDays = (cycle, idx) => {
    const leftMostDays =
      contactLogicArray[0].cycles[idx]?.daysToPushOutreachDate;
    const currentDays = cycle.daysToPushOutreachDate;

    return leftMostDays !== currentDays;
  };

  const renderCycles = () => {
    let contactLogic = contactLogicArray[index];
    return contactLogic.cycles.map((cycle, idx) => (
      <React.Fragment key={cycle.id}>
        <div className="table-cell">
          <div className="table-uneditable">{cycle.cycleCount}</div>
        </div>
        <div className="table-cell">
          {renderContactLogicCellData(cycle, idx, "daysToPushOutreachDate")}
        </div>
        {renderContactActionButtonsInline(cycle, idx, cycle.PatientTypeId)}
      </React.Fragment>
    ));
  };

  const renderFormOfContactButton = formOfContact => {
    const value =
      formOfContactOptions.find(x => x.text == formOfContact)?.key ?? "";
    const isActive = selectedFormOfContact === value;
    const className = `form-of-contact-button ${isActive ? "active" : ""}`;

    return (
      <button
        type="button"
        title={formOfContact}
        onClick={() => onFormOfContactSelect(value)}
        className={className}
      >
        {renderContactIcon(formOfContact)}
      </button>
    );
  };

  const renderFormOfContactButtonList = () => {
    return (
      <div className={"form-of-contact-button-container-for-modal"}>
        {renderFormOfContactButton("Manual Call")}
        {renderFormOfContactButton("Email")}
        {renderFormOfContactButton("Text Message")}
        {renderFormOfContactButton("Push Notification")}
        {renderFormOfContactButton("IVR")}
      </div>
    );
  };

  const getTotalDays = () => {
    let contactLogic = contactLogicArray[index];
    return contactLogic.cycles.reduce(
      (acc, cur) => acc + cur.daysToPushOutreachDate,
      0
    );
  };

  const createNewCycle = () => {
    if (editable && ((onFormCreation != undefined && onFormCreation) || patientTypeId == 2) && contactLogic?.companyId) {
      let newCycle = {
        "id": getTempContactLogicId().toString(),
        "companyId": contactLogic.companyId,
        "actionId": selectedFormOfContact,
        "cycleCount": getCurrentCycleCount() + 1,
        "daysToPushOutreachDate": 1,
        "actions": getComplianceContactActions(),
        "PatientTypeId": patientTypeId,
        "shouldCreate": true,
        "shouldDelete": false,
        "shouldUpdate": false
      };

      onCycleAdded(contactLogic.companyId, newCycle);
    }
  }

  const deleteLastCycle = () => {
    let lastCycle = contactLogicArray[index]
      .cycles
      .reduce((a, b) => a.cycleCount > b.cycleCount ? a : b);

    lastCycle.shouldDelete = true;
    onCycleDelete(contactLogic.companyId, lastCycle);
  }

  const getCurrentCycleCount = () => {
    let currentCycleCount = 0;

    if (contactLogicArray &&
      contactLogicArray[index] &&
      contactLogicArray[index].cycles &&
      contactLogicArray[index].cycles.length > 0) {
      currentCycleCount = contactLogicArray[index]
        .cycles
        .reduce((a, b) => a.cycleCount > b.cycleCount ? a : b)
        .cycleCount;
    }

    return currentCycleCount;
  }

  const getComplianceContactActions = () => {
    return ["Place Manual Call",
      "Send Form Pending Answer Reminder Email",
      "Send Form Pending Answer Reminder SMS",
      "Send Form Pending Answer Reminder Push Notification",
      "Place IVR Call"].map((actionText) => {
        let actionId = contactActionsOptions.find(cao => cao.text == actionText).key;
        return {
          "isActive": false,
          "actionId": actionId
        }
      })
  }

  const getTempContactLogicId = (contactLogicArray) => {
    let tempId = getRandomInt();

    while (contactLogicArray &&
      contactLogicArray[index] &&
      contactLogicArray[index].cycles &&
      contactLogicArray[index].cycles.some(c => c.id == tempId)) {
      tempId = getRandomInt();
    }
    return tempId;
  }

  const getRandomInt = () => {
    return Math.floor(Math.random() * 2147483647);
  }

  return (
    <div ref={tableRef}>
      {
        ((onFormCreation === undefined || !onFormCreation) && (onFormVisualization === undefined || !onFormVisualization)) &&
        <div className="contact-logic-search-container" >
          <div className="close-button">
            <MdClose color="salmon" size={30} onClick={onClose} />
          </div>
          <SearchableDropdown
            options={companyOptions}
            placeholder="Company..."
            selected={contactLogic?.companyId ?? ""}
            onSelect={value => onCompanySelect(value)}
            fontPercent="100%"
          />
        </div>
      }

      <div className="contact-logic-table-container">
        <div>{renderFormOfContactButtonList()}</div>
        <div>
          <div className="contact-logic-table-header-container-for-modal">
            <div className="table-header">Cycle</div>
            <div className="table-header">Days</div>
            <div className="table-header">Actions</div>
          </div>
          <div className="contact-logic-table">
            {renderCycles()}
            <div className="table-info-container-for-modal">
              <div className="table-info">Days</div>
              <div className="table-info">{getTotalDays()}</div>
              {
                (onFormVisualization === undefined || !onFormVisualization) &&

                <div className="table-info-toggle" style={patientTypeId == 2 && editable && contactLogic?.companyId ? { padding: "6px 1px" } : {}}>
                  {editable &&
                    ((onFormCreation !== undefined && onFormCreation) || (patientTypeId == 2 && contactLogic?.companyId)) &&
                    <FaPlusCircle onClick={createNewCycle}
                      style={{ fill: "green", border: "green" }} />
                  }
                  {editable &&
                    ((onFormCreation !== undefined && onFormCreation) || (patientTypeId == 2 && contactLogic?.companyId)) &&
                    contactLogic?.cycles?.length > 0 &&
                    <MdDelete onClick={deleteLastCycle}
                      style={{ fill: "red", border: "red" }} />
                  }
                  <FaWrench size={15} />
                  <ToggleSlider
                    width="50px"
                    value={editable}
                    onChange={setEditable}
                  />
                </div>
              }
              {
                (onFormVisualization !== undefined || onFormVisualization) && <div className="table-info-toggle"></div>
              }
            </div>
          </div>
        </div>
      </div>

      {
        patientTypeId == 2 &&
        <PureModal
          renderTrigger={({ openModal }) => <div onClick={() => openModal()} id="modalDiv"></div>}
          renderContent={({ closeModal }) => <ConfirmationModal title="If you are changing the days of the cycles, remember to change the available days of the compliance forms." closeModal={closeModal} yesAction={() => onSave(contactLogic?.companyId)} notNowAction={() => { }} />}
        >
        </PureModal>
      }
    </div>
  );
};

ContactLogicTable.propTypes = {
  index: PropTypes.number.isRequired,
  companyOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCompanySelect: PropTypes.func.isRequired,
  contactActionsOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  formOfContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedFormOfContact: PropTypes.string.isRequired,
  onFormOfContactSelect: PropTypes.func.isRequired,
  contactLogicArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func,
  onCycleChanged: PropTypes.func,
  onSave: PropTypes.func,
  onFormCreation: PropTypes.bool
};

ContactLogicTable.defaultProps = {
  showDiff: false,
  selectedFormOfContact: ""
};

export default connect(state => ({
  contactActionsOptions: selectors.getContactActionsOptions(state)
}))(ContactLogicTable);
