import React from "react";
import PureModal from "components/ui/modal/pure-modal";
import MdHelp from "react-icons/lib/md/help";
import { IconButton } from "components/ui";

const CalanderHelpMenu = () => (
  <PureModal
    renderTrigger={({ openModal }) => (
      <IconButton
        icon={<MdHelp />}
        onClick={openModal}
        style={{
          fontSize: 16,
          background: "none",
          width: 30,
          justifySelf: "flex-end",
          border: "none",
          boxShadow: "none"
        }}
      />
    )}
    renderContent={() => (
      <div style={{ padding: 20 }}>
        Drag And and release mouse over the desired time range, select type of
        outreach to schedule time slot for and any other desired options, hit
        the save button to save new time slot
      </div>
    )}
  />
);

export default CalanderHelpMenu;
