import React from "react";
import { connect } from "react-redux";
import { selectors } from "reducers";
import PropTypes from "prop-types";
import { getInitials } from "utils/misc";
import { requestUpdateOrderClaimedBy } from "actions/order-status";
import MdClose from "react-icons/lib/md/close";

const ClaimOrder = ({
  claimed_by,
  id,
  userId,
  userRole,
  canClaim,
  requestUpdateOrderClaimedBy
}) => {
  const isClaimed = claimed_by && claimed_by.value !== " ";
  const canClaimThis = canClaim && userId !== (claimed_by && claimed_by.key);
  const canUnclaim =
    userRole === "CompanyAdministrator" || userRole === "Administrator";
  return (
    <span
      className={`${isClaimed && "claimed-by"} ${canClaimThis && "claim"}`}
      onClick={
        canClaimThis ? () => requestUpdateOrderClaimedBy(id, userId) : undefined
      }
      title={`${isClaimed && canClaimThis ? "Claim Order From" : ""} ${
        isClaimed ? claimed_by.value : ""
      }`}
    >
      {isClaimed && canUnclaim && (
        <span
          style={{ paddingRight: 5, cursor: "pointer" }}
          title="Unclaim order"
          onClick={e => {
            e.stopPropagation();
            requestUpdateOrderClaimedBy(id, null);
          }}
        >
          <MdClose color="red" />
        </span>
      )}
      {isClaimed ? getInitials(claimed_by.value) : canClaimThis ? "claim" : ""}
    </span>
  );
};

ClaimOrder.propTypes = {
  canClaim: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  requestUpdateOrderClaimedBy: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  claimed_by: PropTypes.object
};

export default connect(
  state => ({
    canClaim: selectors.getCanCurrentUserClaimOrder(state),
    userId: selectors.getUserId(state),
    userRole: selectors.getUserRole(state)
  }),
  {
    requestUpdateOrderClaimedBy
  }
)(ClaimOrder);
