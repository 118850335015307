import React from "react";
import PropTypes from "prop-types";
import { formatTimeFromTimeSlot, getGridRowSpan } from "./scheduler-helpers";

const TempScheduledSlot = React.memo(({ col, row1, row2, setEditSlot }) => {
  const [mouseDown, setMouseDown] = React.useState(false);
  return (
    <div
      className="scheduled-slot temp"
      style={{
        gridColumn: `${col + 1} / span 1`,
        gridRow: `${row1 + 1} / span ${getGridRowSpan(row1, row2)}`
      }}
      onMouseUp={() => {
        if (mouseDown) {
          setEditSlot();
          setMouseDown(false);
        }
      }}
      onMouseDown={e => {
        e.stopPropagation();
        setMouseDown(true);
      }}
    >
      <div>
        {formatTimeFromTimeSlot(row1)}-{formatTimeFromTimeSlot(row2)}
      </div>
    </div>
  );
});

TempScheduledSlot.propTypes = {
  col: PropTypes.number.isRequired,
  row1: PropTypes.number.isRequired,
  row2: PropTypes.number.isRequired,
  setEditSlot: PropTypes.func
};

export default TempScheduledSlot;
