import React from "react";
import { MdError } from "react-icons/lib/md";
import moment from "moment";

export default function ModalAskForFormsRescheduling({ data, closeModal, updatePatient }) {
    const { OutreachDate, Name } = data;

    return <div>
        <span style={{ fontSize: '20px' }}> <MdError size="3rem" style={{ color: 'orange' }} />
            Do you want to change compliance form {Name} to start on: {moment(OutreachDate).format('MM/DD/yyyy')}?       
        </span>

        <div className='dd_col-12'>
            <div className='dd_col-6'>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <button type="button" className="button alert" onClick={() => { closeModal(); updatePatient(false) }}> Not Now </button>
                </div>
            </div>
            <div className='dd_col-6'>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <button type="button" className="button" onClick={() => { closeModal(); updatePatient(true) }}> Yes </button>
                </div>

            </div>
        </div>
    </div>
}