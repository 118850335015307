import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";

const ScDashboardTableSelect = ({ setPatientsToView, patientsToView }) => {
  return (
    <div className="sc-table-select-container">
      <div className="sc-table-radio-group">
        {/* Compliance ACtivity Center temporarily hidden, according to S3 request */}
        {/*<label>
          <span
            className={patientsToView === "eligible_patients" ? "selected" : ""}
          >
            Compliance Activity Center
          </span>
          <input
            type="radio"
            value="compliance_activity_center"
            checked={patientsToView === "compliance_activity_center"}
            onChange={({ target }) => setPatientsToView(target.value)}
          />
        </label>*/}
        <label>
          <span
            className={patientsToView === "legacy_autodialer" ? "selected" : ""}
          >
            Legacy Autodialer
          </span>
          <input
            type="radio"
            value="legacy_autodialer"
            checked={patientsToView === "legacy_autodialer"}
            onChange={({ target }) => setPatientsToView(target.value)}
          />
        </label>
      </div>
    </div>
  );
};

ScDashboardTableSelect.propTypes = {
  setPatientsToView: PropTypes.func.isRequired,
  patientsToView: PropTypes.string,
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  sleepCoaches: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(state => ({
  sleepCoaches: selectors.getFormOptionsSleepCoachWithUserFirst(state),
  teams: selectors.getTeamNameOptions(state)
}))(ScDashboardTableSelect);
