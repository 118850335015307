import React from 'react'
import { useEffect, useState } from 'react'
import { PageHeader, InlineWaiting } from 'components/ui'
import { useFetch } from 'hooks';
import { get } from 'utils/api';
import '../pages/companyForms.css'
import { path } from "ramda";
import { getApiErrorMessage } from "utils/misc";
import { errorMessage } from "actions/message";
import Table from "./table";
import { useHistory } from "react-router-dom";
import { PureModal } from "components/ui";
import ModalAssignFormToPatients from "./assign-form-to-patient-confirmation";
import AssignablePatientsFormModal, { AssignablePatientsFormOpenModal} from "components/company-assignable-patients-forms/open-modal"
import 'hooks/useQuery/local-query/style.scss'

export default function CompanyForms({ params }) {

    const [formList, setFormList] = useState([]);
    const [managesAtLeastOnePatientType, setManagesAtLeastOnePatientType] = useState(false);
    const [cannotCreateNewFormMessage, setCannotCreateNewFormMessage] = useState({
        color: '',
        text: ''
    });

    const [formToBeAssignedId, setFormToBeAssignedId] = useState([]);

    const idCompany = params.id;

    const urlGetForms = `forms/company/${idCompany}`;

    const history = useHistory();

    const {
        fetchData: fetchForms,
        isFetching: fetchingForms,
    } = useFetch({
        apiFn: () => get(urlGetForms),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    useEffect(() => {
        getForms();
    }, [])

    const getForms = async () => {
        const response = await fetchForms()

        if (response) {
            setManagesAtLeastOnePatientType(response.ManagesAtLeastOnePatientType);
            setFormList(response.Rows);

            if (!response.ManagesAtLeastOnePatientType)
                setCannotCreateNewFormMessage({ color: 'red', value: 'This company does not supports any kind of patient type workflow' });
        }
    }

    function updateRowArray(FormId, newActiveValue) {
        setFormList(formList =>
            formList.map(obj => {
                if (obj.FormId == FormId) {
                    return { ...obj, IsActive: newActiveValue };
                }
                return obj;
            }),
        );
    }

    const handleView = (idForm) => {
        history.push(`/companies/${idCompany}/forms/viewForm/${idForm}`);
    }


    const triggerFormAssignmentModal = (formId) => {
        setFormToBeAssignedId(formId);
        document.getElementById("assignPatientsConfirmationModal").click();
    }

    const ModalAssignFormToPatientsOnYes = ()=>{
        AssignablePatientsFormOpenModal()
    }

    return (
        <div>
            <PageHeader title="Forms" button={managesAtLeastOnePatientType && { path: `/companies/${idCompany}/forms/newForm`, value: 'New Form' }} message={!managesAtLeastOnePatientType && cannotCreateNewFormMessage} ></PageHeader>
            {fetchingForms && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <InlineWaiting />
                    Loading Forms Company....
                </div>)
            }
            <Table
                handleView={handleView}
                formList={formList}
                idCompany={idCompany}
                fetchForms={fetchForms}
                getForms={getForms}
                updateRowArray={updateRowArray}
                assignFormConfirmation={triggerFormAssignmentModal}
                setFormToBeAssignedId={(formId) => setFormToBeAssignedId(formId)}        
            />

            <PureModal
                renderTrigger={({ openModal }) => <div onClick={() => openModal()} id="assignPatientsConfirmationModal"></div>}
                renderContent={({ closeModal }) =>
                    <ModalAssignFormToPatients
                        onYes={()=> ModalAssignFormToPatientsOnYes()}
                        closeModal={() => closeModal()}
                    />
                }
            />

            <AssignablePatientsFormModal
                formId={formToBeAssignedId}
                additionalFunc={getForms}
            />
        </div>
    )
}