import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  resendPDFTemplate,
  getPDFTemplate
} from "components/dd_newModules/Redux/Actions/pdftemplate";
import moment from "moment";
import momentTz from "moment-timezone";
import MdDownload from "react-icons/lib/md/file-download";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import ResendPdfButton from "./resend-pdf-button";
import { createSelector } from "reselect";
import "./style.scss";

const formatDate = date => {
  if (!Date.parse(date)) return date;
  return moment
    .utc(momentTz.tz(date, "YYYY-MM-DDThh:mm:ss", "America/Chicago"))
    .local()
    .format("M/D/YYYY h:mm a");
};

const PdfSignatureHistory = ({
  getPDFTemplate,
  pdf_assignments,
  isLoading,
  resendPDFTemplate
}) => {
  const { rows, query, queryDispatcher, count } = useLocalQuery(
    pdf_assignments,
    {
      sort: { by: "date_sent", direction: "desc" },
      filters: {},
      pagination: { page: 1, per: 100 }
    }
  );
  React.useEffect(() => {
    getPDFTemplate();
  }, []);
  return (
    <Table
      containerClass="query-table manage-pdf-table"
      isFetching={isLoading}
      rows={rows}
      query={query}
      queryDispatcher={queryDispatcher}
      count={count}
      rowKey="assignment_id"
      columns={[
        {
          header: "Document",
          field: "document_title"
        },
        {
          header: "Patient",
          field: "name"
        },
        {
          header: "Signed",
          field: "signed",
          filters: [
            {
              value: true,
              text: "Yes"
            },
            {
              value: false,
              text: "No"
            }
          ]
        },
        {
          header: "Date Sent",
          field: "date_sent"
        },
        {
          header: "Expires",
          field: "email_link_expiration"
        },
        {
          field: "controls",
          header: " "
        }
      ]}
      TableRow={row => (
        <tr>
          <td>{row.document_title}</td>
          <td>
            <Link to={`/patients/${row.patient_id}`}>{row.name}</Link>
          </td>
          <td style={{ color: row.signed ? "green" : "default" }}>
            {row.signed ? "Yes" : "No"}
          </td>
          <td>{formatDate(row.date_sent)}</td>
          <td>{formatDate(row.email_link_expiration)}</td>
          <td>
            {!row.signed && (
              <ResendPdfButton
                patientId={row.patient_id}
                resendPDFTemplate={resendPDFTemplate}
                getPDFTemplate={getPDFTemplate}
                pdf_template_id={row.pdf_template_id}
              />
            )}
            {row.download_url && row.signed && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={row.download_url}
                style={{ color: "green" }}
              >
                <MdDownload style={{ fontSize: 20 }} /> Download
              </a>
            )}
          </td>
        </tr>
      )}
    />
  );
};

// TODO, create api route and move to reducers and add tests
const getPdfAssignments = createSelector(
  [state => state.getIn(["newRootReducer"]).rdc_PDFTemplate.PDFTemplate],
  PDFTemplates =>
    PDFTemplates
      ? PDFTemplates.reduce(
          (acc, cur) => [
            ...acc,
            ...cur.patients.map(p => ({
              patient_id: p.id,
              date_of_delivery: cur.date_of_delivery,
              document_template_url: cur.document_template_url,
              document_title: cur.document_title,
              html_template_id: cur.html_template_id,
              pdf_template_id: cur.id,
              ...p,
              signed: p.status == 0 ? false : true
            }))
          ],
          []
        )
      : []
);

export default connect(
  state => ({
    pdf_assignments: getPdfAssignments(state),
    isLoading: state.getIn(["newRootReducer"]).rdc_loader.isLoader
  }),
  { getPDFTemplate, resendPDFTemplate }
)(PdfSignatureHistory);

PdfSignatureHistory.propTypes = {
  isLoading: PropTypes.bool,
  pdf_assignments: PropTypes.arrayOf(PropTypes.object).isRequired,
  getPDFTemplate: PropTypes.func.isRequired,
  resendPDFTemplate: PropTypes.func.isRequired
};
