import React from "react";
import PropTypes from "prop-types";
import { propOr } from "ramda";
import { useFetch } from "hooks";
import { put } from "utils/api";
import moment from "moment";
import momentTz from "moment-timezone";
import { scheduleTypeOptions, getChatType } from "./options";
import { Spinner } from "components/ui";
import MdPerson from "react-icons/lib/md/person";

const TimeSlotSearch = ({ values, setValues }) => {
  const {
    response: timeSlots,
    fetchData,
    isFetching
  } = useFetch({
    apiFn: values => put("dme_portal/available_slots", values),
    defaultValue: [],
    transformResponse: propOr([], "slots")
  });
  const [selectedDate, setSelectedDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [chatType, setChatType] = React.useState("");

  React.useEffect(() => {
    fetchData({ date: selectedDate, chat_type: chatType });
  }, [selectedDate, chatType]);
  return (
    <div style={{ display: "grid", gridGap: 10, gridRows: "1fr 2fr" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "center"
        }}
      >
        <div className="form-field">
          <div className="form-field-header">Select Date</div>
          <input
            type="date"
            value={selectedDate}
            onChange={({ target }) => setSelectedDate(target.value)}
            className="input-field"
          />
        </div>

        <div className="form-field">
          <div className="form-field-header">Chat Type</div>
          <select
            value={chatType}
            onChange={({ target }) => setChatType(target.value)}
            className="input-field"
          >
            <option />
            {scheduleTypeOptions.map(({ text, value }) => (
              <option key={value} value={value}>
                {text}
              </option>
            ))}
          </select>
        </div>
      </div>

      {isFetching ? (
        <div
          className="list-selector-default"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Spinner size="45px" />
        </div>
      ) : (
        <div
          className="list-selector"
          style={{ minHeight: 300, display: "flex", flexDirection: "column" }}
        >
          <div className="list-selector-entry-wrapper">
            {timeSlots.map(
              ({
                slot_id,
                master_chat_type,
                start_time,
                end_time,
                slots_remaining,
                active_appointments
              }) => (
                <>
                  <div
                    onClick={() => {
                      if (values.slot_id == slot_id) {
                        setValues({
                          ...values,
                          slot_id: "",
                          chat_type: ""
                        });
                      } else {
                        setValues({
                          ...values,
                          slot_id,
                          chat_type: getChatType(master_chat_type)
                        });
                      }
                    }}
                    className={
                      values.slot_id == slot_id
                        ? "list-selector-entry-active"
                        : "list-selector-entry"
                    }
                  >
                    <div
                      style={{ display: "flex", flex: 1, userSelect: "none" }}
                    >
                      <span>{master_chat_type} - </span>
                      <span style={{ fontWeight: "bold", paddingLeft: 5 }}>
                        {moment
                          .utc(
                            momentTz.tz(
                              start_time,
                              "YYYY-MM-DDThh:mm:ss",
                              "America/Chicago"
                            )
                          )
                          .local()
                          .format("h:mm A") +
                          " to " +
                          moment
                            .utc(
                              momentTz.tz(
                                end_time,
                                "YYYY-MM-DDThh:mm:ss",
                                "America/Chicago"
                              )
                            )
                            .local()
                            .format("h:mm A")}
                      </span>
                    </div>
                    <div
                      style={{
                        justifyContent: "flex-end",
                        alignItems: "center"
                      }}
                    >
                      <MdPerson />
                      <span style={{ paddingLeft: 5 }}>
                        {active_appointments} /{" "}
                        {slots_remaining + active_appointments}
                      </span>
                    </div>
                  </div>
                  <div className="list-selector-entry-divider" />
                </>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

TimeSlotSearch.defaultProps = {};

TimeSlotSearch.propTypes = {
  setValues: PropTypes.func.isRequired,
  values: PropTypes.shape({
    slot_id: PropTypes.string,
    chat_type: PropTypes.number
  }).isRequired
};

export default TimeSlotSearch;
