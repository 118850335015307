import { call, spawn, delay, fork, all, put, take } from "redux-saga/effects";
import { enumLoad, enumFormAttributes, enumFetchError } from "actions/enum";
import { Types as AuthTypes } from "actions/authentication";
import { formAttributeTransform } from "actions/patient";
import { fetchInventory } from "actions/inventory";
import { fetchTypeManufacturer } from "actions/order";
import { getSearchFormAttributes } from "action-creators";
import { requestVoipUsers } from "actions/phone-book";
import { isUserTokenExpired } from "utils/misc";
import * as api from "utils/api";
import * as R from "ramda";

export const fetchTasks = all({
  formsOfContact: fork(fetchFormList, {
    route: "form_lists/form_of_contact",
    responseKey: "form_of_contact",
    storeKey: "formOfContact"
  }),
  contactActions: fork(fetchFormList, {
    route: 'form_lists/contact_action',
    responseKey: 'contact_action',
    storeKey: 'contactActions'
  }),
  fulfillmentTypes: fork(fetchFormList, {
    route: "form_lists/fulfillment_types",
    responseKey: "fulfillment_types",
    storeKey: "fulfillmentTypes"
  }),
  orderStatuses: fork(fetchFormList, {
    route: "form_lists/order_statuses",
    responseKey: "order_statuses",
    storeKey: "orderStatuses"
  }),
  admins: fork(fetchFormList, {
    route: "form_lists/admins",
    responseKey: "admins",
    storeKey: "admins"
  }),
  inactivationReasons: fork(fetchFormList, {
    route: "form_lists/inactivation_reasons",
    responseKey: "inactivation_reasons",
    storeKey: "inactivationReasons"
  }),
  rotechInactivationReasons: fork(fetchFormList, {
    route: "form_lists/rotech_inactivation_reasons",
    responseKey: "rotech_inactivation_reasons",
    storeKey: "rotechInactivationReasons"
  }),
  orderRejectedReasons: fork(fetchFormList, {
    route: "form_lists/reject_reasons",
    responseKey: "rejections_reasons",
    storeKey: "orderRejectedReasons"
  }),
  email_templates: fork(fetchFormList, {
    route: "form_lists/email_templates",
    responseKey: "templates",
    storeKey: "patient_email_templates",
    transform: R.indexBy(R.prop("id"))
  }),
  genders: fork(fetchFormList, {
    route: "form_lists/gender_types",
    responseKey: "gender_types",
    storeKey: "genderTypes",
  }),
  insurance_relationship_types: fork(fetchFormList, {
    route: "form_lists/insurance_relationship_types",
    responseKey: "insurance_relationship_types",
    storeKey: "insuranceRelationshipTypes",
  }),
  insurance_companies: fork(fetchFormList, {
    route: "form_lists/insurance_companies",
    responseKey: "insurance_companies",
    storeKey: "insuranceCompanies",
  }),
  formAttributes: fork(fetchFormAttributes),
  inventory: fork(loadInventory),
  fetchTypeManufacturer: fork(fetchEquipmentTypes),
  fetchSearchFormAttributes: fork(fetchSearchFormAttributes),
  fetchPhoneBook: fork(loadPhoneBook)
});

export default function* rootEnumSaga() {
  while (true) {
    const {
      payload: { user }
    } = yield take([AuthTypes.LOAD_STORED_USER, AuthTypes.LOGIN_USER_RESPONSE]);
    if (!isUserTokenExpired(user)) {
      yield fetchTasks;
    }
  }
}

function* fetchSearchFormAttributes() {
  yield put(getSearchFormAttributes());
}

export function* fetchFormList({ route, responseKey, storeKey, transform }) {
  try {
    const response = yield call(api.get, route);
    yield put(
      enumLoad({
        [storeKey]:
          typeof transform === "function"
            ? transform(response[responseKey])
            : response[responseKey]
      })
    );
  } catch (error) {
    yield put(enumFetchError(error));
  }
}

function* fetchFormAttributes() {
  try {
    const response = yield call(api.get, "patients/form_attributes");
    yield put(enumFormAttributes(formAttributeTransform(response)));
  } catch (error) {
    if (error.status != 401) {
      yield spawn(retryFetchFormAttributes);
    }
    yield put(enumFetchError(error));
  }
}

// seems to be an issue after a build where initial request fails when fetching form attributes, temporary fix
function* retryFetchFormAttributes() {
  yield delay(5000);
  yield call(fetchFormAttributes);
}

//Note: fetchTypeManufacturer is a thunk
function* fetchEquipmentTypes() {
  try {
    yield put(fetchTypeManufacturer());
  } catch (error) {
    yield put(enumFetchError(error));
  }
}

function* loadInventory() {
  try {
    yield put(fetchInventory());
  } catch (error) {
    yield put(enumFetchError(error));
  }
}

function* loadPhoneBook() {
  try {
    yield put(requestVoipUsers());
  } catch (error) {
    yield put(enumFetchError(error));
  }
}
