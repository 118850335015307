import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import compose from "lodash/fp/compose";
import { setDialerNumber } from "actions/phone";
import { resupplyEmailToPatient, updatePatient } from "actions/patient";
import { formatDate, getLocalTimeFromZip, getAge } from "utils/dates";
import SectionHeader from "components/profile/section_header";
import { Ul, AttributeItem } from "components/lists";
import { formatPhone } from "utils/misc";
import PureModal from "components/ui/modal/pure-modal";
import { InlineWaiting, withModal, ConfirmationDialog } from "components/ui";
import ContactRecord from "components/profile/modals/contact-record";
import EditAccountInformation from "./edit_account_information";
import { FaEdit } from "react-icons/lib/fa";
import IconButton from "components/ui/icon-button";
import { MdEdit, MdSend } from "react-icons/lib/md";
import { aLink } from "utils/styles";
import { path } from "ramda";
import HasAppPermission from "components/shared/has-app-permission";
import SmsPhoneStatus from "./sms-status";

const AccountInformation = ({
  patientInfo,
  patientId,
  setDialerNumber,
  resupplyEmailToPatient,
  updatePatient,
  isUpdatingPatient,
  formOfContactOptions,
  genderOptions,
  openModal,
  closeModal,
  readOnly
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  return isEditing ? (
    <EditAccountInformation
      updatePatient={formValues => {
        if (
          formValues.form_of_contact === "Email" &&
          patientInfo.can_email === false
        ) {
          openModal(
            <ConfirmationDialog
              onCancel={closeModal}
              warningMsg="Cannot change patients form of contact to email if patient does allow resupply emails to be sent"
              continueMsg="Grant resupply email permission and continue?"
              onConfirm={() => {
                updatePatient({
                  patientId,
                  updates: {
                    ...formValues,
                    date_of_birth: new Date(formValues.date_of_birth),
                    can_email: true
                  }
                });
                closeModal();
              }}
            />
          );
        } else {
          updatePatient({
            patientId,
            updates: {
              ...formValues,
              date_of_birth: formValues.date_of_birth,
              setup_date: formValues.setup_date
            }
          });
        }
        setIsEditing(false);
      }}
      patientId={patientId}
      formOfContactOptions={formOfContactOptions}
      genderOptions={genderOptions}
      handleCancel={() => {
        setIsEditing(false);
      }}
      initialValues={{
        form_of_contact: patientInfo.form_of_contact ?? "",
        can_email: patientInfo.can_email ?? false,
        phone_number: patientInfo.phone_number ?? "",
        mobile_number: patientInfo.mobile_number ?? "",
        email: patientInfo.email || "",
        speaks_spanish: patientInfo.speaks_spanish,
        gender: patientInfo.gender ?? "",
        date_of_birth: patientInfo?.date_of_birth
          ? formatDate(patientInfo.date_of_birth, "YYYY-MM-DD")
          : "",
        setup_date: patientInfo?.setup_date
          ? formatDate(patientInfo.setup_date, "YYYY-MM-DD")
          : ""
      }}
      patientInfo={patientInfo}
    />
  ) : (
    <div>
      <SectionHeader>
        Patient Information{" "}
        <IconButton
          icon={<MdEdit />}
          text="Edit"
          onClick={() => {
            setIsEditing(true);
          }}
          disabled={readOnly}
          style={{ fontSize: "12px", float: "right" }}
        />
      </SectionHeader>
      {isUpdatingPatient ? (
        <InlineWaiting />
      ) : (
        <Ul>
          <HasAppPermission permission="orderSupplies">
            <AttributeItem title="Contact method:">
              {patientInfo.form_of_contact}
            </AttributeItem>
          </HasAppPermission>
          <AttributeItem
            title="Phone number:"
            actions={
              <PureModal
                renderTrigger={({ openModal }) => (
                  <HasAppPermission permission="orderSupplies">
                    <IconButton
                      icon={<FaEdit size={14} />}
                      onClick={openModal}
                      title="Create Contact Record"
                    />
                  </HasAppPermission>
                )}
                renderContent={({ closeModal }) => (
                  <ContactRecord
                    patientId={patientId}
                    closeSelf={closeModal}
                    initialType="Manual Call"
                  />
                )}
              />
            }
          >
            <div
              style={aLink}
              onClick={() => {
                setDialerNumber(patientInfo.phone_number, patientId);
              }}
            >
              {formatPhone(patientInfo.phone_number)}
            </div>
            {patientInfo.phone_number && (
              <SmsPhoneStatus
                text_consent={patientInfo.phone_number_text_consent}
                text_consent_information={
                  patientInfo.phone_number_text_consent_information
                }
              />
            )}
          </AttributeItem>
          <AttributeItem title="Phone type:">
            <div>{patientInfo.phone_number_type ?? "U"}</div>
          </AttributeItem>
          <AttributeItem title="Mobile number:">
            <div
              style={aLink}
              onClick={() => {
                setDialerNumber(patientInfo.mobile_number, patientId);
              }}
            >
              {formatPhone(patientInfo.mobile_number)}
            </div>
            {patientInfo.mobile_number && (
              <SmsPhoneStatus
                text_consent={patientInfo.mobile_number_text_consent}
                text_consent_information={
                  patientInfo.mobile_number_text_consent_information
                }
              />
            )}
          </AttributeItem>
          <AttributeItem
            title="Email Address:"
            actions={
              isSendingEmail ? (
                <InlineWaiting />
              ) : (
                <HasAppPermission permission="orderSupplies">
                  <IconButton
                    title="Send Resupply E-mail"
                    icon={<MdSend size={14} />}
                    onClick={async () => {
                      try {
                        setIsSendingEmail(true);
                        await resupplyEmailToPatient({
                          patientId
                        });
                      } finally {
                        setIsSendingEmail(false);
                      }
                    }}
                    disabled={readOnly || isSendingEmail}
                  />
                </HasAppPermission>
              )
            }
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            <a href={`mailto:${patientInfo.email}`}>{patientInfo.email}</a>
          </AttributeItem>
          <AttributeItem
            title="Date of Birth:"
            actions={
              <span>Age: {getAge(patientInfo.date_of_birth) || ""}</span>
            }
          >
            {formatDate(patientInfo.date_of_birth, "MM/DD/YYYY")}
          </AttributeItem>
          <AttributeItem title="Local Time:">
            {path(["address", "zip"], patientInfo) && (
              <span>
                {getLocalTimeFromZip(path(["address", "zip"], patientInfo))}
              </span>
            )}
          </AttributeItem>
          <AttributeItem title="Primary Language:">
            {patientInfo.speaks_spanish ? "Spanish" : "English"}
          </AttributeItem>
          <AttributeItem title="Gender:">{patientInfo.gender}</AttributeItem>
          <AttributeItem title="Setup Date:">
            {formatDate(patientInfo.setup_date, "MM/DD/YYYY")}
          </AttributeItem>
        </Ul>
      )}
    </div>
  );
};

AccountInformation.propTypes = {
  patientInfo: PropTypes.object.isRequired,
  patientId: PropTypes.string.isRequired,
  setDialerNumber: PropTypes.func.isRequired,
  resupplyEmailToPatient: PropTypes.func.isRequired,
  updatePatient: PropTypes.func.isRequired,
  isUpdatingPatient: PropTypes.bool,
  formOfContactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  genderOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

AccountInformation.defaultProps = {
  readOnly: false
};

export default compose(
  withModal,
  connect(
    (state, { patientId }) => ({
      isUpdatingPatient: state.getIn([
        "fetch",
        "UPDATE_PATIENT",
        patientId,
        "isFetching"
      ]),
      genderOptions: selectors.getGenderOptions(state),
      formOfContactOptions: selectors.getFormOfContactOptions(state)
    }),
    {
      setDialerNumber,
      resupplyEmailToPatient,
      updatePatient
    }
  )
)(AccountInformation);
