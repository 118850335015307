import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader, Waiting, ListItem, PureModal } from "components/ui";
import HasRolePermission from "components/shared/has-role-permission";
import EditCompanyInventoryForm from "./edit-company-inventory";
import InventoryInfoTabs from "../../inventory/inventory-profile/inventory-info-tabs";
import { useFetch } from "hooks";
import { get, put } from "utils/api";
import { isEmpty } from "lodash";

const CopmpanyInventoryProfile = () => {
  const history = useHistory();
  const { companyId, xrefId } = useParams();
  const [media, setMedia] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getCompanyInventoryAPI = useFetch({
    apiFn: () => get(`companies/${companyId}/company_inventory/${xrefId}`),
    defaultValue: {}
  });

  const getProductVideosAPI = useFetch({
    apiFn: () =>
      get(`dme_portal/products/${getCompanyInventoryAPI.response.id}/videos`),
    defaultValue: {}
  });

  const getProductPhotosAPI = useFetch({
    apiFn: () =>
      get(`dme_portal/products/${getCompanyInventoryAPI.response.id}/photos`),
    defaultValue: {}
  });

  const updateCompanyInventoryAPI = useFetch({
    apiFn: payload => put(`companies/${companyId}/inventory`, payload),
    onSuccess: () => {
      getCompanyInventoryAPI.fetchData();
    }
  });

  useEffect(() => {
    getCompanyInventoryAPI.fetchData();
  }, [xrefId]);

  useEffect(() => {
    const initialize = async () => {
      await getProductVideosAPI.fetchData();
      await getProductPhotosAPI.fetchData();
    };

    if (getCompanyInventoryAPI.response.id) initialize();
  }, [getCompanyInventoryAPI.response.id]);

  useEffect(() => {
    // Blame Jayson for 3 useEffects, feel free to write this better
    if (
      !isEmpty(getProductPhotosAPI.response) &&
      !isEmpty(getProductVideosAPI.response)
    ) {
      setMedia({
        images: getProductPhotosAPI.response.media,
        videos: getProductVideosAPI.response.media
      });
      setIsLoading(false);
    }
  }, [getProductVideosAPI.response, getProductPhotosAPI.response]);

  return !isLoading ? (
    <div className="inventory-profile">
      <PageHeader title={getCompanyInventoryAPI.response.name}>
        <HasRolePermission
          allowedRoles={[
            "Administrator",
            "ServiceAdmin",
            "ServiceCoach",
            "CompanyAdministrator"
            //,'ContractorAdministrator'
          ]}
        >
          <PureModal
            renderTrigger={({ openModal }) => (
              <button className="button small" onClick={openModal}>
                Edit
              </button>
            )}
            renderContent={({ closeModal }) => (
              <EditCompanyInventoryForm
                inventoryItem={getCompanyInventoryAPI.response}
                setIsLoading={setIsLoading}
                updateCompanyInventoryAPI={updateCompanyInventoryAPI}
                closeModal={closeModal}
              />
            )}
          />
        </HasRolePermission>
        <button className="button small" onClick={history.goBack}>
          Go Back
        </button>
      </PageHeader>
      <div className="inventory-item-details">
        {getCompanyInventoryAPI.response.photo_url ? (
          <img
            className="inventory-photo"
            src={getCompanyInventoryAPI.response.photo_url}
            alt="Inventory Photo"
          ></img>
        ) : (
          <div className="inventory-photo photo-not-found">
            <div className="photo-not-found-text">No Photo</div>
          </div>
        )}

        <div className="inventory-item-list">
          <ListItem
            label="Manufacturer"
            value={getCompanyInventoryAPI.response.manufacturer}
          />
          <ListItem
            label="HCPCS"
            value={getCompanyInventoryAPI.response.hcpcs}
          />
          <ListItem
            label="Style"
            value={getCompanyInventoryAPI.response.product_style}
          />
          <ListItem
            label="Type"
            value={getCompanyInventoryAPI.response.product_type}
          />
          <ListItem
            label="Description"
            value={getCompanyInventoryAPI.response.description}
          />
          <ListItem
            label="Avg Reimbursement"
            value={`$${getCompanyInventoryAPI.response.avgReimbursment}`}
          />
        </div>
        <div className="inventory-item-list">
          <ListItem
            label="Company Id"
            value={getCompanyInventoryAPI.response.company_product_id}
          />
          <ListItem
            label="Manufacturer Id"
            value={getCompanyInventoryAPI.response.product_id}
          />
          <ListItem
            label="Active"
            value={`${getCompanyInventoryAPI.response.active}`}
          />
          <ListItem
            label="Backordered"
            value={`${getCompanyInventoryAPI.response.backordered}`}
          />
        </div>
      </div>
      {getProductPhotosAPI.response && getProductVideosAPI.response && (
        <InventoryInfoTabs
          media={media}
          isLoading={isLoading}
          inventoryItem={getCompanyInventoryAPI.response}
        />
      )}
    </div>
  ) : (
    <Waiting />
  );
};

export default CopmpanyInventoryProfile;
