import React, { useContext } from "react";
import PropTypes from "prop-types";
import QueryTableContext from "../context";
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/lib/md";
import FilterDropDown from "./filter-dropdown";
import { hasFilter } from "hooks/useQuery/helpers";
import { path } from "ramda";

const getSortIcon = (field, query) => {
  if (query?.sorty?.by == field) {
    return path(["sort", "direction"], query) === "asc" ? (
      <MdArrowDropUp size={16} />
    ) : (
      <MdArrowDropDown size={16} />
    );
  }
};

const TableHeaderColumn = ({
  header,
  field,
  filters,
  filterKey,
  headerClassName,
  filterDownSelect,
  style,
  title,
  SubFilter,
  dropdownPosition,
  FilterComponent
}) => {
  const { query, queryDispatcher } = useContext(QueryTableContext);
  return (
    <td
      className={`query-table-header-cell-container ${headerClassName}`}
      style={style}
      title={title}
    >
      <div className="query-table-header-cell">
        <div
          className={`header-text ${
            hasFilter(filterKey, query.filters) ? "active" : ""
          }`}
          onClick={() => {
            queryDispatcher({
              type: "toggleSort",
              by: field
            });
          }}
        >
          {header}
          <div className="sort-icon">{getSortIcon(field, query)}</div>
        </div>
        {(filters || FilterComponent) && (
          <FilterDropDown
            filters={filters}
            filterKey={filterKey ? filterKey : field}
            query={query}
            queryDispatcher={queryDispatcher}
            filterDownSelect={filterDownSelect}
            SubFilter={SubFilter}
            dropdownPosition={dropdownPosition}
            FilterComponent={FilterComponent}
          />
        )}
      </div>
    </td>
  );
};

export default TableHeaderColumn;

TableHeaderColumn.propTypes = {
  field: PropTypes.string.isRequired,
  headerClassName: PropTypes.string,
  style: PropTypes.object,
  filters: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.number
        ]),
        text: PropTypes.string
      })
    )
  ]),
  filterKey: PropTypes.string, // should be required if filters provided
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  format: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  filterDownSelect: PropTypes.bool,
  title: PropTypes.string,
  SubFilter: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.func
  ]),
  dropdownPosition: PropTypes.oneOf(["", "left", "right", "middle"]),
  FilterComponent: PropTypes.elementType
};

TableHeaderColumn.defaultProps = {
  headerClassName: "",
  style: {},
  filterDownSelect: false
};

export const NonSortableHeader = ({ text, style }) => (
  <td className="query-table-header-cell-container" style={style}>
    <div className="query-table-header-cell" style={{ cursor: "default" }}>
      <div className={`header-text`}>{text}</div>
    </div>
  </td>
);

NonSortableHeader.defaultProps = { style: {} };
NonSortableHeader.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.object
};
