import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SearchableDropdown from "components/searchable-dropdown";
import { Formik, Form, Field } from "formik";
import RadioGroupField from "components/forms/radio-group-field";
import FormikField from "components/forms/formik-field";
import { PageHeader, InlineWaiting } from "components/ui";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import * as Yup from "yup";

const FormUpdater = ({ owner, setFieldValue }) => {
  useEffect(() => {
    setFieldValue("assigned_to_guid", "");
  }, [owner]);
  return null;
};

FormUpdater.propTypes = {
  owner: PropTypes.string,
  setFieldValue: PropTypes.func.isRe
};

const TaskForm = ({ handleSubmit, accessible_users, task, patientId }) => {
  const taskActions = useSelector(selectors.getTaskActionsOptions);
 
  return (
    <div>
      <div>
          <PageHeader title={task.action_id? "Edit Task" : "Create Task"}/>
      </div>
      <Formik
        initialValues={{
          ...task,
          patientId
        }}
        validationSchema={Yup.object().shape({
          type: Yup.string().required("Required"),
          action_id: Yup.string().required("Required")
        })}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <FormUpdater owner={values.owner} setFieldValue={setFieldValue} />
            <div
              style={{
                display: "flex",
                padding: 10,
                flex: 1,
                flexDirection: "column",
                minHeight: "60vh"
              }}
            >
              <div
                style={{
                  marginBottom: 5,
                  display: "grid",
                  gridGap: 10,
                  gridTemplateColumns: "1fr 1fr"
                }}
              >
                {/* <FormikField name="type" component="select" label="Type"> */}
                {/*   <option value="Action">Action</option> */}
                {/*   <option value="Informational">Informational</option> */}
                {/* </FormikField> */}
                <FormikField
                  name="action_id"
                  label="Task"
                  component="select"
                  disabled={values.action_id}
                >
                  <option value="" />
                  {taskActions
                    .filter(x => task?.action_id? x.value === task.action_id : x.is_patient_task)
                    .map(({ text, value }) => (
                      <option key={value} value={value} selected={value === values.action_id}>
                        {text}
                      </option>
                    ))}
                </FormikField>
              </div>
              <FormikField name="status" component="select" label="Status">
                {/* <option /> */}
                {/* <option value="Created">Created</option> */}
                <option value="Pending">Pending</option>
                <option value="Completed">Completed</option>
              </FormikField>
              <FormikField
                name="text"
                label="Description"
                type="text"
                style={{ margin: 0 }}
              />
              {/* {orderId && ( */}
              {/*   <div> */}
              {/*     <label style={{ width: 160 }}>Attach to Order</label> */}
              {/*     <input */}
              {/*       value={orderId && values.order_id == orderId} */}
              {/*       type="checkbox" */}
              {/*       onChange={({ target }) => { */}
              {/*         if (target.checked) setFieldValue("order_id", orderId); */}
              {/*         else setFieldValue("order_id", ""); */}
              {/*       }} */}
              {/*     /> */}
              {/*   </div> */}
              {/* )} */}
              <hr />
              <Field
                component={RadioGroupField}
                name="owner"
                label="Task Owner"
                value={values.owner}
                options={[
                  { value: "S3", text: "S3" },
                  { value: "DME", text: "DME" }
                ]}
              />
              <div
                style={{
                  display: "flex",
                  paddingTop: 20,
                  flex: 1
                }}
              >
                <label style={{ width: 160 }}>Assigned To:</label>
                {values.owner === "S3" ? (
                  <SearchableDropdown
                    options={[
                      { text: "", value: "", key: "" },
                      ...accessible_users.s3_users
                    ]}
                    selected={values.assigned_to_guid}
                    onSelect={value => {
                      setFieldValue("assigned_to_guid", value);
                    }}
                    style={{ width: 600 }}
                    contentStyle={{
                      minWidth: 600
                    }}
                  />
                ) : (
                  <SearchableDropdown
                    options={[
                      { text: "", value: "", key: "" },
                      ...accessible_users.dmes
                    ]}
                    selected={values.assigned_to_guid}
                    onSelect={value => {
                      setFieldValue("assigned_to_guid", value);
                    }}
                    style={{ width: 600 }}
                    contentStyle={{
                      minWidth: 600
                    }}
                  />
                )}
              </div>
              <div>
                <div
                  style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
                >
                  {isSubmitting ? (
                    <InlineWaiting />
                  ) : (
                    <button type="submit" style={{ margin: 0 }}>
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

TaskForm.propTypes = {
  patientId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  accessible_users: PropTypes.shape({
    dmes: PropTypes.arrayOf(PropTypes.object),
    s3_users: PropTypes.arrayOf(PropTypes.object)
  }),
  task: PropTypes.object,
  orderId: PropTypes.string
};

TaskForm.defaultProps = {
  accessible_users: { dmes: [], s3_users: [] },
  task: { type: "Action", text: "", action_id: "", owner: "" }
};
export default TaskForm;
