import { combineReducers } from "redux";
import omit from "lodash/omit";
import keyBy from "lodash/keyBy";
import { createReducer, ifNoError } from "utils/reducer";
import { Types } from "actions/order-profile";
import * as R from "ramda";
import { getEquipmentQuestions } from "utils/order-equipment-questions";
import { createSelector } from "reselect";

const orderInfo = createReducer(
  {},
  {
    [Types.INVALIDATE_ORDERS]: () => {
      return {};
    },
    [Types.RESPONSE_ORDER_INFO]: ifNoError(
      (state, { payload: { order_info }, meta: { orderId } }) => ({
        ...state,
        [orderId]: omit(order_info, "admin")
      })
    ),

    // updates record assuming the API request succeeds and will be
    // overwritten by subsequent `RESPONSE_ORDER_INFO` action
    [Types.API_ORDER_UPDATE]: (
      state,
      {
        payload: {
          apiRequest: { params: optimisticUpdate }
        },
        meta: { orderId }
      }
    ) => ({
      ...state,
      [orderId]: {
        ...state[orderId],
        ...optimisticUpdate
      }
    })
  }
);

const orderRelations = createReducer(
  {},
  {
    [Types.RESPONSE_ORDER_INFO]: ifNoError(
      (
        state,
        {
          payload: {
            order_info: {
              admin: { id }
            }
          },
          meta: { orderId }
        }
      ) => ({
        ...state,
        [orderId]: {
          ...state[orderId],
          adminInfo: id
        }
      })
    ),
    [Types.RESPONSE_PATIENT_INFO]: ifNoError(
      (
        state,
        {
          payload: {
            patient_info: { patient_id }
          },
          meta: { orderId }
        }
      ) => ({
        ...state,
        [orderId]: {
          ...state[orderId],
          patientInfo: patient_id
        }
      })
    )
  }
);

const adminInfo = createReducer(
  {},
  {
    [Types.RESPONSE_ORDER_INFO]: ifNoError(
      (state, { payload: { order_info } }) => ({
        ...state,
        [order_info.admin.id]: order_info.admin
      })
    )
  }
);

const patientInfo = createReducer(
  {},
  {
    [Types.RESPONSE_PATIENT_INFO]: ifNoError(
      (state, { payload: { patient_info } }) => ({
        ...state,
        [patient_info.patient_id]: omit(patient_info, "physician")
      })
    )
  }
);

const physicianInfo = createReducer(
  {},
  {
    [Types.RESPONSE_PATIENT_INFO]: ifNoError(
      (state, { payload: { patient_info } }) => ({
        ...state,
        [patient_info.patient_id]: {
          ...omit(patient_info.physician, "address"),
          ...patient_info.physician.address
        }
      })
    )
  }
);

const complianceInfo = createReducer(
  {},
  {
    [Types.RESPONSE_COMPLIANCE_INFO]: ifNoError(
      (state, { payload: { compliance }, meta: { orderId } }) => ({
        ...state,
        [orderId]: compliance
      })
    )
  }
);

// const groupByItemType = flow(
//   _map(({ item_type, item_name }) => ({ [item_type]: [item_name] })),
//   _mergeAllWith((objVal, srcVal) => isArray(objVal) ? objVal.concat(srcVal) : undefined)
// )

const equipmentInfo = createReducer(
  {},
  {
    [Types.RESPONSE_EQUIPMENT_INFO]: ifNoError(
      (
        state,
        {
          payload: {
            equipment_info: { current_equip }
          },
          meta: { orderId }
        }
      ) => ({
        ...state,
        [orderId]: current_equip //groupByItemType(current_equip)
      })
    )
  }
);

// do not confuse this entity with `lineItems`, which stores the
// response of the `equipment_requested` route; this entity extracts
// the `requested` property from `equipment_info` route
const equipmentRequested = createReducer(
  {},
  {
    [Types.RESPONSE_EQUIPMENT_INFO]: ifNoError(
      (
        state,
        {
          payload: {
            equipment_info: { requested }
          },
          meta: { orderId }
        }
      ) => ({
        ...state,
        [orderId]: requested
      })
    )
  }
);

const dysfunctionInfo = createReducer(
  {},
  {
    [Types.RESPONSE_DYSFUNCTION_INFO]: ifNoError(
      (state, { payload: { dysfunctional_equipment }, meta: { orderId } }) => ({
        ...state,
        [orderId]: dysfunctional_equipment
      })
    )
  }
);

const notes = createReducer(
  {},
  {
    [Types.RESPONSE_NOTES]: ifNoError((state, { payload: { notes } }) => ({
      ...state,
      ...keyBy(notes, "id")
    }))
  }
);

const companyInfo = createReducer(
  {},
  {
    [Types.RESPONSE_COMPANY_INFO]: ifNoError(
      (state, { payload: { company_info }, meta: { orderId } }) => ({
        ...state,
        [orderId]: company_info
      })
    )
  }
);

const lineItems = createReducer(
  {},
  {
    [Types.RESPONSE_LINE_ITEMS]: ifNoError(
      (state, { payload: { equipment_requested } }) => ({
        ...state,
        ...keyBy(
          equipment_requested.map(item => ({
            ...omit(item, "shippingDetails"),
            ...item.shippingDetails
          })),
          "line_item_id"
        )
      })
    )
  }
);

const accessLogs = createReducer(
  {},
  {
    [Types.RESPONSE_ACCESS_LOGS]: ifNoError((state, { payload }) => ({
      ...state,
      ...keyBy(payload, "id")
    }))
  }
);

const changeLogs = createReducer(
  {},
  {
    [Types.RESPONSE_CHANGE_LOGS]: ifNoError((state, { payload }) => ({
      ...state,
      ...keyBy(payload, "id")
    }))
  }
);

// const mapGetGuid = _map(_get('GUID'))

const entities = combineReducers({
  orderInfo,
  orderRelations,
  adminInfo,
  patientInfo,
  physicianInfo,
  complianceInfo,
  equipmentInfo,
  equipmentRequested,
  dysfunctionInfo,
  notes,
  companyInfo,
  lineItems,
  accessLogs,
  changeLogs
});

export default entities;

const getOrderInfo = (state, { orderId }) => state.orderInfo[orderId];
const getOrderStatus = createSelector([getOrderInfo], R.prop("status_text"));
const getOrderAdminInfo = (state, { orderId }) => {
  const { adminInfo } = state.orderRelations[orderId] || {};
  return state.adminInfo[adminInfo];
};
const getOrderPatientInfo = (state, { orderId }) => {
  const { patientInfo } = state.orderRelations[orderId] || {};
  return state.patientInfo[patientInfo];
};
const getOrderPhysicianInfo = (state, { orderId }) => {
  // use of `patientInfo` is not a mistake
  const { patientInfo } = state.orderRelations[orderId] || {};
  return state.physicianInfo[patientInfo];
};
const getOrderComplianceInfo = (state, { orderId }) =>
  state.complianceInfo[orderId];
const getOrderPatientEquipmentInfo = (state, { orderId }) =>
  R.pathOr([], ["equipmentInfo", orderId], state);
const getOrderEquipmentRequested = (state, { orderId }) =>
  R.pathOr([], ["equipmentRequested", orderId], state);
const getNotes = state => state.notes;
const getOrderCompanyInfo = (state, { orderId }) => state.companyInfo[orderId];
const getLineItems = state => state.lineItems;
const getLineItem = (state, { lineItemId }) => state.lineItems[lineItemId];
const getAccessLogs = state => state.accessLogs;
const getChangeLogs = state => state.changeLogs;

const getOrderDysfunctionInfo = (state, { orderId }) => {
  const info = R.pathOr({}, ["dysfunctionInfo", orderId], state);
  const requestedEquipment = R.propOr([], "requested_equipment", info);
  const questions = R.pipe(
    R.map(getEquipmentQuestions),
    R.flatten,
    R.map(q => ({ ...q, value: R.propOr(false, q.key, info) })),
    R.filter(({ key }) => R.prop(key, info) === true)
  )(requestedEquipment);
  return {
    info,
    requestedEquipment,
    questions
  };
};

const getOrderDysfunctionEquipment = (state, { orderId }) => {
  return R.pipe(
    R.pathOr([], ["dysfunctionInfo", orderId, "requested_equipment"]),
    R.uniq
  )(state);
};

export const selectors = {
  getOrderInfo,
  getOrderAdminInfo,
  getOrderPatientInfo,
  getOrderPhysicianInfo,
  getOrderComplianceInfo,
  getOrderPatientEquipmentInfo,
  getOrderEquipmentRequested,
  getOrderDysfunctionEquipment,
  getOrderDysfunctionInfo,
  getNotes,
  getOrderCompanyInfo,
  getLineItems,
  getLineItem,
  getAccessLogs,
  getChangeLogs,
  getOrderStatus
};
