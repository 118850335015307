import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { fetchPatientChangeLogs } from "actions/patient";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";

const ChangeLogsTab = ({
  patientId,
  fetchPatientChangeLogs,
  changeLogs,
  isFetchingChangeLogs,
  patientInfo
}) => {
  const updatedPatientInfo = useMemo(() => {
    return { patientInfo };
  }, [patientInfo]);

  useEffect(() => {
    fetchPatientChangeLogs({ patientId });
  }, [updatedPatientInfo]);

  const { rows, query, queryDispatcher } = useLocalQuery(changeLogs, {
    sort: { by: "changedDate", direction: "desc" }
  });
  return (
    <div>
      <Table
        isFetching={isFetchingChangeLogs}
        containerClass="query-table patient-tab-table dynamic-columns"
        TableRow={ChangeLogsTableRow}
        columns={[
          {
            header: "Time",
            field: "changedDate",
            style: { width: 200 }
          },
          {
            header: "User",
            field: "changedByName",
            style: { width: 200 }
          },
          {
            header: "Changes",
            field: "changedField"
          }
        ]}
        query={query}
        queryDispatcher={queryDispatcher}
        rows={rows}
        rowKey="id"
      />
    </div>
  );
};

export default connect(
  (state, { patientId }) => {
    const isFetchingChangeLogs = state.getIn(
      ["fetch", fetchPatientChangeLogs.baseType, patientId, "isFetching"],
      false
    );
    return {
      changeLogs: selectors.getPatientChangeLogs(state, patientId),
      isFetchingChangeLogs
    };
  },
  { fetchPatientChangeLogs }
)(ChangeLogsTab);

ChangeLogsTab.propTypes = {
  patientId: PropTypes.string.isRequired,
  fetchPatientChangeLogs: PropTypes.func.isRequired,
  changeLogs: PropTypes.array,
  isFetchingChangeLogs: PropTypes.bool,
  patientInfo: PropTypes.object
};

const ChangeLogsTableRow = ({
  changedDate,
  changedByName,
  changedField,
  oldValue,
  newValue
}) => (
  <tr>
    <td>{moment(changedDate).format("MMM DD, YYYY hh:mm A")}</td>
    <td>{changedByName}</td>
    <td>
      {changedField} changed from {oldValue} to {newValue}
    </td>
  </tr>
);

ChangeLogsTableRow.propTypes = {
  changedDate: PropTypes.string,
  changedByName: PropTypes.string,
  changedField: PropTypes.string,
  oldValue: PropTypes.string,
  newValue: PropTypes.string
};
