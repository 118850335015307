import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import PatientsTable from "./patients-table/index.jsx";
import CoachStatsComplianceOutreach from "./coach-stats-compliance-outreach.jsx";
import ScDashboardTableSelect from "./table-select.jsx";
import { connect } from "react-redux";
import { selectors } from "reducers";
/*Compliance Activity Center temporarily hidden, according to S3 request */
/*import AutodialerFilter from "./autodialer-filter.jsx";*/
import PhoneNumberPrompt from "components/two-factor-auth/phone-number-prompt";
import "./style.scss"
import { get } from "utils/api";
import { useFetch } from "hooks";
import { errorMessage } from 'actions/message';
import { getApiErrorMessage } from "utils/misc";

const CompliancePatientOutreach = ({ userRole, user }) => {

  const [complianceCoachOptions, setComplianceCoachOptions] = useState([])

  const {
    fetchData: fetchComplianceCoaches,
  } = useFetch({
  apiFn: () => get("users?type=compliance_coaches"),
    defaultValue: {},
    onError: error => {
        errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
    },
    onSuccess: (response) => {
        setComplianceCoachOptions(() => response.map((element) => {
            return {
                ...element,
                GUID: element.id,
                text: element.name
            }
        }))
    }
  })

  useEffect(() => {
    fetchComplianceCoaches()
  }, [])

  const [patientsToView, setPatientsToView] =
    React.useState("legacy_autodialer");
  return (
    userRole != "ContractorAdministrator" &&
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <PhoneNumberPrompt user={user} />
      <CoachStatsComplianceOutreach />
      <ScDashboardTableSelect
        patientsToView={patientsToView}
        setPatientsToView={setPatientsToView}
      />
      {/* Compliance ACtivity Center temporarily hidden, according to S3 request */}
      {/*{patientsToView === "compliance_activity_center" && <AutodialerFilter />}*/}
      {patientsToView === "legacy_autodialer" && (
        <PatientsTable
          initialQuery={{
            sort: {
              by: "last_name",
              direction: "asc"
            },
            pagination: {
              page: 1,
              per: 500
            },
            filters: {}
          }}
          complianceCoachOptions={complianceCoachOptions}
        />
      )}
    </div>
  );
};

export default connect(state => ({
  userId: selectors.getUserId(state),
  userTeam: selectors.getUserTeam(state),
  userRole: selectors.getUserRole(state),
  user: selectors.getUser(state)
}))(CompliancePatientOutreach);

CompliancePatientOutreach.propTypes = {
  userId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  userTeam: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};
