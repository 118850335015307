import React from "react";
import { PageHeader, TabList } from "components/ui";
import { Switch, Route } from "react-router-dom";
import AvailabilityScheduler from "./availability-scheduler";
import AssignChat from "./assign-chat";
import UpcomingChats from "./upcoming";
import ChatHistory from "./history";

const ChatsContainer = () => {
  return (
    <div>
      <PageHeader title="Video/Text Chats" />
      <div
        style={{
          display: "flex",
          padding: "5px 0px",
          alignItems: "center"
        }}
      >
        <TabList
          routes={[
            {
              path: `/chats/upcoming`,
              label: "Upcoming"
            },
            {
              path: `/chats/time-slots`,
              label: "My Schedule"
            },
            {
              path: `/chats/assign`,
              label: "Assign Patients"
            },
            {
              path: `/chats/history`,
              label: "History"
            }
          ]}
        />
      </div>
      <Switch>
        <Route path="/chats/time-slots" component={() => <AvailabilityScheduler /> } />
        <Route path="/chats/assign" component={AssignChat} />
        <Route path="/chats/upcoming" component={UpcomingChats} />
        <Route path="/chats/history" component={ChatHistory} />
      </Switch>
    </div>
  );
};

export default ChatsContainer;
