import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useFetch } from "hooks";
import { post, del } from "utils/api";
import { Formik, Form } from "formik";
import { InlineWaiting } from "components/ui";
import FormikField from "components/forms/formik-field";
import * as Yup from "yup";
import { isValidPhone } from "utils/redux-form";
import PhoneNumberField from "components/forms/phone-number-field";
import AuthCodeInput from "components/two-factor-auth/auth-code-input";
import { MdVerifiedUser } from "react-icons/lib/md";
import { formatPhone } from "utils/misc";
import FaEraser from "react-icons/lib/fa/eraser";

const MFAPhoneNumber = ({ user }) => {
  const resetMFAPhoneNumberApi = useFetch({
    apiFn: () => del("mfa-cell-reset")
  });
  const dispatch = useDispatch();
  return (
    <div style={{ padding: 10, marginTop: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          fontSize: "1.05rem",
          fontWeight: "400",
          padding: "5px"
        }}
      >
        <h4>Cell Phone #</h4>
        {user.MFACellNumber && user.MFACellNumberVerified ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <span
                className="clear-form"
                style={{
                  margin: "5px 5px",
                  paddingRight: 30,
                  color: "red",
                  cursor: "pointer",
                  fontSize: "0.9rem",
                  transition: "all 0.2s ease-in-out"
                }}
                onClick={async () => {
                  if (
                    confirm(
                      "Are you sure you want to remove this verified number? You will no longer be able to recieve two factor auth codes via sms."
                    )
                  ) {
                    // console.log("yes");
                    const result = await resetMFAPhoneNumberApi.fetchData();
                    if (result == true) {
                      dispatch({
                        type: "USER_SETTINGS_UPDATE_EVENT",
                        payload: {
                          MFACellNumber: "",
                          MFACellNumberVerified: false
                        }
                      });
                    }
                  }
                }}
              >
                <FaEraser /> Reset MFA Cell Number
              </span>
            </div>
            <span>{formatPhone(user.MFACellNumber)}</span>
            <span style={{ color: "green", paddingLeft: 5 }}>
              <span title="Verified">
                <MdVerifiedUser />
              </span>
            </span>
          </div>
        ) : (
          <EditMFAPhoneNumber user={user} />
        )}
      </div>
    </div>
  );
};

MFAPhoneNumber.propTypes = { user: PropTypes.object };

export default MFAPhoneNumber;

const EditMFAPhoneNumber = () => {
  const [editingStep, setEditingStep] = React.useState(0);
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const [phoneNumberVerifyCode, setPhoneNumberVerifyCode] = React.useState("");
  const dispatch = useDispatch();
  const verifyPhoneNumberApi = useFetch({
    apiFn: ({ phone_number, code }) =>
      post(`mfa-cell-verify`, { phone_number, code })
  });

  const setPhoneNumberApi = useFetch({
    apiFn: phone_number => post(`mfa-cell-set`, { phone_number })
  });
  if (editingStep == 0)
    return (
      <div>
        For security purposes we have enacted an extra level of validation for
        all users with access to private patient data.
        <div>
          Our records indicate we do not have verified cell phone number on file
          for you
        </div>
        <div>
          Please verify your phone number to allow this to be used for verifing
          your identity
        </div>
        <Formik
          initialValues={{ phone_number: "" }}
          validationSchema={Yup.object({
            phone_number: Yup.string()
              .required("Required")
              .test(
                "valid-number",
                "Invalid phone number",
                val => !isValidPhone(val)
              )
          })}
          onSubmit={async ({ phone_number }) => {
            const result = await setPhoneNumberApi.fetchData(phone_number);
            if (result == true) {
              setPhoneNumber(phone_number);
              setEditingStep(1);
            }
          }}
        >
          {({ isValid, isSubmitting }) => (
            <Form
              style={{
                display: "grid",
                gridTemplateColumns: "1fr auto",
                gridGap: 10,
                alignItems: "center"
              }}
            >
              <FormikField
                name="phone_number"
                label="Please Enter cell phone number"
                type="tel"
                component={PhoneNumberField}
              />
              <div>
                {isSubmitting ? (
                  <InlineWaiting />
                ) : (
                  <button
                    disabled={!isValid}
                    type="submit"
                    style={{ padding: "10px 10px", margin: 0 }}
                  >
                    Submit
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
        <div style={{ color: "red" }}>
          {setPhoneNumberApi.error &&
            "Sorry something went wrong, this request could not be processed"}
        </div>
      </div>
    );
  if (editingStep == 1)
    return (
      <div>
        <label>Please enter code to verify this phone number</label>
        <AuthCodeInput
          value={phoneNumberVerifyCode}
          onChange={value => {
            setPhoneNumberVerifyCode(value);
          }}
        />
        <button
          disabled={phoneNumberVerifyCode.length != 6}
          onClick={async () => {
            const result = await verifyPhoneNumberApi.fetchData({
              phone_number: phoneNumber,
              code: phoneNumberVerifyCode
            });
            if (result == true) {
              dispatch({
                type: "USER_SETTINGS_UPDATE_EVENT",
                payload: {
                  MFACellNumber: phoneNumber,
                  MFACellNumberVerified: true
                }
              });
            }
            setEditingStep(2);
          }}
        >
          Submit
        </button>
        <div style={{ color: "red" }}>
          {verifyPhoneNumberApi.error &&
            "Sorry something went wrong, this request could not be processed"}
        </div>
      </div>
    );
  if (editingStep == 2)
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>Thank you, cell phone number has been verified</div>
        <div style={{ display: "flex" }}>
          <span>{phoneNumber}</span>
        </div>
      </div>
    );
};
