import { errorMessage } from "actions/message";
import { browserHistory } from "browser-history";
import ContactLogicTable from "components/screens/company-contact-logic/contact-logic-table";
import { PageHeader, Spinner } from 'components/ui';
import { useFetch } from 'hooks';
import { clone, path } from "ramda";
import React, { useEffect, useState } from 'react';
import GotriangleDown from "react-icons/lib/go/triangle-down";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { selectors } from "reducers";
import { getApiErrorMessage } from "utils/misc";
import { get } from '../../../../utils/api';
import '../pages/companyForms.css';
import CopyForm from './copy-form';
import ModalAssignFormToPatients from "./assign-form-to-patient-confirmation";
import AssignablePatientsFormModal, { AssignablePatientsFormOpenModal} from "./../../../../components/company-assignable-patients-forms/open-modal"
import { PureModal } from "components/ui";

const ViewForm = ({ formOfContactOptions }) => {

    const contactLogicDefaultValue = [{ companyId: "", isFetching: false, cycles: [] }];

    const [expandedRows, setExpandedRows] = useState([]);
    const [expandState, setExpandState] = useState({});

    const { idForm, id } = useParams();

    const [form, setForm] = useState(null);

    const [patientType, setPatientType] = useState([]);

    const [questionsTypes, setQuestionsTypes] = useState([]);

    const [comparisons, setComparisons] = useState([]);

    const [binaryOperatorType, setBinaryOperatorType] = useState([]);

    const [taskActions, setTaskActions] = useState([]);

    const [selectedFormOfContactOption, setSelectedFormOfContactOption] = useState("");

    const [filteredContactLogic, setFilteredContactLogic] = useState(clone(contactLogicDefaultValue));

    const [customContactLogic, setCustomContactLogic] = useState(clone(contactLogicDefaultValue));

    const {

        fetchData: fetchFormById,
        isFetching,
    } = useFetch({
        apiFn: () => get(`forms/${idForm}`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        // response : forms, 
        fetchData: fetchPatientType,
        //  isFetching,
    } = useFetch({
        apiFn: () => get('forms/patientTypes'),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        // response : forms, 
        fetchData: fetchQuestionsTypes,
        //  isFetching,
    } = useFetch({
        apiFn: () => get(`/forms/questionsTypes`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        // response : forms, 
        fetchData: fetchTaskActions,
        //  isFetching,
    } = useFetch({
        apiFn: () => get(`/tasks`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        // response : forms, 
        fetchData: fetchComparison,
        //  isFetching,
    } = useFetch({
        apiFn: () => get(`/forms/comparison-operator-types`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    const {
        // response : forms, 
        fetchData: fetchBinaryOperatorType,
        //  isFetching,
    } = useFetch({
        apiFn: () => get(`/forms/binary-operator-types`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            errorMessage(`Failed to get Forms: ${getApiErrorMessage(error)}`);
        }
    })

    useEffect(() => {
        getPatientsType();
        getQuestionsTypes();
        getTaskActions();
        getComparison();
        getBinaryOperatorType();
        getFormById();
    }, [])

    useEffect(() => {
        const mutableContactLogicArray = clone(customContactLogic);

        mutableContactLogicArray.forEach((element) => {
            element.cycles = element.cycles.filter(
                cycle =>
                    !selectedFormOfContactOption ||
                    cycle.actionId === selectedFormOfContactOption)
        }
        );

        setFilteredContactLogic(mutableContactLogicArray);
    }, [selectedFormOfContactOption, customContactLogic]);

    useEffect(() => {
        // set initial selected form of contact
        if (formOfContactOptions) {
            const callId =
                formOfContactOptions.find(x => x.text === "Manual Call")?.key ?? "";
            setSelectedFormOfContactOption(callId);
        }

        if (customContactLogic.length > 0 || filteredContactLogic.length > 0) {
            setCustomContactLogic(contactLogicDefaultValue);
            setFilteredContactLogic(contactLogicDefaultValue)
        }

    }, [formOfContactOptions]);

    const getPatientsType = async () => {

        const patientsTypeResponse = await fetchPatientType();
        if (patientsTypeResponse)
            setPatientType(patientsTypeResponse.Values);
    }

    const getFormById = async () => {
        const response = await fetchFormById()

        if (response.success) {
            setForm(response);

            let retrievedContactLogic = clone(contactLogicDefaultValue);
            retrievedContactLogic[0].cycles = response.ContactLogic;
            setCustomContactLogic(retrievedContactLogic);
        }
        
    }

    const getQuestionsTypes = async () => {

        const response = await fetchQuestionsTypes();
        if (response)
            setQuestionsTypes(response.Values);
    }

    const getTaskActions = async () => {

        const response = await fetchTaskActions();
        if (response)
            setTaskActions(response.Values);
    }

    const getComparison = async () => {

        const response = await fetchComparison();
        if (response)
            setComparisons(response.Values);
    }

    const getBinaryOperatorType = async () => {

        const response = await fetchBinaryOperatorType();

        if (response)
            setBinaryOperatorType(response.Values);
    }

    const getNameList = (list, id) => {

        return id ? list.find(x => x.ItemId === id).ItemValue : '';
    }

    const showQuestions = () => {

        return (
            form.Questions.map((item, i) => (

                <div key={i}>
                    <button className='title' title={expandState[i] ? "Less Details" : "More Details"} onClick={() => handleEpandRowDetail(i)}>
                        {item.Text}  <GotriangleDown />
                    </button>

                    {expandedRows.includes(i) ?
                        <div>
                            <ul>
                                <li><strong>Order: </strong> {item.Order}</li>
                                <li><strong>Spanish Text: </strong> {item.SpanishText}</li>
                                <li><strong>Question Type:</strong> {getNameList(questionsTypes, item.QuestionType)}</li>
                                <li><strong>Is Required: </strong> {item?.IsRequired ? "YES" : "NO"}</li>
                                <li><strong>Has N/A Option: </strong> {item?.HasNAOption ? "YES" : "NO"}</li>
                            </ul>
                            {showInfoByQuestionType(item)}
                        </div>
                        : null}
                    <hr />
                </div>
            ))
        )
    }

    const showInfoByQuestionType = (question) => {

        switch (question.QuestionType) {
            case 2:
                return setMultipleChoice(question);
            case 3:
                return setLogical(question);
            case 4:
                return setConditional(question);
            default:
                break;
        }
    }

    const setLogical = (question) => {

        return (
            <ul>
                <li><strong>Is Logical: </strong> {question.Conditions ? question.Conditions.length ? question.Conditions[0].Value === 1 ? 'YES' : 'NO' : '' : ''} </li>
                <li><strong>Task Action: </strong> {question.Options ? question.Options.length ? getNameList(taskActions, question.Options[0].TaskActionId) : '-' : '-'}</li>
            </ul>
        )
    }

    const setMultipleChoice = (question) => {

        return (
            <div className='scrollTableAQuestion'>
                <table>
                    <thead>
                        <tr> {showHeadersQuestionOption()}</tr>
                    </thead>
                    <tbody> {showQuestionsOptions(question.Options)}</tbody>
                </table>
            </div>
        )
    }

    const showHeadersQuestionOption = () => {

        const headers = ["ORDER", "TEXT", "SPANISH TEXT", "TASK ACTION", "TWILIO KEY PAD OPTION"];
        return headers.map((h, i) => <th key={i}>{h}</th>);
    };

    const showQuestionsOptions = (options) => {

        if (options) {

            return options.map((item, i) => (
                <tr key={i}>
                    <td>{item.Order}</td>
                    <td>{item.Text}</td>
                    <td>{item.SpanishText}</td>
                    <td>{item.TaskActionId ? getNameList(taskActions, item.TaskActionId) : '-'}</td>
                    <td>{item.TwilioKeyPadOption}</td>
                </tr>
            ));
        }
    }

    const setConditional = (question) => {

        return (
            <div>
                <div className='scrollTableAQuestion'>
                    <table>
                        <thead>
                            <tr> {showHeadersQuestionConditional()}</tr>
                        </thead>
                        <tbody> {showQuestionsConditions(question.Conditions)}</tbody>
                    </table>
                </div>

                <p><strong>Task Action: </strong> {question.Options ? question.Options.length ? getNameList(taskActions, question.Options[0].TaskActionId) : '-' : '-'}</p>
            </div>
        )
    }

    const showHeadersQuestionConditional = () => {

        const headers = ["ORDER", "VALUE", "COMPARISON OPERATOR", "BINARY OPERATOR"];
        return headers.map((h, i) => <th key={i}>{h}</th>);
    };

    const showQuestionsConditions = (conditions) => {

        if (conditions) {

            return conditions.map((item, i) => (
                <tr key={i}>
                    <td>{item.Order}</td>
                    <td>{item.Value}</td>
                    <td>{item.ComparisonOperator ? getNameList(comparisons, item.ComparisonOperator) : '-'}</td>
                    <td>{item.BinaryOperator ? getNameList(binaryOperatorType, item.BinaryOperator) : '-'}</td>
                </tr>
            ));
        }
    }


    const handleEpandRowDetail = (repId) => {

        const currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(repId);

        let obj = {};
        isRowExpanded ? (obj[repId] = false) : (obj[repId] = true);
        setExpandState(obj);

        const newExpandedRows = isRowExpanded ? currentExpandedRows.filter(id => id !== repId) : currentExpandedRows.concat(repId);

        setExpandedRows(newExpandedRows);
    }

    const onYes = (closeModal)=>{
        closeModal();
        AssignablePatientsFormOpenModal();
    }

    return (
        <div>
            {isFetching && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Spinner />
                    Loading Data....
                </div>)
            }

            <PageHeader title="View Form">
                {
                    form && 
                    <div>
                        <CopyForm 
                            companyId={id} 
                            formId={idForm} 
                            iconSize='3em' 
                            name={form.Name} 
                            order={form.Order}
                            patientType={form.PatientType}
                            successAction={() => { document.getElementById("assignPatientsConfirmationModal").click(); }}       
                        />  
                        <PureModal
                            renderTrigger={({ openModal }) => <div onClick={() => openModal()} id="assignPatientsConfirmationModal"></div>}
                            renderContent={({ closeModal }) => <ModalAssignFormToPatients formId={idForm} closeModal={() => closeModal()} onYes={() => onYes(closeModal) } onNo={() => browserHistory.goBack()}/>}
                        >
                        </PureModal>

                        <AssignablePatientsFormModal formId={idForm} additionalFunc={() => browserHistory.goBack() }></AssignablePatientsFormModal>
                    </div>                 
                }             
            </PageHeader>

            <div className="dd_col-6">
                <label>Name</label>
                <input type="text" className='inputView' value={form ? form.Name : ''} disabled={true}></input>
            </div>
            <div className="dd_col-6">
                <label>Description</label>
                <input type="text" className='inputView' value={form ? form.Description : ''} disabled={true}></input>
            </div>

            <div className="dd_col-4">
                <label>Available Days</label>
                <input type="text" className='inputView' value={form ? form.AvailableDays : ''} disabled={true}></input>
            </div>

            <div className="dd_col-4">
                <label>Order</label>
                <input type="text" className='inputView' value={form ? form.Order : ''} disabled={true}></input>
            </div>

            <div className="dd_col-4">
                <label>Patient Type</label>
                <input type="text" className='inputView' value={form ? getNameList(patientType, form.PatientType) : ''} disabled={true}></input>
            </div>

            <div className="dd_col-2">
                <input type="checkbox" disabled={true} checked={form ? form.IsActive : false} />
                <label className='inputView'>Active</label><br></br>
            </div>

            <div className="dd_col-3">
                <input type="checkbox" disabled={true} checked={form ? form.UsesCustomContactLogic : false} />
                <label className='inputView'>Uses Custom Contact Logic</label><br></br>
            </div>

            <div className="dd_col-12"><PageHeader title="Questions"></PageHeader> <span>Click for more details</span></div>

            <div className="dd_col-12">
                {form ? showQuestions() : null}
            </div>

            <div hidden={form ? !form.UsesCustomContactLogic : false}>
                <div className="dd_col-12"><PageHeader title="Contact Logic"></PageHeader> </div>
                <div className="contact-logic-container">
                    <ContactLogicTable
                        key={0}
                        companyOptions={[]}
                        onCompanySelect={() => { }}
                        formOfContactOptions={formOfContactOptions}
                        selectedFormOfContact={selectedFormOfContactOption || ""}
                        onFormOfContactSelect={(value) => { setSelectedFormOfContactOption(value) }}
                        contactLogicArray={filteredContactLogic}
                        index={0}
                        onCycleChanged={() => { }}
                        onClose={() => { }}
                        onSave={() => { }}
                        onFormVisualization={true}
                    />
                </div>

            </div>
            <div className="dd_col-12">

                <div style={{ display: "flex", justifyContent: "center" }}>

                    <button
                        type="button"
                        className="button primary"
                        onClick={e => {
                            e.preventDefault();
                            browserHistory.goBack();
                        }}
                    >
                        Back
                    </button>
                </div>
            </div>
        </div>
    )
}

export default connect(
    state => ({
        formOfContactOptions: selectors.getFormOfContactOptions(state),
        contactActionsOptions: selectors.getContactActionsOptions(state)
    }),
    null
)(ViewForm);