import React from 'react'
import { useEffect, useState } from 'react'
import { PageHeader, InlineWaiting } from 'components/ui'
import { useFetch } from 'hooks';
import { get } from 'utils/api';
import './complianceCoachTable.css'
import { path } from "ramda";
import { getApiErrorMessage } from "utils/misc";
import { useDispatch } from 'react-redux';
import { exportUserTable } from 'actions/users';
import DataColumnFilterTable from 'components/ui/data-column-filter-table';
import { Link } from 'react-router-dom';
import * as msg from "actions/message";
import capitalize from 'lodash/capitalize'

export default function MultiFilterComplianceCoachTable() {

    const dispatch = useDispatch()

    const nameSort = ({name}) => capitalize(name)

    const [complianceCoachesList, setComplianceCoachesList] = useState([]);

    const {
        fetchData: fetchComplianceCoaches,
        isFetching: fetchingComplianceCoaches,
    } = useFetch({
        apiFn: () => get(`users?type=compliance_coaches`),
        defaultValue: {},
        transformError: path(["response", "body", "status"]),
        onError: error => {
            dispatch(msg.errorMessage(`Failed to get Compliance Coaches: ${getApiErrorMessage(error)}`));
        }
    })

    useEffect(() => {
        getForms();
    }, [])

    const getForms = async () => {
        const response = await fetchComplianceCoaches()
        if (response) {
            setComplianceCoachesList(response);
        }
    }

    const handleRefresh = () => {
        getForms()
    }
    
    const handleExport = (filteredRows) => {
        dispatch(exportUserTable('compliance_coaches', filteredRows))
    }

    return (
        <div>
            <PageHeader title="Compliance Coaches" ></PageHeader>
            {fetchingComplianceCoaches && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <InlineWaiting />
                    Loading Compliance Coaches....
                </div>)
            }
            {complianceCoachesList?.length === 0 && !fetchingComplianceCoaches ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    No registers found
                </div>)
                :
                null
            }
            <DataColumnFilterTable
                columns={[
                    {
                    title: 'Sort by name',
                    header: 'Name',
                    field: user => user?.name,
                    format: user => (
                        <Link to={`/users/${user.id}`}>                
                        {user.name}
                        </Link>
                    ),
                    
                    filterTest: 'name'
                    },                       
                    {
                    title: 'Sort by Assigned Team',
                    header: 'Team',
                    field: user => user?.team_name,
                    },
                    {
                    title: 'Sort by Active Patients',
                    header: 'Active Patients',
                    field: user => user?.active_patients,
                    numerical: true
                    },
                    {
                    title: 'Sort by Follow Ups',
                    header: 'Follow Ups',
                    field: user => user?.follow_ups,
                    numerical: true
                    },
                    {
                    title: 'Sort by Second Follow Ups',
                    header: 'Second Follow Ups',
                    field: user => user?.second_follow_ups,
                    numerical: true
                    },
                    {
                    title: 'Sort by Eligible',
                    header: 'Eligible',
                    field: user => user?.eligible,
                    numerical: true
                    },
                    {
                    title: 'Sort by Sleep Coach Active',
                    header: 'Active',
                    field: user => user?.active.toString(),
                    boolean: true,
                    
                    }
                ]}
                rows={complianceCoachesList}
                onRefresh = {handleRefresh}
                onExport= {handleExport}
                initialSort={{ iteratee: nameSort }}
                initialFilters={[{
                    input: '',
                    columnIndex: 6,
                    testOpKey: 'REG_EXP',
                    selectActiveBoolean: 'true'
                }]} 
            />
        </div>
    )
}