import React, { useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { Form, Formik } from "formik";
import { useFetch } from "hooks";
import { path } from "ramda";
import { get, put } from "utils/api";
import { formatDate } from "utils/misc";
import FormikField from "components/forms/formik-field";
import YesNoCheckbox from "./yes-no-checkbox";
import { Spinner } from "components/ui";
import { PatientProfileContext } from "components/profile/context";
import "./../tabsStyles.css";

const ComplianceData = ({ patientId, setComplianceCoach, complianceActive, displaySpinner, userRole, setIsCompliantForBanner, companyManagesCompliance }) => {

    const [isCompliant, setIsCompliant] = useState(false);
    const [complianceMetEditionAllowed, setComplianceMetEditionAllowed] = useState(false);

    const { accessible_users } = useContext(PatientProfileContext);

    const {
        response: ComplianceInfo,
        fetchData,
        isFetching: fetchingComplianceInfo
    } = useFetch({
        apiFn: patientId => get(`patients/${patientId}/compliance-info`),
        defaultValue: [],
        transformError: path(["response", "body", "status"])
    });

    const {
        response: papTypes,
        fetchData: fetchPapTypes,
        isFetching: fetchingPapTypes
    } = useFetch({
        apiFn: () => get(`patients/PAPTypes`),
        defaultValue: [],
        transformError: path(["response", "body", "status"])
    });

    const {
        response: setupTypes,
        fetchData: fetchSetupTypes,
        isFetching: fetchingSetupTypes
    } = useFetch({
        apiFn: () => get(`patients/SetupTypes`),
        defaultValue: [],
        transformError: path(["response", "body", "status"])
    });

    const {
        response: complianceCoaches,
        fetchData: fetchComplianceCoaches,
        isFetching: fetchingComplianceCoaches
    } = useFetch({
        apiFn: () => get(`patients/${patientId}/complianceCoaches`),
        defaultValue: [],
        transformError: path(["response", "body", "status"])
    });

    const {
        response: complianceSoftwares,
        fetchData: fetchComplianceSoftwares,
        isFetching: fetchingComplianceSoftware
    } = useFetch({
        apiFn: () => get(`patients/complianceSoftware`),
        defaultValue: [],
        transformError: path(["response", "body", "status"])
    });

    async function evaluateCall() {
        await fetchData(patientId);
        await fetchPapTypes();
        await fetchSetupTypes();
        await fetchComplianceCoaches();
        await fetchComplianceSoftwares();
    }

    React.useEffect(() => {
        setIsCompliant(ComplianceInfo.compliant_met);

        if (ComplianceInfo)
            setIsCompliantForBanner(ComplianceInfo.compliant_met);
    }, [ComplianceInfo])

    const [canEdit, setCanEdit] = useState(true);

    React.useEffect(() => {
        evaluateCall()
    }, [patientId, complianceActive]);

    React.useEffect(() => {
        showValuesOnConsole();
        setCanEdit(ComplianceInfo.can_edit);
    }, [ComplianceInfo])

    React.useEffect(() => {
        if (userRole == "Administrator" || userRole == "CompanyAdministrator" || userRole == "OfficeAdministrator") {
            setComplianceMetEditionAllowed(true);
        }
    }, []);

    const [arrivingValues, setArrivingValues] = useState({
        pap_type: ComplianceInfo.pap_type,
        setup_type: ComplianceInfo.setup_type,
        compliance_coach: ComplianceInfo.compliance_coach,
        compliant_met: ComplianceInfo.compliant_met,
        compliance_percentage: ComplianceInfo.compliance_percentage,
        compliance_software: ComplianceInfo.compliance_software,
        compliant_met_date: formatDate(ComplianceInfo.compliant_met_date, "YYYY-MM-DD"),
        md_follow_up_date: formatDate(ComplianceInfo.md_follow_up_date, "YYYY-MM-DD"),
        high_cai: ComplianceInfo.high_cai,
        post_90_day_monitoring: ComplianceInfo.post_90_day_monitoring
    })

    const validateCompliantPercentage = compliancePercentage => {
        let errorMessage;
        if (!compliancePercentage || compliancePercentage > 100 || compliancePercentage < 0 || isNaN(compliancePercentage) || compliancePercentage === null) {
            errorMessage = "Invalid amount. Value must be 0-100";
        }
        return errorMessage;
    };

    const uploadComplianceChanges = useFetch({
        apiFn: ({ patientId, values }) => put(`patients/${patientId}/compliance-info`, values),
        defaultValue: [],
        transformError: path(["response", "body", "status"])
    })

    function showValuesOnConsole() {
        setArrivingValues({
            ...arrivingValues,
            pap_type: ComplianceInfo.pap_type,
            setup_type: ComplianceInfo.setup_type,
            compliance_coach: ComplianceInfo.compliance_coach,
            compliant_met: ComplianceInfo.compliant_met,
            compliance_percentage: ComplianceInfo.compliance_percentage,
            compliance_software: ComplianceInfo.compliance_software,
            compliant_met_date: formatDate(ComplianceInfo.compliant_met_date, "YYYY-MM-DD"),
            md_follow_up_date: formatDate(ComplianceInfo.md_follow_up_date, "YYYY-MM-DD"),
            post_90_day_monitoring: ComplianceInfo.post_90_day_monitoring,
            high_cai: ComplianceInfo.high_cai
        })
    }
    const validateNull = value => {
        let errorMessage;
        if (!value) {
            errorMessage = "You must select an option";
        }
        return errorMessage;
    };
    const validateEmptyDate = value => {
        let errorMessage;
        if (!value) {
            errorMessage = "You must select a date";
        }
        return errorMessage;
    };

    const formRef = useRef(null);

    const compliantMetChange = (value) => {
        if (value === null) {
            formRef.current.setFieldValue("compliant_met_date", "")
        }
        formRef.current.setFieldValue("compliant_met", value)

        if (!formRef.current.values.compliance_percentage) {
            formRef.current.setTouched({ ["compliance_percentage"]: false }, true)
        }
    }

    return (
        <div className='special-fields'>
            {
                !fetchingComplianceInfo && !fetchingPapTypes && !fetchingSetupTypes && !fetchingComplianceCoaches && !fetchingComplianceSoftware && !displaySpinner ? (
                    <div>
                        <Formik
                            initialValues={{
                                pap_type: arrivingValues.pap_type,
                                setup_type: arrivingValues.setup_type,
                                compliance_coach: arrivingValues.compliance_coach,
                                compliant_met: arrivingValues.compliant_met,
                                compliance_percentage: arrivingValues.compliance_percentage,
                                compliance_software: arrivingValues.compliance_software,
                                compliant_met_date: arrivingValues.compliant_met_date,
                                md_follow_up_date: arrivingValues.md_follow_up_date,
                                post_90_day_monitoring: arrivingValues.post_90_day_monitoring,
                                high_cai: arrivingValues.high_cai
                            }}
                            innerRef={formRef}
                            enableReinitialize={true}
                            onSubmit={async (values, { setSubmitting }) => {
                                await uploadComplianceChanges.fetchData({ patientId, values })
                                setSubmitting(false)
                                evaluateCall();
                                setComplianceCoach(values.compliance_coach);
                            }}
                        >
                            {({
                                values,
                                handleChange,
                                isSubmitting
                            }) => (

                                <Form
                                    className='compliance-data-width'
                                >
                                    <FormikField
                                        name="pap_type"
                                        component="select"
                                        label="PAP type"
                                        validate={validateNull}
                                        disabled={isSubmitting || !canEdit || !companyManagesCompliance}
                                        onChange={e => {
                                            handleChange(e)
                                        }}
                                    >
                                        <option value={null}></option>
                                        {
                                            papTypes ?
                                                papTypes.map(({ Text, Value }) => (
                                                    <option key={Value} value={Value}>
                                                        {Text}
                                                    </option>
                                                ))
                                                :
                                                null
                                        }
                                    </FormikField>

                                    <FormikField
                                        name="setup_type"
                                        component="select"
                                        label="Setup type"
                                        validate={validateNull}
                                        disabled={isSubmitting || !canEdit || !companyManagesCompliance}
                                        onChange={e => {
                                            handleChange(e)
                                        }}
                                    >
                                        <option value={null}></option>
                                        {
                                            setupTypes ?
                                                setupTypes.map(({ Text, Value }) => (
                                                    <option key={Value} value={Value}>
                                                        {Text}
                                                    </option>
                                                ))
                                                :
                                                null
                                        }
                                    </FormikField>

                                    <FormikField
                                        name="compliance_coach"
                                        component="select"
                                        label="Compliance Coach"
                                        validate={validateNull}
                                        disabled={isSubmitting || !canEdit || !companyManagesCompliance}
                                        onChange={(e) => {
                                            handleChange(e)
                                        }}
                                    >
                                        <option value={null}></option>
                                        {
                                            complianceCoaches.employees ?
                                                complianceCoaches.employees.map(({ Text, Value }) => (
                                                    <option key={Value} value={Value}>
                                                        {Text}
                                                    </option>
                                                ))
                                                :
                                                null
                                        }
                                        {accessible_users?.dmes.map(x => (
                                          <option key={x.key} value={x.value}>
                                            {x.text}
                                          </option>
                                        ))}
                                    </FormikField>

                                    <YesNoCheckbox
                                        defaultValue={values.compliant_met}
                                        disabled={isSubmitting || !canEdit || (isCompliant !== null && !complianceMetEditionAllowed) || !companyManagesCompliance}
                                        onChange={compliantMetChange}
                                        hasMinimumTherapyDays={ComplianceInfo?.has_minimum_therapy_days}
                                    />

                                    <FormikField
                                        name="compliant_met_date"
                                        type="date"
                                        label="Compliance Date Met"
                                        validate={values.compliant_met == true ? validateEmptyDate : null}
                                        style={{ display: values.compliant_met === null ? "none" : "unset" }}
                                        disabled={isSubmitting || !canEdit || (isCompliant !== null && !complianceMetEditionAllowed) || !companyManagesCompliance}
                                        onChange={(e) => {
                                            handleChange(e)
                                        }}
                                    />

                                    <FormikField
                                        name="compliance_percentage"
                                        type="number"
                                        label="Compliance Percentage"
                                        validate={values.compliant_met !== null ? validateCompliantPercentage : null}
                                        disabled={isSubmitting || !canEdit || (isCompliant !== null && !complianceMetEditionAllowed) || !companyManagesCompliance}
                                        onChange={(e) => {
                                            handleChange(e)
                                        }}
                                    />

                                    <FormikField
                                        name="md_follow_up_date"
                                        type="date"
                                        label="MD Follow Up Date"
                                        disabled={isSubmitting || !canEdit || !companyManagesCompliance}
                                        onChange={(e) => {
                                            handleChange(e)
                                        }}
                                    />

                                    <FormikField
                                        name="compliance_software"
                                        component="select"
                                        label="Compliance Software"
                                        validate={validateNull}
                                        disabled={isSubmitting || !canEdit || !companyManagesCompliance}
                                        onChange={(e) => {
                                            handleChange(e)
                                        }}
                                    >
                                        <option value={null}></option>
                                        {
                                            complianceSoftwares ?
                                                complianceSoftwares.map(({ Text, Value }) => (
                                                    <option key={Value} value={Value}>
                                                        {Text}
                                                    </option>
                                                ))
                                                :
                                                null
                                        }
                                    </FormikField>

                                    <FormikField
                                        name="high_cai"
                                        type="checkbox"
                                        label="High CAI"
                                        disabled={isSubmitting || !canEdit || !companyManagesCompliance}
                                        onChange={(e) => {
                                            handleChange(e)
                                        }}
                                        checked={values.high_cai}
                                        className={"form-field-inline"}
                                        inlineError={true}
                                    />

                                    <FormikField
                                        name="post_90_day_monitoring"
                                        type="checkbox"
                                        label="Post 90 Day Monitoring"
                                        disabled={isSubmitting || !canEdit || !companyManagesCompliance}
                                        onChange={(e) => {
                                            handleChange(e)
                                        }}
                                        checked={values.post_90_day_monitoring}
                                        className={"form-field-inline"}
                                        inlineError={true}
                                    />

                                    <div className="submit-button-container">
                                        <button className="submit-button" type="submit" disabled={isSubmitting || !canEdit || !companyManagesCompliance}>Submit</button>
                                        {isSubmitting && <Spinner />}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                ) : (
                    <Spinner />
                )}
        </div>
    );
}

ComplianceData.propTypes = {
  patientId: PropTypes.string.isRequired,
  displaySpinner: PropTypes.bool.isRequired,
  companyManagesCompliance: PropTypes.bool,
  setIsCompliantForBanner: PropTypes.func.isRequired,
  setComplianceCoach: PropTypes.func.isRequired,
  userRole: PropTypes.string,
  complianceActive: PropTypes.bool
};

export default connect(state => ({
    userRole: selectors.getUserRole(state)
})
)(ComplianceData);
