import * as R from "ramda";
import slice from "lodash/slice";

/* eslint-disable-next-line */
const getProp = R.useWith(R.path, [R.split(".")]);
const applySort = sort =>
  R.sort(
    sort.by && sort.direction
      ? sort.direction === "asc"
        ? R.ascend(getProp(sort.by))
        : R.descend(getProp(sort.by))
      : undefined
  );

const applyFilters = filters => rows => {
  const filterFns = R.pipe(
    R.keys,
    R.filter(key => !R.isEmpty(R.prop(key, filters))),
    R.map(key =>
      R.filter(row => R.includes(R.prop(key, row), R.prop(key, filters)))
    )
  )(filters);
  return filterFns.length > 0 ? R.pipe(...filterFns)(rows) : rows;
};

const applyPagination = pagination => rows => {
  const { page, per } = pagination;
  const count = rows.length;
  if (!page || !per || per <= 0) return { page: rows, totalPages: 1, count };
  if (typeof page !== "number") {
    return { page: [], totalPages: 1, count };
  }
  const totalPages = Math.ceil(
    rows.length / (per <= rows.length ? per : rows.length)
  );
  if (page < 1 || page > totalPages) {
    return { page: [], totalPages: 1, count };
  }
  const begin = (page - 1) * per;
  const end = begin + per;
  return {
    totalPages,
    page: slice(rows, begin, end),
    count
  };
};

export const applyQuery = (rows, query) => {
  const sort = R.propOr({}, "sort", query);
  const filters = R.propOr({}, "filters", query);
  const pagination = R.propOr({}, "pagination", query);
  return R.pipe(
    applyFilters(filters),
    typeof sort !== "function" ? applySort(sort) : sort,
    applyPagination(pagination)
  )(rows);
};

const exists = v => v;
const isNotEmpty = v => !R.isEmpty(v);
export const hasFilter = (field, filters) =>
  R.pipe(R.prop(field), R.allPass([exists, isNotEmpty]))(filters);

export const serializeDateFilter = filterDateObj => {
  try {
    if (filterDateObj.mode && filterDateObj.date)
      return `${filterDateObj.mode}^${filterDateObj.date}${
        filterDateObj.date2 ? "^" + filterDateObj.date2 : ""
      }`;
    else return "";
  } catch (e) {
    return "";
  }
};
export const parseDateFilter = filterDateString => {
  try {
    const filterList = R.split("^", filterDateString);
    // // const filterObj = JSON.parse(filterDateString);
    // return filterDateString;
    if (filterList.length >= 2)
      return {
        mode: R.nth(0, filterList),
        date: R.nth(1, filterList),
        date2: R.nth(2, filterList)
      };
    else return {};
  } catch (e) {
    return {};
  }
};
