import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { formatDate } from "utils/misc";
import { RowSelectCell } from "hooks/useRowSelectNew";
import ClaimOrder from "components/order-status/claim-order";
import PendingInfo from "components/order-status/pending-info";
import MdPriorityHigh from "react-icons/lib/md/priority-high";
import { OrdersTableSettingsContext } from "./settings";

const OrderTableRow = ({
  id,
  invoice_number,
  claimed_by,
  status,
  patient_id,
  patient,
  patient_account_number,
  company,
  submitted,
  status_changed,
  last_updated,
  pending_delayed_date,
  sleep_coach,
  insurance,
  high_priority,
  followUpReason,
  reason,
  hold_count_total,
  hold_count_closed,
  hold_count_open,
  onHoldReason,
  pending_info,
  platform,
  sleep_coach_assignment,
  team_name,
  order_loading
}) => {
  const { isColumnVisible } = useContext(OrdersTableSettingsContext);
  function checkColumn(field) {
    return isColumnVisible({ field });
  }
  return (
    <tr>
      {checkColumn("all") && <RowSelectCell id={id} />}
      {checkColumn("invoice_number") && (
        <td>
          <Link to={`/orders/${id}`}>{invoice_number}</Link>
        </td>
      )}
      {checkColumn("patient") && (
        <td>
          <Link to={`/patients/${patient_id}`}>{patient}</Link>
        </td>
      )}
      {checkColumn("patient_account_number") && (
        <td>{patient_account_number}</td>
      )}
      {checkColumn("status") && <td>{status}</td>}
      {checkColumn("claimed_by") && (
        <td>
          <ClaimOrder claimed_by={claimed_by} id={id} />
        </td>
      )}
      {checkColumn("company") && <td>{company}</td>}
      {checkColumn("sleep_coach") && <td>{sleep_coach}</td>}
      {checkColumn("sleep_coach_assignment") && (
        <td>{sleep_coach_assignment}</td>
      )}
      {checkColumn("team_name") && <td>{team_name}</td>}
      {checkColumn("submitted") && <td>{formatDate(submitted)}</td>}
      {checkColumn("platform") && <td>{platform}</td>}
      {checkColumn("status_changed") && <td>{formatDate(status_changed)}</td>}
      {checkColumn("hold_count_open") && (
        <td>
          {hold_count_closed}/{hold_count_total}
          {hold_count_open > 1 && <MdPriorityHigh size="16px" color="red" />}
        </td>
      )}
      {checkColumn("last_updated") && <td>{formatDate(last_updated)}</td>}
      {checkColumn("pending_delayed_date") && (
        <td>{formatDate(pending_delayed_date)}</td>
      )}
      {checkColumn("insurance") && <td>{insurance}</td>}
      {checkColumn("high_priority") && <td>{high_priority ? "Yes" : "No"}</td>}
      {checkColumn("followUpReason") && <td>{followUpReason}</td>}
      {checkColumn("onHoldReason") && <td>{onHoldReason}</td>}
      {checkColumn("reason") && <td>{reason}</td>}
      {checkColumn("pending_info") && (
        <td>
          <PendingInfo
            orderId={id}
            value={pending_info}
            disabled={status === "rejected" || status === "voided"}
          />
        </td>
      )}
      {checkColumn("order_loading") && <td>{order_loading ? "Yes" : "No"}</td>}
    </tr>
  );
};

export default OrderTableRow;

OrderTableRow.propTypes = {
  id: PropTypes.string.isRequired,
  invoice_number: PropTypes.string,
  claimed_by: PropTypes.shape({
    value: PropTypes.string,
    key: PropTypes.string,
    pk: PropTypes.number
  }),
  status: PropTypes.string,
  patient_id: PropTypes.string,
  patient: PropTypes.string,
  patient_account_number: PropTypes.string,
  company: PropTypes.string,
  submitted: PropTypes.string,
  status_changed: PropTypes.string,
  last_updated: PropTypes.string,
  pending_delayed_date: PropTypes.string,
  sleep_coach: PropTypes.string,
  insurance: PropTypes.string,
  high_priority: PropTypes.bool,
  followUpReason: PropTypes.string,
  reason: PropTypes.string,
  hold_count_total: PropTypes.number,
  hold_count_closed: PropTypes.number,
  hold_count_open: PropTypes.number,
  onHoldReason: PropTypes.string,
  pending_info: PropTypes.string,
  platform: PropTypes.string,
  team_name: PropTypes.string,
  sleep_coach_assignment: PropTypes.string,
  order_loading: PropTypes.bool
};
