import React, { useState } from "react";
import PropTypes from "prop-types";
import { Rows, InlineWaiting } from "components/ui";
import { dissoc, assoc } from "ramda";
import IoMdTrash from "react-icons/lib/io/trash-b";

const FieldOptions = {
  owner: [
    { text: "S3", value: "S3" },
    { text: "DME", value: "DME" }
  ],
  status: [
    { text: "Created", value: "Created" },
    { text: "Pending", value: "Pending" },
    { text: "Completed", value: "Completed" }
  ]
};

const BulkUpdateTasks = ({ tasks, handleBulkUpdateTasks }) => {
  const [fields, setFields] = useState({});
  const [isFetching] = useState(false);
  return (
    <div style={{ padding: 10 }}>
      <h2>Bulk Update - {tasks.length} Tasks</h2>
      <Rows>
        {Object.keys(fields).map(field => (
          <div
            key={field}
            className="variable-field"
            style={{
              display: "flex",
              alignItems: "flex-start"
            }}
          >
            <div
              style={{
                flex: "1",
                marginRight: "2rem",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <div style={{ fontWeight: "bold" }}>{field}</div>
              <select
                type="checkbox"
                value={fields[field]}
                onChange={({ target }) => {
                  setFields(assoc(field, target.value));
                }}
                style={{ margin: "unset" }}
              >
                <option />
                {FieldOptions[field].map(({ text, value }) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </select>
            </div>
            <i
              onClick={() => setFields(dissoc(field))}
              title="Remove field"
              style={{
                fontSize: "2rem",
                cursor: "pointer"
              }}
            >
              <IoMdTrash />
            </i>
          </div>
        ))}
      </Rows>
      <div className="add-field-container" style={{ paddingTop: 15 }}>
        <select
          onChange={({ target }) => {
            setFields(assoc(target.value, false));
          }}
        >
          <option value="" className="choose-field">
            Choose Field
          </option>
          {!("status" in fields) && <option value="status">Status</option>}
          {!("owner" in fields) && <option value="owner">Owner</option>}
        </select>
      </div>
      {isFetching ? (
        <InlineWaiting />
      ) : (
        <button
          disabled={Object.keys(fields).length <= 0}
          onClick={async () => {
            handleBulkUpdateTasks({
              task_ids: tasks.map(x => x.id),
              ...fields
            });
          }}
        >
          Submit
        </button>
      )}
    </div>
  );
};

BulkUpdateTasks.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object),
  handleBulkUpdateTasks: PropTypes.func.isRequired
};

export default BulkUpdateTasks;
