import React from "react";
import { MdError } from "react-icons/lib/md";

export default function ModalAskForCompliantMet({ isCompliant, closeModal, updateCompliantMet, hasMinimumTherapyDays, intentionToSetAsCompliant }) {

    const addCompliantDataMessage = (
        <>
            Once you check Is Compliant yes or no, the unanswered forms will be unassigned and only administrators will be able to modify the following data.<br />
            <span style={{ color: 'blue' }}>Compliance date Met, Compliance Percentage and Is Compliant.</span><br />
            Are you sure you want to continue?
        </>
    )

    const RemoveCompliantDataMessage = `Are you sure you want to change the patient back to In Progress?`;
    const InsufficientDaysFromSetupDate = "To be Compliant, a patient has to be using therapy within compliance for a minimum of 21 days"

    return (
        <div style={{margin: "15px", padding: "10px"}}>
            <span style={{ fontSize: '20px', whiteSpace: 'pre-line'  }}> <MdError size="3rem" style={{ color: 'orange' }} />
                {(!hasMinimumTherapyDays && intentionToSetAsCompliant) ? InsufficientDaysFromSetupDate : isCompliant ? RemoveCompliantDataMessage : addCompliantDataMessage  }
            </span>

            <div className='dd_col-12' style={{alignItems: "center"}}>
                <div className='dd_col-6'>
                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        <button type="button" className="button alert" onClick={() => { closeModal(); }}>{hasMinimumTherapyDays ? " No " : " Close "}</button>
                    </div>
                </div>
                {
                    !hasMinimumTherapyDays && intentionToSetAsCompliant ?
                       null
                    :
                    <div className='dd_col-6'>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <button type="button" className="button" onClick={() => { closeModal(); updateCompliantMet() }}> Yes </button>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}