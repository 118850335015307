import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectors } from "reducers";
import { StatBlock } from "components/ui";
import { getCompanyOrdersReport, exportCompanyInsights } from "actions/company";
import {
  OrderBreakdown,
  StatusBreakdown,
  OrdersBySleepCoach,
  OrdersByContactType,
  OrdersByOutreachCycle
} from "../charts";
import DateRange from "components/ui/date-range";
import DateRangeContext from "../date-range-context";
import OrderStatusesChangeHeatMap from "components/ui/statistics/order-status-change-heat-map";

const CompanyOrders = ({
  isFetchingOrderReport,
  orderReport,
  params,
  orderStatusCounts,
  ordersByFormOfContact,
  ordersCreatedByCycle,
  exportCompanyInsights
}) => {
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    dateRangeIsValid,
    handleSubmit
  } = React.useContext(DateRangeContext);

  const onExport = (exportType) => {
    const payload = {
      companyId: params.id,
      startDate: startDate,
      endDate: endDate,
      orders: {
        [exportType]: true,
      },
    }

    exportCompanyInsights(payload);
  }

  return (
    <div className="company-order-report-container">
      <div className="company-order-report-date-range">
        <DateRange
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleSubmit={handleSubmit}
          isValid={dateRangeIsValid}
          isFetching={isFetchingOrderReport}
        />
      </div>
      <div className="orderSumRow">
        <StatBlock title="Total Orders" value={orderStatusCounts.TOTAL || 0} />
        <StatBlock title="Completed" value={orderStatusCounts.COMPLETED || 0} />
        <StatBlock title="Rejected" value={orderStatusCounts.REJECTED || 0} />
        <StatBlock
          title="Walk In Orders"
          value={orderStatusCounts.WALKINS || 0}
        />
        <StatBlock
          title="Estimated Revenue"
          value={`$${orderReport.revenue}`}
        />
      </div>
      <div className="pad-bottom">
        <OrderBreakdown data={orderReport.equipment_breakdown} onExport={() => onExport("itemTypesRequested")} />
      </div>

      <div className="row2 pad-bottom">
        <StatusBreakdown stats={orderReport.status_breakdown} onExport={() => onExport("orderStats")} />
        <OrdersBySleepCoach stats={orderReport.sleep_coach_numbers} onExport={() => onExport("ordersBySleepCoach")} />
      </div>
      <div className="pad-bottom">
        <OrdersByContactType data={ordersByFormOfContact} onExport={() => onExport("ordersByContactType")} />
      </div>
      <div className="pad-bottom">
        <OrdersByOutreachCycle data={ordersCreatedByCycle} onExport={() => onExport("ordersByOutreachCycle")} />
      </div>
      <div className="pad-bottom">
        <OrderStatusesChangeHeatMap
          id={params.id}
          dates={{ startDate, endDate }}
          entity_type="CompanyOrderStatusChangeCounts"
        />
      </div>
    </div>
  );
};

export default connect(
  state => ({
    isFetchingOrderReport: selectors.getIsFetchingCompanyOrderReport(state),
    orderReport: selectors.getCompanyOrderReport(state),
    orderStatusCounts: selectors.getCompanyOrderReportStatusCounts(state),
    ordersByFormOfContact: selectors.getCompanyOrdersByFormOfContactReport(
      state
    ),
    ordersCreatedByCycle: selectors.getCompanyOrderCycleReport(state)
  }),
  {
    getCompanyOrdersReport,
    exportCompanyInsights
  }
)(CompanyOrders);

CompanyOrders.propTypes = {
  getCompanyOrdersReport: PropTypes.func.isRequired,
  isFetchingOrderReport: PropTypes.bool,
  orderReport: PropTypes.object.isRequired,
  orderStatusCounts: PropTypes.object.isRequired,
  ordersByFormOfContact: PropTypes.arrayOf(PropTypes.object).isRequired,
  params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  ordersCreatedByCycle: PropTypes.arrayOf(PropTypes.object).isRequired,
  exportCompanyInsights: PropTypes.func.isRequired
};
