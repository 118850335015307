import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { fetchInventory, invalidateInventory } from "actions/inventory";
import { selectors } from "reducers";
import { PageHeader, Waiting, ListItem } from "components/ui";
import HasRolePermission from "components/shared/has-role-permission";
import InventoryInfoTabs from "./inventory-info-tabs";
import InventoryPhotoEditor from "./inventory-photo-editor";
import PureModal from "components/ui/modal/pure-modal";
import { getProductVideos, getProductPhotos } from "actions/product-media";
import { getRouteParam } from "routes";

const InventoryProfile = ({
  inventoryItem,
  media,
  isFetchingInventory,
  history,
  getProductVideos,
  getProductPhotos
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initialize = async () => {
      try {
        await getProductVideos(inventoryItem.id);
        await getProductPhotos(inventoryItem.id);
      } finally {
        setIsLoading(false);
      }
    };

    if (!isFetchingInventory) initialize();
  }, [inventoryItem, isFetchingInventory]);

  return !isFetchingInventory ? (
    <div className="inventory-profile">
      <PageHeader title={inventoryItem.name}>
        <HasRolePermission allowedRoles={["Administrator"]}>
          <Link
            to={`/inventory/${inventoryItem.id}/edit`}
            className="button small"
          >
            Edit
          </Link>
        </HasRolePermission>
        <button className="button small" onClick={history.goBack}>
          Go Back
        </button>
      </PageHeader>
      <div className="inventory-item-details">
        <PureModal
          renderTrigger={({ openModal }) => (
            <div
              className="inventory-photo-container"
              onClick={openModal}
              title="Change the inventory photo"
            >
              <div className="photo-add-indicator">+</div>
              {inventoryItem.photo_url ? (
                <img
                  className="inventory-photo"
                  src={inventoryItem.photo_url}
                  alt="Inventory Photo"
                ></img>
              ) : (
                <div className="inventory-photo photo-not-found">
                  <div className="photo-not-found-text">No Photo</div>
                </div>
              )}
            </div>
          )}
          renderContent={({ closeModal }) => (
            <InventoryPhotoEditor
              inventoryItem={inventoryItem}
              onFinished={() => closeModal()}
            />
          )}
        />

        <div className="inventory-item-list">
          <ListItem label="Manufacturer" value={inventoryItem.manufacturer} />
          <ListItem label="HCPCS" value={inventoryItem.hcpcs} />
          <ListItem label="Description" value={inventoryItem.description} />
          <ListItem
            label="Avg Reimbursement"
            value={`$${inventoryItem.avgReimbursment}`}
          />
        </div>
        <div className="inventory-item-list">
          <ListItem label="Product Id" value={inventoryItem.product_id} />
          <ListItem label="Style" value={inventoryItem.product_style} />
          <ListItem label="Type" value={inventoryItem.product_type} />
          <ListItem label="Active" value={`${inventoryItem.productActive}`} />
          <ListItem label="Quantity" value={`${inventoryItem.quantity}`} />
        </div>
      </div>
      <InventoryInfoTabs
        media={media}
        isLoading={isLoading}
        inventoryItem={inventoryItem}
      />
    </div>
  ) : (
    <Waiting />
  );
};
export default withRouter(
  connect(
    (state, props) => ({
      inventoryItem: selectors.getInventoryItem(
        state,
        getRouteParam(props, "id")
      ),
      isFetchingInventory: selectors.isFetchingInventory(state),
      media: state.getIn(["media"])
    }),
    {
      fetchInventory,
      invalidateInventory,
      getProductVideos,
      getProductPhotos
    }
  )(InventoryProfile)
);

InventoryProfile.propTypes = {
  inventoryItem: PropTypes.object.isRequired,
  isFetchingInventory: PropTypes.bool,
  history: PropTypes.object.isRequired,
  getProductVideos: PropTypes.func.isRequired,
  getProductPhotos: PropTypes.func.isRequired,
  media: PropTypes.object
};
