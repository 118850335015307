import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { selectors } from "reducers";
import { InlineWaiting, IconButton } from "components/ui";
import { IoAndroidRefresh } from "react-icons/lib/io";
import { MdUnfoldMore, MdUnfoldLess } from "react-icons/lib/md";
import {
  fetchPatientOrderHistory,
  invalidatePatientOrderHistory
} from "actions/patient";
import HcpcRow from "./hcpc-row";

const HcpcHistory = ({ patientId }) => {
  const dispatch = useDispatch();
  const orderHistory = useSelector(state =>
    selectors.getPatientOrderHistory(state, patientId)
  );

  const isFetching = useSelector(state =>
    state.getIn(
      ["fetch", fetchPatientOrderHistory.baseType, patientId, "isFetching"],
      false
    )
  );

  const [expandedRows, setExpandedRows] = React.useState({});

  const [editRow, setEditRow] = React.useState(null);

  const toggleExpanded = productType => {
    const current = expandedRows[productType] ?? false;
    setExpandedRows({ ...expandedRows, [productType]: !current });
    setEditRow(null);
  };

  const allExpanded =
    !Object.values(expandedRows).some(x => x === false) ||
    Object.values(expandedRows).length === 0;

  React.useEffect(() => {
    setEditRow(null);
  }, [expandedRows, patientId]);

  return (
    <div className="patient-hcpc-history-tab">
      {isFetching ? (
        <div
          style={{
            minHeight: 300,
            display: "flex",
            flex: 1,
            alignContent: "center",
            justifyContent: "center"
          }}
        >
          <InlineWaiting />
        </div>
      ) : (
        <div>
          <div className="eligibility-table-header">
            <div>Product Type</div>
            <div>Eligibility Date</div>
            <div>Last Ordered Date</div>
            <div>Last Ordered Item</div>
            <div>Last Ordered Item ID</div>
            <div>Insurance Rule(days|qty|offset)</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                justifyContent: "space-between"
              }}
            >
              <IconButton
                icon={
                  allExpanded ? (
                    <MdUnfoldLess size={18} />
                  ) : (
                    <MdUnfoldMore size={18} />
                  )
                }
                onClick={() => {
                  setEditRow(null);
                  if (allExpanded) {
                    setExpandedRows(
                      orderHistory.reduce(
                        (acc, x) => ({ ...acc, [x.productType]: false }),
                        {}
                      )
                    );
                  } else {
                    setExpandedRows(
                      orderHistory.reduce(
                        (acc, x) => ({ ...acc, [x.productType]: true }),
                        {}
                      )
                    );
                  }
                }}
              />
              <IconButton
                icon={<IoAndroidRefresh size={18} />}
                disabled={isFetching}
                onClick={() => {
                  dispatch(invalidatePatientOrderHistory({ patientId }));
                  dispatch(fetchPatientOrderHistory({ patientId }));
                }}
              />
            </div>
          </div>
          {orderHistory
            ?.filter(x => x.productType !== "Mask with Headgear")
            .map(x => (
              <HcpcRow
                key={x.productType}
                eligiblity={x}
                toggleExpanded={() => toggleExpanded(x.productType)}
                expanded={expandedRows[x.productType]}
                editRow={editRow}
                setEditRow={setEditRow}
                patientId={patientId}
              />
            ))}
        </div>
      )}
    </div>
  );
};

HcpcHistory.propTypes = {
  patientId: PropTypes.string.isRequired
};

export default HcpcHistory;
