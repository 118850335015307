import React from "react";
import { Tooltip } from "components/ui/tool-tip/tooltip";
import { IoClipboard } from "react-icons/lib/io";

export default function AssignFormToPatients({ openModal }) {

    return (
        <div>
            <Tooltip
                id={`single-stat-tooltip-b`}
                className="single-stat-tooltip"
                effect="solid"
                message={'Assign Form'}
                >
                <IoClipboard style={{ color: "#007bff", fontSize: '1.5em', cursor: 'pointer' }} onClick={openModal} />
            </Tooltip>
        </div>
    )
}