import * as R from "ramda";
import { createSelector } from "reselect";

// entity selectors
const getEquipmentEntities = createSelector(
  [state => state.getIn(["entities", "equipment"])],
  equipment => (equipment ? equipment.toJS() : {})
);
const getHcpcsEntities = createSelector(
  [state => state.getIn(["entities", "hcpcsRecords"])],
  records => (records ? records.toJS() : {})
);

const getOrderHistoryEntities = createSelector(
  [state => state.getIn(["entities", "eligiblity"])],
  records => (records ? records.toJS() : {})
);

const getXZEROEntities = createSelector(
  [state => state.getIn(["entities", "xZeros"])],
  records => (records ? records.toJS() : {})
);

const getAccessLogsEntitites = createSelector(
  [state => state.getIn(["entities", "accessLogs"])],
  accessLogs => (accessLogs ? accessLogs.toJS() : {})
);

const getChangeLogsEntitites = createSelector(
  [state => state.getIn(["entities", "changeLogs"])],
  changeLogs => (changeLogs ? changeLogs.toJS() : {})
);

const getPatientEntities = createSelector(
  [state => state.getIn(["entities", "patients"])],
  patients => (patients ? patients.toJS() : {})
);
const getContactRecordEntities = createSelector(
  [state => state.getIn(["entities", "contactRecords"])],
  records => (records ? records.toJS() : {})
);
const getWalkinOrderEntities = createSelector(
  [state => state.getIn(["entities", "walkInOrders"])],
  walkInOrders => (walkInOrders ? walkInOrders.toJS() : {})
);
const getOrderEntities = createSelector(
  [state => state.getIn(["entities", "orders"])],
  orders => (orders ? orders.toJS() : {})
);

const getPatientProfile = createSelector(
  [getPatientEntities, (_, id) => id],
  (patients, patientId) => R.propOr({}, patientId, patients)
);
// patient selectors
const getPatientNotes = createSelector(
  [state => state.getIn(["entities", "patientNotes"]), (_, id) => id],
  (allNotes, patientId) =>
    R.pipe(
      R.propOr([], patientId),
      R.sortBy(R.prop("sort_index"))
    )(allNotes.toJS())
);

const getPatientsXZEROHistory = createSelector(
  [getXZEROEntities, (_, patientId) => patientId],
  (records, patientId) =>
    R.pipe(
      R.propOr([], patientId),
      R.sortWith([R.ascend(R.prop("priority")), R.descend(R.prop("id"))])
    )(records)
);

const getPatientsHcpcsHistory = createSelector(
  [getHcpcsEntities, (_, patientId) => patientId],
  (records, patientId) =>
    R.pipe(
      R.propOr([], patientId),
      R.sortWith([R.ascend(R.prop("priority")), R.descend(R.prop("id"))])
    )(records)
);

const getPatientOrderHistory = createSelector(
  [getOrderHistoryEntities, (_, patientId) => patientId],
  (records, patientId) =>
    R.pipe(
      R.propOr([], patientId)
      // R.sortWith([R.ascend(R.prop("priority")), R.descend(R.prop("id"))])
    )(records)
);
const getPatientEligibleEquipment = createSelector(
  [getPatientsHcpcsHistory],
  R.pipe(
    R.filter(equip => equip.eligible && equip.productType),
    R.map(item => item.typeLowerCase)
  )
);

const getPatientsEquipment = createSelector(
  [getEquipmentEntities, (_, patientId) => patientId],
  (equipment, patientId) =>
    R.pipe(
      R.values,
      R.filter(eq => eq.patientID === patientId),
      R.sortWith([R.ascend(R.prop("priority")), R.descend(R.prop("id"))])
    )(equipment)
);

const getPatientAccessLogs = createSelector(
  [getAccessLogsEntitites, (_, patientId) => patientId],
  (accessLogs, patientId) =>
    R.pipe(
      R.propOr([], patientId),
      R.sortWith([R.descend(R.prop("accessedDate"))])
    )(accessLogs)
);

const getPatientChangeLogs = createSelector(
  [getChangeLogsEntitites, (_, patientId) => patientId],
  (changeLogs, patientId) =>
    R.pipe(
      R.propOr([], patientId),
      R.sortWith([R.descend(R.prop("changedDate"))])
    )(changeLogs)
);

const getPatientContactRecords = createSelector(
  [getContactRecordEntities, (_, patientId) => patientId],
  (contactRecords, patientId) =>
    R.pipe(
      R.propOr([], patientId),
      R.sortWith([R.descend(R.prop("created_at"))])
    )(contactRecords)
);

const getPatientWalkinOrders = createSelector(
  [getWalkinOrderEntities, (_, patientId) => patientId],
  (walkInOrders, patientId) =>
    R.pipe(
      R.propOr([], patientId),
      R.sortWith([R.descend(R.prop("order_date"))])
    )(walkInOrders)
);

const getPatientOrders = createSelector(
  [getOrderEntities, (_, patientId) => patientId],
  (orders, patientId) =>
    R.pipe(
      R.values,
      R.filter(p => p.patientID == patientId),
      R.sortWith([R.descend(R.prop("created_at"))])
    )(orders)
);

const getPatientInfo = createSelector(
  [getPatientEntities, (_, patientId) => patientId],
  (patients, patientId) => R.propOr({}, patientId, patients)
);

const getPatientSpecialInstructions = createSelector(
  [getPatientInfo],
  R.propOr([], "special_instructions")
);
const getPatientMaskFittingResults = createSelector(
  [
    state => state.getIn(["entities", "maskFittingResults"]).toJS(),
    (_, patientId) => patientId
  ],
  (maskFittingResults, patientId) =>
    R.pipe(
      R.propOr([], patientId),
      R.sortWith([R.descend(R.prop("date_submitted"))])
    )(maskFittingResults)
);

const getPatientPhoneNumbers = createSelector(
  [getPatientInfo],
  R.pipe(
    R.pick(["phone_number", "mobile_number"]),
    R.values,
    R.filter(v => v !== "0000000000" && !R.isEmpty(v)),
    R.uniq
  )
);

const getPatientPhoneNumbersToCall = createSelector(
  [getPatientInfo],
  patientInfo => {
    const outreachCycle = R.propOr(1, "outreach_cycle_count", patientInfo);
    const phoneNumbers = R.pipe(
      R.pick(["phone_number", "mobile_number"]),
      R.values,
      R.filter(v => v !== "0000000000" && v),
      R.uniq
    )(patientInfo);
    if (phoneNumbers.length > 1)
      return outreachCycle % 2 === 1 ? [phoneNumbers[0]] : [phoneNumbers[1]];
    return phoneNumbers;
  }
);

export const selectors = {
  getPatientsEquipment,
  getPatientAccessLogs,
  getPatientChangeLogs,
  getPatientsHcpcsHistory,
  getPatientOrderHistory,
  getPatientsXZEROHistory,
  getPatientNotes,
  getPatientPhoneNumbers,
  getPatientContactRecords,
  getPatientWalkinOrders,
  getPatientOrders,
  getPatientInfo,
  getPatientSpecialInstructions,
  getPatientProfile,
  getPatientMaskFittingResults,
  getPatientEligibleEquipment,
  getPatientPhoneNumbersToCall
};
