import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import FormikField from "components/forms/formik-field";
import { selectors } from "reducers";
import { InlineWaiting } from "components/ui";
import { PatientCompanyActiveEquipmentContext } from "./index.js";

// Product List Select
// filtered with prop
const ProductSelect = React.memo(({ filter }) => {
  const { active_products, isFetchingCompanyActiveEq } = React.useContext(
    PatientCompanyActiveEquipmentContext
  );
  const filteredProducts = useSelector(state =>
    selectors.getFilteredInventoryItems(state, {
      filter: { ...filter, active_products }
    })
  );

  return isFetchingCompanyActiveEq ? (
    <InlineWaiting />
  ) : (
    <FormikField
      name="id"
      label="Product *"
      component="select"
      disabled={filteredProducts <= 0}
    >
      <option />
      {filteredProducts.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </FormikField>
  );
});

ProductSelect.propTypes = {
  filter: PropTypes.shape({
    compatibleEquipment: PropTypes.bool,
    compatible_equip: PropTypes.arrayOf(PropTypes.string),
    product_type: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ]),
    manufacturer_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string)
    ])
  }),
  patientId: PropTypes.string.isRequired
};

export default ProductSelect;
