import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createPatientNote, fetchPatientNotes } from "actions/patient";
import { containsNoCreditCardInfo } from "utils/redux-form";

const NewNote = ({ patientId, dispatch }) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState();
  const handleChange = ({ target }) => {
    setValue(target.value);
    setError(containsNoCreditCardInfo(target.value));
  };
  return (
    <form
      className="new-note"
      onSubmit={e => {
        e.preventDefault();
        if (!value.trim()) {
          return;
        }
        dispatch(
          createPatientNote({
            patientId,
            noteContent: value
          })
        ).then(() => dispatch(fetchPatientNotes({ patientId })));
        setValue("");
      }}
    >
      <label>
        <span>New note</span>
        <span className="note-validation-error">{error}</span>
        <textarea value={value} onChange={handleChange} />
      </label>
      <button type="submit" disabled={error}>
        Submit
      </button>
    </form>
  );
};

NewNote.propTypes = {
  patientId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect()(NewNote);
