import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getFormOptionsSleepCoachWithUserFirst, selectors } from "reducers";
import { PageHeader } from "components/ui";
import useApiQuery, { Table } from "hooks/useQuery/api-query";
import useRowSelect, {
  SelectRowContext,
  RowSelectHeaderCell
} from "hooks/useRowSelectNew";
import VoicemailTableRow from "./table-row";
import VoicemailTableControls from "./table-controls";
import "./style.scss";

const VoicemailTable = ({ sleepCoaches, teams }) => {
  const initialQuery = {
    sort: { by: "vmID", direction: "desc" },
    filters: { flags: ["d"] },
    pagination: { page: 1, per: 15 }
  };
  const { query, queryDispatcher, rows, data, isFetching, fetchData } =
    useApiQuery("voicemails", initialQuery, "voicemails");
  const {
    toggleAllRows,
    isRowSelected,
    toggleRow,
    clearSelectedRows,
    selectedRows
  } = useRowSelect(rows, "id", query, row => ({
    vmID: row.vmID
  }));
  return (
    <div className="voicemails-table-page">
      <PageHeader title="Voicemails" />
      <SelectRowContext.Provider
        value={{
          toggleAllRows,
          toggleRow,
          selectedRows,
          isRowSelected,
          clearSelectedRows
        }}
      >
        <Table
          query={query}
          data={data}
          queryDispatcher={queryDispatcher}
          isFetching={isFetching}
          containerClass="query-table voicemail-table dynamic-columns"
          columns={[
            {
              header: "all",
              field: "all",
              Cell: RowSelectHeaderCell
            },
            {
              header: "Audio",
              field: "audio"
            },
            {
              header: "Flags",
              field: "flags",
              filters: [
                {
                  text: "Listened",
                  value: "a"
                },
                {
                  text: "Unlistened",
                  value: "b"
                },
                {
                  text: "Worked",
                  value: "c"
                },
                {
                  text: "Not Worked",
                  value: "d"
                },
                {
                  text: "Assigned",
                  value: "e"
                },
                {
                  text: "Not Assigned",
                  value: "f"
                }
              ]
            },
            {
              header: "ID",
              field: "id"
            },
            {
              header: "Date",
              field: "date"
            },
            {
              header: "Team",
              field: "team",
              filterKey: "calledCompanyTeam",
              filterDownSelect: true,
              filters: teams
            },
            {
              header: "To",
              field: "to"
            },
            {
              header: "From",
              field: "from"
            },
            {
              header: "Assignee",
              field: "sleepCoachAssigned",
              fliterKey: "sleepCoachAssigned",
              filters: sleepCoaches,
              filterDownSelect: true
            },
            {
              header: "Worker",
              field: "worker",
              filterKey: "worked_by",
              filters: sleepCoaches,
              filterDownSelect: true
            }
          ]}
          rows={rows}
          TableRow={VoicemailTableRow}
          rowKey="id"
          perPageOptions={[
            { text: "15", value: 15 },
            { text: "50", value: 50 },
            { text: "150", value: 150 }
          ]}
          Controls={() => (
            <VoicemailTableControls
              query={query}
              selectedRows={selectedRows}
              clearSelectedRows={clearSelectedRows}
              isFetching={isFetching}
              refreshData={() => {
                clearSelectedRows();
                fetchData(query);
              }}
            />
          )}
        ></Table>
      </SelectRowContext.Provider>
    </div>
  );
};

export default connect(state => ({
  sleepCoaches: getFormOptionsSleepCoachWithUserFirst(state),
  teams: selectors.getTeamNameOptions(state)
}))(VoicemailTable);

VoicemailTable.propTypes = {
  sleepCoaches: PropTypes.arrayOf(PropTypes.object).isRequired,
  teams: PropTypes.arrayOf(PropTypes.object).isRequired
};
