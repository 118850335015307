import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "lodash/fp/compose";
import Table from "components/ui/impl/table";
import { selectors } from "reducers";
import { mapDispatchToProps } from "actions/order-profile";
import Header from "./header";
import IoMdClose from "react-icons/lib/io/close";
import VerifyOrderProductCompatibility from "./verify-order-product-compatibility";
import compact from "lodash/compact";
import ModalLink from "components/shared/modal-link";

const QuantityInput = ({
  line_item_id,
  disabled,
  quantity,
  requestUpdateLineItemById
}) => {
  const [value, setValue] = React.useState(quantity || 0);
  React.useEffect(() => {
    setValue(quantity);
  }, [quantity]);

  const handleChange = ({ target: { value } }) => {
    if (!value || value > 0) setValue(value);
  };

  const handleBlur = () => {
    if (value !== quantity) {
      if (value > 9) {
        if (
          window.confirm(
            `Are you sure you want to set quantity to ${value} for this item?`
          )
        )
          requestUpdateLineItemById({
            line_item_id,
            quantity: value
          });
        else {
          setValue(quantity);
        }
      } else
        requestUpdateLineItemById({
          line_item_id,
          quantity: value
        });
    }
  };

  return (
    <input
      className="quantity-input"
      type="number"
      min="1"
      disabled={disabled}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

QuantityInput.propTypes = {
  line_item_id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  quantity: PropTypes.number,
  requestUpdateLineItemById: PropTypes.func.isRequired
};

const ZeroBilledInput = ({
  value,
  line_item_id,
  requestUpdateLineItemById,
  disabled
}) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1
    }}
  >
    <input
      type="checkbox"
      checked={!!value}
      disabled={disabled}
      onChange={async ({ target }) => {
        await requestUpdateLineItemById({
          line_item_id,
          zeroChargeItem: target.checked
        });
      }}
    />
  </div>
);

ZeroBilledInput.propTypes = {
  line_item_id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  orderId: PropTypes.string.isRequired,
  requestUpdateLineItemById: PropTypes.func.isRequired,
  value: PropTypes.bool
};

// NOTE MedSouth wanted this sort order, backend sorts eq by hcpc by default and should keep this behavior for other companies
//      not really ideal and should probably let bakcned handle all sorting and rethink if this is actually necessary
export const sortOrderLineItemsByType = lineItems => {
  const eqOrder = [
    "Disposable Filter",
    "Nondisposable Filter",
    "Filter",
    "Tubing",
    "Water Chamber",
    "Seals",
    "Mask",
    "Mask with Headgear",
    "Headgear",
    "Chinstrap",
    "Machine",
    "Misc"
  ];
  return lineItems.sort(function (a, b) {
    return eqOrder.indexOf(a.type) - eqOrder.indexOf(b.type);
  });
};

const EquipmentRequested = ({
  isFetching,
  isSubmitting,
  columns,
  uses_bonafide_api,
  rows,
  requestedEquipment
}) => (
  <div>
    <Header showSpinner={isFetching || isSubmitting}>
      Equipment Requested:{" "}
      {requestedEquipment.map(({ name, hasLineItem }) => (
        <span
          className={`equipment-label ${hasLineItem ? "hasLineItem" : ""}`}
          key={name}
        >
          {name}
        </span>
      ))}
    </Header>
    <Table
      isLoading={isFetching || isSubmitting}
      columns={columns}
      rows={uses_bonafide_api ? sortOrderLineItemsByType(rows) : rows}
      rowKey="line_item_id"
    />
  </div>
);

EquipmentRequested.propTypes = {
  orderId: PropTypes.string.isRequired
};

const mapStateToProps = (state, props) => {
  const { data: rows, isFetching } = selectors.getOrderLineItems(state, props);
  const { data: companyInfo } = selectors.getOrderCompanyInfo(state, props);
  const { data: patientCurrentEquipment } =
    selectors.getOrderPatientEquipmentInfo(state, props);
  const isBonafideOrderConfirmed = selectors.getIsBonafideResultConfirmed(
    state,
    props
  );
  return {
    isFetching,
    companyInfo,
    uses_bonafide_api: companyInfo?.insurance_verification_type === "Bonafide",
    isSubmitting:
      selectors.getIsSubmittingLineItemRemoval(state, props) ||
      selectors.getIsSubmittingNewLineItem(state, props),
    rows,
    patientCurrentEquipment,
    canRemove:
      selectors.getCanRemoveEquipmentRequested(state, props) &&
      !isBonafideOrderConfirmed,
    canChangeQuantities:
      selectors.getCanChangeQuantities(state, props) &&
      !isBonafideOrderConfirmed,
    requestedEquipment: selectors
      .getOrderDysfunctionEquipment(state, props)
      .map(name => ({
        name,
        hasLineItem: rows.some(({ type = "" }) =>
          type.replace(" ", "").toLowerCase().includes(name.toLowerCase())
        )
      }))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    (
      {
        canRemove,
        canChangeQuantities,
        isSubmitting,
        isFetching,
        patientCurrentEquipment,
        uses_bonafide_api,
        ...passThroughStateProps
      },
      { requestLineItemRemoval, requestLineItems, requestUpdateLineItemById },
      ownProps
    ) => ({
      ...ownProps,
      isSubmitting,
      uses_bonafide_api,
      isFetching,
      ...passThroughStateProps,
      columns: compact([
        /* eslint-disable react/prop-types */
        ...(canRemove
          ? [
              {
                header: "",
                field: "line_item_id",
                format: ({ line_item_id }) => (
                  <i
                    onClick={() => requestLineItemRemoval(line_item_id)}
                    className="remove-equipment-x"
                  >
                    <IoMdClose />
                  </i>
                )
              }
            ]
          : []),
        {
          header: "",
          field: "inventoryGUID",
          format: ({ inventoryGUID, back_ordered }) => {
            const { rows } = passThroughStateProps;
            return (
              <VerifyOrderProductCompatibility
                orderEquipment={rows
                  .map(({ inventoryGUID }) => inventoryGUID)
                  .filter(lineItemGuid => lineItemGuid !== inventoryGUID)}
                back_ordered={back_ordered}
                equipmentId={inventoryGUID}
                patientEquipment={patientCurrentEquipment.map(
                  ({ item_id }) => item_id
                )}
              />
            );
          }
        },
        {
          header: "Type",
          field: "type"
        },
        {
          header: "Manufacturer ID",
          field: "id"
        },
        {
          header: "Company ID",
          field: "company_product_id",
          format: ({ company_product_id, id }) => {
            return company_product_id ?? id;
          }
        },
        {
          header: "Manufacturer",
          field: "manufacturer"
        },
        {
          header: "HCPCS",
          field: "HCPCS"
        },
        {
          header: "Product",
          field: "Product",
          format: ({ Product, company_inventory_guid, company_id }) => (
            <ModalLink
              to={`/companies/${company_id}/profile/inventory/${company_inventory_guid}`}
            >
              {Product}
            </ModalLink>
          )
        },
        {
          header: "Qty",
          field: "quantity",
          format:
            canChangeQuantities &&
            (({ quantity, line_item_id }) => {
              return (
                <QuantityInput
                  disabled={isSubmitting || isFetching}
                  orderId={ownProps.orderId}
                  line_item_id={line_item_id}
                  quantity={quantity}
                  requestUpdateLineItemById={requestUpdateLineItemById}
                />
              );
            })
        },
        {
          header: "Zero Billed",
          field: "zeroChargeItem",
          format: ({ zeroChargeItem, line_item_id }) => (
            <ZeroBilledInput
              disabled={isSubmitting || isFetching || !canChangeQuantities}
              orderId={ownProps.orderId}
              line_item_id={line_item_id}
              value={zeroChargeItem}
              requestUpdateLineItemById={requestUpdateLineItemById}
            />
          )
        }
      ]),
      requestLineItems
    })
  )
)(EquipmentRequested);

EquipmentRequested.propTypes = {
  isFetching: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  requestedEquipment: PropTypes.arrayOf(PropTypes.object),
  patientCurrentEquipment: PropTypes.arrayOf(PropTypes.object),
  uses_bonafide_api: PropTypes.bool
};
