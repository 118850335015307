import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { formatDate } from "utils/misc";
import { RowSelectCell } from "hooks/useRowSelectNew";

const AssignablePatientsTableRow = ({
  PatientId,
  Name,
  AccountNumber,
  ContactType,
  Insurance,
  Doctor,
  ComplianceCoach,
  CompanyName,
  NextOutreachDate,
  OutreachCycle,
  CreatedAt
}) => {

  return (
    <tr>
      <RowSelectCell id={PatientId} />
      <td><Link to={`/patients/${PatientId}`}>{Name}</Link></td>
      <td>{AccountNumber}</td>
      <td>{ContactType}</td>
      <td>{Insurance}</td>
      <td>{Doctor}</td>
      <td>{ComplianceCoach}</td>
      <td>{CompanyName}</td>
      <td>{formatDate(NextOutreachDate)}</td>
      <td>{OutreachCycle}</td>
      <td>{formatDate(CreatedAt)}</td>
    </tr>
  );
};

export default AssignablePatientsTableRow;

AssignablePatientsTableRow.propTypes = {
  PatientId: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  AccountNumber: PropTypes.string.isRequired,
  ContactType: PropTypes.string,
  Insurance: PropTypes.string,
  Doctor: PropTypes.string,
  ComplianceCoach: PropTypes.string,
  CompanyName: PropTypes.string.isRequired,
  NextOutreachDate: PropTypes.date,
  OutreachCycle: PropTypes.number,
  CreatedAt: PropTypes.date
};